import React from "react";
import { Header } from "components";

export default function HeaderRow() {
    return (
        <Header>
            <div className="flex-1 text-right">Job number</div>
            <div className="flex-1 text-right">Customer name</div>
            <div className="flex-1 text-right">Item value</div>
            <div className="flex-1 text-right">Location</div>
            <div className="flex-1 text-center">Promise date</div>
            <div className="w-10" />
        </Header>
    );
}
