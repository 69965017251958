import React, { useState, useEffect } from "react"
import EntitySection from "./EntitySection"
import AddGroupModal from "./AddGroupModal"
import Presets from "./Presets";
import { Drawer, RenderIf } from "components"
import { Button, Tooltip, IconButton } from "@material-tailwind/react";
import { useQuery, useLazyQuery, useMutation, useApolloClient } from "@apollo/client"
import { GET_REPAIR_CODES } from "apollo/queries/getRepairCodes"
import { GET_USER_DATA } from "apollo/queries/getUserData"
import { UPDATE_USER_REPAIR_CODES } from "apollo/mutations/updateUserRepairCodes";
import { UPDATE_USER } from "apollo/mutations/updateUser";
import { useUser } from 'hooks'
import { jewelryCodes } from "../../constants/jewelryCodes";
import { smartphoneCodes } from "../../constants/smartphoneCodes";
import { genericCodes } from "../../constants/genericCodes";
import { BeatLoader } from 'react-spinners'
import { RiAddFill } from "react-icons/ri";

// ading another preset

const pageFirstEntity = {
	jewelry: 'gold',
	smartphones: 'iPhone',
	computer: 'generic',
	drones: 'generic',
	antiques: 'generic',
	watchmaker: 'generic',
	sunglasses: 'generic',
	videogameConsoles: 'generic',
	generalElectronics: 'generic',
	securityCameras: 'generic',
	other: 'generic',
}

const TaskCodeConstructors = {
    jewelry: jewelryCodes,
	smartphones: smartphoneCodes,
	computer: genericCodes,
	drones: genericCodes,
	antiques: genericCodes,
	watchmaker: genericCodes,
	sunglasses: genericCodes,
	videogameConsoles: genericCodes,
	generalElectronics: genericCodes,
	securityCameras: genericCodes,
	other: genericCodes,
}

// WARNING! This page is a Core feature directly related with users onboarding into the app!
// Be careful while refactoring or adding code to this page
// This repair code and prices page includes the new users adding code presets
const PriceCodes = () => {
    const client = useApolloClient()
    const { currentUser } = useUser()

    const [pageEntity, setPageEntity] = useState('')
    const [isAddGroupModalOpen, setIsAddGroupModalOpen] = useState(false)
    const toggleAddGroupModal = () => setIsAddGroupModalOpen(!isAddGroupModalOpen)
    const [suggestedTaskNumbers, setSuggestedTaskNumbers] = useState([])

    // 1- Getting user data to fetch codes using id, here we set the state
    // to display the right code group if user is not new
    const { data: userData, loading: loadingUserData } = useQuery(GET_USER_DATA, {
        variables: { email: currentUser.email },
        onCompleted: res => { 
            if (res.getUser.industry === 'jewelry') {
                setPageEntity('gold')
            } else if (res.getUser.industry === 'smartphones'){
                setPageEntity('iPhone')
            } else {
                setPageEntity('generic')
            }
        }
    })

    // hook that fetch the codes on demand, if there is Codes, chain stops here
    // if user has no codes, chain starts on step 3
    const [fetchRepairCodes, { data, loading: loadingRepairCodes }] = useLazyQuery(GET_REPAIR_CODES, {
        variables: { id: userData?.getUser?.userRepairCodes?.id },
        fetchPolicy: 'cache-first',
        onCompleted: res => {
            let userExistingCodesArray = []
            res.getUserRepairCodes?.userRepairCodeGroups.forEach(group => { 
                let groupCodesArray = []
                group.groupCodes.forEach(code => { 
                    groupCodesArray.push(code.code)
                })
                userExistingCodesArray = [...userExistingCodesArray, ...groupCodesArray]
            })
            if (userExistingCodesArray.length > 3) {
                const lowestCode = Math.min(...userExistingCodesArray)
                const highestCode = Math.max(...userExistingCodesArray)
                const availablesNumbersForCodeArray = []
                for (let i = lowestCode; i <= highestCode + 10; i++) {
                    if (!userExistingCodesArray.includes(i)) {
                        availablesNumbersForCodeArray.push(i)
                    }
                }
                console.log({ availablesNumbersForCodeArray })
                setSuggestedTaskNumbers(availablesNumbersForCodeArray)
            }
        },
        onError: error => console.log({ error })
    })

    // 5 - Mutation to add user repairs preset
    const [updateUserRepairCodes, { loading: loadingUserMutation }] = useMutation(UPDATE_USER_REPAIR_CODES, {
        onCompleted: async (res) => {
            // after codes are added to the user in the db we invalidate the codes
            // this will fetch again codes and hydrate { data }, render should change here with data
            await client.refetchQueries({
                include: ["getUserRepairCodes"],
            })
            console.log('Response From updateUserRepairCodes', { res })
        },
        onError: err => console.log(err),
    })

    // 4- Mutation to add the industry on button Click (adding preset)
    const [updateUserIndustry, { loading: loadingUserUpdate }] = useMutation(UPDATE_USER, {
        onCompleted: async (res) => {
            // setting state to display the right code group as now we know the new user industry
            const userIndustryFirstEntity = pageFirstEntity[res.updateUser.user[0].industry]
            setPageEntity(userIndustryFirstEntity)
            // we pick the right preset function of codes from constants
            const priceCodesConstructor = TaskCodeConstructors[res.updateUser.user[0].industry]
            // we update the user repair codes
            updateUserRepairCodes({
                variables: {
                    input: {
                        filter: { id: userData?.getUser?.userRepairCodes?.id },
                        set: { userRepairCodeGroups: priceCodesConstructor(currentUser.email) }
                    }
                },
            })
            // we invalidate the user data this will make the industry available on state cache
            await client.refetchQueries({
                include: ["getUserData"],
            })
        },
        onError: err => console.log(err),
    })


    // 3 - onClick handler to Add Codes Preset
    const handleAddPresetClick = (e) => {
        // we add the user industry
        updateUserIndustry({
            variables: {
                input: {
                    filter: { id: userData?.getUser?.id },
                    set: { industry: e.target.name }
                }
            }
        })
    }

    // 2 - Page query codes to display them, this codes { data } drives the renders
    useEffect(() => { 
        if (userData?.getUser?.userRepairCodes?.id) {
            console.log('Running')
            fetchRepairCodes()
        }
    }, [userData, fetchRepairCodes])
    
    console.log({ userData })
    console.log({ data })
    return (
        <div className="w-full flex">
            <Drawer />
            <div className="w-full h-screen px-12 overflow-y-auto bg-blue-gray-50">
                <RenderIf isTrue={!loadingUserData && !loadingRepairCodes &&
                    (data?.getUserRepairCodes === null || (data?.getUserRepairCodes?.userRepairCodeGroups && !data?.getUserRepairCodes?.userRepairCodeGroups?.length))}>
                    <Presets handleAddPresetClick={handleAddPresetClick} />
                </RenderIf>
                
                <RenderIf isTrue={data?.getUserRepairCodes?.userRepairCodeGroups?.length}>
                    <div className="w-full flex justify-between items-center">
                        <RenderIf isTrue={userData?.getUser?.industry === 'jewelry'}>
                            <div className="flex items-center mb-6 mt-16">
                                <Button color="blue-gray" className="mr-3" onClick={() => setPageEntity('gold')}>
                                    Gold Codes
                                </Button>
                                <Button color="blue-gray" className="mr-3" onClick={() => setPageEntity('platinum')}>
                                    Platinum Codes
                                </Button>
                                <Button color="blue-gray" className="mr-3" onClick={() => setPageEntity('silver')}>
                                    Sterling Silver Codes
                                </Button>
                            </div>
                        </RenderIf>
                        <RenderIf isTrue={userData?.getUser?.industry === 'smartphones'}>
                            <div className="flex items-center mb-6 mt-16">
                                <Button color="blue-gray" className="mr-3" onClick={() => setPageEntity('iPhone')}>
                                    iPhones
                                </Button>
                                <Button color="blue-gray" className="mr-3" onClick={() => setPageEntity('iPad')}>
                                    iPads
                                </Button>
                                <Button color="blue-gray" className="mr-3" onClick={() => setPageEntity('Samsung Galaxy Platform')}>
                                    Samsung Galaxy Platform
                                </Button>
                                <Button color="blue-gray" className="mr-3" onClick={() => setPageEntity('generic')}>
                                    Others
                                </Button>
                            </div>
                        </RenderIf>
                        <RenderIf isTrue={userData?.getUser?.industry !== 'jewelry' && userData?.getUser?.industry !== 'smartphones'}>
                            <div className="flex items-center mb-6 mt-16">
                                <Button color="blue-gray" className="mr-3" onClick={() => setPageEntity('generic')}>
                                    Task Codes
                                </Button>
                            </div>
                        </RenderIf>

                        <Tooltip content="Add Task Group" placement="left">
                            <IconButton
                                variant="gradient"
                                onClick={toggleAddGroupModal}
                                className="mt-12"
                            >
                                <RiAddFill className="text-xl font-black"/>
                            </IconButton>
                        </Tooltip>
                    </div>
                    <EntitySection
                        codeGroups={data?.getUserRepairCodes?.userRepairCodeGroups.filter(group => group.entity === pageEntity)}
                        suggestedTaskNumbers={suggestedTaskNumbers}
                    />
                </RenderIf>

                <RenderIf isTrue={loadingUserUpdate || loadingUserMutation}>
                    <div className="flex mt-16">
                        <BeatLoader color="#94A3B8" size={9} />
                        <BeatLoader color="#94A3B8" size={9} className="ml-1" />
                        <BeatLoader color="#94A3B8" size={9} className="ml-1" />
                        <BeatLoader color="#94A3B8" size={9} className="ml-1" />
                        <BeatLoader color="#94A3B8" size={9} className="ml-1" />
                        <BeatLoader color="#94A3B8" size={9} className="ml-1" />
                        <BeatLoader color="#94A3B8" size={9} className="ml-1" />
                    </div>
                    <div className="flex mt-3">
                        <BeatLoader color="#94A3B8" size={9} />
                        <BeatLoader color="#94A3B8" size={9} className="ml-1" />
                        <BeatLoader color="#94A3B8" size={9} className="ml-1" />
                        <BeatLoader color="#94A3B8" size={9} className="ml-1" />
                    </div>
                </RenderIf>

            </div>
            <AddGroupModal
                isOpen={isAddGroupModalOpen}
                toggle={toggleAddGroupModal}
                entity={pageEntity}
                userRepairCodesId={userData?.getUser?.userRepairCodes?.id}
                suggestedTaskNumbers={suggestedTaskNumbers}
            />
        </div>
    );
}

export default PriceCodes
