import React from "react";
import InputComp from "./InputComp";
import { Drawer } from "components";

export default function Photography() {
  
    return (
        <div className="w-full flex">
            <Drawer />
            <div className="w-full h-screen px-12 overflow-y-auto bg-blue-gray-50">
                <InputComp />
            </div>
        </div>
    );
}
