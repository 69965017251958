import React from "react";
import Group from "./Group";

function EntitySection({ codeGroups, suggestedTaskNumbers }) {
    return (
        <div className="columns-2 mb-24">
            {codeGroups?.map((group, index) => (
                <Group
                    key={index}
                    group={group}
                    suggestedTaskNumbers={suggestedTaskNumbers}
                />
            ))}
        </div>
    );
}

export default React.memo(EntitySection);
