
import { gql } from '@apollo/client';

export const ADD_JOB = gql`
mutation addJob($input: [AddJobInput!]!) {
    addJob(input: $input) {
        job {
            id
        }
    }
}`



// {
//   "input": {
//         "createdOn": "2022-08-12T04:29:53.543Z",
//         "promiseDate": "2022-08-27T05:00:00.000Z",
//         "itemValue": 9000,
//         "customerEmail": "roli.niubo@gmail.com",
//         "customerPhoneNumber": "8573124946",
//         "customerName": "Rolando Niubo",
//         "jobNumber": "1660278593543",
//         "status":0,
//         "store": {
//             "storeNumber": "4455",
//             "user": {
//                 "email": "roli.niubo@gmail.com"
//             }
//         },
//         "userJobs": {
//             "id": "0xfffd8d67e61e0fd3"
//         }
// 	}
// }

