import React, { Fragment, useState } from "react";
import PriceContainer from "./PriceContainer";
import DescriptionContainer from "./DescriptionContainer";
import {
    Button,
    Chip,
    Popover,
    PopoverHandler,
    PopoverContent,
    Tooltip
} from "@material-tailwind/react";
import { ADD_REPAIR_CODE } from "apollo/mutations/addRepairCode";
import { DELETE_REPAIR_GROUP } from "apollo/mutations/deleteRepairGroup";
import { DELETE_REPAIR_CODE } from "apollo/mutations/deleteRepairCode";
import { useMutation, useApolloClient } from "@apollo/client";
import { BsThreeDots } from "react-icons/bs";
import { BsThreeDotsVertical } from "react-icons/bs";
import DeleteTaskConfirmationModal from "./DeleteTaskConfirmationModal";
import DeleteGroupConfirmationModal from "./DeleteGroupConfirmationModal";
import { RenderIf } from "components";
import { TbUpload } from "react-icons/tb";
import { CgClose } from "react-icons/cg";
import { BeatLoader } from 'react-spinners'


export default function Blocks({ group, suggestedTaskNumbers }) {
    const client = useApolloClient()
    
    const [isDeleteTaskModalOpen, setIsDeleteTaskModalOpen] = useState(false)
    const toggleDeleteTaskModal = () => setIsDeleteTaskModalOpen(!isDeleteTaskModalOpen)
    const [isDeleteGroupModalOpen, setIsDeleteGroupModalOpen] = useState(false)
    const toggleDeleteGroupModal = () => setIsDeleteGroupModalOpen(!isDeleteGroupModalOpen)
    const [codeToDelete, setCodeToDelete] = useState('')
    const [groupToDelete, setGroupToDelete] = useState('')
    const [isGroupOptionsPopoverOpen, setIsGroupOptionsPopoverOpen] = useState(false)
    const [showTaskInputs, setShowTaskInputs] = useState(false)

    const [newTaskCode, setNewTaskCode] = useState(null)
    const [newTaskDescription, setNewTaskDescription] = useState('')

    const [addRepairCodeToDb, { loading: isAddRepairCodeMutationLoading }] = useMutation(ADD_REPAIR_CODE, {
        onCompleted: async () => { 
            setShowTaskInputs(false)
            await client.refetchQueries({ include: ["getUserRepairCodes"] })

        }
    })

    const [deleteTaskCode, { loading: isTaskMutationLoading }] = useMutation(DELETE_REPAIR_CODE, {
        onCompleted: async () => {
            toggleDeleteTaskModal()
            await client.refetchQueries({ include: ["getUserRepairCodes"] })
        }
    })

    const [deleteGroup, { loading: isGroupMutationLoading }] = useMutation(DELETE_REPAIR_GROUP, {
        onCompleted: async () => {
            toggleDeleteGroupModal()
            await client.refetchQueries({ include: ["getUserRepairCodes"] })
        }
    })

    const openDeleteTaskModal = (code) => { 
        toggleDeleteTaskModal()
        setCodeToDelete(code)
    }

    const handleDeleteTaskModal = () => deleteTaskCode({
        variables: { id: codeToDelete.id }
    })
    
    const openDeleteGroupModal = (group) => { 
        toggleDeleteGroupModal()
        setGroupToDelete(group)
    }
    const handleDeleteGroup = () => deleteGroup({
        variables: {
            id: groupToDelete.id
        }
    })

    const openNewTasksInputs = () => { 
        setShowTaskInputs(true)
        setIsGroupOptionsPopoverOpen(false)
    }

    const cancelNewTaskInput = () => {
        setShowTaskInputs(false)
        setNewTaskCode(null)
        setNewTaskDescription('')
    }

    const submitNewTaskInput = () => {
        if (group.entity && newTaskCode && newTaskDescription && group.id) {
            addRepairCodeToDb({
                variables: {
                    input: {
                        entity: group.entity,
                        code: newTaskCode, 
                        description: newTaskDescription, 
                        wholeSalePrice: 1.00, 
                        retailPrice: 1.00, 
                        group: {
                            id: group.id
                        }
                    }
                }
            })
        } else {
            // throw error
        }
    }

    const evaluateKey = (e) => {
        if (e.keyCode === 13) {
            submitNewTaskInput()
        } else if (e.keyCode === 27) {
            cancelNewTaskInput()
        }
    };

    return (
        <Fragment>
            {/*  List of itmes */}
            {group?.groupCodes?.map((code, index) => (
                <div className="flex mt-2 justify-between items-center" key={index}>
                    <Popover placement="right-start">
                        <PopoverHandler>
                            <div>
                                <BsThreeDotsVertical
                                    className="text-blue-gray-50 text-2xl cursor-pointer hover:text-blue-gray-300 -ml-4" />
                            </div>
                        </PopoverHandler>
                        <PopoverContent className="bg-slate-800 shadow-xl border-2 border-white">
                            <div className="flex justify-start items-center mb-3">
                                <p className="font-semibold text-blue-gray-500">
                                    {code?.description}
                                </p>
                            </div>
                            <p className="text-white text-xs w-72">
                                Deleting this Task Code will delete it from your price inventory database permanently.
                            </p>
                            <Button
                                variant="gradient"
                                color="red"
                                size="sm"
                                onClick={() => openDeleteTaskModal(code)}
                                className="mt-6 px-6"
                            >
                                Delete
                            </Button>
                        </PopoverContent>
                    </Popover>
                    <Chip
                        value={code?.code ?? "Loading..."}
                        color="amber"
                        className="text-xs font-semibold mr-2"
                    />
                    <DescriptionContainer code={code} />
                    <div className="w-32 text-right">
                        <PriceContainer code={code} market="wholesale" />
                    </div>
                    <div className="w-32 text-right">
                        <PriceContainer code={code} market="retail" />
                    </div>
                </div>
            ))}

            {/*  Input to enter new task */}
            <RenderIf isTrue={showTaskInputs && !isAddRepairCodeMutationLoading}>
                <div className="w-full px-2 mt-3 flex justify-between items-center mb-6">
                    <Tooltip content="Pick an available code">
                        <select
                            className="w-14 h-8 p-1 mr-3 border border-1 border-blue-gray-100 rounded-lg text-xs font-semibold"
                            onChange={e => setNewTaskCode(Number(e.target.value))}
                        >
                            <option value="">---</option>
                            {suggestedTaskNumbers.map(number => (
                                <option key={number} value={number}>
                                    {number}
                                </option>
                            ))}
                        </select>
                    </Tooltip>
                    <input
                        type="text"
                        className="w-full h-8 p-1 mr-3 border border-1 border-blue-gray-100 rounded-lg px-2 text-sm"
                        value={newTaskDescription}
                        // ref={inputRef}
                        onChange={(e) => setNewTaskDescription(e.target.value)}
                        onKeyDown={evaluateKey}
                    />
                    <TbUpload
                        className="bg-blue-gray-100 cursor-pointer text-white font-semibold rounded-lg h-8 w-12 p-1 mr-3"
                        onClick={submitNewTaskInput}
                    />
                    <CgClose
                        className="bg-pink-100 cursor-pointer text-white font-semibold rounded-lg h-8 w-12 p-1"
                        onClick={cancelNewTaskInput}
                    />
                </div>
            </RenderIf>
            <RenderIf isTrue={isAddRepairCodeMutationLoading}>
                <div className="flex">
                    <BeatLoader color="#94A3B8" size={9} className="my-4 ml-2" />
                    <BeatLoader color="#94A3B8" size={9} className="my-4 ml-2" />
                </div>
            </RenderIf>

            {/*  Group Menu */}
            <RenderIf isTrue={!showTaskInputs}>
                <div className="w-full flex justify-end items-center">
                    <Popover
                        placement="bottom-start"
                        open={isGroupOptionsPopoverOpen}
                        handler={() => setIsGroupOptionsPopoverOpen(!isGroupOptionsPopoverOpen)}
                    >
                        <PopoverHandler>
                            <div>
                                <BsThreeDots className="text-blue-gray-50 text-2xl cursor-pointer hover:text-blue-gray-300" />
                            </div>
                        </PopoverHandler>
                        <PopoverContent className="bg-black shadow-xl border-2 border-white w-72">
                            <div className="flex justify-start items-center mb-3">
                                <p className="font-semibold text-blue-gray-50">
                                    Group Options
                                </p>
                            </div>
                            <div className="flex justify-start items-start mb-2">
                                <span className="text-white font-semibold mr-2">Group:</span>
                                <span className="font-semibold text-blue-gray-500 text-sm">
                                    {group?.repairGroup}
                                </span>
                            </div>
                            <div className="w-full border border-b-1 border-blue-gray-900 mb-2" />
                            <p className="text-blue-gray-400 text-xs mb-2">
                                Adding new tasks and price them will make them available while charging a Job.
                            </p>
                            <Button
                                variant="gradient"
                                size="sm"
                                onClick={() => openNewTasksInputs(group)}
                                className="mb-3 px-6"
                            >
                                Add new task
                            </Button>
                            <div className="w-full border border-b-1 border-blue-gray-900 mb-2" />
                            <p className="text-blue-gray-400 text-xs mb-2">
                                Deleting this Task Group will delete it from your price inventory database permanently.
                            </p>
                            <Button
                                variant="gradient"
                                color="red"
                                size="sm"
                                onClick={() => openDeleteGroupModal(group)}
                                className="px-6"
                            >
                                Delete Group
                            </Button>
                        </PopoverContent>
                    </Popover>             
                </div>
            </RenderIf>

            {/*  Dialog for deleting task code */}
            <DeleteTaskConfirmationModal
                isDeleteTaskModalOpen={isDeleteTaskModalOpen}
                toggleDeleteTaskModal={toggleDeleteTaskModal}
                isTaskMutationLoading={isTaskMutationLoading}
                handleDeleteTaskModal={handleDeleteTaskModal}
            />

            {/*  Dialog for Group */}
            <DeleteGroupConfirmationModal
                isDeleteGroupModalOpen={isDeleteGroupModalOpen}
                toggleDeleteGroupModal={toggleDeleteGroupModal}
                isGroupMutationLoading={isGroupMutationLoading}
                handleDeleteGroup={handleDeleteGroup}
            />
        </Fragment>
    );
}
