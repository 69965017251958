import React from 'react'
import { Drawer } from 'components'
const Inventory = () => {
  return (
    
    <div className="w-full flex">
    <Drawer />
     
    </div>
  )
}

export default Inventory
