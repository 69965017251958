import React from 'react'
import { createRoot } from 'react-dom/client';
import './fonts/monaco.ttf'
import './index.css'
import App from './App'
import "./firebaseConfig/config";
import './index.scss'

const app = (
    <App />
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(app);
