import React, { useState, useEffect, useRef } from "react"
import JobViewModal from "./JobViewModal"
import accounting from "accounting-js"
import moment from "moment"
import { ListRow, RenderIf } from 'components'
import copy from "copy-to-clipboard";
import { TbCopy } from "react-icons/tb";
import { Tooltip } from "@material-tailwind/react";
import { STATUS } from "constants";
import { BeatLoader } from "react-spinners";

export default function JobsList({ currentJobs, loading }) {
    const [state, setState] = useState({
        show: false,
        clickedJob: {}
    });

    const [copiedIndex, setCopiedIndex] = useState();
    const isMounted = useRef(false)

    const onHide = () => {
        setState({ ...state, show: false });
    };

    const copyHandler = (event, index, jobNumber) => { 
        event.stopPropagation();
        setCopiedIndex(index)
        copy(jobNumber)
    }

    useEffect(() => { 
        if (isMounted.current && currentJobs) {
            const updatedJob = currentJobs?.find(job => job.id === state.clickedJob?.id)
            setState({ ...state, clickedJob: updatedJob })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, [currentJobs])

    useEffect(() => { 
         isMounted.current = true
    }, [])


    return (
        <>
            <RenderIf isTrue={loading}>
                <div className="flex flex-col ml-2">
                    <BeatLoader color="#94A3B8" size={9} className="ml-1 mt-3" />
                    <div className="flex">
                        <BeatLoader color="#94A3B8" size={9} className="ml-1 mt-3" />
                        <BeatLoader color="#94A3B8" size={9} className="ml-1 mt-3" />
                        <BeatLoader color="#94A3B8" size={9} className="ml-1 mt-3" />
                    </div>
                    <BeatLoader color="#94A3B8" size={9} className="ml-1 mt-3" />
                </div>
            </RenderIf>
            <RenderIf isTrue={!loading && (!currentJobs || (currentJobs && !currentJobs.length))}>
                <p className="italic text-sm text-blue-gray-200 mt-3 ml-2">
                    There is no Items to show under this criteria.
                </p>
            </RenderIf>
            <RenderIf isTrue={!loading && currentJobs?.length}>
                <div className="mt-2">
                    {currentJobs && currentJobs.map((job, index) => {
                        return (
                            <ListRow
                                key={`Job-${job.id}-at-index-${index}`}
                                handleClick={() => setState({ clickedJob: job, show: true })}
                                className="flex justify-between items-center pl-3 pr-4 py-0"
                            >
                                <div className="flex-1 flex items-center">
                                    <Tooltip
                                        content={copiedIndex === index ? "Copied" : "Copy to clipboard"}
                                        className="text-xs">
                                        <span>
                                            <TbCopy
                                                className="mr-2 text-blue-700"
                                                onClick={(event) => copyHandler(event, index, job.jobNumber)} />
                                        </span>
                                    </Tooltip>
                                    <span className="text-sm">
                                        {job.jobNumber}
                                    </span>
                                </div>
                                <div className="flex-1 text-right truncate text-sm">
                                    {job.customerName}
                                </div>
                                <div className="flex-1 text-right text-sm">
                                    {accounting.formatMoney(job.itemValue)}
                                </div>
                                <div className="flex-1 text-right text-sm">
                                    {job?.store?.storeNumber}
                                </div>
                                <div className="flex-1 text-right text-sm">
                                    {moment(job.createdOn).format("l")}
                                </div>
                                <div className="flex-1 text-right text-sm">
                                    {moment(job.promiseDate).format("l")}
                                </div>
                                <div className="flex-1 text-right">
                                    <RenderIf isTrue={!job.status}>
                                        <span className="text-blue-gray-500 text-sm">
                                            New Job
                                        </span>
                                    </RenderIf>
                                    <RenderIf isTrue={job.status === STATUS.RECEIVED}>
                                        <span className="text-orange-600 text-sm">
                                            Received
                                        </span>
                                    </RenderIf>
                                    <RenderIf isTrue={job.status === STATUS.COMPLETED}>
                                        <span className="text-green-500 text-sm">
                                            Completed
                                        </span>
                                    </RenderIf>
                                    <RenderIf isTrue={job.status === STATUS.INVOICED}>
                                        <span className="text-blue-500 text-sm">
                                            Invoiced
                                        </span>
                                    </RenderIf>
                                    <RenderIf isTrue={job.status === STATUS.DELIVERED}>
                                        <span className="text-indigo-600 text-sm">
                                            Delivered
                                        </span>
                                    </RenderIf>

                                </div>
                            </ListRow>
                        );
                    })}
                </div>
            </RenderIf>
            <RenderIf isTrue={state.show}>
                <JobViewModal
                    show={state.show}
                    onHide={onHide}
                    id={state?.clickedJob?.id}
                />
            </RenderIf>
        </>
    );
}
