
function isNumeric(str) {
  return /^\d+$/.test(str);
}

export const buildFilters = (state) => { 
    let filters = [{has: "store"}];

    const {
        jobNumber,
        customer,
        // store,
        status,
        receivedFromDate,
        receivedToDate,
        promiseFromDate,
        promiseToDate,
        minValue,
        maxValue,
    } = state;

    if (jobNumber) {
        filters.push({ jobNumber: { allofterms: jobNumber } });
    }

    if (status === null) {
        filters.push({ status: { lt: 4 } });
    } else if (status) {
        filters.push({ status: { eq: status } });
    }
    
    if (customer) {
        if (isNumeric(customer)) {
            filters.push({ customerPhoneNumber: { anyofterms: customer } })
        } else if (customer.includes('@')) {
            filters.push({ customerEmail: { anyofterms: customer } })
        } else {
            filters.push({ customerName : { anyofterms: customer } })
        }
    }

    if (receivedFromDate && receivedToDate) {
        filters.push(
            { createdOn: { between: { min: receivedFromDate, max: receivedToDate } } }
        )
    } else if(receivedFromDate && !receivedToDate) {
        filters.push(
            { createdOn: { gt: receivedFromDate } }
        )
    } else if (!receivedFromDate && receivedToDate) {
        filters.push(
            { createdOn: { lt: receivedToDate } }
        )
    }
    
    if (promiseFromDate && promiseToDate) {
        filters.push(
            { promiseDate: { between: { min: promiseFromDate, max: promiseToDate } } }
        )
    } else if(promiseFromDate && !promiseToDate) {
        filters.push(
            { promiseDate: { gt: promiseFromDate } }
        )
    } else if (!promiseFromDate && promiseToDate) {
        filters.push(
            { promiseDate: { lt: promiseToDate } }
        )
    }

    if (minValue && maxValue) {
        filters.push(
            { itemValue: { between: { min: minValue, max: maxValue } } }
        )
    } else if(minValue && !maxValue) {
        filters.push(
            { itemValue: { gt: minValue } }
        )
    } else if (!minValue && maxValue) {
        filters.push(
            { itemValue: { lt: maxValue } }
        )
    }

    return filters;
}