import React from 'react'
import classNames from 'classnames'

const Heading = ({ size = 'md', className, style, colorClass = 'text-slate-700', children }) => {
  return (
    <div className={className} style={style}>
      <span className={classNames(`font-black ${colorClass}`, {
        'text-md': size === 'xxs',
        'text-lg': size === 'xs',
        'text-xl': size === 'sm',
        'text-2xl': size === 'md',
        'text-3xl': size === 'lg',
      })}>
        {children}
      </span>
    </div>

  )
}

export default Heading