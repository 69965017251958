import { gql } from "@apollo/client";

export const GET_USER_DATA = gql`
query getUserData($email: String!) {
    getUser(email: $email) {
        businessAddress
        businessName
        createdOn
        email
        id
        phoneNumber
        stripeCustomerId
        industry
        userStores {
            id
            relation
            storeName
            storeNumber
            storeAddress
            storeEmail
            storePhoneNumber
        }
        userJobs {
            id
        }
        userRepairCodes {
            id
        }
    }
}
`
// eq	equals to  ===
// lt	less than  <
// le	less than or equal to <=
// gt	greater than >
// ge	greater than or equal to >=
