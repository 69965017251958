import axios from 'axios';
import { useMutation } from 'react-query';

const createCustomerPortal = ({ id }) => axios.post(
    `https://us-central1-bench-manager-cms.cloudfunctions.net/v1/create-customer-portal-session`, {
        id
    }
);

const useCreateCustomerPortalSession = options => useMutation(createCustomerPortal, options);

export default useCreateCustomerPortalSession;