import React from "react";
import NewJobDisplayView from "./NewJobDisplayView";
import NewJobEditModeView from "./NewJobEditModeView";
import { RenderIf } from "components";
import { useMutation, useApolloClient } from "@apollo/client";
import { DELETE_JOB_MUTATION } from "apollo/mutations/deleteJob";

const NewJob = ({ job, id, ...rest }) => {
    const client = useApolloClient()
    const [mutate] = useMutation(DELETE_JOB_MUTATION)

    const [editMode, setEditMode] = React.useState(false);

    const removeJob = () => {
        mutate({
            variables: { id: job.id },
            onCompleted: async () => {
                await client.refetchQueries({
                    include: ["getUserJobs"],
                })
            }
        })
    }

    return (
        <>
            <RenderIf isTrue={!editMode}>
                <NewJobDisplayView
                    props={rest}
                    job={job}
                    id={id}
                    toggleView={() => setEditMode(current => !current)}
                    removeJob={removeJob}
                />
            </RenderIf>
            <RenderIf isTrue={editMode}>
                <NewJobEditModeView
                    props={rest}
                    job={job}
                    id={id}
                    toggleView={() => setEditMode(current => !current)}
                />
            </RenderIf>
        </>
    )
}

export default NewJob;
