import React from 'react'
import { useHistory, useLocation } from "react-router-dom";
import { RiDashboard3Fill } from "react-icons/ri";
import { FaUserCog } from "react-icons/fa";
import { MdInventory, MdOutlineSettingsSuggest } from "react-icons/md"
import { RiFolderAddFill } from "react-icons/ri";
import { MdAddAPhoto } from "react-icons/md";
import { FaFileInvoiceDollar, FaMailBulk } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { BsCalendarCheckFill } from "react-icons/bs";
import { MdEditLocationAlt } from "react-icons/md";
import { useUser } from "hooks";
import classNames from "classnames";
import { ToastContainer, toast } from "react-toastify";
import { useQuery } from "@apollo/client";
import { GET_USER_DATA } from "apollo/queries/getUserData";
import { Avatar } from "@material-tailwind/react";
import img_placeholder from "images/img_placeholder.jpg";
import { useUserPhoto } from "states";
import RenderIf from "../RenderIf/RenderIf";
import { inventoryFeatureAuthorizedUsers } from "featureFlags";

const Drawer = ({ className, ...props }) => {
    const { currentUser } = useUser()
    const history = useHistory()
    const { pathname } = useLocation()

    const [userPhoto] = useUserPhoto()

    const routeHandler = (route) => {
        history.push(route)
    }

    const { data } = useQuery(GET_USER_DATA, {
        variables: { email: currentUser.email },
        onError: (error) => toast(error.message, {
            type: 'error',
        })
    })

    return (
      <div className={`h-screen sticky min-w-max bg-slate-900 px-6 shadow-2xl ${className}`} {...props}>
        <div className='flex'>
            <Avatar
                src={userPhoto || img_placeholder}
                alt='avatar'
                size='sm'
                className='mt-6'
            />
            <div className="my-6 ml-3">
                <div className="text-white font-black">
                    {data?.getUser?.businessName}
                </div>
                <div className="text-white text-xs">
                    {data?.getUser?.email}
                </div>
            </div>
        </div>
        <ul className="text-white mt-6">
            <li 
                className={classNames("p-3 my-1 flex items-center text-sm cursor-pointer hover:bg-slate-800 rounded-xl", {
                    "bg-slate-800": pathname === '/dashboad'
                })} 
                onClick={() => routeHandler('/')}>
                <RiDashboard3Fill className="text-xl mr-2" />
                <span>Dashboard</span>
            </li>
            <li 
                className={classNames("p-3 my-1 flex items-center text-sm cursor-pointer hover:bg-slate-800 rounded-xl", {
                    "bg-slate-800": pathname === '/shop-account'
                })} 
                onClick={() => routeHandler('/shop-account')}>
                <FaUserCog className="text-xl mr-2" />
                <span>Profile</span>
            </li>
            {/* <RenderIf isTrue={inventoryFeatureAuthorizedUsers.includes(currentUser.email)}>
                <li className={classNames("p-3 my-1 flex items-center text-sm cursor-pointer hover:bg-slate-800 rounded-xl", {
                        "bg-slate-800": pathname === '/Inventory'
                    })} 
                    onClick={() => routeHandler('/Inventory')}>
                        <MdInventory className="text-xl mr-2"/>
                    <span>Your Inventory</span>
                </li>
            </RenderIf> */}
            <li 
                className={classNames("p-3 my-1 flex items-center text-sm cursor-pointer hover:bg-slate-800 rounded-xl", {
                    "bg-slate-800": pathname === '/price-codes'
                })} 
                onClick={() => routeHandler('/price-codes')}>
                <MdOutlineSettingsSuggest className="text-2xl mr-2" />
                <span>Your Services</span>
            </li>
            <li 
                className={classNames("p-3 my-1 flex items-center text-sm cursor-pointer hover:bg-slate-800 rounded-xl", {
                    "bg-slate-800": pathname === '/queries'
                })} 
                onClick={() => routeHandler('/queries')}>
                <FaSearch className="text-xl mr-2" />
                <span>Search Job</span>
            </li>
            <li 
                className={classNames("p-3 my-1 flex items-center text-sm cursor-pointer hover:bg-slate-800 rounded-xl", {
                    "bg-slate-800": pathname === '/receive-job'
                })} 
                onClick={() => routeHandler('/receive-job')}>
                <RiFolderAddFill className="text-xl mr-2" />
                <span>Receive Job</span>
            </li>
            <li 
                className={classNames("p-3 my-1 flex items-center text-sm cursor-pointer hover:bg-slate-800 rounded-xl", {
                    "bg-slate-800": pathname === '/photography'
                })} 
                onClick={() => routeHandler('/photography')}>
                <MdAddAPhoto className="text-xl mr-2" />
                <span>Photograph Job</span>
            </li>
            <li 
                className={classNames("p-3 my-1 flex items-center text-sm cursor-pointer hover:bg-slate-800 rounded-xl", {
                    "bg-slate-800": pathname === '/complete-job'
                })} 
                onClick={() => routeHandler('/complete-job')}>
                <BsCalendarCheckFill className="text-xl mr-2" />
                <span>Complete Job</span>
            </li>
            <li 
                className={classNames("p-3 my-1 flex items-center text-sm cursor-pointer hover:bg-slate-800 rounded-xl", {
                    "bg-slate-800": pathname === '/invoice'
                })} 
                onClick={() => routeHandler('/invoice')}>
                <FaFileInvoiceDollar className="text-xl mr-2" />
                <span>Send Invoice</span>
            </li>
            <li 
                className={classNames("p-3 my-1 flex items-center text-sm cursor-pointer hover:bg-slate-800 rounded-xl", {
                    "bg-slate-800": pathname === '/delivery'
                })} 
                onClick={() => routeHandler('/delivery')}>
                <FaMailBulk className="text-xl mr-2" />
                <span>Deliver Jobs</span>
            </li>
            <li 
                className={classNames("p-3 my-1 flex items-center text-sm cursor-pointer hover:bg-slate-800 rounded-xl", {
                    "bg-slate-800": pathname === '/locations'
                })} 
                onClick={() => routeHandler('/locations')}>
                <MdEditLocationAlt className="text-xl mr-2" />
                <span>Store Locations</span>
            </li>
        </ul>
        <ToastContainer
            position="bottom-right"
            theme="colored"
            autoClose={8000}
            closeOnClick={true}
        />
    </div>
  )
}

export default Drawer