import React, { useState } from "react";
import ForgottenForm from "./ForgottenForm";
import ForgottenSent from "./ForgottenSent";
import { RenderIf } from "components";

export default function ForgottenPass({ forgottenPassToggling }) {
    const [sent, setSent] = useState(false);
    const sendingEmail = () => {
        setSent(!sent);
    };
    return (
        <div className="w-96">
            <RenderIf isTrue={sent}>
                <ForgottenSent
                    sendingEmail={sendingEmail}
                    forgottenPassToggling={forgottenPassToggling}
                />
            </RenderIf>
            <RenderIf isTrue={!sent}>
                <ForgottenForm
                    forgottenPassToggling={forgottenPassToggling}
                    sendingEmail={sendingEmail}
                />
            </RenderIf>
        </div>
    );
}
