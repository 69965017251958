import React, { useEffect, useRef, useCallback } from 'react'
import { GoogleAddressLookup } from 'react-rainbow-components';


// TODO : Remove this component from react rainbow components
// implement a google lookup with @material-tailwind/react inputs
const LookupInputWrap = ({ selectedValue, addressChangeHandler, label, onFocus, error }) => {
    const isMounted = useRef(false)
    const ref = useRef();

    const focusHandler = useCallback(() => { 
        const nestedInput = document.querySelector('#gaddressLookupInput>div>div>input');
        nestedInput.focus()
        onFocus()
    }, [onFocus])

    const onChangeHandler = (value) => {
        addressChangeHandler(value)
        focusHandler()
    }

    useEffect(() => { 
        if (selectedValue) {
            focusHandler()
        }
    }, [selectedValue, focusHandler])

    useEffect(() => { 
        ref.current.style.top = '-10px';
        ref.current.style.left = '10px';
        ref.current.style.backgroundColor = 'white';
        ref.current.style.fontSize = '12px';
        ref.current.style.paddingLeft = '4px';
        ref.current.style.paddingRight = '4px';
        ref.current.style.color = '#308CEA';
    }, [])

    useEffect(() => { 
        if (isMounted.current) {
            if (error) {
                ref.current.style.color = '#ed271d'
                document.querySelector('#gaddressLookupInput>div>div>input').style.border = '1px solid #f32323'
            } else {
                ref.current.style.color = '#59717E'
                document.querySelector('#gaddressLookupInput>div>div>input').style.border = '1px solid #B0BEC5'
            }
        }
    }, [error])

    useEffect(() => { 
        isMounted.current = true
    }, [])

  return (
    <div className="w-full relative z-50">
        <GoogleAddressLookup
            id="gaddressLookupInput"
            onChange={onChangeHandler}
            onClick={focusHandler}
            value={selectedValue}
            apiKey="AIzaSyCLIY0fV5w-HlVm-pJ89BT0MQ7Z2b2xwtI"
            className="w-full giardiaInput"
        />
        <label
            htmlFor="gaddressLookupInput"
            className="absolute text-blue-gray-500 text-sm labelAnimation cursor-text"
            onClick={focusHandler}
            ref={ref}
        >
            {label}
        </label>
    </div>
  )
}

export default LookupInputWrap



// This is the rainbow components lookup component hacked so styled match our look
// This component needs this styles on App.css:

// #gaddressLookupInput>div>div>input:focus {
//     outline: none !important;
//     border:2px solid #308CEA;
//     box-shadow: none;
//     box-sizing: none !important;
//   }

// #gaddressLookupInput>div>div>input:focus ~ .labelAnimation {
//     top: -5px;
// }

// #gaddressLookupInput>div>div>span:first-child {
//     display: none;
// }

// #gaddressLookupInput>div>div>input {
//     border-radius: 4px !important;
//     box-sizing: none !important;
//     -webkit-text-size-adjust: 100%;
//     tab-size: 4;
//     --toastify-color-light: #fff;
//     --toastify-color-dark: #121212;
//     --toastify-color-info: #3498db;
//     --toastify-color-success: #07bc0c;
//     --toastify-color-warning: #f1c40f;
//     --toastify-color-error: #e74c3c;
//     --toastify-color-transparent: rgba(255, 255, 255, 0.7);
//     --toastify-icon-color-info: var(--toastify-color-info);
//     --toastify-icon-color-success: var(--toastify-color-success);
//     --toastify-icon-color-warning: var(--toastify-color-warning);
//     --toastify-icon-color-error: var(--toastify-color-error);
//     --toastify-toast-width: 320px;
//     --toastify-toast-background: #fff;
//     --toastify-toast-min-height: 64px;
//     --toastify-toast-max-height: 800px;
//     --toastify-font-family: sans-serif;
//     --toastify-z-index: 9999;
//     --toastify-text-color-light: #757575;
//     --toastify-text-color-dark: #fff;
//     --toastify-text-color-info: #fff;
//     --toastify-text-color-success: #fff;
//     --toastify-text-color-warning: #fff;
//     --toastify-text-color-error: #fff;
//     --toastify-spinner-color: #616161;
//     --toastify-spinner-color-empty-area: #e0e0e0;
//     --toastify-color-progress-light: linear-gradient(
//     to right,
//     #4cd964,
//     #5ac8fa,
//     #007aff,
//     #34aadc,
//     #5856d6,
//     #ff2d55
//   );
//     --toastify-color-progress-dark: #bb86fc;
//     --toastify-color-progress-info: var(--toastify-color-info);
//     --toastify-color-progress-success: var(--toastify-color-success);
//     --toastify-color-progress-warning: var(--toastify-color-warning);
//     --toastify-color-progress-error: var(--toastify-color-error);
//     -webkit-font-smoothing: antialiased;
//     --tw-gradient-from: #0f172a;
//     --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
//     --tw-gradient-to: #1e293b;
//     --tw-bg-opacity: 1;
//     --tw-space-y-reverse: 0;
//     box-sizing: border-box;
//     border-style: solid;
//     --tw-border-spacing-x: 0;
//     --tw-border-spacing-y: 0;
//     --tw-translate-x: 0;
//     --tw-translate-y: 0;
//     --tw-rotate: 0;
//     --tw-skew-x: 0;
//     --tw-skew-y: 0;
//     --tw-scale-x: 1;
//     --tw-scale-y: 1;
//     --tw-pan-x: ;
//     --tw-pan-y: ;
//     --tw-pinch-zoom: ;
//     --tw-scroll-snap-strictness: proximity;
//     --tw-ordinal: ;
//     --tw-slashed-zero: ;
//     --tw-numeric-figure: ;
//     --tw-numeric-spacing: ;
//     --tw-numeric-fraction: ;
//     --tw-ring-inset: ;
//     --tw-ring-offset-width: 0px;
//     --tw-ring-offset-color: #fff;
//     --tw-ring-color: rgb(33 150 243 / 0.5);
//     --tw-ring-offset-shadow: 0 0 #0000;
//     --tw-ring-shadow: 0 0 #0000;
//     --tw-shadow: 0 0 #0000;
//     --tw-shadow-colored: 0 0 #0000;
//     --tw-blur: ;
//     --tw-brightness: ;
//     --tw-contrast: ;
//     --tw-grayscale: ;
//     --tw-hue-rotate: ;
//     --tw-invert: ;
//     --tw-saturate: ;
//     --tw-sepia: ;
//     --tw-drop-shadow: ;
//     --tw-backdrop-blur: ;
//     --tw-backdrop-brightness: ;
//     --tw-backdrop-contrast: ;
//     --tw-backdrop-grayscale: ;
//     --tw-backdrop-hue-rotate: ;
//     --tw-backdrop-invert: ;
//     --tw-backdrop-opacity: ;
//     --tw-backdrop-saturate: ;
//     --tw-backdrop-sepia: ;
//     margin: 0;
//     height: 100%;
//     width: 100%;
//     border-radius: 7px;
//     background-color: transparent;
//     padding-left: 0.75rem;
//     padding-right: 0.75rem;
//     padding-top: 0.625rem;
//     padding-bottom: 0.625rem;
//     font-family: Roboto, sans-serif;
//     font-size: 0.875rem;
//     line-height: 1.25rem;
//     font-weight: 400;
//     --tw-text-opacity: 1;
//     color: rgb(69 90 100 / var(--tw-text-opacity));
//     outline-style: solid;
//     outline-width: 0px;
//     transition-property: all;
//     transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
//     transition-duration: 150ms;
//     border-width: 1px;
//     border-color: rgb(176 190 197 / var(--tw-border-opacity));
//     --tw-border-opacity: 1;
//     border-top-color: rgb(176 190 197 / var(--tw-border-opacity));  
// }
