import React, { useState, useEffect } from "react";
import accounting from "accounting-js";
import moment from "moment";
import img_placeholder from "../../images/img_placeholder.jpg";
import CommentsView from "./CommentsView";
import { RenderIf } from "components";
import {
    Dialog,
    DialogHeader,
    DialogBody,
    Button,
    Chip,
    Input,
    Textarea
} from "@material-tailwind/react";
// import useAddJobComment from "hooks/queries/useAddJobComment";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { STATUS } from "constants";
import { GET_JOB } from "apollo/queries/getJob";
import { UPDATE_JOB } from "apollo/mutations/updateJob";
import { TbCopy } from "react-icons/tb";
import copy from "copy-to-clipboard";
import { Tooltip } from "@material-tailwind/react";

export default function JobViewModal({ show, onHide, id }) {
    const client = useApolloClient()

    // const { updateComents } = useAddJobComment()
    const { data: clickedJob, loading } = useQuery(GET_JOB, {
        variables: { id },
        onCompleted: res => console.log({ res })
    })

    const [mutate, { loading: isLoadingCommentUpdate }] = useMutation(UPDATE_JOB)

    const [index, setIndex] = useState(0);
    const [charges, setCharges] = useState([]);
    const [commentBody, setCommentBody] = useState("");
    const [commentTitle, setCommentTittle] = useState("");
    const [copied, setCopied] = useState(false);

    const CommentInput = React.useRef(null);
    const CommentTextarea = React.useRef(null);

    useEffect(() => {
        console.log({ clickedJob })
        if (clickedJob?.getJob?.status === STATUS.COMPLETED) {
            setCharges(
                clickedJob?.getJob?.codesCharged?.map((code) => code.code)
            );
        }
        // dispatch({ type: "updateComments", payload: clickedJob?.comments });

        //eslint-disable-next-line
    }, [clickedJob]);

    const formatingDate = (value) => {
        return moment(value).format("l");
    };

    const onChangeTitle = (e) => setCommentTittle(e.target.value);

    const onChangeBody = (e) => {
        setCommentBody(e.target.value);
    };

    const addComment = () => {
        if (commentTitle || commentBody) {
            mutate({
                variables: {
                    input: {
                        filter: { id },
                        set: {
                            comments: [
                                { title: commentTitle, body: commentBody }
                            ]
                        }
                    }
                },
                onCompleted: async () => { 
                    await client.refetchQueries({
                        include: ["getJob"],
                    })
                }
            });

            setCommentTittle("");
            setCommentBody("");
            CommentInput.current.value = "";
            CommentTextarea.current.value = "";
        }
    };

    const copyHandler = (event) => { 
        event.stopPropagation();
        setCopied(true)
        copy(clickedJob?.getJob?.jobNumber)
    }    

    const chipTextValues = ["New Job", "Received", "Completed", "Invoiced", "Picked Up"];
    const chipColorValues = ["gray", "green", "amber", "blue", "purple"]

    return (
        <>
            <Dialog open={show} handler={onHide} size="lg" className="outline-none">
                <DialogHeader>
                    <div className="w-full flex justify-between items-center">
                        <Tooltip
                            content={copied ? "Copied" : "Copy to clipboard"}
                            className="text-xs"
                        >
                            <div
                                className="flex items-center bg-light-blue-500 rounded py-1 px-3 cursor-pointer"
                                onClick={copyHandler}
                            >
                                <TbCopy className="text-sm text-white mr-1" />
                                <span className="text-sm text-white">
                                    {clickedJob?.getJob?.jobNumber ?? "Loading..."}
                                </span>
                            </div>
                        </Tooltip>

                        <div>
                            <Chip
                                value={chipTextValues[clickedJob?.getJob?.status] ?? "Loading..."}
                                color={chipColorValues[clickedJob?.getJob?.status]}
                            />
                        </div>
                    </div>
                </DialogHeader>

                <DialogBody>
                    <RenderIf isTrue={loading}>
                        Loading...
                    </RenderIf>
                    <RenderIf isTrue={!loading && clickedJob}>
                        <div className="flex">
                            <div className="flex-1 mr-6 -ml-4">
                                <div className="w-full flex flex-col">
                                    <RenderIf isTrue={clickedJob?.getJob?.photosLocalIds.length}>
                                        <div className="grow ml-4">
                                            {clickedJob?.getJob?.photosLocalIds &&
                                                <img
                                                    src={clickedJob?.getJob?.photosLocalIds[index]}
                                                    alt="idx"
                                                    width="100%"
                                                    className="mb-2 rounded-lg"
                                                />}
                                            <div className="flex">
                                                {clickedJob?.getJob?.photosLocalIds?.map(
                                                    (photo, idx) => {
                                                        return (
                                                            <img
                                                                src={photo}
                                                                alt="idx"
                                                                key={idx}
                                                                width="30%"
                                                                className="mr-2 rounded-lg cursor-pointer"
                                                                onClick={() => setIndex(idx)}
                                                            />
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </RenderIf>
                                    <RenderIf isTrue={!clickedJob?.getJob?.photosLocalIds.length}>
                                        <div className="grow ml-4">
                                            <img
                                                src={img_placeholder}
                                                alt=""
                                                height="70%"
                                                width="100%"
                                                className="rounded-xl mb-2"
                                            />
                                        </div>
                                    </RenderIf>

                                    <div className="flex-1 ml-2">
                                        <div className="ml-2 mt-3">
                                            <div className="w-full flex justify-between items-center border-b-2 border-gray-200">
                                                <div className="text-sm font-semibold">Customer Name</div>
                                                <div className="text-sm font-semibold">{clickedJob?.getJob?.customerName}</div>
                                            </div>
                                            <div className="w-full flex justify-between items-center border-b-2 border-gray-200">
                                                <div className="text-sm font-semibold">Customer Phone Nomber</div>
                                                <div className="text-sm font-semibold">{clickedJob?.getJob?.customerPhoneNumber}</div>
                                            </div>
                                        </div>

                                        <div className="ml-2 mt-2">
                                            <div className="w-full flex justify-between items-center border-b-2 border-gray-200">
                                                <div className="text-sm font-semibold">Location</div>
                                                <Chip value={clickedJob?.getJob?.store?.storeNumber ?? "Loading..."} className="text-xs mb-1" />
                                            </div>
                                            <div className="w-full flex justify-between items-center border-b-2 border-gray-200">
                                                <div className="text-sm font-semibold">Value</div>
                                                <div className="text-sm font-semibold">{accounting.formatMoney(clickedJob?.getJob?.itemValue)}</div>
                                            </div>
                                            <div className="w-full flex justify-between items-center border-b-2 border-gray-200">
                                                <div className="text-sm font-semibold">Received</div>
                                                <div className="text-sm font-semibold">{formatingDate(clickedJob?.getJob?.receivedDate)}</div>
                                            </div>
                                            <div className="w-full flex justify-between items-center border-b-2 border-gray-200">
                                                <div className="text-sm font-semibold">Promised</div>
                                                <div className="text-sm font-semibold">{formatingDate(clickedJob?.getJob?.promiseDate)}</div>
                                            </div>
                                            <div className="w-full flex justify-between items-center border-b-2 border-gray-200">
                                                <div className="text-sm font-semibold">Market</div>
                                                <div className="text-sm font-semibold">{clickedJob?.getJob?.market}</div>
                                            </div>
                                            <div className="w-full flex justify-between items-center border-b-2 border-gray-200">
                                                <div className="text-sm font-semibold">Charges</div>
                                                <RenderIf isTrue={clickedJob?.getJob?.status !== STATUS.NEW_JOB && clickedJob?.getJob?.status !== STATUS.RECEIVED}>
                                                    <div className="text-sm font-semibold text-green-500">
                                                        {accounting?.formatMoney(clickedJob?.getJob?.moneyCharged)}
                                                    </div>
                                                </RenderIf>
                                                <RenderIf isTrue={clickedJob?.getJob?.status === STATUS.NEW_JOB || clickedJob?.getJob?.status === STATUS.RECEIVED}>
                                                    <div className="text-gray-400 italic text-xs">This value will update once Job is completed</div>
                                                </RenderIf>
                                            </div>
                                            <div className="w-full flex justify-between items-center">
                                                <div className="text-md font-semibold">Codes</div>
                                                <div>
                                                    <RenderIf isTrue={clickedJob?.getJob?.status !== STATUS.NEW_JOB && clickedJob?.getJob?.status !== STATUS.RECEIVED}>
                                                        {charges?.map((charge, index) => (
                                                            <Chip
                                                                key={index}
                                                                value={charge || "loading..."}
                                                                color="amber"
                                                                className="m-1"
                                                            />
                                                        ))}
                                                    </RenderIf>
                                                    <RenderIf isTrue={clickedJob?.getJob?.status === STATUS.NEW_JOB || clickedJob?.getJob?.status === STATUS.RECEIVED}>
                                                        <div className="text-gray-400 italic text-xs">This value will update once Job is completed</div>
                                                    </RenderIf>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex-1">
                                <CommentsView
                                    comments={clickedJob?.getJob?.comments}
                                    isLoadingCommentUpdate={isLoadingCommentUpdate}
                                />
                                <div className="flex flex-col justify-start items-start space-y-2">
                                    <Input
                                        label="Comment Title"
                                        ref={CommentInput}
                                        onChange={onChangeTitle}
                                        value={commentTitle}
                                    />
                                    <Textarea
                                        label="Comment Body"
                                        ref={CommentTextarea}
                                        onChange={onChangeBody}
                                        value={commentBody}
                                    />
                                    <Button
                                        variant="gradient"
                                        onClick={addComment}
                                    >
                                        Add Comment
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </RenderIf>
                </DialogBody>
                {/* <ToastContainer 
                        position="bottom-right"
                        theme="colored"
                        autoClose={8000}
                        closeOnClick={true}
                /> */}
            </Dialog>
        </>
    );
}
