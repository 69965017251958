import React, { useState } from 'react'
import EditView from "./EditView";
import DisplayView from "./DisplayView";
import { RenderIf } from "components";


const ToggleComponent = ({ field, store }) => {
    const [editMode, setEditMode] = useState(false)

    return (
        <div className="mb-2">
            <RenderIf isTrue={editMode}>
                <EditView
                    field={field}
                    store={store}
                    setEditMode={setEditMode}
                />
            </RenderIf>
            <RenderIf isTrue={!editMode}>
                <DisplayView
                    field={field}
                    store={store}
                    setEditMode={setEditMode}
                />
            </RenderIf>
        </div>
    )
}

export default ToggleComponent
