import React from "react";
import LoginForm from "./LoginForm.js";
import ForgottenPass from "./ForgottenPass";
import { RenderIf, PageHeader } from 'components'

const AuthLogin = () => {
    const [forgottenPassword, setForgottenPassword] = React.useState(false)

    const forgottenPassToggling = (e) => {
        e.preventDefault();
        setForgottenPassword(current => !current);
    };

   
    return (
        <div className="w-full h-screen flex flex-col justify-between items-center bg-blue-gray-50">
            <PageHeader />
            <div className="bg-gradient-to-b from-blue-gray-50 to-white py-16 3xl:py-20 px-12 3xl:px-16 shadow-xl rounded-xl border border-1 border-white -mt-44">
                <RenderIf isTrue={forgottenPassword}>
                    <ForgottenPass forgottenPassToggling={forgottenPassToggling} />
                </RenderIf>
                <RenderIf isTrue={!forgottenPassword}>
                    <LoginForm forgottenPassToggling={forgottenPassToggling} />
                </RenderIf>
            </div>
            <div className="w-1/2 text-center text-xs text-white mb-12">
                Bench Manager is a software produced by Softcro.
                2022. All Rights Reserved.
            </div>
        </div>

    );

}

export default AuthLogin;
