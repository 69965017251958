import { useState, useEffect } from "react"
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom"
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { RenderIf, Spinner } from "components"
import {
    Website,
    AuthLogin,
    // ErrorPage,
    NewRegistrationLandingPage,
    AuthRegistration,
} from "pages";
import { privateRoutes } from "./config";
import PrivateRoute from "./PrivateRoute";
import { getFunctions, httpsCallable } from "firebase/functions";
import "firebase/functions";
// apollo client provider
import { ApolloProvider } from "@apollo/client";
import { createApolloClient } from "apollo/client";
import { useRegisteringUser, useUserPhoto } from "states";

export default function Routes() {
    const auth = getAuth()
    const functions = getFunctions();
    const addUserClaim = httpsCallable(functions, 'addUserClaim');

    const [registeringUser] = useRegisteringUser()
    const [, setUserPhoto] = useUserPhoto()
    
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)
    const [tokenId, setTokenId] = useState(true)


    const authListener = () => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {                
                setUser(user);
                addUserClaim({ email: user.email });
                const token = await user.getIdToken();
                setTokenId(token);
                setUserPhoto(user.photoURL)
            } else {
                setUser(null);
            }
            setLoading(false)
        });
    }

    useEffect(() => {
        authListener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const client = createApolloClient(tokenId)

    return (
        <ApolloProvider client={client}>
            {loading ?
                <div className="w-full h-screen flex justify-center items-center">
                    <Spinner />
                </div>
                : 
                <BrowserRouter>
                    <Switch>
                        {/* Maybe the route / should just point to the website */}
                        <Route path="/" exact>
                            <RenderIf isTrue={!user}>
                                <Website />
                            </RenderIf>
                            <RenderIf isTrue={user && !registeringUser}>
                                <Redirect to="/dashboard" />
                            </RenderIf>
                        </Route>

                        <Route path="/registration-form" exact>
                            <RenderIf isTrue={!user || registeringUser}>
                                <AuthRegistration />
                            </RenderIf>
                            <RenderIf isTrue={user && !registeringUser}>
                                <Redirect to="/dashboard" />
                            </RenderIf>
                        </Route>
                        
                        <Route path="/authenticate" exact>
                            <RenderIf isTrue={!user}>
                                <AuthLogin />
                            </RenderIf>
                            <RenderIf isTrue={user && !registeringUser}>
                                <Redirect to="/dashboard" />
                            </RenderIf>
                        </Route>
                        
                        <Route path="/user-needs-email-verifcation-landing-page" exact>
                            <RenderIf isTrue={user && !registeringUser && !user?.emailVerified}>
                                <NewRegistrationLandingPage />
                            </RenderIf>
                            <RenderIf isTrue={user && !registeringUser && user?.emailVerified}>
                                <Redirect to="/dashboard" />
                            </RenderIf>
                        </Route>

                        {/*  Protected routes */}
                        {privateRoutes.map(({ component, path }) => (
                            <PrivateRoute
                                key={path}
                                component={component}
                                path={path}
                                user={user}
                            />
                        ))}
                        {/* <Route path="*">
                            <ErrorPage />
                        </Route> */}
                    </Switch>
                </BrowserRouter>
            }
        </ApolloProvider>
    );
}
