import React, { useState, useEffect } from 'react'
import { getAuth, signOut } from "firebase/auth";
import { useHistory } from "react-router-dom";
import { Button, Input } from "@material-tailwind/react";
import ConfirmationModal from "./ConfirmationModal";
import useUser from 'hooks/useUser'
import { RenderIf, Heading } from "components";
import { ToastContainer, toast } from "react-toastify";
import { useQuery, useMutation } from "@apollo/client";
import { GET_USER_DATA } from "apollo/queries/getUserData";
import { UPDATE_USER } from "apollo/mutations/updateUser";
import moment from 'moment';
import { useCreateCustomerPortalSession, useRetrieveStripeCustomer } from 'hooks';

const SettingsCard = () => {
    const auth = getAuth()
    const history = useHistory();
    const { currentUser } = useUser()

    const { mutate: retrieveStripeCustomer, data: customerData } = useRetrieveStripeCustomer({
        onSuccess: async response => {
            console.log('retrieved customer: ', response)
        },
        onError: error => console.log('Error retrieving customer: ', { error })
    })

    const { data, refetch } = useQuery(GET_USER_DATA, {
        variables: { email: currentUser.email },
        onCompleted: response => {
            console.log('user data on page', { response })
            retrieveStripeCustomer({ id: response.getUser.stripeCustomerId })
        },
        onError: (error) => toast(error.message, {
            type: 'error',
        })
    })

    const { mutate: createCustomerPortalSession } = useCreateCustomerPortalSession({
        onSuccess: response => {
            console.log('Customer Portal Session:', { response })
            window.location = response.data.sessionUrl
        }
    })

    const [logoutModalOpen, setLogoutModalOpen] = useState(false)
    const [showSubmitUserInfoButton, setShowSubmitUserInfoButton] = useState(false)
    const [userContactInfo, setUserContactInfo] = useState({
        businessName: '',
        phoneNumber: '',
        businessAddress: '',
    })

    const [updateUser, { loading: loadingUserMutation }] = useMutation(UPDATE_USER)

    const handleInputChange = (e) => { 
        setUserContactInfo(current => ({ ...current, [e.target.name]: e.target.value }))
    }

    const logout = async () => {
        await signOut(auth)
            .then(() => history.push("/authenticate"))
    };

    const handleUpdateUserContactInfo = () => {
        updateUser({
            variables: {
                input: {
                    filter: {
                        id: data?.getUser?.id,
                    }, set: {
                        ...userContactInfo
                    }
                }
            },
            onError: err => console.log(err),
            onCompleted: () => { 
                refetch()
                setShowSubmitUserInfoButton(false)
            }
        })
    }

    useEffect(() => { 
        if (
            userContactInfo.businessName !== data?.getUser?.businessName ||
            userContactInfo.phoneNumber !== data?.getUser?.phoneNumber ||
            userContactInfo.businessAddress !== data?.getUser?.businessAddress
        ) {
            setShowSubmitUserInfoButton(true)
        } else { 
            setShowSubmitUserInfoButton(false)
        }
    }, [userContactInfo])

    useEffect(() => { 
        setUserContactInfo({
            businessName: data?.getUser?.businessName,
            phoneNumber: data?.getUser?.phoneNumber,
            businessAddress: data?.getUser?.businessAddress,
        })
    }, [data])


    return (
        <div className='flex flex-col bg-white w-full mb-6 shadow-lg rounded-xl border border-blue-gray-50 p-6'>
            <Heading size='sm' className="mb-6">
                Account Settings
            </Heading>

            <Heading size='xs' className="my-3" colorClass="text-blue-gray-300">
                Account Activity:
            </Heading>
            <div className='w-full flex flex-col justify-between items-center'>
                <div className='w-full flex'>
                    <div className='mr-6 flex-1'>
                        <Row
                            label="Created on:"
                            data={moment(data?.getUser?.createdOn).format("LLLL")}
                        />
                        <Row 
                            label="Last login at:"
                            data={moment(new Date(Number(currentUser.metadata.lastLoginAt))).format("LLLL")}
                        />
                        <Row 
                            label="Last Signin time at:"
                            data={moment(currentUser.metadata.lastSignInTime).format("LLLL")}
                        />
                    </div>
                </div>
            </div>

            <Heading size='xs' className="mt-8" colorClass="text-blue-gray-300">
                Account Information:
            </Heading>
            <div className='w-full flex mt-6'>
                <div className='flex-1 w-full md:w-1/2 lg:w-1/3 mr-6'>
                    <Input
                        label='Account Phone Number:'
                        value={userContactInfo.phoneNumber}
                        name="phoneNumber"
                        onChange={handleInputChange}
                    />
                </div>
                <div className='flex-1 w-full md:w-1/2 lg:w-1/3 mr-6'>
                    <Input
                        label='Account Company Name:'
                        value={userContactInfo.businessName}
                        name="businessName"
                        onChange={handleInputChange}
                    />
                </div>
                <div className='flex-1 w-full md:w-1/2 lg:w-1/3 mr-6'>
                    <Input
                        label='Account Address:'
                        value={userContactInfo.businessAddress}
                        name="businessAddress"
                        onChange={handleInputChange}
                    />
                </div>
            </div>
            <RenderIf isTrue={showSubmitUserInfoButton}>
                <div className="w-full flex justify-start">
                    <Button
                        size='sm'
                        className='mt-6 w-fit'
                        color={loadingUserMutation ? 'gray' : 'light-blue'}
                        onClick={handleUpdateUserContactInfo}
                        disabled={loadingUserMutation}
                    >
                        {loadingUserMutation ? 'Working...' : 'Save changes'}
                    </Button>
                </div>
            </RenderIf>

            <Heading size='xs' className="mt-8" colorClass="text-blue-gray-300">
                Subscription Information:
            </Heading>
            <div className='w-full flex flex-col '>
                <div className='w-full flex mt-6'>
                    <div className='mr-6 flex-1'>
                        <Row
                            label="User:"
                            data={data?.getUser?.email}
                        />
                        <Row
                            label="Status:"
                            data={customerData?.data?.customer?.subscriptions?.data[0]?.status}
                        />
                        <Row
                            label="Subscribed on:"
                            data={moment(customerData?.data?.customer?.subscriptions?.data[0]?.created).format("LLLL")}
                        />
                        <Row
                            label="Currency:"
                            data={customerData?.data?.customer?.subscriptions?.data[0]?.plan.currency}
                        />
                        <Row
                            label="Current billing cycle end:"
                            data={moment(customerData?.data?.customer?.subscriptions?.data[0]?.current_period_end).format("LLLL")}
                        />
                    </div>
                </div>
            </div>
            <div className='w-full flex justify-end mt-40'>
                <div
                    className='text-blue-500 mt-3 text-xs cursor-pointer'
                    onClick={() => createCustomerPortalSession({ id: customerData?.data?.customer?.id })}
                >
                    Edit Billing and Plan
                </div>
            </div>
            <div className='pb-6 border-b-2 border-blue-gray-200 mb-6' />
            <div className='w-full flex justify-start items-center'>
                <Button
                    variant="gradient"
                    size='sm'
                    color='deep-purple'
                    className="mb-2"
                    onClick={() => setLogoutModalOpen(true)}
                >
                    Logout
                </Button>
            </div>

            <ConfirmationModal
                isOpen={logoutModalOpen}
                setIsOpen={setLogoutModalOpen}
                headerText="User Logout"
                message="Are you sure you want to logout?"
                callback={logout}
            />
            <ToastContainer 
                position="bottom-right"
                theme="colored"
                autoClose={8000}
                closeOnClick={true}
            />
        </div>
    )
}

export default SettingsCard


const Row = ({ label, data }) => (
    <div className='flex items-center text-xs text-blue-gray-400'>
        <RenderIf isTrue={label}>
            <h6 className='mr-2 font-black'>{label}</h6>
        </RenderIf>
            <div>{data}</div>
        </div>
    )