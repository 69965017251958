import { gql } from "@apollo/client";

export const UPDATE_STORE = gql`
mutation updateStore($input: UpdateStoreInput!) {
    updateStore(input: $input) {
        store {
            id
        }
    }
}
`