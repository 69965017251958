import React, { useState, Fragment } from "react";
import accounting from "accounting-js";
import { Input, Button, Tooltip } from '@material-tailwind/react';
import { IoMdAdd } from 'react-icons/io';
import { ToastContainer, toast } from "react-toastify";
import { useQuery, useLazyQuery } from "@apollo/client";
import { GET_USER_DATA } from "apollo/queries/getUserData";
import { GET_REPAIR_CODES } from "apollo/queries/getRepairCodes";
import { useUser } from "hooks";
import { RenderIf } from "components";

const CodeInputRow = ({ job, market, buildChargedCode }) => {
    const { currentUser } = useUser()

    const [showSuggestions, setShowSuggestions] = useState([])
    const CodeInputElement = React.useRef(null)
    const [state, setState] = React.useState({
        codeFieldValue: "",
        quantityFieldValue: "1",
        taskDescription: "Task Description...",
        taskPrice: 0,
        errors: "",
    })

    const [getRepairCodes, { data }] = useLazyQuery(GET_REPAIR_CODES, {
        onCompleted: (res) => console.log({res})
    })

    useQuery(GET_USER_DATA, {
        variables: { email: currentUser.email },
        onCompleted: (res) => getRepairCodes({
            variables: { id: res?.getUser?.userRepairCodes?.id },
        })
    })

    const blurHandler = (e) => {
        let repairGroups = data?.getUserRepairCodes?.userRepairCodeGroups;
        if (e.target.value !== "") {
            for (let g = 0; g < repairGroups?.length; g++) {
                const repairGroup = repairGroups[g]
                const repairGroupCodes = repairGroup.groupCodes
                for (let c = 0; c < repairGroupCodes?.length; c++) {
                    let code = repairGroupCodes[c];
                    if (Number(state.codeFieldValue) === code.code) {
                        setState(current => ({
                            ...current,
                            taskDescription: code.description,
                            taskPrice: market === "Retail" ? code.retailPrice : code.wholeSalePrice
                        }));
                        return;
                    }
                }
            }
        }
    };

    const handleCodeClick = (code) => { 
        setState(current => ({
            ...current,
            codeFieldValue: code.code.toString(),
            taskDescription: code.description,
            taskPrice: market === "Retail" ? code.retailPrice : code.wholeSalePrice
        }));
        setShowSuggestions(false)
    }

    const focusHandler = () => {
        setState(current => ({ ...current, errors: "" }));
    };

    const qtyOnBlurHandler = () => {
        setState(current => ({
            ...current,
            taskPrice: Number(state.taskPrice) * Number(state.quantityFieldValue)
        }));
    };

    const sendCode = () => {
        if (state.codeFieldValue && state.taskPrice !== undefined && !(state.taskDescription === "Task Description...")) {
            buildChargedCode(
                Number(state.codeFieldValue),
                state.taskDescription,
                state.quantityFieldValue,
                Number(state.taskPrice)
            );
            setState({
                codeFieldValue: "",
                quantityFieldValue: "1",
                taskDescription: "Task Description...",
                taskPrice: 0,
                errors: "",
            });
        } else {
            setState(current => ({ ...current, errors: `The code entered doesn't exist.` }));
        }
    };


    const onChangeHandler = (e) => {
        setState(current => ({ ...current, [e.target.name]: e.target.value }));
        setShowSuggestions(true)
    };

    React.useEffect(() => { 
        CodeInputElement.current.focus();
    }, [])
    
    React.useEffect(() => { 
        if (state.errors) {
            toast(state.errors, {
                type: "error",
            })
        }
    }, [state.errors])

    const flattenedPrices = data?.getUserRepairCodes?.userRepairCodeGroups.map(group => group.groupCodes).flat()
    const suggestedCodesList = flattenedPrices?.filter(
        code => (
            code?.code?.toString().includes(state?.codeFieldValue) ||
            code?.description?.toLowerCase()?.includes(state?.codeFieldValue?.toLowerCase())
        )
    )

    const evaluateKey = (e, code) => {
        if (e.keyCode === 32) {
            handleCodeClick(code)
        }
    };

    return (
        <>
            <div className="w-full flex items-center">
                <span className="flex-1 relative">
                    <Input
                        label="Code"
                        name="codeFieldValue"
                        id="CodeInputElement"
                        ref={CodeInputElement}
                        value={state.codeFieldValue}
                        onBlur={blurHandler}
                        onFocus={focusHandler}
                        onChange={onChangeHandler}
                        disabled={!job}
                    />
                    <RenderIf isTrue={state.codeFieldValue && showSuggestions && suggestedCodesList?.length}>
                        <div
                            className="absolute top-8 bg-white py-2 rounded text-blue-gray-400 max-h-96 overflow-y-auto z-50"
                            style={{ width: '600px'}}
                        >
                            {suggestedCodesList?.map((code, index) => (
                                <Fragment key={`${code.code}-${index}`}>
                                    <div
                                        tabIndex="0"
                                        className="py-1 px-6 hover:bg-blue-gray-50 cursor-pointer"
                                        onClick={() => handleCodeClick(code)}
                                        onKeyDown={(e) => evaluateKey(e, code)}
                                    >
                                        <div className="flex items-center">
                                            <span className="mr-1 text-blue-gray-800 font-bold">{code?.code}</span>
                                            <span>{`| ${code?.description}`}</span>
                                        </div>
                                    </div>
                                </Fragment>
                            ))}
                        </div>
                    </RenderIf>
                </span>
                <span className="flex-1 text-center text-xs">
                    {state?.taskDescription}
                </span>
                <span className="flex-1 text-right">
                    <Input
                        label="Quantity"
                        name="quantityFieldValue"
                        value={state?.quantityFieldValue}
                        onChange={onChangeHandler}
                        onBlur={qtyOnBlurHandler}
                        disabled={!job}
                    />
                </span>
                <span className="flex-1 text-right font-semibold">
                    {accounting.formatMoney(state?.taskPrice)}
                </span>
                <span className="flex-1 text-right">
                    <Tooltip content="Add code">
                        <Button
                            variant="gradient"
                            color={job ? "green" : "gray"}
                            onClick={sendCode}
                            disabled={!job}
                        >
                            <IoMdAdd className="text-xl font-semibold" />
                        </Button>
                    </Tooltip>
                </span>
            </div>
            <ToastContainer 
                position="bottom-right"
                theme="colored"
                autoClose={8000}
                closeOnClick={true}
            />
        </>
    );
}

export default CodeInputRow