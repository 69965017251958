import React from 'react'
import accounting from "accounting-js"
import { ListRow } from 'components'
import { Checkbox } from "@material-tailwind/react";

const ListRowComp = ({ job, checkboxOnChangeHandler }) => {

    return (
        <ListRow className="flex justify-between items-center pl-6 pr-8">
            <div className='flex-1 flex items-center'>
                <Checkbox
                    // type="checkbox"
                    name={job.customerName}
                    checked={job.isChecked || false}
                    onChange={checkboxOnChangeHandler}
                />
                <div>{job.jobNumber}</div>
            </div>
            <div className='flex-1 text-right'>{job.customerName}</div>
            <div className='flex-1 text-right'>{accounting.formatMoney(job.moneyCharged)}</div>
            <div className='flex-1 text-right'>{job.storeNumber}</div>
        </ListRow>
    )
}

export default ListRowComp
