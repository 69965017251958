import React from 'react'

const Badge = ({ children, className, color }) => {
  const badgeColor = {
    indigo: 'bg-indigo-500 text-white',
    yellow: 'bg-yellow-500 text-black',
  }
  const backgroundColor = color ? badgeColor[color] : badgeColor['indigo']
    
  return (
      <span className={`${backgroundColor} text-xs font-medium px-1.5 py-0.5 rounded-full ${className}`}>
          {children}
      </span>
  )
}

export default Badge