import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth';
import { getStorage } from "firebase/storage";

const config = {
    apiKey: "AIzaSyAdW5FCkqABrzcdL6SRcbIRjovI16LiwcU",
    authDomain: "bench-manager-cms.firebaseapp.com",
    databaseURL: "https://bench-manager-cms.firebaseio.com",
    projectId: "bench-manager-cms",
    storageBucket: "bench-manager-cms.appspot.com",
    messagingSenderId: "1081347499056",
    appId: "1:1081347499056:web:43707a3062d90bd54039f5",
    measurementId: "G-K8GV3BYCQK"
}

const app = initializeApp(config)

export const auth = getAuth(app)
export const storage = getStorage(app)

export default app
