import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Input } from "@material-tailwind/react";
import { Heading, RenderIf } from "components";
import classNames from "classnames";
import { marketToLocationRelation } from "constants";
import * as yup from 'yup';
import { ToastContainer, toast } from "react-toastify";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { GET_USER_DATA } from "apollo/queries/getUserData";
import { ADD_JOB } from "apollo/mutations/addJob";
import { useUser } from "hooks";
import moment from "moment";
import { IoCalendar } from "react-icons/io5";

export default function InputNewJob({ setLoadingMutation }) {
    const client = useApolloClient()
    const { currentUser } = useUser()
    
    const [mutate, { loading }] = useMutation(ADD_JOB)
    React.useEffect(() => { 
        setLoadingMutation(loading)
    }, [loading, setLoadingMutation])

    const { data: userData } = useQuery(GET_USER_DATA, {
        variables: { email: currentUser.email },
        onError: (error) => toast(error.message, { type: 'error' }),
    })

    const [jobNumber, setJobNumber] = React.useState("");
    const [customerName, setCustomerName] = React.useState("");
    const [customerEmail, setCustomerEmail] = React.useState("");
    const [customerPhoneNumber, setCustomerPhoneNumber] = React.useState("");
    const [itemValue, setItemValue] = React.useState("");
    const [storeNumber, setStoreNumber] = React.useState("Select Store");
    const [market, setMarket] = React.useState("");
    const [promiseDate, setPromiseDate] = React.useState("");
    const [errors, setErrors] = React.useState([])

    const clearState = () => {
        setJobNumber("");
        setCustomerName("");
        setCustomerPhoneNumber("");
        setCustomerEmail("");
        setItemValue("");
        setStoreNumber("Select Store");
        setMarket("");
        setPromiseDate("");
        setErrors([])
    };

    const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
    const validationSchema = yup.object().shape({
        jobNumber: yup.string(),
        customerName: yup.string().required(),
        customerPhoneNumber: yup.string().matches(phoneRegExp, 'customerPhoneNumber is not valid'),
        customerEmail: yup.string().email().required(),
        itemValue: yup.number().required().positive(),
        promiseDate: yup.date().required(),
        createdOn: yup.date().default(function () {
            return new Date();
        }),
    })

    // Validate all this with yup
    const onSubmit = () => {
        let integerUniqueId;
        if (itemValue) {
            setItemValue(current => Number(itemValue).toFixed(2))
        }
        if (!jobNumber) { 
            integerUniqueId = Date.now()
            setJobNumber(integerUniqueId.toString())
        }
        validationSchema.validate({
            jobNumber: jobNumber || integerUniqueId.toString(),
            customerName: customerName.trim(),
            customerPhoneNumber,
            customerEmail: customerEmail.trim(),
            itemValue: Number(itemValue).toFixed(2),
            promiseDate,
        }, { abortEarly: false })
            .then(data => {
            mutate({
                variables: { input: {
                        ...data,
                        comments: [],
                        status:0,
                        market,
                        store: {
                            storeNumber: storeNumber,
                            user: { email: currentUser.email },
                            id: userData?.getUser?.userStores.find(store => store.storeNumber === storeNumber).id
                        },
                        userJobs: { id: userData.getUser.userJobs.id }
                    }
                },
                onCompleted: async () => { 
                await client.refetchQueries({
                        include: ["getUserJobs"]
                    })
                }
            })
            clearState();
            setStoreNumber("Select Store")
        }).catch(error => { 
            console.log({ error })
            setErrors(error?.errors)
            toast(`Please ensure that all required fields are filled in with information in the correct format.`, {
                type: "error",
            })
        })
    };

    const handleFocus = (e) => {
        setErrors([]);
    };

    const onNumberChange = (e) => setJobNumber(e.target.value);
    const onNameChange = (e) => setCustomerName(e.target.value);
    const onPhoneChange = (e) => setCustomerPhoneNumber(e.target.value);
    const onEmailChange = (e) => setCustomerEmail(e.target.value);
    const onValueChange = (e) => setItemValue(e.target.value);
    const onStoreChange = (e) => {
        console.log(e.target.value)
        setStoreNumber(e.target.value);
        let store = userData?.getUser?.userStores?.find((store) => store.storeNumber === e.target.value);
        const marketPrice = marketToLocationRelation[store.relation]
        setMarket(marketPrice)
    };
    const onDateChange = (date) => {
        handleFocus();
        setPromiseDate(date);
    };

    React.useEffect(() => {
        return () => clearState();
    }, []);

    const DatepickerCustomInput = forwardRef(({ onClick }, ref) => (
        <Button
            color="gray"
            onClick={onClick}
            className='w-full'
            ref={ref}
        >
            <RenderIf isTrue={promiseDate}>
                {moment(promiseDate).format("l")}
            </RenderIf>
            <RenderIf isTrue={!promiseDate}>
                <div className="w-full flex justify-center items-center">
                    Set Promise Date
                    <IoCalendar className="ml-2" />
                </div>
            </RenderIf>
        </Button>
    ));

    return (
        <div className="w-80 p-1 mr-6">
            <div className="flex flex-col">
                <form
                    autoComplete="off"
                    className="bg-white space-y-2 p-6 shadow-xl rounded-xl border border-blue-gray-50" id="myform">
                <Heading size='sm'>
                    Enter Job:
                </Heading>
                    <Input
                        label="Job Number"
                        name="jobNumber"
                        value={jobNumber}
                        onChange={onNumberChange}
                        onFocus={handleFocus}
                        error={Boolean(errors?.some(error => error?.includes('jobNumber')))}
                    />
                    <Input
                        label="Customer Name"
                        name="customerName"
                        value={customerName}
                        onChange={onNameChange}
                        onFocus={handleFocus}
                        error={Boolean(errors?.some(error => error?.includes('customerName')))}
                    />
                    <Input
                        type="tel"
                        label="Phone number"
                        name="customerPhoneNumber"
                        value={customerPhoneNumber}
                        onChange={onPhoneChange}
                        onFocus={handleFocus}
                        error={Boolean(errors?.some(error => error?.includes('customerPhoneNumber')))}
                    />
                    <Input
                        label="Email"
                        name="customerEmail"
                        value={customerEmail}
                        onChange={onEmailChange}
                        onFocus={handleFocus}
                        error={Boolean(errors?.some(error => error?.includes('customerEmail')))}
                    />
                    <Input
                        label="Item Value"
                        name="itemValue"
                        value={itemValue}
                        onChange={onValueChange}
                        onFocus={handleFocus}
                        error={Boolean(errors?.some(error => error?.includes('itemValue')))}
                    />
                    <select
                        name="storeNumber"
                        value={storeNumber}
                        onChange={onStoreChange}
                        className={classNames(`h-10 border border-1 border-blue-gray-300 rounded-lg w-full px-2 text-blue-gray-500 text-sm`, {
                            "border-red-600 text-red-600": Boolean(errors?.some(error => error?.includes('storeNumber'))),
                        })}
                    >
                        <option value="Select Store">
                            {"Select Store"}
                        </option>
                        {userData?.getUser?.userStores?.map((store) =>
                            <option key={store.id} value={store.storeNumber.toString()}>
                                {store?.storeNumber?.toString()}
                            </option>
                        )}
                    </select>
                    <h1 className="text-xs">Promise Date:</h1>
                    <DatePicker
                        onChange={onDateChange}
                        selected={promiseDate}
                        minDate={new Date()}
                        showDisabledMonthNavigation
                        customInput={<DatepickerCustomInput />}
                    />
                    <Button
                        variant="gradient"
                        color="blue"
                        style={{ width: "100%" }}
                        onClick={onSubmit}
                    >
                        Enter
                    </Button>
                </form>
            </div>
            <ToastContainer 
                position="bottom-right"
                theme="colored"
                autoClose={8000}
                closeOnClick={true}
            />
        </div>
    );
}
