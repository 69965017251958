import axios from 'axios';
import { useMutation } from 'react-query';

const verifyTokenResponse = ({ token }) =>
    axios.post(`https://us-central1-bench-manager-cms.cloudfunctions.net/v1/google-recapcha-verification`, {
        token,
    }
);

const useGoogleCaptchaVerification = (options) =>
    useMutation(verifyTokenResponse, options);

export default useGoogleCaptchaVerification;
