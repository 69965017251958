import React from "react";
import AddedCode from "./AddedCode";

const AddedCodesList = ({ codesCharged, removeCode, updateTotal }) => (
    <>
        {codesCharged.map((code, index) => (
            <AddedCode
                key={`${index} - ${code.code}`}
                code={code}
                removeCode={removeCode}
                updateTotal={updateTotal}
            />
        ))}
    </>
);

export default AddedCodesList;
