export const smartphoneCodes = (userEmail) => ([
    {
        entity: 'iPhone',
        repairGroup: "Screen Replacements",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "iPhone",
                code: 101,
                description: "iPhone 4/4S Screen Replacement",
                wholeSalePrice: 25.00,
                retailPrice: 49.99,
            },
            {
                entity: "iPhone",
                code: 102,
                description: "iPhone 5/5S/5C/SE Screen Repair",
                wholeSalePrice: 35,
                retailPrice: 60,
            },
            {
                entity: "iPhone",
                code: 103,
                description: "iPhone 6 Screen Repair",
                wholeSalePrice: 35,
                retailPrice: 60,
            },
            {
                entity: "iPhone",
                code: 104,
                description: "iPhone 6 Plus Screen Repair",
                wholeSalePrice: 45,
                retailPrice: 70,
            },
            {
                entity: "iPhone",
                code: 105,
                description: "iPhone 6S Glass & LCD Screen Repair",
                wholeSalePrice: 45,
                retailPrice: 70,
            },
            {
                entity: "iPhone",
                code: 106,
                description: "iPhone 6S Plus Screen Replacement",
                wholeSalePrice: 49.99,
                retailPrice: 80,
            },
            {
                entity: "iPhone",
                code: 107,
                description: "iPhone 7 Screen Replacement",
                wholeSalePrice: 49.99,
                retailPrice: 80,
            },
            {
                entity: "iPhone",
                code: 108,
                description: "iPhone 7 Plus Screen Replacement",
                wholeSalePrice: 55.99,
                retailPrice: 90,
            },
            {
                entity: "iPhone",
                code: 109,
                description: "iPhone 8 Screen Replacement",
                wholeSalePrice: 55.99,
                retailPrice: 90,
            },
            {
                entity: "iPhone",
                code: 110,
                description: "iPhone 8 Plus Screen Replacement",
                wholeSalePrice: 60,
                retailPrice: 100,
            },
            {
                entity: "iPhone",
                code: 111,
                description: "iPhone 7 Screen Replacement",
                wholeSalePrice: 49.99,
                retailPrice: 80,
            },
            {
                entity: "iPhone",
                code: 112,
                description: "iPhone SE 2020 Screen Replacement",
                wholeSalePrice: 50,
                retailPrice: 90,
            },
            {
                entity: "iPhone",
                code: 113,
                description: "iPhone X Screen Replacement",
                wholeSalePrice: 69.99,
                retailPrice: 120,
            },
            {
                entity: "iPhone",
                code: 114,
                description: "iPhone XR Screen Replacement",
                wholeSalePrice: 69.99,
                retailPrice: 120,
            },
            {
                entity: "iPhone",
                code: 115,
                description: "iPhone XS Screen Replacement",
                wholeSalePrice: 69.99,
                retailPrice: 120,
            },
            {
                entity: "iPhone",
                code: 116,
                description: "iPhone XS Max Screen Replacement",
                wholeSalePrice: 79.99,
                retailPrice: 140,
            },
            {
                entity: "iPhone",
                code: 117,
                description: "iPhone 11 Screen Replacement",
                wholeSalePrice: 79.99,
                retailPrice: 140,
            },
            {
                entity: "iPhone",
                code: 118,
                description: "iPhone 11 Pro Screen Replacement",
                wholeSalePrice: 95.99,
                retailPrice: 160,
            },
            {
                entity: "iPhone",
                code: 119,
                description: "iPhone 11 Pro Max Screen Replacement",
                wholeSalePrice: 140,
                retailPrice: 200,
            },
            {
                entity: "iPhone",
                code: 120,
                description: "iPhone 12 OEM Quality Screen Replacement",
                wholeSalePrice: 180,
                retailPrice: 260,
            },
            {
                entity: "iPhone",
                code: 121,
                description: "iPhone 12 Aftermarket Screen Replacement",
                wholeSalePrice: 140,
                retailPrice: 210,
            },
            {
                entity: "iPhone",
                code: 122,
                description: "iPhone 12 Mini OEM Quality Screen Replacement",
                wholeSalePrice: 115,
                retailPrice: 180,
            },
            {
                entity: "iPhone",
                code: 123,
                description: "iPhone 12 Pro OEM Quality Screen Replacement",
                wholeSalePrice: 180,
                retailPrice: 260,
            },
            {
                entity: "iPhone",
                code: 124,
                description: "iPhone 12 Pro Aftermarket Screen Replacement",
                wholeSalePrice: 140,
                retailPrice: 210,
            },
            {
                entity: "iPhone",
                code: 125,
                description: "iPhone 12 Pro Max OEM Quality Screen Replacement",
                wholeSalePrice: 170,
                retailPrice: 280,
            },
        ]
    },
    {
        entity: 'iPhone',
        repairGroup: "Battery Replacements",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "iPhone",
                code: 130,
                description: "iPhone 4/4S Battery Replacement",
                wholeSalePrice: 25,
                retailPrice: 40,
            },
            {
                entity: "iPhone",
                code: 131,
                description: "iPhone 5/5S/5C/SE Battery Replacement",
                wholeSalePrice: 28.99,
                retailPrice: 45,
            },
            {
                entity: "iPhone",
                code: 132,
                description: "iPhone 6 Battery Replacement",
                wholeSalePrice: 39.99,
                retailPrice: 60,
            },
            {
                entity: "iPhone",
                code: 133,
                description: "iPhone 6S Battery Replacement",
                wholeSalePrice: 28.99,
                retailPrice: 50,
            },
            {
                entity: "iPhone",
                code: 134,
                description: "iPhone 6 Plus Battery Replacement",
                wholeSalePrice: 39.99,
                retailPrice: 60,
            },
            {
                entity: "iPhone",
                code: 135,
                description: "iPhone 6S Plus Battery Replacement",
                wholeSalePrice: 28.99,
                retailPrice: 50,
            },
            {
                entity: "iPhone",
                code: 136,
                description: "iPhone 7 Battery Replacement",
                wholeSalePrice: 28.99,
                retailPrice: 50,
            },
            {
                entity: "iPhone",
                code: 137,
                description: "iPhone 7 Plus Battery Replacement",
                wholeSalePrice: 34.99,
                retailPrice: 60,
            },
            {
                entity: "iPhone",
                code: 138,
                description: "iPhone 8 Battery Replacement",
                wholeSalePrice: 38.99,
                retailPrice: 60,
            },
            {
                entity: "iPhone",
                code: 139,
                description: "iPhone 8 Plus Battery Replacement",
                wholeSalePrice: 45.99,
                retailPrice: 70,
            },
            {
                entity: "iPhone",
                code: 140,
                description: "iPhone X Battery Replacement",
                wholeSalePrice: 50.99,
                retailPrice: 80,
            },
            {
                entity: "iPhone",
                code: 141,
                description: "iPhone XR Battery Replacement",
                wholeSalePrice: 50.99,
                retailPrice: 80,
            },
            {
                entity: "iPhone",
                code: 142,
                description: "iPhone XS Battery Replacement",
                wholeSalePrice: 45.99,
                retailPrice: 70,
            },
            {
                entity: "iPhone",
                code: 143,
                description: "iPhone XS Max Battery Replacement",
                wholeSalePrice: 50.99,
                retailPrice: 80,
            },
            {
                entity: "iPhone",
                code: 144,
                description: "iPhone 11 Battery Replacement",
                wholeSalePrice: 60.99,
                retailPrice: 90,
            },
            {
                entity: "iPhone",
                code: 145,
                description: "iPhone 11 Pro Battery Replacement",
                wholeSalePrice: 60.99,
                retailPrice: 90,
            },
            {
                entity: "iPhone",
                code: 146,
                description: "iPhone 11 Pro Max Battery Replacement",
                wholeSalePrice: 60.99,
                retailPrice: 100,
            },
            {
                entity: "iPhone",
                code: 147,
                description: "iPhone SE 2020 Battery Replacement",
                wholeSalePrice: 36.99,
                retailPrice: 60,
            },
            {
                entity: "iPhone",
                code: 148,
                description: "iPhone 12 Battery Replacement",
                wholeSalePrice: 58.99,
                retailPrice: 99,
            },
            {
                entity: "iPhone",
                code: 149,
                description: "iPhone 12 Mini Battery Replacement",
                wholeSalePrice: 58.99,
                retailPrice: 99,
            },
            {
                entity: "iPhone",
                code: 150,
                description: "iPhone 12 Pro Battery Replacement",
                wholeSalePrice: 69.99,
                retailPrice: 120,
            },
            {
                entity: "iPhone",
                code: 151,
                description: "iPhone 12 Pro Max Battery Replacement",
                wholeSalePrice: 69.99,
                retailPrice: 120,
            },
        ]
    },
    {
        entity: 'iPhone',
        repairGroup: "Cameras Replacements",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "iPhone",
                code: 160,
                description: "iPhone 4/4S Camera Replacement",
                wholeSalePrice: 25,
                retailPrice: 45,
            },
            {
                entity: "iPhone",
                code: 161,
                description: "iPhone 5/5S/5C/SE Camera Replacement",
                wholeSalePrice: 28.99,
                retailPrice: 50,
            },
            {
                entity: "iPhone",
                code: 162,
                description: "iPhone 6S Camera Lens",
                wholeSalePrice: 25,
                retailPrice: 40,
            },
            {
                entity: "iPhone",
                code: 163,
                description: "iPhone 6S Camera Replacement",
                wholeSalePrice: 30,
                retailPrice: 50,
            },
            {
                entity: "iPhone",
                code: 164,
                description: "iPhone 6 Camera Lens",
                wholeSalePrice: 25,
                retailPrice: 40,
            },
            {
                entity: "iPhone",
                code: 165,
                description: "iPhone 6 Rear Camera Replacement",
                wholeSalePrice: 39,
                retailPrice: 60,
            },
            {
                entity: "iPhone",
                code: 166,
                description: "iPhone 6 Plus Camera Lens",
                wholeSalePrice: 25,
                retailPrice: 40,
            },
            {
                entity: "iPhone",
                code: 167,
                description: "iPhone 6 Plus Rear Camera Replacement",
                wholeSalePrice: 39.99,
                retailPrice: 60,
            },
            {
                entity: "iPhone",
                code: 168,
                description: "iPhone 6S Plus Camera Lens",
                wholeSalePrice: 25,
                retailPrice: 40,
            },
            {
                entity: "iPhone",
                code: 169,
                description: "iPhone 6S Plus Camera Replacement",
                wholeSalePrice: 60,
                retailPrice: 100,
            },
            {
                entity: "iPhone",
                code: 170,
                description: "iPhone 7 Camera Lens",
                wholeSalePrice: 25,
                retailPrice: 40,
            },
            {
                entity: "iPhone",
                code: 171,
                description: "iPhone 7 Camera Replacement",
                wholeSalePrice: 25,
                retailPrice: 100,
            },
            {
                entity: "iPhone",
                code: 172,
                description: "iPhone 7 Plus Camera Lens",
                wholeSalePrice: 25,
                retailPrice: 40,
            },
            {
                entity: "iPhone",
                code: 173,
                description: "iPhone 7 Plus Rear Camera Replacement",
                wholeSalePrice: 45,
                retailPrice: 90,
            },
            {
                entity: "iPhone",
                code: 174,
                description: "iPhone 8 Camera Lens",
                wholeSalePrice: 25,
                retailPrice: 40,
            },
            {
                entity: "iPhone",
                code: 175,
                description: "iPhone 8 Back Glass Replacement",
                wholeSalePrice: 55,
                retailPrice: 99,
            },
            {
                entity: "iPhone",
                code: 176,
                description: "iPhone 8 Plus Camera Lens",
                wholeSalePrice: 25,
                retailPrice: 40,
            },
            {
                entity: "iPhone",
                code: 177,
                description: "iPhone 8 Rear Camera Replacement",
                wholeSalePrice: 25,
                retailPrice: 80,
            },
            {
                entity: "iPhone",
                code: 178,
                description: "iPhone 8 Plus Rear Camera Replacement",
                wholeSalePrice: 69.99,
                retailPrice: 120,
            },
            {
                entity: "iPhone",
                code: 179,
                description: "iPhone X Camera Lens",
                wholeSalePrice: 30,
                retailPrice: 50,
            },
            {
                entity: "iPhone",
                code: 180,
                description: "iPhone X Front Camera Replacement",
                wholeSalePrice: 25,
                retailPrice: 70,
            },
            {
                entity: "iPhone",
                code: 181,
                description: "iPhone X Rear Camera Replacement",
                wholeSalePrice: 62.99,
                retailPrice: 110,
            },
            {
                entity: "iPhone",
                code: 182,
                description: "iPhone XR Camera Lens",
                wholeSalePrice: 25,
                retailPrice: 50,
            },
            {
                entity: "iPhone",
                code: 183,
                description: "iPhone XR Front Camera Replacement",
                wholeSalePrice: 40,
                retailPrice: 70,
            },
            {
                entity: "iPhone",
                code: 184,
                description: "iPhone XR Rear Camera Replacement",
                wholeSalePrice: 65,
                retailPrice: 110,
            },
            {
                entity: "iPhone",
                code: 185,
                description: "iPhone XS Camera Lens",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "iPhone",
                code: 186,
                description: "iPhone XS Front Camera Replacement",
                wholeSalePrice: 60,
                retailPrice: 100,
            },
            {
                entity: "iPhone",
                code: 187,
                description: "iPhone XS Rear Camera Replacement",
                wholeSalePrice: 69.99,
                retailPrice: 120,
            },
            {
                entity: "iPhone",
                code: 188,
                description: "iPhone XS Max Camera Lens",
                wholeSalePrice: 30,
                retailPrice: 50,
            },
            {
                entity: "iPhone",
                code: 189,
                description: "iPhone XS Max Front Camera Replacement",
                wholeSalePrice: 50,
                retailPrice: 80,
            },
            {
                entity: "iPhone",
                code: 190,
                description: "iPhone XS Max Rear Camera Replacement",
                wholeSalePrice: 70,
                retailPrice: 120,
            },
            {
                entity: "iPhone",
                code: 191,
                description: "iPhone 11 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 192,
                description: "iPhone 11 Rear Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 193,
                description: "iPhone 11 Pro Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 194,
                description: "iPhone 11 Pro Rear Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 195,
                description: "iPhone 11 Pro Max Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 196,
                description: "iPhone 11 Pro Max Rear Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 197,
                description: "iPhone SE 2020 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 198,
                description: "iPhone SE 2020 Rear Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 199,
                description: "iPhone 12 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 200,
                description: "iPhone 12 Rear Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 201,
                description: "iPhone 12 Mini Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 202,
                description: "iPhone 12 Mini Camera Lens Replacement",
                wholeSalePrice: 28.99,
                retailPrice: 50,
            },
            {
                entity: "iPhone",
                code: 203,
                description: "iPhone 12 Mini Rear Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 204,
                description: "iPhone 12 Pro Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 205,
                description: "iPhone 12 Pro Rear Camera Replacement",
                wholeSalePrice: 90,
                retailPrice: 170,
            },
            {
                entity: "iPhone",
                code: 206,
                description: "iPhone 12 Pro Max Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 207,
                description: "iPhone 12 Pro Max Camera Lens",
                wholeSalePrice: 30,
                retailPrice: 50,
            },
            {
                entity: "iPhone",
                code: 208,
                description: "iPhone 12 Pro Max Rear Camera Replacement",
                wholeSalePrice: 89.99,
                retailPrice: 160,
            },
        ]
    },
    {
        entity: 'iPhone',
        repairGroup: "Back Glass Replacements",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "iPhone",
                code: 210,
                description: "iPhone 4/4S Back Glass",
                wholeSalePrice: 20,
                retailPrice: 35,
            },
            {
                entity: "iPhone",
                code: 211,
                description: "iPhone 5/5S/5C/SE Rear Housing Glass",
                wholeSalePrice: 25,
                retailPrice: 40,
            },
            {
                entity: "iPhone",
                code: 212,
                description: "iPhone 8 Plus Back Glass Replacement",
                wholeSalePrice: 55,
                retailPrice: 99,
            },
            {
                entity: "iPhone",
                code: 213,
                description: "iPhone X Back Glass Replacement",
                wholeSalePrice: 55,
                retailPrice: 99,
            },
            {
                entity: "iPhone",
                code: 214,
                description: "iPhone XR Back Glass Replacement",
                wholeSalePrice: 55,
                retailPrice: 99,
            },
            {
                entity: "iPhone",
                code: 215,
                description: "iPhone XS Back Glass Replacement",
                wholeSalePrice: 55,
                retailPrice: 99,
            },
            {
                entity: "iPhone",
                code: 216,
                description: "iPhone XS Max Back Glass Replacement",
                wholeSalePrice: 55,
                retailPrice: 99,
            },
            {
                entity: "iPhone",
                code: 217,
                description: "iPhone 11 Back Glass Replacement",
                wholeSalePrice: 55,
                retailPrice: 99,
            },
            {
                entity: "iPhone",
                code: 218,
                description: "iPhone 11 Pro Back Glass Replacement",
                wholeSalePrice: 55,
                retailPrice: 99,
            },
            {
                entity: "iPhone",
                code: 219,
                description: "iPhone 11 Pro Max Back Glass Replacement",
                wholeSalePrice: 69.00,
                retailPrice: 110,
            },
            {
                entity: "iPhone",
                code: 220,
                description: "iPhone SE 2020 Back Glass Replacement",
                wholeSalePrice: 60,
                retailPrice: 100,
            },
            {
                entity: "iPhone",
                code: 221,
                description: "iPhone 12 Back Glass Replacement",
                wholeSalePrice: 70,
                retailPrice: 120,
            },
            {
                entity: "iPhone",
                code: 222,
                description: "iPhone 12 Mini Back Glass Replacement",
                wholeSalePrice: 70,
                retailPrice: 120,
            },
            {
                entity: "iPhone",
                code: 223,
                description: "iPhone 12 Pro Back Glass Replacement",
                wholeSalePrice: 70,
                retailPrice: 120,
            },
            {
                entity: "iPhone",
                code: 224,
                description: "iPhone 12 Pro Max Back Glass Replacement",
                wholeSalePrice: 70,
                retailPrice: 120,
            },
        ]
    },
    {
        entity: 'iPhone',
        repairGroup: "Charging Port Replacement",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "iPhone",
                code: 230,
                description: "iPhone 4/4S Charge Port Replacement",
                wholeSalePrice: 25,
                retailPrice: 45,
            },
            {
                entity: "iPhone",
                code: 231,
                description: "iPhone 5/5S/5C/SE Charge Port Replacement",
                wholeSalePrice: 30,
                retailPrice: 50,
            },
            {
                entity: "iPhone",
                code: 232,
                description: "iPhone 6 Charge Port Replacement",
                wholeSalePrice: 30,
                retailPrice: 50,
            },
            {
                entity: "iPhone",
                code: 233,
                description: "iPhone 6 Plus Charge Port Replacement",
                wholeSalePrice: 35,
                retailPrice: 60,
            },
            {
                entity: "iPhone",
                code: 234,
                description: "iPhone 6S Charge Port Replacement",
                wholeSalePrice: 35,
                retailPrice: 60,
            },
            {
                entity: "iPhone",
                code: 235,
                description: "iPhone 6S Plus Charge Port Replacement",
                wholeSalePrice: 35,
                retailPrice: 60,
            },
            {
                entity: "iPhone",
                code: 236,
                description: "iPhone 7 Charge Port Replacement",
                wholeSalePrice: 35,
                retailPrice: 60,
            },
            {
                entity: "iPhone",
                code: 237,
                description: "iPhone 7 Plus Charge Port Replacement",
                wholeSalePrice: 35,
                retailPrice: 60,
            },
            {
                entity: "iPhone",
                code: 238,
                description: "iPhone 8 Charge Port Replacement",
                wholeSalePrice: 50,
                retailPrice: 80,
            },
            {
                entity: "iPhone",
                code: 239,
                description: "iPhone 8 Plus Charge Port Replacement",
                wholeSalePrice: 50,
                retailPrice: 80,
            },
            {
                entity: "iPhone",
                code: 240,
                description: "iPhone X Charge Port Replacement",
                wholeSalePrice: 50,
                retailPrice: 80,
            },
            {
                entity: "iPhone",
                code: 241,
                description: "iPhone XR Charge Port Replacement",
                wholeSalePrice: 59.99,
                retailPrice: 99,
            },
            {
                entity: "iPhone",
                code: 242,
                description: "iPhone XS Charge Port Replacement",
                wholeSalePrice: 59.99,
                retailPrice: 99,
            },
            {
                entity: "iPhone",
                code: 243,
                description: "iPhone XS Max Charge Port Replacement",
                wholeSalePrice: 59.99,
                retailPrice: 99,
            },
            {
                entity: "iPhone",
                code: 244,
                description: "iPhone 11 Charge Port Replacement",
                wholeSalePrice: 60,
                retailPrice: 100,
            },
            {
                entity: "iPhone",
                code: 245,
                description: "iPhone 11 Pro Charge Port Replacement",
                wholeSalePrice: 65,
                retailPrice: 110,
            },
            {
                entity: "iPhone",
                code: 246,
                description: "iPhone 11 Pro Max Charge Port Replacement",
                wholeSalePrice: 80,
                retailPrice: 140,
            },
            {
                entity: "iPhone",
                code: 247,
                description: "iPhone SE 2020 Charge Port Replacement",
                wholeSalePrice: 59.00,
                retailPrice: 90,
            },
            {
                entity: "iPhone",
                code: 248,
                description: "iPhone 12 Charge Port Replacement",
                wholeSalePrice: 85,
                retailPrice: 140,
            },
            {
                entity: "iPhone",
                code: 249,
                description: "iPhone 12 Mini Charge Port Replacement",
                wholeSalePrice: 85,
                retailPrice: 140,
            },
            {
                entity: "iPhone",
                code: 250,
                description: "iPhone 12 Pro Charge Port Replacement",
                wholeSalePrice: 85,
                retailPrice: 140,
            },
            {
                entity: "iPhone",
                code: 251,
                description: "iPhone 12 Pro Max Charge Port Replacement",
                wholeSalePrice: 85,
                retailPrice: 140,
            },
        ]
    },
    {
        entity: 'iPhone',
        repairGroup: "Ear or Loud Speaker Replacement",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "iPhone",
                code: 260,
                description: "iPhone 4/4S Ear or Loud Speaker Replacement",
                wholeSalePrice: 25,
                retailPrice: 45,
            },
            {
                entity: "iPhone",
                code: 261,
                description: "iPhone 5/5S/5C/SE Ear or Loud Speaker Replacement",
                wholeSalePrice: 30,
                retailPrice: 50,
            },
            {
                entity: "iPhone",
                code: 262,
                description: "iPhone 6 Ear or Loud Speaker Replacement",
                wholeSalePrice: 30,
                retailPrice: 50,
            },
            {
                entity: "iPhone",
                code: 263,
                description: "iPhone 6 Plus Ear or Loud Speaker Replacement",
                wholeSalePrice: 30,
                retailPrice: 50,
            },
            {
                entity: "iPhone",
                code: 264,
                description: "iPhone 6S Ear or Loud Speaker Replacement",
                wholeSalePrice: 30,
                retailPrice: 50,
            },
            {
                entity: "iPhone",
                code: 265,
                description: "iPhone 6S Plus Ear or Loud Speaker Replacement",
                wholeSalePrice: 30,
                retailPrice: 50,
            },
            {
                entity: "iPhone",
                code: 266,
                description: "iPhone 7 Ear or Loud Speaker Replacement",
                wholeSalePrice: 30,
                retailPrice: 50,
            },
            {
                entity: "iPhone",
                code: 267,
                description: "iPhone 7 Plus Ear or Loud Speaker Replacement",
                wholeSalePrice: 35,
                retailPrice: 70,
            },
            {
                entity: "iPhone",
                code: 268,
                description: "iPhone 8 Ear or Loud Speaker Replacement",
                wholeSalePrice: 305,
                retailPrice: 50,
            },
            {
                entity: "iPhone",
                code: 269,
                description: "iPhone 8 Plus Ear or Loud Speaker Replacement",
                wholeSalePrice: 30,
                retailPrice: 50,
            },
            {
                entity: "iPhone",
                code: 270,
                description: "iPhone X Ear or Loud Speaker Replacement",
                wholeSalePrice: 50,
                retailPrice: 80,
            },
            {
                entity: "iPhone",
                code: 271,
                description: "iPhone XR Ear or Loud Speaker Replacement",
                wholeSalePrice: 60,
                retailPrice: 100,
            },
            {
                entity: "iPhone",
                code: 272,
                description: "iPhone XS Ear or Loud Speaker Replacement",
                wholeSalePrice: 60,
                retailPrice: 100,
            },
            {
                entity: "iPhone",
                code: 273,
                description: "iPhone XS Max Ear or Loud Speaker Replacement",
                wholeSalePrice: 50,
                retailPrice: 80,
            },
            {
                entity: "iPhone",
                code: 274,
                description: "iPhone 11 Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 275,
                description: "iPhone 11 Pro Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 276,
                description: "iPhone 11 Pro Max Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 276,
                description: "iPhone SE 2020 Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 277,
                description: "iPhone 12 Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 278,
                description: "iPhone 12 Mini Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 279,
                description: "iPhone 12 Pro Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 280,
                description: "iPhone 12 Pro Max Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
        ]
    },

    {
        entity: 'iPhone',
        repairGroup: "Home Button Replacement",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "iPhone",
                code: 300,
                description: "iPhone 4/4S Home Button Replacement",
                wholeSalePrice: 25,
                retailPrice: 40,
            },
            {
                entity: "iPhone",
                code: 301,
                description: "iPhone 5/5S/5C/SE Home Button Replacement",
                wholeSalePrice: 28.99,
                retailPrice: 50,
            },
            {
                entity: "iPhone",
                code: 302,
                description: "iPhone 6 Home Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code:303,
                description: "iPhone 6 Plus Home Button Replacement",
                wholeSalePrice: 170,
                retailPrice: 280,
            },
            {
                entity: "iPhone",
                code: 304,
                description: "iPhone 6S Home Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 305,
                description: "iPhone 6S Plus Home Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 306,
                description: "iPhone 7 Home Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 307,
                description: "iPhone 7 Plus Home Button Replacement",
                wholeSalePrice: 70,
                retailPrice: 70,
            },
            {
                entity: "iPhone",
                code: 308,
                description: "iPhone 8 Home Button Replacement",
                wholeSalePrice: 35,
                retailPrice: 70,
            },
            {
                entity: "iPhone",
                code: 309,
                description: "iPhone 8 Plus Home Button Replacement",
                wholeSalePrice: 49.99,
                retailPrice: 70,
            },
        ]
    },
    {
        entity: 'iPhone',
        repairGroup: "Volume/Power Button Replacement",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "iPhone",
                code: 310,
                description: "iPhone 4/4S Volume/Power Button Replacement",
                wholeSalePrice: 25,
                retailPrice: 45,
            },
            {
                entity: "iPhone",
                code: 311,
                description: "iPhone 5/5S/5C/SE Volume/Power Button Replacement",
                wholeSalePrice: 35,
                retailPrice: 60,
            },
            {
                entity: "iPhone",
                code: 312,
                description: "iPhone 6 Volume/Power Button Replacement",
                wholeSalePrice: 35,
                retailPrice: 60,
            },
            {
                entity: "iPhone",
                code: 313,
                description: "iPhone 6 Plus Volume/Power Button Replacement",
                wholeSalePrice: 35,
                retailPrice: 60,
            },
            {
                entity: "iPhone",
                code: 314,
                description: "iPhone 6S Volume/Power Button Replacement",
                wholeSalePrice: 39.99,
                retailPrice: 70,
            },
            {
                entity: "iPhone",
                code: 315,
                description: "iPhone 6S Plus Volume/Power Button Replacement",
                wholeSalePrice: 39.99,
                retailPrice: 70,
            },
            {
                entity: "iPhone",
                code: 316,
                description: "iPhone 7 Volume/Power Button Replacement",
                wholeSalePrice: 48.99,
                retailPrice: 90,
            },
            {
                entity: "iPhone",
                code: 317,
                description: "iPhone 7 Plus Volume/Power Button Replacement",
                wholeSalePrice: 48.99,
                retailPrice: 90,
            },
            {
                entity: "iPhone",
                code: 318,
                description: "iPhone 8 Volume/Power Button Replacement",
                wholeSalePrice: 60,
                retailPrice: 100,
            },
            {
                entity: "iPhone",
                code: 319,
                description: "iPhone 8 Plus Volume/Power Button Replacement",
                wholeSalePrice: 60,
                retailPrice: 100,
            },
            {
                entity: "iPhone",
                code: 320,
                description: "iPhone X Volume/Power Button Replacement",
                wholeSalePrice: 60,
                retailPrice: 100,
            },
            {
                entity: "iPhone",
                code: 321,
                description: "iPhone XR Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 322,
                description: "iPhone XS Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 323,
                description: "iPhone XS Max Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 324,
                description: "iPhone 11 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 325,
                description: "iPhone 11 Pro Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 326,
                description: "iPhone 11 Pro Max Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 327,
                description: "iPhone SE 2020 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
        ]
    },
    {
        entity: 'iPhone',
        repairGroup: "Headphone Jack Replacement",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "iPhone",
                code: 330,
                description: "iPhone 4/4S Headphone Jack",
                wholeSalePrice: 25,
                retailPrice: 50,
            },
            {
                entity: "iPhone",
                code: 331,
                description: "iPhone 5/5S/5C/SE Headphone Jack",
                wholeSalePrice: 25,
                retailPrice: 50,
            },
            {
                entity: "iPhone",
                code: 332,
                description: "iPhone 6 Headphone Jack",
                wholeSalePrice: 25,
                retailPrice: 50,
            },
            {
                entity: "iPhone",
                code: 333,
                description: "iPhone 6 Plus Headphone Jack",
                wholeSalePrice: 35,
                retailPrice: 60,
            },
            {
                entity: "iPhone",
                code: 334,
                description: "iPhone 6S Headphone Jack",
                wholeSalePrice: 35,
                retailPrice: 60,
            },
            {
                entity: "iPhone",
                code: 335,
                description: "iPhone 6S Plus Headphone Jack",
                wholeSalePrice: 35,
                retailPrice: 60,
            },
            {
                entity: "iPhone",
                code: 336,
                description: "iPhone 7 Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
        ]
    },
    {
        entity: 'iPhone',
        repairGroup: "Fingerprint Sensor Replacements",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "iPhone",
                code: 340,
                description: "iPhone 11 Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 341,
                description: "iPhone 11 Pro Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 342,
                description: "iPhone 11 Pro Max Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 343,
                description: "iPhone SE 2020 Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 344,
                description: "iPhone 12 Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 345,
                description: "iPhone 12 Mini Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 346,
                description: "iPhone 12 Pro Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPhone",
                code: 347,
                description: "iPhone 12 Pro Max Face ID Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },

        ]
    },
    {
        entity: 'iPhone',
        repairGroup: "Miscellaneous",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "iPhone",
                code: 350,
                description: "Free Diagnostic",
                wholeSalePrice: 0.00,
                retailPrice: 0.00,
            },
            {
                entity: "iPhone",
                code: 351,
                description: "Water Damage Diagnostic",
                wholeSalePrice: 15.00,
                retailPrice: 20.00,
            },
            {
                entity: "iPhone",
                code: 352,
                description: "Water Damage Additional Fee",
                wholeSalePrice: 35.99,
                retailPrice: 60.00,
            },
        ]
    },
    // ******** iPads *************** iPads ************** iPads **************** iPads ***************** 
    // * iPads *********** iPads **************** iPads ************** iPads *************** iPads ****** 
    // ******** iPads *************** iPads ************** iPads **************** iPads ***************** 
    // * iPads *********** iPads **************** iPads ************** iPads *************** iPads ****** 
    // ******** iPads *************** iPads ************** iPads **************** iPads ***************** 
    // * iPads *********** iPads **************** iPads ************** iPads *************** iPads ****** 
    {
        entity: 'iPad',
        repairGroup: "Screen Replacement",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "iPad",
                code: 360,
                description: "iPad Air 2 Glass & LCD Screen Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 361,
                description: "iPad Air Glass & LCD Screen Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 362,
                description: "iPad Mini 4 Glass & LCD Screen Replacement",
                wholeSalePrice: 180,
                retailPrice: 240,
            },
            {
                entity: "iPad",
                code: 363,
                description: "iPad Mini 3 Glass & LCD Screen Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 364,
                description: "iPad Mini 2 Glass & LCD Screen Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 365,
                description: "iPad Mini 1 Glass & LCD Screen Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 366,
                description: "iPad 4 Glass & LCD Screen Replacement",
                wholeSalePrice: 180,
                retailPrice: 220,
            },
            {
                entity: "iPad",
                code: 367,
                description: "iPad 3 Glass & LCD Screen Replacement",
                wholeSalePrice: 180,
                retailPrice: 220,
            },
            {
                entity: "iPad",
                code: 368,
                description: "iPad 2 Glass & LCD Screen Replacement",
                wholeSalePrice: 180,
                retailPrice: 220,
            },
            {
                entity: "iPad",
                code: 369,
                description: "iPad 1st Gen Glass & LCD Screen Replacement",
                wholeSalePrice: 125,
                retailPrice: 170,
            },
        ]
    },
    {
        entity: 'iPad',
        repairGroup: "Glass Only",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "iPad",
                code: 370,
                description: "iPad Air 2 Glass Only",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 371,
                description: "iPad Air Glass Only",
                wholeSalePrice: 85,
                retailPrice: 130,
            },
            {
                entity: "iPad",
                code: 372,
                description: "iPad Mini 4 Glass Only",
                wholeSalePrice: 180,
                retailPrice: 240,
            },
            {
                entity: "iPad",
                code: 373,
                description: "iPad Mini 3 Glass Only",
                wholeSalePrice: 95,
                retailPrice: 150,
            },
            {
                entity: "iPad",
                code: 374,
                description: "iPad Mini 2 Glass Only",
                wholeSalePrice: 95,
                retailPrice: 130,
            },
            {
                entity: "iPad",
                code: 375,
                description: "iPad Mini 1 Glass Only",
                wholeSalePrice: 80,
                retailPrice: 120,
            },
            {
                entity: "iPad",
                code: 376,
                description: "iPad 4 Glass Only",
                wholeSalePrice: 80,
                retailPrice: 120,
            },
            {
                entity: "iPad",
                code: 377,
                description: "iPad 3 Glass Only",
                wholeSalePrice: 80,
                retailPrice: 120,
            },
            {
                entity: "iPad",
                code: 378,
                description: "iPad Mini 3 Glass Only",
                wholeSalePrice: 90,
                retailPrice: 150,
            },
            {
                entity: "iPad",
                code: 379,
                description: "iPad 2 Glass Only",
                wholeSalePrice: 80,
                retailPrice: 120,
            },
        ]
    },
    {
        entity: 'iPad',
        repairGroup: "Cameras Replacements",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "iPad",
                code: 380,
                description: "iPad Air 2 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 381,
                description: "iPad Air 2 Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 382,
                description: "iPad Air Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 383,
                description: "iPad Air Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 384,
                description: "iPad Mini 4 Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 385,
                description: "iPad Mini 4 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 386,
                description: "iPad Mini 2 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 387,
                description: "iPad Mini 2 Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 388,
                description: "iPad Mini 1 Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 389,
                description: "iPad Mini 1 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 390,
                description: "iPad Mini 3 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 391,
                description: "iPad Mini 3 Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 392,
                description: "iPad 4 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 393,
                description: "iPad 4 Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 394,
                description: "iPad 3 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 395,
                description: "iPad 3 Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 396,
                description: "iPad 2 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 397,
                description: "iPad 2 Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 398,
                description: "iPad 1st Gen Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 399,
                description: "iPad 1st Gen Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
        ]
    },
    {
        entity: 'iPad',
        repairGroup: "Back Replacements",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "iPad",
                code: 400,
                description: "iPad Air 2 Back",
                wholeSalePrice: 160,
                retailPrice: 210,
            },
            {
                entity: "iPad",
                code: 401,
                description: "iPad Air Back",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 402,
                description: "iPad Mini 4 Back",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 403,
                description: "iPad Mini 3 Back",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 404,
                description: "iPad Mini 2 Back",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 405,
                description: "iPad Mini 1 Back",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 406,
                description: "iPad 4 Back",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 407,
                description: "iPad 3 Back",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 408,
                description: "iPad 2 Back",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 409,
                description: "iPad 1st Gen Back",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
        ]
    },
    {
        entity: 'iPad',
        repairGroup: "Charging Ports",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "iPad",
                code: 410,
                description: "iPad Air 2 Charge Port Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 411,
                description: "iPad Air Charge Port Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 412,
                description: "iPad Mini 4 Charge Port Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 413,
                description: "iPad Mini 3 Charge Port Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 414,
                description: "iPad Mini 2 Charge Port Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 415,
                description: "iPad Mini 1 Charge Port Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 416,
                description: "iPad 4 Charge Port Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 417,
                description: "iPad 3 Charge Port Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 418,
                description: "iPad 2 Charge Port Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 419,
                description: "iPad 1st Gen Charge Port Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
        ]
    },
    {
        entity: 'iPad',
        repairGroup: "Ear or Loud Speaker Replacement",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "iPad",
                code: 420,
                description: "iPad Air 2 Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 421,
                description: "iPad Air Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 422,
                description: "iPad Mini 4 Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 423,
                description: "iPad Mini 3 Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 424,
                description: "iPad Mini 2 Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 425,
                description: "iPad Mini 1 Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 426,
                description: "iPad 4 Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 427,
                description: "iPad 3 Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 428,
                description: "iPad 2 Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 429,
                description: "iPad 1st Gen Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
         ]
    },
    {
        entity: 'iPad',
        repairGroup: "Volume/Power Button Replacement",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
                        {
                entity: "iPad",
                code: 430,
                description: "iPad Air 2 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 431,
                description: "iPad Air Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 432,
                description: "iPad Mini 4 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },           
            {
                entity: "iPad",
                code: 433,
                description: "iPad Mini 3 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },           
            {
                entity: "iPad",
                code: 434,
                description: "iPad Mini 2 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },           
            {
                entity: "iPad",
                code: 435,
                description: "iPad Mini 1 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },           
            {
                entity: "iPad",
                code: 436,
                description: "iPad 4 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },           
            {
                entity: "iPad",
                code: 437,
                description: "iPad 3 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },           
            {
                entity: "iPad",
                code: 438,
                description: "iPad 2 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },           
            {
                entity: "iPad",
                code: 439,
                description: "iPad 1st Gen Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },           
        ]
    },
    {
        entity: 'iPad',
        repairGroup: "Sim Card/SD Reader",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "iPad",
                code: 440,
                description: "iPad Air 2 Sim Card/SD Reader",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 441,
                description: "iPad Air Sim Card/SD Reader",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 442,
                description: "iPad Mini 4 Sim Card/SD Reader",
                wholeSalePrice: 0,
                retailPrice: 0,
            }, 
            {
                entity: "iPad",
                code: 443,
                description: "iPad Mini 3 Sim Card/SD Reader",
                wholeSalePrice: 0,
                retailPrice: 0,
            },           
            {
                entity: "iPad",
                code: 444,
                description: "iPad Mini 2 Sim Card/SD Reader",
                wholeSalePrice: 0,
                retailPrice: 0,
            },           
            {
                entity: "iPad",
                code: 445,
                description: "iPad Mini 1 Sim Card/SD Reader",
                wholeSalePrice: 0,
                retailPrice: 0,
            },           
            {
                entity: "iPad",
                code: 446,
                description: "iPad 4 Sim Card/SD Reader",
                wholeSalePrice: 0,
                retailPrice: 0,
            },           
            {
                entity: "iPad",
                code: 447,
                description: "iPad 3 Sim Card/SD Reader",
                wholeSalePrice: 0,
                retailPrice: 0,
            },           
            {
                entity: "iPad",
                code: 448,
                description: "iPad 2 Sim Card/SD Reader",
                wholeSalePrice: 0,
                retailPrice: 0,
            },           
            {
                entity: "iPad",
                code: 449,
                description: "iPad 1st Gen Sim Card/SD Reader",
                wholeSalePrice: 0,
                retailPrice: 0,
            },           
        ]
    },
    {
        entity: 'iPad',
        repairGroup: "Battery Replacement",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
                        {
                entity: "iPad",
                code: 450,
                description: "iPad Air 2 Battery Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 451,
                description: "iPad Air Battery Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 452,
                description: "iPad Mini 4 Battery Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 453,
                description: "iPad Mini 3 Battery Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 454,
                description: "iPad Mini 2 Battery Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 455,
                description: "iPad Mini 1 Battery Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 456,
                description: "iPad 4 Battery Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 457,
                description: "iPad 3 Battery Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 458,
                description: "iPad 2 Battery Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "iPad",
                code: 459,
                description: "iPad 1st Gen Battery Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },           
        ]
    },
    // ******************************** Samsung Galaxy Devices ************************************** Samsung Galaxy Devices ************** 
    // * Samsung Galaxy Devices ************************************* Samsung Galaxy Devices ********************************************** 
    // ******************************** Samsung Galaxy Devices ************************************** Samsung Galaxy Devices ************** 
    // * Samsung Galaxy Devices ************************************* Samsung Galaxy Devices ********************************************** 
    // ******************************** Samsung Galaxy Devices ************************************** Samsung Galaxy Devices ************** 
    // * Samsung Galaxy Devices ************************************* Samsung Galaxy Devices ********************************************** 
    {
        entity: 'Samsung Galaxy Platform',
        repairGroup: "Screen & LCD Replacements",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "Samsung Galaxy Platform",
                code: 460,
                description: "Samsung Note 20 Ultra Glass & LCD Screen Replacement",
                wholeSalePrice: 310,
                retailPrice: 440,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 461,
                description: "Samsung Note 10+ Glass & LCD Screen Replacement",
                wholeSalePrice: 280,
                retailPrice: 380,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 462,
                description: "Samsung Note 10 Glass & LCD Screen Replacement",
                wholeSalePrice: 210,
                retailPrice: 280,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 463,
                description: "Samsung Note 9 Glass & LCD Screen Replacement",
                wholeSalePrice: 189.99,
                retailPrice: 260,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 464,
                description: "Samsung S21 Ultra Glass & LCD Screen Replacement",
                wholeSalePrice: 360,
                retailPrice: 410,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 465,
                description: "Samsung S21 + Glass & LCD Screen Replacement",
                wholeSalePrice: 190,
                retailPrice: 245,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 466,
                description: "Samsung S21 Glass & LCD Screen Replacement",
                wholeSalePrice: 160,
                retailPrice: 230,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 467,
                description: "Samsung Note 8 Glass & LCD Screen Replacement",
                wholeSalePrice: 180,
                retailPrice: 240,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 468,
                description: "Samsung S20 Glass & LCD Screen Replacement",
                wholeSalePrice: 260,
                retailPrice: 320,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 469,
                description: "Samsung S20 FE Glass & LCD Screen Replacement",
                wholeSalePrice: 130,
                retailPrice: 190,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 470,
                description: "Samsung S20+ Glass & LCD Screen Replacement",
                wholeSalePrice: 270,
                retailPrice: 340,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 471,
                description: "Samsung S20 Ultra Glass & LCD Screen Replacement",
                wholeSalePrice: 250,
                retailPrice: 330,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 472,
                description: "Samsung S10 E Glass & LCD Screen Replacement",
                wholeSalePrice: 130,
                retailPrice: 200,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 473,
                description: "Samsung S9 Plus Glass & LCD Screen Replacement",
                wholeSalePrice: 135,
                retailPrice: 210,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 474,
                description: "Samsung S10 Plus Glass & LCD Screen Replacement",
                wholeSalePrice: 190,
                retailPrice: 330,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 475,
                description: "Samsung S10 Glass & LCD Screen Replacement",
                wholeSalePrice: 185,
                retailPrice: 310,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 476,
                description: "Samsung S8 Plus Glass & LCD Screen Replacement",
                wholeSalePrice: 130,
                retailPrice: 210,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 477,
                description: "Samsung S8 Glass & LCD Screen Replacement",
                wholeSalePrice: 130,
                retailPrice: 200,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 478,
                description: "Samsung S7 Edge Glass & LCD Screen Replacement",
                wholeSalePrice: 105,
                retailPrice: 180,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 479,
                description: "Samsung S9 Glass & LCD Screen Replacement",
                wholeSalePrice: 130,
                retailPrice: 200,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 480,
                description: "Samsung S6 Edge Plus Glass & LCD Screen Replacement",
                wholeSalePrice: 100,
                retailPrice: 170,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 481,
                description: "Samsung S6 Edge Glass & LCD Screen Replacement",
                wholeSalePrice: 90,
                retailPrice: 140,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 482,
                description: "Samsung S6 Glass & LCD Screen Replacement",
                wholeSalePrice: 55,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 483,
                description: "Samsung S7 Glass & LCD Screen Replacement",
                wholeSalePrice: 75,
                retailPrice: 130,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 484,
                description: "Samsung A72 Glass & LCD Screen Replacement",
                wholeSalePrice: 100,
                retailPrice: 170,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 485,
                description: "Samsung A71 Glass & LCD Screen Replacement",
                wholeSalePrice: 95,
                retailPrice: 160,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 486,
                description: "Samsung A70 Glass & LCD Screen Replacement",
                wholeSalePrice: 110,
                retailPrice: 180,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 487,
                description: "Samsung A80 Glass & LCD Screen Replacement",
                wholeSalePrice: 150,
                retailPrice: 215,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 488,
                description: "Samsung A51 Glass & LCD Screen Replacement",
                wholeSalePrice: 95,
                retailPrice: 160,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 489,
                description: "Samsung A50 Glass & LCD Screen Replacement",
                wholeSalePrice: 90,
                retailPrice: 140,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 490,
                description: "Samsung A32 (5G) Glass & LCD Screen Replacement",
                wholeSalePrice: 55,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 491,
                description: "Samsung A52 Glass & LCD Screen Replacement",
                wholeSalePrice: 100,
                retailPrice: 160,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 492,
                description: "Samsung A31 Glass & LCD Screen Replacement",
                wholeSalePrice: 100,
                retailPrice: 160,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 493,
                description: "Samsung A30 Glass & LCD Screen Replacement",
                wholeSalePrice: 100,
                retailPrice: 160,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 494,
                description: "Samsung A22 Glass & LCD Screen Replacement",
                wholeSalePrice: 85,
                retailPrice: 135,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 495,
                description: "Samsung A32 (4G) Glass & LCD Screen Replacement",
                wholeSalePrice: 100,
                retailPrice: 160,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 496,
                description: "Samsung A21 Glass & LCD Screen Replacement",
                wholeSalePrice: 0,
                retailPrice: 120,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 497,
                description: "Samsung A20s Glass & LCD Screen Replacement",
                wholeSalePrice: 74.99,
                retailPrice: 115,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 498,
                description: "Samsung A20e Glass & LCD Screen Replacement",
                wholeSalePrice: 80,
                retailPrice: 130,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 499,
                description: "Samsung A21s Glass & LCD Screen Replacement",
                wholeSalePrice: 80,
                retailPrice: 115,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 500,
                description: "Samsung A12 Glass & LCD Screen Replacement",
                wholeSalePrice: 80,
                retailPrice: 115,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 501,
                description: "Samsung A11 Glass & LCD Screen Replacement",
                wholeSalePrice: 80,
                retailPrice: 115,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 502,
                description: "Samsung A10 Glass & LCD Screen Replacement",
                wholeSalePrice: 60,
                retailPrice: 99,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 503,
                description: "Samsung A20 Glass & LCD Screen Replacement",
                wholeSalePrice: 95,
                retailPrice: 165,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 504,
                description: "Samsung A5 2017 Glass & LCD Screen Replacement",
                wholeSalePrice: 95,
                retailPrice: 155,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 505,
                description: "Samsung A5 Glass & LCD Screen Replacement",
                wholeSalePrice: 115,
                retailPrice: 180,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 506,
                description: "Samsung S5 Neo Glass & LCD Screen Replacement",
                wholeSalePrice: 90,
                retailPrice: 140,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 507,
                description: "Samsung A8 Glass & LCD Screen Replacement",
                wholeSalePrice: 100,
                retailPrice: 165,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 508,
                description: "Samsung S4 Glass & LCD Screen Replacement",
                wholeSalePrice: 70,
                retailPrice: 100,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 509,
                description: "Samsung S5 Glass & LCD Screen Replacement",
                wholeSalePrice: 80,
                retailPrice: 120,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 510,
                description: "Samsung S4 Mini Glass & LCD Screen Replacement",
                wholeSalePrice: 90,
                retailPrice: 130,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 511,
                description: "Samsung S5 Active Glass & LCD Screen Replacement",
                wholeSalePrice: 110,
                retailPrice: 160,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 512,
                description: "Samsung Note 5 Glass & LCD Screen Replacement",
                wholeSalePrice: 135,
                retailPrice: 185,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 513,
                description: "Samsung Note 5 Battery Replacement",
                wholeSalePrice: 65,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 514,
                description: "Samsung S3 Glass & LCD Screen Replacement",
                wholeSalePrice: 85,
                retailPrice: 125,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 515,
                description: "Samsung Note 4 Glass & LCD Screen Replacement",
                wholeSalePrice: 130,
                retailPrice: 190,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 516,
                description: "Samsung S3 Mini Glass & LCD Screen Replacement",
                wholeSalePrice: 85,
                retailPrice: 110,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 517,
                description: "Samsung Note 2 Glass & LCD Screen Replacement",
                wholeSalePrice: 0,
                retailPrice: 170,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 518,
                description: "Samsung Note 3 Glass & LCD Screen Replacement",
                wholeSalePrice: 130,
                retailPrice: 180,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 519,
                description: "Samsung Grand Prime 2 Glass Only",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 520,
                description: "Samsung Grand Prime 2 Glass & LCD Screen Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 521,
                description: "Samsung Note 1 Glass & LCD Screen Replacement",
                wholeSalePrice: 140,
                retailPrice: 190,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 522,
                description: "Samsung Galaxy Core LTE Glass",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 523,
                description: "Samsung Grand Prime Glass",
                wholeSalePrice: 65,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 524,
                description: "Samsung Grand Prime Glass & LCD Screen Replacement",
                wholeSalePrice: 95,
                retailPrice: 130,
            },
        ]
    },    
    {
        entity: 'Samsung Galaxy Platform',
        repairGroup: "Battery Replacements",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "Samsung Galaxy Platform",
                code: 525,
                description: "Samsung Note 20 Ultra Battery Replacement",
                wholeSalePrice: 60,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 526,
                description: "Samsung Note 10+ Battery Replacement",
                wholeSalePrice: 60,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 527,
                description: "Samsung Note 10 Battery Replacement",
                wholeSalePrice: 60,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 528,
                description: "Samsung Note 9 Battery Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 529,
                description: "Samsung S21 Ultra Battery Replacement",
                wholeSalePrice: 60,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 530,
                description: "Samsung S21 + Battery Replacement",
                wholeSalePrice: 60,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 531,
                description: "Samsung S21 Battery Replacement",
                wholeSalePrice: 60,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 532,
                description: "Samsung Note 8 Battery Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 533,
                description: "Samsung S20 Battery Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 534,
                description: "Samsung S20 FE Battery Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 535,
                description: "Samsung S20+ Battery Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 536,
                description: "Samsung S20 Ultra Battery Replacement",
                wholeSalePrice: 60,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 537,
                description: "Samsung S10 E Battery Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 538,
                description: "Samsung S9 Plus Battery Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 539,
                description: "Samsung S10 Plus Battery Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 540,
                description: "Samsung S10 Battery Replacement",
                wholeSalePrice: 60,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 541,
                description: "Samsung S8 Plus Battery Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 542,
                description: "Samsung S8 Battery Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 543,
                description: "Samsung S7 Edge Battery Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 544,
                description: "Samsung S9 Battery Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 545,
                description: "Samsung S6 Edge Plus Battery Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 546,
                description: "Samsung S6 Edge Battery Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 547,
                description: "Samsung S6 Battery Replacement",
                wholeSalePrice: 40,
                retailPrice: 50,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 548,
                description: "Samsung S7 Battery Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 549,
                description: "Samsung A72 Battery Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 550,
                description: "Samsung A71 Battery Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 551,
                description: "Samsung A70 Battery Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 552,
                description: "Samsung A80 Battery Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 553,
                description: "Samsung A51 Battery Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 554,
                description: "Samsung A50 Battery Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 555,
                description: "Samsung A32 (5G) Battery Replacement",
                wholeSalePrice: 40,
                retailPrice: 50,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 556,
                description: "Samsung A52 Battery Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 557,
                description: "Samsung A31 Battery Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 558,
                description: "Samsung A30 Battery Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 559,
                description: "Samsung A22 Battery Replacement",
                wholeSalePrice: 60,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 560,
                description: "Samsung A32 (4G) Battery Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 561,
                description: "Samsung A21 Battery Replacement",
                wholeSalePrice: 57.99,
                retailPrice: 85,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 101,
                description: "Samsung A20s Battery Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 562,
                description: "Samsung A20e Battery Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 563,
                description: "Samsung A21s Battery Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 564,
                description: "Samsung A12 Battery Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 565,
                description: "Samsung A11 Battery Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 566,
                description: "Samsung A10 Battery Replacement",
                wholeSalePrice: 77.99,
                retailPrice: 110,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 567,
                description: "Samsung A20 Battery Replacement",
                wholeSalePrice: 69,
                retailPrice: 99,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 568,
                description: "Samsung A5 2017 Battery Replacement",
                wholeSalePrice: 60,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 569,
                description: "Samsung A5 Battery Replacement",
                wholeSalePrice: 55,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 570,
                description: "Samsung S5 Neo Battery Replacement",
                wholeSalePrice: 60,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 571,
                description: "Samsung A8 Battery Replacement",
                wholeSalePrice: 0,
                retailPrice: 100,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 572,
                description: "Samsung S4 Battery Replacement",
                wholeSalePrice: 0,
                retailPrice: 35,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 573,
                description: "Samsung S5 Battery Replacement",
                wholeSalePrice: 22.99,
                retailPrice: 35,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 574,
                description: "Samsung S4 Mini Battery Replacement",
                wholeSalePrice: 22.99,
                retailPrice: 35,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 575,
                description: "Samsung S5 Active Battery Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 576,
                description: "Samsung Note 5 Battery Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 577,
                description: "Samsung S3 Battery Replacement",
                wholeSalePrice: 25,
                retailPrice: 35,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 578,
                description: "Samsung Note 4 Battery Replacement",
                wholeSalePrice: 25,
                retailPrice: 35,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 579,
                description: "Samsung S3 Mini Battery Replacement",
                wholeSalePrice: 25,
                retailPrice: 35,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 580,
                description: "Samsung Note 2 Battery Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 581,
                description: "Samsung Note 3 Battery Replacement",
                wholeSalePrice: 30,
                retailPrice: 40,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 582,
                description: "Samsung Grand Prime 2 Battery Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 583,
                description: "Samsung Note 1 Battery Replacement",
                wholeSalePrice: 25,
                retailPrice: 35,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 584,
                description: "Samsung Galaxy Core LTE Battery Replacement",
                wholeSalePrice: 25,
                retailPrice: 35,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 585,
                description: "Samsung Grand Prime Battery Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
        ]
    },    
    {
        entity: 'Samsung Galaxy Platform',
        repairGroup: "Cameras Replacements",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "Samsung Galaxy Platform",
                code: 586,
                description: "Samsung Note 20 Ultra Camera Lens",
                wholeSalePrice: 35,
                retailPrice: 50,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 587,
                description: "Samsung Note 20 Ultra Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 588,
                description: "Samsung Note 10+ Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 589,
                description: "Samsung Note 10+ Camera Replacement",
                wholeSalePrice: 87.99,
                retailPrice: 120,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 590,
                description: "Samsung Note 10 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 591,
                description: "Samsung Note 10 Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 592,
                description: "Samsung Note 9 Camera Lens",
                wholeSalePrice: 35,
                retailPrice: 45,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 593,
                description: "Samsung Note 9 Camera Replacement",
                wholeSalePrice: 60,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 594,
                description: "Samsung S21 Ultra Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 595,
                description: "Samsung S21 Ultra Camera Replacement",
                wholeSalePrice: 120,
                retailPrice: 150,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 596,
                description: "Samsung S21 + Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 597,
                description: "Samsung S21 + Camera Replacement",
                wholeSalePrice: 80,
                retailPrice: 110,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 598,
                description: "Samsung S21 Camera Lens",
                wholeSalePrice: 45,
                retailPrice: 65,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 599,
                description: "Samsung S21 Camera Replacement",
                wholeSalePrice: 80,
                retailPrice: 110,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 600,
                description: "Samsung Note 8 Camera Lens",
                wholeSalePrice: 37.88,
                retailPrice: 50,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 604,
                description: "Samsung Note 8 Camera Replacement",
                wholeSalePrice: 60,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 602,
                description: "Samsung S20 Camera Lens",
                wholeSalePrice: 40,
                retailPrice: 50,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 603,
                description: "Samsung S20 Camera Replacement",
                wholeSalePrice: 60,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 604,
                description: "Samsung S20 FE Camera Lens",
                wholeSalePrice: 40,
                retailPrice: 50,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 605,
                description: "Samsung S20 FE Camera Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 606,
                description: "Samsung S20+ Camera Lens",
                wholeSalePrice: 40,
                retailPrice: 50,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 607,
                description: "Samsung S20+ Camera Replacement",
                wholeSalePrice: 60,
                retailPrice: 99,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 608,
                description: "Samsung S20 Ultra Camera Lens",
                wholeSalePrice: 40,
                retailPrice: 50,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 609,
                description: "Samsung S20 Ultra Camera Replacement",
                wholeSalePrice: 80,
                retailPrice: 110,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 610,
                description: "Samsung S10 E Camera Lens",
                wholeSalePrice: 35,
                retailPrice: 45,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 611,
                description: "Samsung S10 E Camera Replacement",
                wholeSalePrice: 60,
                retailPrice: 99,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 612,
                description: "Samsung S9 Plus Camera Lens",
                wholeSalePrice: 40,
                retailPrice: 50,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 613,
                description: "Samsung S9 Plus Camera Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 614,
                description: "Samsung S10 Plus Camera Lens",
                wholeSalePrice: 35,
                retailPrice: 45,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 615,
                description: "Samsung S10 Plus Camera Replacement",
                wholeSalePrice: 60,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 616,
                description: "Samsung S10 Camera Lens",
                wholeSalePrice: 35,
                retailPrice: 45,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 617,
                description: "Samsung S10 Camera Replacement",
                wholeSalePrice: 60,
                retailPrice: 99,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 618,
                description: "Samsung S8 Plus Camera Lens",
                wholeSalePrice: 30,
                retailPrice: 40,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 619,
                description: "Samsung S8 Plus Camera Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 620,
                description: "Samsung S8 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 40,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 621,
                description: "Samsung S8 Camera Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 622,
                description: "Samsung S7 Edge Camera Lens",
                wholeSalePrice: 25,
                retailPrice: 35,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 623,
                description: "Samsung S7 Edge Camera Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 624,
                description: "Samsung S9 Camera Lens",
                wholeSalePrice: 30,
                retailPrice: 40,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 625,
                description: "Samsung S9 Camera Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 626,
                description: "Samsung S6 Edge Plus Camera Lens",
                wholeSalePrice: 30,
                retailPrice: 40,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 627,
                description: "Samsung S6 Edge Plus Camera Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 628,
                description: "Samsung S6 Edge Camera Lens",
                wholeSalePrice: 25,
                retailPrice: 35,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 629,
                description: "Samsung S6 Edge Camera Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 630,
                description: "Samsung S6 Camera Lens",
                wholeSalePrice: 25,
                retailPrice: 35,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 631,
                description: "Samsung S6 Camera Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 632,
                description: "Samsung S7 Camera Lens",
                wholeSalePrice: 25,
                retailPrice: 30,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 633,
                description: "Samsung S7 Camera Replacement",
                wholeSalePrice: 45,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 634,
                description: "Samsung A72 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 635,
                description: "Samsung A72 Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 636,
                description: "Samsung A71 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 637,
                description: "Samsung A71 Camera Replacement",
                wholeSalePrice: 60,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 638,
                description: "Samsung A70 Camera Lens",
                wholeSalePrice: 25,
                retailPrice: 35,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 639,
                description: "Samsung A70 Camera Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 640,
                description: "Samsung A80 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 641,
                description: "Samsung A80 Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 642,
                description: "Samsung A51 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 643,
                description: "Samsung A51 Camera Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 644,
                description: "Samsung A50 Camera Lens",
                wholeSalePrice: 25,
                retailPrice: 35,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 645,
                description: "Samsung A50 Camera Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 646,
                description: "Samsung A32 (5G) Camera Lens",
                wholeSalePrice: 25,
                retailPrice: 35,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 647,
                description: "Samsung A32 (5G) Camera Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 648,
                description: "Samsung A52 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 649,
                description: "Samsung A52 Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 650,
                description: "Samsung A31 Camera Lens",
                wholeSalePrice: 25,
                retailPrice: 35,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 651,
                description: "Samsung A30 Camera Lens",
                wholeSalePrice: 25,
                retailPrice: 35,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 652,
                description: "Samsung A30 Camera Replacement",
                wholeSalePrice: 55,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 653,
                description: "Samsung A22 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 654,
                description: "Samsung A22 Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 655,
                description: "Samsung A32 (4G) Camera Lens",
                wholeSalePrice: 25,
                retailPrice: 35,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 656,
                description: "Samsung A32 (4G) Camera Replacement",
                wholeSalePrice: 55,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 657,
                description: "Samsung A21 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 658,
                description: "Samsung A21 Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 659,
                description: "Samsung A20s Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 660,
                description: "Samsung A20s Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 661,
                description: "Samsung A20e Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 662,
                description: "Samsung A20e Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 663,
                description: "Samsung A21s Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 664,
                description: "Samsung A21s Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 665,
                description: "Samsung A12 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 666,
                description: "Samsung A12 Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 667,
                description: "Samsung A11 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 668,
                description: "Samsung A11 Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 669,
                description: "Samsung A10 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 670,
                description: "Samsung A10 Camera Replacement",
                wholeSalePrice: 70,
                retailPrice: 100,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 671,
                description: "Samsung A20 Camera Lens",
                wholeSalePrice: 35,
                retailPrice: 45,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 672,
                description: "Samsung A20 Camera Replacement",
                wholeSalePrice: 70,
                retailPrice: 100,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 673,
                description: "Samsung A5 2017 Camera Lens",
                wholeSalePrice: 30,
                retailPrice: 40,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 674,
                description: "Samsung A5 2017 Camera Replacement",
                wholeSalePrice: 65,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 675,
                description: "Samsung A5 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 676,
                description: "Samsung A5 Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 677,
                description: "Samsung S5 Neo Camera Lens",
                wholeSalePrice: 50,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 678,
                description: "Samsung S5 Neo Camera Replacement",
                wholeSalePrice: 50,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 679,
                description: "Samsung A8 Camera Lens",
                wholeSalePrice: 70,
                retailPrice: 100,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 680,
                description: "Samsung A8 Camera Replacement",
                wholeSalePrice: 65,
                retailPrice: 95,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 681,
                description: "Samsung S4 Camera Lens",
                wholeSalePrice: 20,
                retailPrice: 30,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 682,
                description: "Samsung S4 Camera Replacement",
                wholeSalePrice: 42.99,
                retailPrice: 55,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 683,
                description: "Samsung S5 Camera Lens",
                wholeSalePrice: 25,
                retailPrice: 35,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 684,
                description: "Samsung S5 Camera Replacement",
                wholeSalePrice: 55,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 685,
                description: "Samsung S4 Mini Camera Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 686,
                description: "Samsung S5 Active Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 687,
                description: "Samsung S5 Active Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 688,
                description: "Samsung Note 5 Camera Lens",
                wholeSalePrice: 30,
                retailPrice: 40,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 689,
                description: "Samsung Note 5 Camera Replacement",
                wholeSalePrice: 60,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 690,
                description: "Samsung S3 Camera Replacement",
                wholeSalePrice: 40,
                retailPrice: 50,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 691,
                description: "Samsung Note 4 Camera Lens",
                wholeSalePrice: 30,
                retailPrice: 40,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 692,
                description: "Samsung Note 4 Camera Replacement",
                wholeSalePrice: 45,
                retailPrice: 65,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 693,
                description: "Samsung S3 Mini Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 694,
                description: "Samsung Note 2 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 695,
                description: "Samsung Note 2 Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 696,
                description: "Samsung Note 3 Camera Lens",
                wholeSalePrice: 30,
                retailPrice: 40,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 697,
                description: "Samsung Note 3 Camera Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 698,
                description: "Samsung Grand Prime 2 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 699,
                description: "Samsung Grand Prime 2 Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 700,
                description: "Samsung Note 1 Camera Lens",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 701,
                description: "Samsung Note 1 Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 702,
                description: "Samsung Galaxy Core LTE Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 703,
                description: "Samsung Grand Prime Camera Lens",
                wholeSalePrice: 30,
                retailPrice: 40,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 704,
                description: "Samsung Grand Prime Camera Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
        ]
    },    
    {
        entity: 'Samsung Galaxy Platform',
        repairGroup: "Charging Port Replacements",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "Samsung Galaxy Platform",
                code: 705,
                description: "Samsung Note 20 Ultra Charge Port Replacement",
                wholeSalePrice: 67.99,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 706,
                description: "Samsung Note 10+ Charge Port Replacement",
                wholeSalePrice: 67.99,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 707,
                description: "Samsung Note 10 Charge Port Replacement",
                wholeSalePrice: 67.99,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 708,
                description: "Samsung Note 9 Charge Port Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 709,
                description: "Samsung S21 Ultra Charge Port Replacement",
                wholeSalePrice: 85,
                retailPrice: 120,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 710,
                description: "Samsung S21 + Charge Port Replacement",
                wholeSalePrice: 75,
                retailPrice: 110,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 711,
                description: "Samsung S21 Charge Port Replacement",
                wholeSalePrice: 60,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 712,
                description: "Samsung Note 8 Charge Port Replacement",
                wholeSalePrice: 60,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 713,
                description: "Samsung S20 Charge Port Replacement",
                wholeSalePrice: 60,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 714,
                description: "Samsung S20 FE Charge Port Replacement",
                wholeSalePrice: 60,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 715,
                description: "Samsung S20+ Charge Port Replacement",
                wholeSalePrice: 60,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 716,
                description: "Samsung S20 Ultra Charge Port Replacement",
                wholeSalePrice: 60,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 717,
                description: "Samsung S10 E Charge Port Replacement",
                wholeSalePrice: 60,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 718,
                description: "Samsung S9 Plus Charge Port Replacement",
                wholeSalePrice: 60,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 719,
                description: "Samsung S10 Plus Charge Port Replacement",
                wholeSalePrice: 69,
                retailPrice: 99,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 720,
                description: "Samsung S10 Charge Port Replacement",
                wholeSalePrice: 69,
                retailPrice: 89,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 721,
                description: "Samsung S8 Plus Charge Port Replacement",
                wholeSalePrice: 55,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 722,
                description: "Samsung S8 Charge Port Replacement",
                wholeSalePrice: 55,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 723,
                description: "Samsung S7 Edge Charge Port Replacement",
                wholeSalePrice: 55,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 724,
                description: "Samsung S9 Charge Port Replacement",
                wholeSalePrice: 55,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 725,
                description: "Samsung S6 Edge Plus Charge Port Replacement",
                wholeSalePrice: 55,
                retailPrice: 79,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 726,
                description: "Samsung S6 Edge Charge Port Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 727,
                description: "Samsung S6 Charge Port Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 728,
                description: "Samsung S7 Charge Port Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 729,
                description: "Samsung A72 Charge Port Replacement",
                wholeSalePrice: 60,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 730,
                description: "Samsung A71 Charge Port Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 731,
                description: "Samsung A70 Charge Port Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 732,
                description: "Samsung A80 Charge Port Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 733,
                description: "Samsung A51 Charge Port Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 734,
                description: "Samsung A50 Charge Port Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 735,
                description: "Samsung A32 (5G) Charge Port Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 736,
                description: "Samsung A52 Charge Port Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 737,
                description: "Samsung A31 Charge Port Replacement",
                wholeSalePrice: 45,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 738,
                description: "Samsung A30 Charge Port Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 739,
                description: "Samsung A22 Charge Port Replacement",
                wholeSalePrice: 70,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 740,
                description: "Samsung A32 (4G) Charge Port Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 741,
                description: "Samsung A21 Charge Port Replacement",
                wholeSalePrice: 60,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 742,
                description: "Samsung A20s Charge Port Replacement",
                wholeSalePrice: 60,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 743,
                description: "Samsung A20e Charge Port Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 744,
                description: "Samsung A21s Charge Port Replacement",
                wholeSalePrice: 60,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 745,
                description: "Samsung A12 Charge Port Replacement",
                wholeSalePrice: 60,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 746,
                description: "Samsung A11 Charge Port Replacement",
                wholeSalePrice: 60,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 747,
                description: "Samsung A10 Charge Port Replacement",
                wholeSalePrice: 65,
                retailPrice: 95,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 748,
                description: "Samsung A20 Charge Port Replacement",
                wholeSalePrice: 65,
                retailPrice: 95,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 749,
                description: "Samsung A5 2017 Charge Port Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 750,
                description: "Samsung A5 Charge Port Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 751,
                description: "Samsung S5 Neo Charge Port Replacement",
                wholeSalePrice: 55,
                retailPrice: 75,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 752,
                description: "Samsung A8 Charge Port Replacement",
                wholeSalePrice: 60,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 753,
                description: "Samsung S4 Charge Port Replacement",
                wholeSalePrice: 40,
                retailPrice: 50,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 754,
                description: "Samsung S5 Charge Port Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 755,
                description: "Samsung S4 Mini Charge Port Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 756,
                description: "Samsung S5 Active Charge Port Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 757,
                description: "Samsung Note 5 Charge Port Replacement",
                wholeSalePrice: 0,
                retailPrice: 100,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 758,
                description: "Samsung S3 Charge Port Replacement",
                wholeSalePrice: 35,
                retailPrice: 50,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 759,
                description: "Samsung Note 4 Charge Port Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 760,
                description: "Samsung S3 Mini Charge Port Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 761,
                description: "Samsung Note 2 Charge Port Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 762,
                description: "Samsung Note 3 Charge Port Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 763,
                description: "Samsung Grand Prime 2 Charge Port Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 764,
                description: "Samsung Note 1 Charge Port Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 765,
                description: "Samsung Galaxy Core LTE Charge Port Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 766,
                description: "Samsung Grand Prime Charge Port Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
        ]
    },
    {
        entity: 'Samsung Galaxy Platform',
        repairGroup: "Ear or Loud Speaker Replacements",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "Samsung Galaxy Platform",
                code: 767,
                description: "Samsung Note 20 Ultra Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 768,
                description: "Samsung Note 10+ Ear or Loud Speaker Replacement",
                wholeSalePrice: 60,
                retailPrice: 90,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 769,
                description: "Samsung Note 10 Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 770,
                description: "Samsung Note 9 Ear or Loud Speaker Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 771,
                description: "Samsung S21 Ultra Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 772,
                description: "Samsung S21 + Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 773,
                description: "Samsung S21 Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 774,
                description: "Samsung Note 8 Ear or Loud Speaker Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 775,
                description: "Samsung S20 Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 776,
                description: "Samsung S20 FE Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 777,
                description: "Samsung S20+ Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 778,
                description: "Samsung S20 Ultra Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 779,
                description: "Samsung S10 E Ear or Loud Speaker Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 780,
                description: "Samsung S9 Plus Ear or Loud Speaker Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 781,
                description: "Samsung S10 Plus Ear or Loud Speaker Replacement",
                wholeSalePrice: 50,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 782,
                description: "Samsung S10 Ear or Loud Speaker Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 783,
                description: "Samsung S8 Plus Ear or Loud Speaker Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 784,
                description: "Samsung S8 Ear or Loud Speaker Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 785,
                description: "Samsung S7 Edge Ear or Loud Speaker Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 786,
                description: "Samsung S9 Ear or Loud Speaker Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 787,
                description: "Samsung S6 Edge Plus Ear or Loud Speaker Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 788,
                description: "Samsung S6 Edge Ear or Loud Speaker Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 789,
                description: "Samsung S6 Ear or Loud Speaker Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 790,
                description: "Samsung S7 Ear or Loud Speaker Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 791,
                description: "Samsung A72 Ear or Loud Speaker Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 792,
                description: "Samsung A71 Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 793,
                description: "Samsung A70 Ear or Loud Speaker Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 794,
                description: "Samsung A80 Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 795,
                description: "Samsung A51 Ear or Loud Speaker Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 796,
                description: "Samsung A50 Ear or Loud Speaker Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 797,
                description: "Samsung A32 (5G) Ear or Loud Speaker Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 798,
                description: "Samsung A52 Ear or Loud Speaker Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 799,
                description: "Samsung A31 Ear or Loud Speaker Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 800,
                description: "Samsung A30 Ear or Loud Speaker Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 801,
                description: "Samsung A22 Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 802,
                description: "Samsung A32 (4G) Ear or Loud Speaker Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 803,
                description: "Samsung A21 Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 804,
                description: "Samsung A20s Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 805,
                description: "Samsung A20e Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 806,
                description: "Samsung A21s Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 807,
                description: "Samsung A12 Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 808,
                description: "Samsung A11 Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 809,
                description: "Samsung A10 Ear or Loud Speaker Replacement",
                wholeSalePrice: 50,
                retailPrice: 75,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 810,
                description: "Samsung A20 Ear or Loud Speaker Replacement",
                wholeSalePrice: 50,
                retailPrice: 75,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 811,
                description: "Samsung A5 2017 Ear or Loud Speaker Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 812,
                description: "Samsung A5 Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 813,
                description: "Samsung S5 Neo Ear or Loud Speaker Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 814,
                description: "Samsung A8 Ear or Loud Speaker Replacement",
                wholeSalePrice: 55,
                retailPrice: 85,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 815,
                description: "Samsung S4 Ear or Loud Speaker Replacement",
                wholeSalePrice: 35,
                retailPrice: 45,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 816,
                description: "Samsung S5 Ear or Loud Speaker Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 817,
                description: "Samsung S4 Mini Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 818,
                description: "Samsung S5 Active Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 819,
                description: "Samsung Note 5 Ear or Loud Speaker Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 820,
                description: "Samsung S3 Ear or Loud Speaker Replacement",
                wholeSalePrice: 35,
                retailPrice: 45,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 821,
                description: "Samsung Note 4 Ear or Loud Speaker Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 822,
                description: "Samsung S3 Mini Ear or Loud Speaker Replacement",
                wholeSalePrice: 35,
                retailPrice: 45,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 823,
                description: "Samsung Note 2 Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 824,
                description: "Samsung Note 3 Ear or Loud Speaker Replacement",
                wholeSalePrice: 40,
                retailPrice: 50,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 825,
                description: "Samsung Grand Prime 2 Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 826,
                description: "Samsung Note 1 Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 827,
                description: "Samsung Galaxy Core LTE Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 828,
                description: "Samsung Grand Prime Ear or Loud Speaker Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
        ]
    },    
    {
        entity: 'Samsung Galaxy Platform',
        repairGroup: "Fingerprint Sensor Replacements",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "Samsung Galaxy Platform",
                code: 829,
                description: "Samsung Note 20 Ultra Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 930,
                description: "Samsung Note 10+ Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 931,
                description: "Samsung Note 10 Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 932,
                description: "Samsung Note 9 Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 933,
                description: "Samsung S21 Ultra Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 934,
                description: "Samsung S21 + Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 935,
                description: "Samsung S21 Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 936,
                description: "Samsung Note 8 Fingerprint Sensor Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 937,
                description: "Samsung S20 Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 938,
                description: "Samsung S20 FE Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 939,
                description: "Samsung S20+ Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 940,
                description: "Samsung S20 Ultra Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 941,
                description: "Samsung S9 Plus Fingerprint Sensor",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 942,
                description: "Samsung S8 Fingerprint Sensor Replacement",
                wholeSalePrice: 40,
                retailPrice: 50,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 943,
                description: "Samsung S8 Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 944,
                description: "Samsung S9 Fingerprint Sensor",
                wholeSalePrice: 45,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 945,
                description: "Samsung S7 Fingerprint Sensor Replacement",
                wholeSalePrice: 40,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 946,
                description: "Samsung A72 Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 947,
                description: "Samsung A70 Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 948,
                description: "Samsung A71 Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 949,
                description: "Samsung A80 Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 950,
                description: "Samsung A51 Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 951,
                description: "Samsung A50 Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 952,
                description: "Samsung A32 (5G) Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 953,
                description: "Samsung A52 Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 954,
                description: "Samsung A31 Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 955,
                description: "Samsung A30 Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 956,
                description: "Samsung A22 Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 957,
                description: "Samsung A32 (4G) Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 958,
                description: "Samsung A21 Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 959,
                description: "Samsung A20s Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 960,
                description: "Samsung A20e Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 961,
                description: "Samsung A21s Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 962,
                description: "Samsung A12 Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 963,
                description: "Samsung A11 Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 964,
                description: "Samsung A10 Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 965,
                description: "Samsung A20 Fingerprint Sensor Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
        ]
    },    
    {
        entity: 'Samsung Galaxy Platform',
        repairGroup: "Volume/Power Button Replacements",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "Samsung Galaxy Platform",
                code: 966,
                description: "Samsung Note 20 Ultra Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 967,
                description: "Samsung Note 10+ Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 968,
                description: "Samsung Note 10 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 969,
                description: "Samsung Note 9 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 970,
                description: "Samsung S10 E Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 971,
                description: "Samsung S10 Plus Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 972,
                description: "Samsung S10 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 973,
                description: "Samsung S8 Plus Volume/Power Button Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 974,
                description: "Samsung S8 Volume/Power Button Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 975,
                description: "Samsung S7 Edge Volume/Power Button Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 976,
                description: "Samsung S6 Edge Plus Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 977,
                description: "Samsung S6 Edge Volume/Power Button Replacement",
                wholeSalePrice: 55,
                retailPrice: 80,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 978,
                description: "Samsung S6 Volume/Power Button Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 979,
                description: "Samsung S7 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 980,
                description: "Samsung A72 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 981,
                description: "Samsung A71 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 982,
                description: "Samsung A70 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 983,
                description: "Samsung A80 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 984,
                description: "Samsung A51 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 985,
                description: "Samsung A50 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 986,
                description: "Samsung A32 (5G) Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 987,
                description: "Samsung A52 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 988,
                description: "Samsung A31 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 989,
                description: "Samsung A30 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 990,
                description: "Samsung A22 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 991,
                description: "Samsung A32 (4G) Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 992,
                description: "Samsung A21 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 993,
                description: "Samsung A20s Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 994,
                description: "Samsung A20e Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 995,
                description: "Samsung A21s Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 996,
                description: "Samsung A12 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 997,
                description: "Samsung A11 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 998,
                description: "Samsung A10 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 999,
                description: "Samsung A20 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1000,
                description: "Samsung A5 2017 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1001,
                description: "Samsung A5 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1002,
                description: "Samsung S5 Neo Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1003,
                description: "Samsung A8 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1004,
                description: "Samsung S4 Volume/Power Button Replacement",
                wholeSalePrice: 30,
                retailPrice: 50,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1005,
                description: "Samsung S5 Volume/Power Button Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1006,
                description: "Samsung S4 Mini Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1007,
                description: "Samsung S5 Active Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1008,
                description: "Samsung Note 5 Volume/Power Button Replacement",
                wholeSalePrice: 55,
                retailPrice: 60,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1009,
                description: "Samsung S3 Volume/Power Button Replacement",
                wholeSalePrice: 40,
                retailPrice: 50,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1010,
                description: "Samsung Note 4 Volume/Power Button Replacement",
                wholeSalePrice: 45,
                retailPrice: 65,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1011,
                description: "Samsung S3 Mini Volume/Power Button Replacement",
                wholeSalePrice: 40,
                retailPrice: 50,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1012,
                description: "Samsung Note 2 Volume/Power Button Replacement",
                wholeSalePrice: 40,
                retailPrice: 60,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1013,
                description: "Samsung Note 3 Volume/Power Button Replacement",
                wholeSalePrice: 40,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1014,
                description: "Samsung Grand Prime 2 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1015,
                description: "Samsung Note 1 Volume/Power Button Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1016,
                description: "Samsung Galaxy Core LTE Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1017,
                description: "Samsung Grand Prime Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },                   
            {
                entity: "Samsung Galaxy Platform",
                code: 1018,
                description: "Samsung Note 8 Volume/Power Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },                   
        ]
    },    
    {
        entity: 'Samsung Galaxy Platform',
        repairGroup: "Headphone Jack Replacements",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "Samsung Galaxy Platform",
                code: 1019,
                description: "Samsung Note 20 Ultra Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1020,
                description: "Samsung Note 10+ Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1021,
                description: "Samsung Note 10 Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1022,
                description: "Samsung Note 9 Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1023,
                description: "Samsung S21 Ultra Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1024,
                description: "Samsung S21 + Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1025,
                description: "Samsung S21 Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1026,
                description: "Samsung S20 Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1027,
                description: "Samsung S20 FE Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1028,
                description: "Samsung S20+ Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1029,
                description: "Samsung S20 Ultra Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1030,
                description: "Samsung S10 E Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1031,
                description: "Samsung S9 Plus Headphone Jack",
                wholeSalePrice: 55,
                retailPrice: 80,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1032,
                description: "Samsung S10 Plus Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1033,
                description: "Samsung S9 Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 80,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1034,
                description: "Samsung A72 Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1035,
                description: "Samsung A71 Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1036,
                description: "Samsung A70 Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1037,
                description: "Samsung A80 Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1038,
                description: "Samsung A51 Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1039,
                description: "Samsung A50 Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1040,
                description: "Samsung A32 (5G) Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1041,
                description: "Samsung A52 Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },                 
            {
                entity: "Samsung Galaxy Platform",
                code: 1042,
                description: "Samsung A31 Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1043,
                description: "Samsung A30 Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },                 
            {
                entity: "Samsung Galaxy Platform",
                code: 1044,
                description: "Samsung A22 Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1045,
                description: "Samsung A32 (4G) Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },                 
            {
                entity: "Samsung Galaxy Platform",
                code: 1046,
                description: "Samsung A21 Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1047,
                description: "Samsung A20s Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },                 
            {
                entity: "Samsung Galaxy Platform",
                code: 1048,
                description: "Samsung A20e Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1049,
                description: "Samsung A21s Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },                 
            {
                entity: "Samsung Galaxy Platform",
                code: 1050,
                description: "Samsung A12 Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1051,
                description: "Samsung A11 Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },                 
            {
                entity: "Samsung Galaxy Platform",
                code: 1052,
                description: "Samsung A10 Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1053,
                description: "Samsung A20 Headphone Jack",
                wholeSalePrice: 0,
                retailPrice: 0,
            },                 
        ]
    },
    {
        entity: 'Samsung Galaxy Platform',
        repairGroup: "Back and Housing Replacements",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "Samsung Galaxy Platform",
                code: 1054,
                description: "Samsung S21 Ultra Back Replacement",
                wholeSalePrice: 45,
                retailPrice: 65,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1055,
                description: "Samsung S21 + Back Replacement",
                wholeSalePrice: 45,
                retailPrice: 65,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1056,
                description: "Samsung S21 Back Replacement",
                wholeSalePrice: 55,
                retailPrice: 75,
            },                 
            {
                entity: "Samsung Galaxy Platform",
                code: 1057,
                description: "Samsung Note 8 Back Glass",
                wholeSalePrice: 50,
                retailPrice: 70,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1058,
                description: "Samsung S20 Back Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },                 
            {
                entity: "Samsung Galaxy Platform",
                code: 1059,
                description: "Samsung S20 FE Back Replacement",
                wholeSalePrice: 45,
                retailPrice: 60,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1060,
                description: "Samsung S20+ Back Replacement",
                wholeSalePrice: 40,
                retailPrice: 60,
            },                 
            {
                entity: "Samsung Galaxy Platform",
                code: 1061,
                description: "Samsung S20 Ultra Back Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1062,
                description: "Samsung S10 E Back Replacement",
                wholeSalePrice: 40,
                retailPrice: 50,
            },                 
            {
                entity: "Samsung Galaxy Platform",
                code: 1063,
                description: "Samsung S9 Plus Back Replacement",
                wholeSalePrice: 40,
                retailPrice: 50,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1064,
                description: "Samsung S10 Plus Back Replacement",
                wholeSalePrice: 40,
                retailPrice: 50,
            },                 
            {
                entity: "Samsung Galaxy Platform",
                code: 1065,
                description: "Samsung S10 Back Replacement",
                wholeSalePrice: 40,
                retailPrice: 50,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1066,
                description: "Samsung S8 Plus Back Glass",
                wholeSalePrice: 40,
                retailPrice: 50,
            },                 
            {
                entity: "Samsung Galaxy Platform",
                code: 1067,
                description: "Samsung S8 Back Glass",
                wholeSalePrice: 40,
                retailPrice: 50,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1068,
                description: "Samsung S7 Edge Back Glass",
                wholeSalePrice: 35,
                retailPrice: 45,
            },                             
            {
                entity: "Samsung Galaxy Platform",
                code: 1069,
                description: "Samsung S9 Back Replacement",
                wholeSalePrice: 40,
                retailPrice: 50,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1070,
                description: "Samsung S6 Edge Plus Back Glass",
                wholeSalePrice: 35,
                retailPrice: 45,
            },                             
            {
                entity: "Samsung Galaxy Platform",
                code: 1071,
                description: "Samsung S6 Edge Back Glass",
                wholeSalePrice: 40,
                retailPrice: 50,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1072,
                description: "Samsung S6 Back Glass",
                wholeSalePrice: 35,
                retailPrice: 45,
            },                             
            {
                entity: "Samsung Galaxy Platform",
                code: 1073,
                description: "Samsung S7 Back Glass",
                wholeSalePrice: 20,
                retailPrice: 35,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1074,
                description: "Samsung A5 2017 Back Glass",
                wholeSalePrice: 50,
                retailPrice: 70,
            },                             
            {
                entity: "Samsung Galaxy Platform",
                code: 1075,
                description: "Samsung A5 Back Glass",
                wholeSalePrice: 30,
                retailPrice: 40,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1076,
                description: "Samsung S5 Neo Back Glass",
                wholeSalePrice: 0,
                retailPrice: 50,
            },                             
            {
                entity: "Samsung Galaxy Platform",
                code: 1077,
                description: "Samsung A8 Back Glass",
                wholeSalePrice: 40,
                retailPrice: 60,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1078,
                description: "Samsung S4 Back Glass",
                wholeSalePrice: 30,
                retailPrice: 40,
            },                             
            {
                entity: "Samsung Galaxy Platform",
                code: 1079,
                description: "Samsung S5 Back Glass",
                wholeSalePrice: 30,
                retailPrice: 40,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1080,
                description: "Samsung S4 Mini Back Glass",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1081,
                description: "Samsung S5 Active Back Glass",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1082,
                description: "Samsung Note 5 Back Glass",
                wholeSalePrice: 50,
                retailPrice: 70,
            },                             
            {
                entity: "Samsung Galaxy Platform",
                code: 1083,
                description: "Samsung S3 Back Glass",
                wholeSalePrice: 20,
                retailPrice: 30,
            },            
            {
                entity: "Samsung Galaxy Platform",
                code: 1084,
                description: "Samsung S3 Mini Back Glass",
                wholeSalePrice: 0,
                retailPrice: 0,
            },                             
            {
                entity: "Samsung Galaxy Platform",
                code: 1085,
                description: "Samsung Galaxy Core LTE Back Glass",
                wholeSalePrice: 0,
                retailPrice: 0,
            },            
        ]
    },
    {
        entity: 'Samsung Galaxy Platform',
        repairGroup: "Home Button Replacements",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "Samsung Galaxy Platform",
                code: 1086,
                description: "Samsung A5 2017 Home Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 100,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1087,
                description: "Samsung A5 Home Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1088,
                description: "Samsung S5 Neo Home Button Replacement",
                wholeSalePrice: 65,
                retailPrice: 100,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1089,
                description: "Samsung A8 Home Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1090,
                description: "Samsung S4 Home Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1091,
                description: "Samsung S5 Home Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1092,
                description: "Samsung S4 Mini Home Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1093,
                description: "Samsung S5 Active Home Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1094,
                description: "Samsung Note 5 Home Button Replacement",
                wholeSalePrice: 65,
                retailPrice: 100,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1095,
                description: "Samsung S3 Home Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1096,
                description: "Samsung Note 4 Home Button Replacement",
                wholeSalePrice: 90,
                retailPrice: 130,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1097,
                description: "Samsung S3 Mini Home Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1098,
                description: "Samsung Note 2 Home Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1099,
                description: "Samsung Note 3 Home Button Replacement",
                wholeSalePrice: 90,
                retailPrice: 130,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1100,
                description: "Samsung Grand Prime 2 Home Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1101,
                description: "Samsung Note 1 Home Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1102,
                description: "Samsung Galaxy Core LTE Home Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1103,
                description: "Samsung Grand Prime Home Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1104,
                description: "Samsung S6 Edge Plus Home Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1105,
                description: "Samsung S6 Edge Home Button Replacement",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1106,
                description: "Samsung S6 Home Button Replacement",
                wholeSalePrice: 50,
                retailPrice: 70,
            },
        ]
    },
    {
        entity: 'Samsung Galaxy Platform',
        repairGroup: "Sim Cards/SD Readers Replacements",
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "Samsung Galaxy Platform",
                code: 1107,
                description: "Samsung S4 Mini Sim Card/SD Reader",
                wholeSalePrice: 32.99,
                retailPrice: 45,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1108,
                description: "Samsung S3 Sim Card/SD Reader",
                wholeSalePrice: 35,
                retailPrice: 45,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1109,
                description: "Samsung Note 4 Sim Card/SD Reader",
                wholeSalePrice: 40,
                retailPrice: 60,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1110,
                description: "Samsung S3 Mini Sim Card/SD Reader",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1111,
                description: "Samsung Note 2 Sim Card/SD Reader",
                wholeSalePrice: 0,
                retailPrice: 50,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1112,
                description: "Samsung Note 3 Sim Card/SD Reader",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1113,
                description: "Samsung Grand Prime 2 Sim Card/SD Reader",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1114,
                description: "Samsung Note 1 Sim Card/SD Reader",
                wholeSalePrice: 30,
                retailPrice: 40,
            },
            {
                entity: "Samsung Galaxy Platform",
                code: 1115,
                description: "Samsung Galaxy Core LTE Sim Card/SD Reader",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
            {
                entity:"Samsung Galaxy Platform",
                code: 1116,
                description: "Samsung Grand Prime Sim Card/SD Reader",
                wholeSalePrice: 0,
                retailPrice: 0,
            },
        ]
    },
    {
        repairGroup: "Other",
        entity: 'generic',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "generic",
                code: 1117,
                description: "Replace component part",
                wholeSalePrice: 1,
                retailPrice: 1,
            },
            {
                entity: "generic",
                code: 1118,
                description: "Item maintenance",
                wholeSalePrice: 1,
                retailPrice: 1,
            },
        ]
    },
]);
