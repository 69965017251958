import React from 'react'
import { Heading } from "components";
import { Chip, Button, Tooltip } from "@material-tailwind/react";

const RoadmapCard = () => (
        <div className='flex flex-col bg-white w-full mb-24 shadow-lg rounded-xl border border-blue-gray-50 p-6'>
            <Heading size='sm' className="mb-6">
                Roadmap
            </Heading>
            <div className='w-full flex flex-col justify-between items-center'>
                <div className='w-full flex flex-col'>                
                    <p className='mr-6 flex-1 text-blue-gray-300 text-sm mb-8'>
                        Welcome to our Roadmap section.
                        This space is designed to provide a transparent view of what's on the horizon for our software.
                        From outlining new features we plan to introduce, to keeping you abreast of recent updates and
                        add-ons, our roadmap serves as a dynamic timeline of our commitment to continual innovation and
                        improvement. This roadmap is a fluid document,
                        adapting and evolving based on user feedback and our dedication to serving your needs.
                        So, explore what's coming, stay informed about the latest developments, and join us on this
                        exciting journey of making Bench Manager even better for the jeweler community.
                    </p>
                
                    <Chip
                        value="High Priority"
                        color="deep-orange"
                    />
                    <ul className="list-disc mt-3 ml-6">
                        <li>
                            <h1 className='font-bold'>Integrated Customer Support Ticket System</h1>
                            <p className='text-sm text-blue-gray-300'>
                                We are building a Customer Support Ticket system. This feature will
                                streamline communication between you and our support team, making it
                                easier to raise concerns, track progress, and receive solutions.
                            </p>
                        </li>
                        <li>
                            <h1 className='font-bold'>Improved Search for Service Prices</h1>
                            <p className='text-sm text-blue-gray-300'>
                                To make the process of finding specific service prices easier,
                                we plan to make service prices searchable. This will enable you
                                to quickly locate and review prices, saving you time and increasing
                                efficiency.
                            </p>
                        </li>
                    </ul>

                    <Chip
                        value="On schedule"
                        color="brown"
                        className='mt-6'
                    />
                    <ul className="list-disc mt-3 ml-6">
                        <li>
                            <h1 className='font-bold'>Account Page Security Enhancement</h1>
                            <p className='text-sm text-blue-gray-300'>
                                In our constant pursuit of protecting your valuable data,
                                we aim to add a new layer of security by password-protecting
                                the Account page. This ensures that only authorized individuals
                                can access and make changes to your account details.
                            </p>                            
                        </li>
                        <li>
                            <h1 className='font-bold'>User Data Export Capability</h1>
                            <p className='text-sm text-blue-gray-300'>
                                We understand the importance of data portability for our users.
                                Hence, we plan on introducing a feature allowing you to export your
                                user data. This will ensure you have complete control over your
                                information and can move it as you see fit.
                            </p>
                        </li>
                        <li>
                            <h1 className='font-bold'>Employee Accountability Feature</h1>
                            <p className='text-sm text-blue-gray-300'>
                                To increase transparency and accountability within your team, we are
                                planning to add an Employee Identification feature. This functionality
                                will log and track every system operation, linking it back to the specific
                                employee who performed it. This way, you'll have a clear record of who
                                carried out each operation, promoting responsibility and aiding in
                                process review and improvement.
                            </p>
                        </li>
                        <li>
                            <h1 className='font-bold'>Integrated Sales Inventory</h1>
                            <p className='text-sm text-blue-gray-300'>
                                This comprehensive feature will allow you to build your inventory and
                                keep a real-time record of your sales, providing you with an immediate
                                overview of what's been at what price. This centralized, automated inventory
                                system will help you monitor sales trends and optimize stock management.
                            </p>
                        </li> 
                    </ul>

                    <Chip
                        value="Blocked"
                        color="deep-orange"
                        className='mt-6'
                    />                        
                    <ul className="list-disc mt-3 ml-6">
                        <li>
                            <h1 className='font-bold'>Partnered Supplies Ordering System</h1>
                            <p className='text-sm text-blue-gray-300'>
                                In our continuous effort to streamline your workshop management, we are
                                planning to introduce a Partnered Supplies Ordering page. By partnering
                                with a major supplier's API, this feature will simplify your restocking
                                process, allowing you to order necessary supplies directly through Bench
                                Manager at not extra cost. This integration aims to save you time, minimize
                                order errors, and keep your workshop running smoothly.
                            </p>                        
                        </li> 
                    </ul>
                    <div>
                        <Tooltip content="Coming Soon!" placement='top'>
                            <Button
                                variant="gradient"
                                className="mt-6 ml-0"
                            >
                                Request Feature
                            </Button>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    )

export default RoadmapCard;
