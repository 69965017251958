import React, { useState, useEffect, useCallback } from "react"
import { useHistory, Link } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify";
import { Input, Button } from "@material-tailwind/react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import {
    getAuth,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    updateProfile
} from "firebase/auth";
import { LookupInputWrap, RenderIf, Badge, BotSVG } from 'components';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { ADD_USER_MUTATION } from "apollo/mutations/addUser";
import { ADD_STORE_MUTATION } from "apollo/mutations/addStore";
import {
    useCreateStripeCustomer,
    // useCreateCheckoutSession,
    useGoogleCaptchaVerification
} from 'hooks';
import { IoShieldCheckmarkSharp } from "react-icons/io5";
import { useRegisteringUser } from "states";
import LogoImage from "images/benchmanager.png";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { GridLoader } from "react-spinners";

// yup schemas form validation
const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

export default function AuthRegistration() {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const auth = getAuth();
    const history = useHistory()

    const [isRegisteringUser, setIsRegisteringUsers] = useRegisteringUser()

    const [isBot, setIsBot] = useState(false)
    const [error, setError] = useState("")
    const [errors, setErrors] = useState([])
    const [userData, setUserData] = useState({})
    // const [customerId, setCustomerId] = useState('')
    const [state, setState] = useState({
        businessName: "",
        email: "",
        phoneNumber: "",
        businessAddress: "",
        password: "",
        passwordConfirmation: "",
        showPassword: false,
        showPasswordConfirmation: false,
    });

    const { mutateAsync } = useGoogleCaptchaVerification({
        onSuccess: res => {
            console.log('Response from Google Reacapcha', { res })
            // If is most likely a Human we start matching the organizations and proceed with the Form
            if (res.data.googleResponse.score >= 0.7) {
                if (!state.businessAddress || state.businessAddress.includes('@')) {
                    setError("Make sure the Address field does contain a valid Address found by our Lookup component")
                    return
                }
                if (!state.password || !state.passwordConfirmation || state.password !== state.passwordConfirmation) {
                    setErrors([...errors, "password and passwordConfirmation didn't match"])
                    return
                }
                validateData(schema, {
                    businessName: state.businessName,
                    email: state.email,
                    phoneNumber: state.phoneNumber,
                    businessAddress: state.businessAddress,
                })
            } else if (res.data.score < 0.7) {
                // is a bot
                setIsBot(true)
            }
        },
        onError: error => {
            toast(
                `Something went wrong trying to validate your human identity 
                with Google ReCapcha v3 please try again later.`, {
                type: 'error',
            })
            console.error('useGoogleCaptchaVerification error', { error })
        },
    });

    // const { mutate: createCheckoutSession } = useCreateCheckoutSession({
    //     onSuccess: response => {
    //         console.log('Checkout Session:', { response })
    //         window.location = response.data.sessionUrl
    //     }
    // })

    const { mutate: createStripeCustomer } = useCreateStripeCustomer({
        onSuccess: response => {
            console.log('Stripe customer created!')
            addUser({
                variables: {
                    user: {
                        ...userData,
                        stripeCustomerId: response.data.customer.id,
                        userRepairCodes: {
                            userRepairCodeGroups: [],
                            user: { email: auth.currentUser.email },
                        },
                        userJobs: {}
                    }
                }
            })
            // setCustomerId(response.data.customer.id)
        }
    })

    // adding user home store sepparatelly because is not inside user, in the schema is just a relation
    const [addStore] = useMutation(ADD_STORE_MUTATION, {
        onCompleted: (res) => {
            console.log("addStore mutation success", { res })
            // createCheckoutSession({ priceId: 'price_1LoeH2IfIvvWDE54oCrndsLM', customerId })
            history.push('/dashboard')
        },
        onError: error => {
            toast(error.message, {
                 type: 'error',
            })
            console.log('Error adding user: ', { error })
        }
    });

    const [addUser] = useMutation(ADD_USER_MUTATION, {
        onCompleted: (res) => { 
            console.log("addUser Succes!");
            addStore({
                variables: {
                    store: {
                        relation: "home",
                        storeAddress: state.businessAddress,
                        storeEmail: state.email,
                        storeName: state.businessName,
                        storeNumber: state.businessAddress?.split(" ")[0]?.match(/\d+/g) ?
                                state.businessAddress?.split(" ")[0]?.match(/\d+/g)[0] :
                                state.businessAddress?.split(" ")[1]?.match(/\d+/g)[0],
                        storePhoneNumber: state.phoneNumber,
                        user: {
                            email: state.email,
                        }
                    }
                }
            })
        },
        onError: error => {
            toast(error.message, {
                 type: 'error',
            })
            console.log('Error adding user: ', { error })
        }
    });

    const handleFocus = useCallback((e) => {
        setError("")
        setErrors([])
    }, []);
    
    const handleClickShowPassword = () =>  setState({ ...state, showPassword: !state.showPassword })
    const handleClickShowConfirmationPassword = () =>  setState({ ...state, showPasswordConfirmation: !state.showPasswordConfirmation })
    const onChangeHandler = (e) => setState({ ...state, [e.target.name]: e.target.value })
    
    // Firebase registration, this fn is called after yup validation
    const registerFunc = async () => {
        setIsRegisteringUsers(true)
        await createUserWithEmailAndPassword(auth, state.email, state.password)
        .then(async () => {
            await sendEmailVerification(auth.currentUser)
            .then(() => {
                window.localStorage.setItem('userEmail', auth.currentUser.email);
                console.log("Email sent!")
            })
            .catch((error) => { 
                setError(error.message)
                console.log("Error sending verification email : ", { error })
            });
        })
        .catch((error) => {
            if (error.code === "auth/email-already-in-use") {
                console.log("User already exist")
                toast('User already exist. You can use the Login page.', {
                    type: 'error',
                })
            } else {
                setError(error.message)
            }
            console.log("Error creating user account : ", { error })
            return
        });
        // forcing user reset in order to update it's data in firebase
        auth.currentUser.getIdToken(true)
        // updating user data in firebase
        await updateProfile(auth.currentUser, { displayName: state.businessName })
        // writing data for the user on database:
        // Now hooks run creating the user in Stripe and adding data to db
        createStripeCustomer({ email: state.email })
    };
    
    // this fn validates and call registerFn
    const validateData = (schema, data) => {
        schema.validate(data, { abortEarly: false })
            .then(data => {
                setUserData(data)
                registerFunc()
            })
            .catch(error => {
                setErrors([...errors, ...error.errors])
            })
    }

    // Validate form, this runs on button click
    const schema = yup.object().shape({
        businessName: yup.string().required(),
        email: yup.string().email().required(),
        phoneNumber: yup.string().matches(phoneRegExp, 'phoneNumber is not valid'),
        businessAddress: yup.string(),
        createdOn: yup.date().default(function () {
            return new Date();
        }),
    });

    const validateGoogleCapchaThenInputs = useCallback(async () => {
        if (!executeRecaptcha) {
            console.error('Execute recaptcha not yet available');
            toast(
                `Something went wrong trying to validate your human identity 
                with Google ReCapcha v3 please try again later. Ensure you
                have a fast internet connection.`, {
                type: 'error',
            })
            return;
        }
        const token = await executeRecaptcha('submit');
        mutateAsync({ token });
    }, [executeRecaptcha, mutateAsync])

    const addressChangeHandler = (address) => { 
        const formattedAddress = address.formatted_address
        setState(current => ({ ...current, businessAddress: formattedAddress }))
    }

    useEffect(() => {
        if (error) {
            toast(error, {
                position: "top-right",
            })
        }
        if (errors.length) {
            toast('Something went wrong, please make sure all fields are filled correctly.', {
                position: "top-right",
            })
        }
    }, [error, errors]);

    return (
        <div className="w-full h-screen flex">
            <div className="w-1/2 h-screen flex justify-end px-16 pt-20 3xl:pt-40 bg-slate-800">
                <div className="w-3/4 flex flex-col justify-between">
                    <Link to="/" className="cursor-pointer">
                        <img src={LogoImage} alt="Company Logo" width={220} />
                    </Link>
                    <div className="mt-12 w-full flex-grow text-gray-100">
                        <h1 className="text-5xl font-black">
                            15 days Free Trial!
                        </h1>
                        <h1 className="xl:text-lg font-medium mt-6">
                            Register your account in 10 seconds 3 easy steps:
                        </h1>
                        <ul className="ml-1 text-sm">
                            <ol className="mt-2 text-xs xl:text-md">
                                <Badge className={'mr-2'}>1</Badge>
                                First add your business information and click Next
                            </ol>
                            <ol className="mt-2 text-xs xl:text-md">
                                <Badge className={'mr-2'}>1</Badge>
                                Add your payment method!
                            </ol>
                            <ol className="mt-2 text-xs xl:text-md">
                                <Badge className={'mr-2'}>1</Badge>
                                Then click your confirmation email link and Voila!
                            </ol>
                        </ul>
                    </div>
                    <h6 className="flex items-center text-xs text-gray-400 mb-10">
                        Your data is secured and encrypted
                        <IoShieldCheckmarkSharp className="text-blue-gray-400 ml-1" />
                    </h6>
                </div>
            </div>

            <RenderIf isTrue={isBot}>
                <div className="w-1/2 h-full flex flex-col items-center mt-24">
                    <BotSVG />
                    <h1 className="text-blue-gray-500 mt-6">
                        Benzh 89skk HioL!
                    </h1>
                    <h1 className="text-blue-gray-500 mt-1">
                        Your behavior is suspicious!
                    </h1>
                    <h1 className="text-blue-gray-500 mt-1">
                        Our Bots have identified you as another Bot!
                    </h1>
                </div>
            </RenderIf>

            <RenderIf isTrue={!isBot && isRegisteringUser}>
                <div className="w-1/2 flex justify-center items-center">
                    <div className="flex flex-col justify-center items-center">
                        <GridLoader color="#94A3B8" size={16} />
                        <h3 className="text-xs text-blue-gray-500 mt-4">Your account is being created!</h3>
                        <h4 className="text-xs text-blue-gray-500 mt-1">Please don't do anything at this time.</h4>
                    </div>
                </div>
            </RenderIf>

            <RenderIf isTrue={!isBot && !isRegisteringUser}>
                <div className="min-h-full ml-20 pt-20 3xl:pt-40 flex flex-col justify-start fadeIn mb-12">
                    <h1 className="mb-3 uppercase text-sm font-medium">Account Information</h1>
                        {/*  First Column  */}
                    <div className="w-96 flex flex-col justify-start space-y-3 3xl:space-y-6 mr-6">
                        <Input
                            label="Business Name"
                            name="businessName"
                            onChange={onChangeHandler}
                            onFocus={handleFocus}
                            error={errors.some(error => error.includes('businessName'))}
                        />
                        <Input
                            label="Business Email"
                            name="email"
                            type="email"
                            onFocus={handleFocus}
                            onChange={onChangeHandler}
                            error={errors.some(error => error.includes('email'))}
                        />
                        <Input
                            label="Phone Number"
                            name="phoneNumber"
                            onChange={onChangeHandler}
                            onFocus={handleFocus}
                            error={errors.some(error => error.includes('phoneNumber'))}
                        />
                        {/* TODO : Remove this component from react rainbow components
                        implement a google lookup with @material-tailwind/react inputs, and deprecate react rainbow */}
                        <LookupInputWrap
                            selectedValue={state.businessAddress}
                            addressChangeHandler={addressChangeHandler}
                            label="Business Address Lookup"
                            onFocus={handleFocus}
                            error={errors.some(error => error.includes('businessAddress'))}
                        />
                        <Input
                            label="Password"
                            type={state.showPassword ? "text" : "password"}
                            name="password"
                            onFocus={handleFocus}
                            onChange={onChangeHandler}
                            icon={state.showPassword ?
                                <FaRegEye onClick={handleClickShowPassword} className="cursor-pointer" /> :
                                <FaRegEyeSlash onClick={handleClickShowPassword} className="cursor-pointer" />
                            }
                            error={errors.some(error => error.includes('password'))}
                        />
                        <Input
                            label="Confirm password"
                            type={state.showPasswordConfirmation ? "text" : "password"}
                            name="passwordConfirmation"
                            onFocus={handleFocus}
                            onChange={onChangeHandler}
                            icon={state.showPasswordConfirmation ?
                                <FaRegEye onClick={handleClickShowConfirmationPassword} className="cursor-pointer" /> :
                                <FaRegEyeSlash onClick={handleClickShowConfirmationPassword} className="cursor-pointer" />
                            }
                            error={errors.some(error => error.includes('passwordConfirmation'))}
                        />

                        <div className="bg-blue-gray-50 p-6 rounded">
                            <p className="text-xs text-blue-gray-500 mb-1">
                                Companies with multiple workshops shall create one account per workshop in order
                                to appreciate accurate analytic reports and take full advantage of our current 
                                and new features. 
                            </p>
                            <p className="text-xs text-blue-gray-500 font-medium">
                                General Manager Dashboard feature coming soon for multiple workshop managers!
                            </p>
                        </div>
                        <div className="w-full flex justify-start">
                            <Button
                                variant="gradient"
                                onClick={validateGoogleCapchaThenInputs}
                                disabled={isRegisteringUser}
                            >
                                Next Step
                            </Button>
                        </div>
                    </div>
                    <div className="w-full mt-6 flex justify-between items-end">
                        <h1
                            className="text-sm cursor-pointer text-light-blue-600 hover:text-yellow-900 font-semibold mt-6"
                            onClick={() => history.push('/authenticate')}>
                            Already have an account?
                        </h1>
                    </div>
                </div>
            </RenderIf>
            <ToastContainer
                position="bottom-right"
                theme="light"
                autoClose={8000}
                closeOnClick={true} 
            />
        </div>
    );
}
