import React from "react";
import Blocks from "./Blocks";
import { Heading } from "components";

export default function Group({ group, suggestedTaskNumbers }) {
    return (
        <div className="h-fit rounded-xl shadow-lg px-4 pt-4 bg-gray-50 border border-blue-gray-50 overflow-hidden mb-4">
            <div className="flex justify-between mb-3">
                <Heading size="xs">
                    {group?.repairGroup}
                </Heading>
                <div className="flex items-center">
                    <h1 className="text-xs w-16 text-center font-bold">Wholesale</h1>
                    <h1 className="text-xs w-16 text-right font-bold">Retail</h1>
                </div>
            </div>

            {/* Mapping of items inside Blocks */}
            <Blocks
                group={group}
                suggestedTaskNumbers={suggestedTaskNumbers}
            />
        </div>
    );
}
