import React from 'react'
import ProfileCard from "./ProfileCard";
import SettingsCard from "./SettingsCard";
import RoadmapCard from "./RoadmapCard";
import { Drawer } from "components";

const AccountPage = () => {
   
    return (
        <div className='w-full flex'>
            <Drawer />
            <div className='w-full h-screen px-12 overflow-y-auto bg-blue-gray-50'>
                <div className='w-full flex justify-around items-start mt-20'>
                    <div className='w-1/3 mr-6'>
                        <ProfileCard />
                    </div>
                    <div className='w-full flex flex-col'>
                        <SettingsCard />
                        <RoadmapCard />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountPage
