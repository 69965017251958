import { gql } from '@apollo/client';

export const GET_USER_JOBS = gql`
query getUserJobs($id: ID!) {
  getUserJobs: getUserJobs(id: $id) {
    userJobs(filter: {
        status: {le: 4}, 
      	has: store
      }, 
      order: {
        desc: createdOn
      }) {
      id
      createdOn
      jobNumber
      customerName
      customerEmail
      customerPhoneNumber
      status
      itemValue
      photosLocalIds
      moneyCharged
      market
      promiseDate
      store {
        id
        storeNumber
      }
    }
  }
}`
