import React from "react";
import JobToCompleteDisplay from "./JobToCompleteDisplay";
import { Drawer, Heading, RenderIf } from "components";
import { Input, Button } from "@material-tailwind/react";
import { ToastContainer, toast } from "react-toastify";

import { STATUS } from "constants";
import { useQuery, useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import { GET_USER_JOBS } from "apollo/queries/getUserJobs";
import { GET_USER_DATA } from "apollo/queries/getUserData";
import { UPDATE_JOB } from "apollo/mutations/updateJob";
import { useUser } from "hooks";
import { AmountDisplayCard, Badge } from "components";
import classNames from 'classnames'



export default function CompleteJob() {
    const client = useApolloClient()
    const { currentUser } = useUser()

    const [getJobs, { data, loading }] = useLazyQuery(GET_USER_JOBS, {
        onCompleted: res => console.log("jobs ->", { res })
    })

    const jobsOnReceiveStatus = data?.getUserJobs?.userJobs?.filter(job => job.status === STATUS.RECEIVED)

    useQuery(GET_USER_DATA, {
        variables: { email: currentUser.email },
        onCompleted: (res) => getJobs({ variables: { id: res?.getUser?.userJobs?.id } }),
        onError: (error) => toast(error.message, { type: 'error' }),
    })

    const [mutate, { loading: loadingMutation }] = useMutation(UPDATE_JOB)

    const [job, setJob] = React.useState('');
    // This codes are the type CodeCharged that we have on graphql schema
    const [codesCharged, setCodesCharged] = React.useState([]);
    const [total, setTotal] = React.useState(0);
    const [errors, setErrors] = React.useState("");
    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('')
    const [isDisabled, setIsDisabled] = React.useState(false)


    const showError = (ErrorMessage = "An error has happened while attempting to complete Job.") => {
        setErrors(ErrorMessage);
    };

    const hideError = () => {
        setErrors("");
    };

    const addCode = (additionalCode) => {
        setCodesCharged(current => ([...current, additionalCode]));
    };

    const removeCode = (passedCode) => {
        let codes = codesCharged.filter((code) => code.code !== passedCode);
        setCodesCharged(codes);
    };

    const updateTotal = (a) => {
        setTotal(total + a);
    };

    const clearState = () => {
        setCodesCharged([])
        setTotal(0)
        setErrors("")
        setJob({})

        setSelectedIndex(null)
        setInputValue('')
        setIsDisabled(false)
    };

    const completeFn = (e) => {
        console.log('Completing job: ', { job })
        if (job) {
            mutate({
                variables: {
                    input: {
                        filter: { id: job.id },
                        set: {
                            status: STATUS.COMPLETED,
                            codesCharged,
                            moneyCharged: total.toFixed(2),
                        }
                    }
                },
                onCompleted: async () => {
                    await client.refetchQueries({
                        include: ["getUserJobs"],
                    })
                    clearState()
                    setSelectedIndex(null)
                    // change this
                    document.getElementById("EnterJobButton").disabled = false
                    document.getElementsByName("jobToCompleteInput")[0].disabled = false
                    document.getElementsByName("jobToCompleteInput")[0].value = ""
                }
            });            
        } else {
            showError();
        }
    };

    const handleJobClick = (jobIndex, jobNumber) => {
        setSelectedIndex(jobIndex)
        setInputValue(jobNumber)
    }
    
    
    const enterJob = () => {
        const enteredJob = Object.keys(job).length ? job : data?.getUserJobs?.userJobs?.find((job) => ((inputValue === job.jobNumber) && (job.status !== STATUS.COMPLETED)))
        if (enteredJob) {
            setJob(enteredJob)
            setIsDisabled(true)
        } else {
            showError()
            setInputValue("")
        }
    }

    const evaluateKey = (e) => {
        if (e.keyCode === 13) {
            enterJob();
        }
    };

    React.useEffect(() => {
        return () => {
            clearState();
        };
    }, []);

    React.useEffect(() => {
        if (errors) {
            toast(errors, {
                type: 'error',
            })
        }
    }, [errors]);

    React.useEffect(() => {
        if (!loadingMutation) {
            setInputValue("")
            setIsDisabled(false)
        }
    }, [loadingMutation]);

   
    return (
        <div className="w-full flex">
            <Drawer />
            <div className="w-full h-screen px-12 overflow-y-auto bg-blue-gray-50">
                <div className="mt-16 flex">
                    <div className="w-80 mr-6">
                        <div className="bg-white flex flex-col justify-starts items-start shadow-xl p-6 rounded-xl h-fit border border-blue-gray-50">
                            <Heading size='sm' className="mb-3">
                                Enter Job:
                            </Heading>
                            <Input
                                label="Job Number"
                                name="jobToCompleteInput"
                                value={inputValue}
                                onFocus={hideError}
                                onChange={(e) => setInputValue(e.target.value)}
                                onKeyDown={evaluateKey}
                                disabled={isDisabled}
                                error={!!errors}
                            />
                            <Button
                                variant="gradient"
                                disabled={isDisabled}
                                id="EnterJobButton"
                                onClick={enterJob}
                                className="mt-2 w-full"
                                color={isDisabled ? 'gray' : 'light-blue'}
                            >
                                Enter
                            </Button>
                        </div>
                        <AmountDisplayCard
                            amount={`$ ${total.toFixed(2)}`}
                            label="Total Charged"
                            className="min-w-full mt-6"
                        />
                        <RenderIf isTrue={!Object.keys(job).length}>
                            <div className="w-full flex justify-between items-center mb-1 mt-6 px-2">
                                <Heading size='xxs'>
                                    Received jobs to Complete
                                </Heading>
                                <Badge className={'bg-blue-gray-400'}>
                                    {jobsOnReceiveStatus?.length}
                                </Badge>
                            </div>
                            <div className="h-96 overflow-y-auto">
                                <RenderIf isTrue={loading}>
                                    <div className="bg-white shadow rounded-lg border border-1 border-blue-gray-50 mb-1 px-3 py-1 cursor-pointer w-full h-12 animate-pulse" />
                                    <div className="bg-white shadow rounded-lg border border-1 border-blue-gray-50 mb-1 px-3 py-1 cursor-pointer w-full h-12 animate-pulse" />
                                    <div className="bg-white shadow rounded-lg border border-1 border-blue-gray-50 mb-1 px-3 py-1 cursor-pointer w-full h-12 animate-pulse" />
                                    <div className="bg-white shadow rounded-lg border border-1 border-blue-gray-50 mb-1 px-3 py-1 cursor-pointer w-full h-12 animate-pulse" />
                                </RenderIf>
                                <RenderIf isTrue={jobsOnReceiveStatus?.length}>
                                    {jobsOnReceiveStatus?.map((job, index) => (
                                        <div
                                            key={job.jobNumber}
                                            className={classNames("bg-white flex flex-col shadow rounded-lg border border-1 border-blue-gray-50 mb-1 px-3 py-1 cursor-pointer", {
                                                "border-blue-gray-300 bg-gray-50": selectedIndex === index,
                                            })}
                                            onClick={() => handleJobClick(index, job.jobNumber)}
                                            onKeyDown={enterJob}
                                            tabIndex="0"
                                        >
                                            <h6 className="text-sm">{job.customerName}</h6>
                                            <h6 className="text-xs">{job.jobNumber}</h6>
                                        </div>
                                    ))}
                                </RenderIf>
                                <RenderIf isTrue={!loading && (!jobsOnReceiveStatus || (jobsOnReceiveStatus && !jobsOnReceiveStatus.length))}>
                                    <p className="ml-2 italic text-xs text-blue-gray-200">
                                        There is no Items to show under this criteria.
                                    </p>
                                </RenderIf>
                            </div>
                        </RenderIf>
                    </div>

                    <div className="grow h-fit">
                        <JobToCompleteDisplay
                            job={job}
                            codesCharged={codesCharged}
                            addCode={addCode}
                            removeCode={removeCode}
                            updateTotal={updateTotal}
                        />
                        <Button
                            variant="gradient"
                            color={loadingMutation || !job || !codesCharged.length ? "gray" : "light-blue"}
                            className="mt-6 mb-24 w-full"
                            onClick={completeFn}
                            disabled={loadingMutation || !job || !codesCharged.length}
                        >
                            Complete Job
                        </Button>
                    </div>
                </div>
            </div>
            <ToastContainer 
                position="bottom-right"
                theme="colored"
                autoClose={8000}
                closeOnClick={true}
            />
        </div>
    );
}
