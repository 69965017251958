import React, { useState, useEffect, useRef } from "react";
import { RenderIf } from 'components'
import { Button } from "@material-tailwind/react";
import Webcam from "react-webcam";
import img_placeholder from "images/img_placeholder.jpg";


const CameraComponent = ({ job, uploadPhotosToDb, uploading, photos, setPhotos }) => {
    const webcamRef = useRef(null);
    const [counter, setCounter] = useState(1);

    const takePhoto = React.useCallback(() => {
        if (counter < 4) { 
            setCounter(counter + 1);
            const imageSrc = webcamRef.current.getScreenshot();
            setPhotos(current => [ ...current, imageSrc ])
        }
    }, [webcamRef])
    
    const pushButtonHandler = () => {
        uploadPhotosToDb();
        setCounter(1);
    };

    useEffect(() => {
        return () => {
            setCounter(1);
        }
    }, [])

    return (
        <>
            <div className="flex">
                <div className="bg-white flex flex-col p-6 shadow-xl rounded-xl h-fit border border-blue-gray-50">
                    <div className="w-full flex justify-between bg-gray-800 p-3 text-white rounded font-bold mb-6">
                        <span className="text-white">
                            <div className="text-xs">Job Number</div>
                            {Object.keys(job).length ? job.jobNumber : ""}
                        </span>
                        <span className="text-white">
                            <div className="text-xs">Customer Name</div>
                            {Object.keys(job).length ? job.customerName : ""}
                        </span>
                        <span className="text-white">
                            <div className="text-xs">Location Number</div>
                            {Object.keys(job).length ? job.storeNumber : ""}
                        </span>
                    </div>

                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                    />

                    <Button
                        disabled={photos.length >= 3 || uploading}
                        variant="gradient"
                        className="w-full mt-3"
                        id="TakePhotoBtn"
                        onClick={takePhoto}
                        color={photos.length >= 3 || uploading ? 'gray' : 'light-blue'}
                    >
                        {counter < 3 ? "Take Photo" : "Excellent Job!"}
                    </Button>
                </div>

                
                <div className="bg-white ml-6 p-6 shadow-xl rounded-xl h-fit border border-blue-gray-50">
                    <div>
                        <RenderIf isTrue={photos[0]}>
                            <img src={photos[0]} alt="" width={260} height={190} className="mb-2 bg-gray-100" />
                        </RenderIf>
                        <RenderIf isTrue={!photos[0]}>
                            <img src={img_placeholder} alt="" width={260} height={190} className="mb-2 bg-gray-100" />
                        </RenderIf>

                        <RenderIf isTrue={photos[1]}>
                            <img src={photos[1]} alt="" width={260} height={190} className="mb-2 bg-gray-100" />
                        </RenderIf>
                        <RenderIf isTrue={!photos[1]}>
                            <img src={img_placeholder} alt="" width={260} height={190} className="mb-2 bg-gray-100" />
                        </RenderIf>

                        <RenderIf isTrue={photos[2]}>
                            <img src={photos[2]} alt="" width={260} height={190} className="mb-2 bg-gray-100" />
                        </RenderIf>
                        <RenderIf isTrue={!photos[2]}>
                            <img src={img_placeholder} alt="" width={260} height={190} className="mb-2 bg-gray-100" />
                        </RenderIf>
                    </div>

                    <Button
                        disabled={counter === 1}
                        color={counter === 1 ? 'gray' : 'light-blue'}
                        variant="gradient"
                        id="EnterJobButton"
                        onClick={pushButtonHandler}
                        className="float-right"
                    >
                        {uploading ? "Uploading..." : "Push to Server"}
                    </Button>
                </div>
            </div>
        </>
    );
}

export default CameraComponent