import React from 'react'
import { GoPencil } from "react-icons/go";
import { Tooltip } from "@material-tailwind/react";

const DisplayView = ({ field, store, setEditMode }) => {
  return (
    <div className="w-full flex justify-between items-center my-3">
        <span className='font-bold'>{`${field} :`}</span>
        <span className="flex items-center">
            <span className="mr-2">{store[StoreInfoContainerFieldDataKey[field]]}</span>
            <Tooltip content="Edit store name">
                <div onClick={() => setEditMode(current => !current)}>
                    <GoPencil className="cursor-pointer text-gray-500 hover:text-green-500 mr-1" />
                </div>
            </Tooltip>
        </span>
    </div>
  )
}

export default DisplayView

const StoreInfoContainerFieldDataKey = {
    "Store Name": "storeName",
    "Store Address": "storeAddress",
    "Store Email": "storeEmail",
    "Store Phone Number": "storePhoneNumber",
    "Store Number": "storeNumber",
}
