import React, { Fragment } from 'react'
import JobsPerDayChart from "./charts/OngoingJobsLineChart"
import ReceivedCompletedPieChart from "./charts/ReceivedCompletedPieChart"
import { Heading, RenderIf, Spinner } from "components"
import { STATUS } from "constants"

const DataSection = ({ userData, activeJobs, loadingUserData, loadingActiveJobs }) => {

    const jobs = activeJobs?.getUserJobs ? activeJobs?.getUserJobs?.userJobs : []
    const storeLocations = userData?.getUser?.userStores;

    const receivedJobs = jobs?.filter(job => ((job?.status === STATUS.NEW_JOB) || (job?.status === STATUS.RECEIVED)))?.length;
    const completedJobs = jobs?.filter(job => (job?.status === STATUS.COMPLETED || job?.status === STATUS.INVOICED))?.length;

    const ongoingJobsData = storeLocations?.map(location => { 
        return ({
            id: location.storeName,
            data: [
                {
                    "x": "Uncompleted",
                    "y": jobs ? jobs?.filter(job => job?.store?.storeNumber === location?.storeNumber && job?.status === STATUS.RECEIVED)?.length : 0,
                },
                {
                    "x": "Completed",
                    "y": jobs ? jobs?.filter(job => job?.store?.storeNumber === location?.storeNumber && job?.status === STATUS.COMPLETED)?.length : 0,
                },
                {
                    "x": "Invoiced",
                    "y": jobs ? jobs?.filter(job => job?.store?.storeNumber === location?.storeNumber && job?.status === STATUS.INVOICED)?.length : 0,
                },
            ]
        })
    })

    const completedVsReceivedData = [
        {
            "id": "completed",
            "label": "completed",
            "value": completedJobs ?? 0,
            "color": "hsl(118, 70%, 50%)"
        },
        {
            "id": "received",
            "label": "received",
            "value": receivedJobs ?? 0,
            "color": "hsl(288, 70%, 50%)"
        }
    ]


    return (
        <Fragment>
            <Heading size='xs' className={"mt-6"}>
                Data Analysis
            </Heading>
            <div className="w-full flex flex-wrap items-start mt-3">
                <RenderIf isTrue={loadingUserData || loadingActiveJobs}>
                    <div className="w-5/12 flex flex-col justify-center items-center h-72 shadow-2xl rounded-xl px-4 pt-4 pb-4 mb-6 mr-3">
                        <Spinner />
                    </div>
                    <div className="w-3/12 flex flex-col justify-center items-center h-72 shadow-2xl rounded-xl px-4 pt-4 pb-4 mb-6 mr-3">
                        <Spinner />
                    </div>
                </RenderIf>
                <RenderIf isTrue={!loadingActiveJobs && userData?.getUser}>
                    <div className="bg-white w-5/12 flex flex-col justify-between items-start h-72 shadow-2xl rounded-xl px-4 pt-4 pb-4 mb-6 mr-3 border border-blue-gray-50">
                        <Heading
                            size="xxs"
                            className={"ml-6"}
                            colorClass={"text-blue-gray-400"}>
                            Jobs Lifecycle
                            {/*  iterar locations y poner cuantos trabajos tienen por entity groups  */}
                            {/*  Recibidos y completados */}
                        </Heading>
                        <p className='text-xs text-blue-gray-500 ml-6'>Ongoing jobs divided by each serviced location.</p>
                        <JobsPerDayChart
                            data={ongoingJobsData}
                            axisBottonLegentTitle="Job Status"
                            axisLeftLegentTitle="Job Count"
                        />
                    </div>
                    <div className="bg-white w-3/12 flex flex-col justify-between items-center h-72 shadow-2xl rounded-xl px-4 pt-4 pb-4 mr-3 border border-blue-gray-50">
                        <Heading
                            size="xxs"
                            colorClass={"text-blue-gray-400"}>
                            Total Completed Jobs
                            {/*  La misma idea por tiendas pero diferenciar los recibidos y los completados */}
                        </Heading>
                        <p className='text-xs text-blue-gray-500'>Received phase vs Completed phase</p>
                        <ReceivedCompletedPieChart data={completedVsReceivedData} />
                    </div>
                </RenderIf>
            </div>

        </Fragment>
    )
}

export default DataSection