import { gql } from '@apollo/client';

export const GET_PAGINATED_STORE_JOBS = gql`
query getStoreJobs(
  $storeId: ID!, 
  $first: Int!, 
  $offset: Int!, 
  $filters: [JobFilter]
) {
  getStoreById: getStore(id: $storeId) {
    jobs(
      first: $first, 
      offset: $offset, 
      filter: { 
        and: $filters
      }, 
      order: { desc: createdOn }
    ) {
      id
      createdOn
      jobNumber
      customerName
      customerEmail
      customerPhoneNumber
      status
      itemValue
      photosLocalIds
      moneyCharged
      market
      promiseDate
      store {
        id
        storeNumber
      }
    }
  }
  aggregateJob(filter: { and: $filters }) {
    count
  }
}
`
