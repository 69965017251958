import { gql } from "@apollo/client";

// get 1 single job for the modal view with all data
export const GET_JOB = gql`
query getJob($id: ID!) {
  getJob(id: $id) {
    createdOn
    customerEmail
    customerName
    customerPhoneNumber
    itemValue
    jobNumber
    market
    moneyCharged
    photosLocalIds
    promiseDate
    status
    comments {
      title
      body
    }
    id
    store {
      storeNumber
    }
    codesCharged {
      code
      description
      price
      quantity
      id
    }
  }
}
`
