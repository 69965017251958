import React, { useState, useEffect } from "react";
import { RenderIf } from "components";
import { RiMessage3Fill } from "react-icons/ri";
import { PulseLoader } from "react-spinners";

export default function CommentsView({ comments, isLoadingCommentUpdate }) {
    const [clicked, setClicked] = useState(undefined);
    const [com, setCom] = useState([]);

    useEffect(() => {
        setCom(comments);
    }, [comments]);

    const clickHandler = (index) => {
        setClicked(index === clicked ? undefined : index);
    };

    return (
        <div className="mx-1 mb-6">
            <h1 className="bg-blue-500 text-sm text-white p-2 font-semibold rounded-lg">Comments:</h1>
            <RenderIf isTrue={com}>
                <div className="overflow-y-auto">
                    {com?.map((comment, index) => (
                        <div
                            className="hover:bg-gray-50 font-semibold cursor-pointer p-1 text-sm"
                            key={index}
                            onClick={() => clickHandler(index)}
                        >
                            <div className="flex items-center">
                                <RiMessage3Fill className="text-gray-500 mr-1" />
                                {comment.title}
                            </div>
                            <RenderIf isTrue={clicked === index}>
                                <div className="text-xs">
                                    {comment.body}
                                </div>
                            </RenderIf>
                        </div>
                    ))}
                    <RenderIf isTrue={isLoadingCommentUpdate}>
                        <PulseLoader color="#94A3B8" size={6} />
                    </RenderIf>
                </div>
            </RenderIf>
            <RenderIf isTrue={!com}>
                <div className="font-semibold italic text-gray-400 text-xs mt-2">There is no comments to show!</div>
            </RenderIf>
        </div>
    );
}
