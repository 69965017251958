import React from 'react';
import { Tooltip } from "@material-tailwind/react";

const AnimatedTooltip = ({ children, content, ...rest }) => (
    <Tooltip
        content={content}
        animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0, y: 25 },
        }}
        {...rest}
    >
        {children}
    </Tooltip>
);

export default AnimatedTooltip;
