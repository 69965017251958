import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import CameraComponent from "./CameraComp";
import { RenderIf, Heading, Badge, CameraSVG } from "components";
import { Input, Button } from "@material-tailwind/react";
import { ToastContainer, toast } from "react-toastify";
import { GET_USER_DATA } from "apollo/queries/getUserData";
import { useUser } from "hooks";
import { useQuery, useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import { GET_USER_JOBS } from "apollo/queries/getUserJobs";
import { UPDATE_JOB } from "apollo/mutations/updateJob";
import classNames from 'classnames'


const InputComp = () => {
    const client = useApolloClient()

    const { currentUser } = useUser()
    const [mutate] = useMutation(UPDATE_JOB)

    const [getNewJobs, { data: userNewReceivedJobs, loading }] = useLazyQuery(GET_USER_JOBS, {
        onCompleted: res => console.log({ res }),
    })

    const jobsWithNoPictures = userNewReceivedJobs?.getUserJobs?.userJobs?.filter(job => !job.photosLocalIds.length)
    
    useQuery(GET_USER_DATA, {
        variables: { email: currentUser.email },
        onCompleted: (res) => getNewJobs({ variables: { id: res?.getUser?.userJobs?.id } }),
        onError: (error) => toast(error.message, { type: 'error' }),
    })


    const history = useHistory();

    const [uploading, setUploading] = React.useState(false);
    const [jobNum, setJobNum] = useState("");
    const [job, setJob] = useState({});
    const [error, setError] = useState("");
    const [camera, setCamera] = useState(false);
    const [photos, setPhotos] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(null);

    const [uploadSuccess, setUploadSuccess] = useState(false)
    const jobNumberInput = useRef(null);

     // Check if job exist, then opens Camera Comp
    const getJob = async () => {
        if (jobNum) {
            setError("");
            const enteredJob = jobsWithNoPictures?.find(job => job.jobNumber === jobNum)
            if (enteredJob && Object.keys(enteredJob).length) {
                console.log({ enteredJob })
                setCamera(true);
                setJob(enteredJob);
            } else { 
                setError("The job number entered doesn't match any entries.");
            }
        } else {
            setError("The job number entered is badly formated.");
        }
    };

    const uploadPhotosToDb = () => { 
        setUploading(true)
        mutate({
            variables: {
                input: {
                    filter: { id: job.id }, 
                    set: {
                        photosLocalIds: [ ...photos ],
                    }
                }
            },
            onCompleted: async () => { 
                await client.refetchQueries({
                    include: ["getUserJobs"],
                })
                setPhotos([])
                setUploading(false)
                setCamera(false)
                setJobNum("")
                setJob({})
                setSelectedIndex(null)
            }
        });
    }

    const clearInputComp = () => {
        setJobNum("");
        setJob({});
        setError("");
        setPhotos([]);
        setUploadSuccess(false)
    };

    const evaluateKey = (e) => {
        if (e.keyCode === 13) {
            getJob();
        }
    };

    const focusHandler = () => {
        if (error) {
            setError("");
            clearInputComp();
        }
    }

    const handleJobClick = (index, jobNumber) => {
        setSelectedIndex(index)
        setJobNum(jobNumber)
    }

    //Autofocus
    React.useEffect(() => {
        jobNumberInput.current.focus();
    }, []);

    React.useEffect(() => { 
        return () => { 
            clearInputComp()
        }
    }, [])

    React.useEffect(() => { 
        if (error) {
            toast(error, {
                type: 'error',
            })
        }
    }, [error])

    return (
        <div className="w-full flex items-start mt-16 ">
            <div className="w-80 mr-6">
                <div className="bg-white flex flex-col justify-starts items-start mr-3 p-6 rounded-xl shadow-xl border border-blue-gray-50">
                    <Heading size='sm' className={'mb-3'}>
                        Input Job Number:
                    </Heading>
                    <Input
                        label="Job Number"
                        type="number"
                        className="h-full py-0 my-0"
                        name="inputJob"
                        ref={jobNumberInput}
                        onFocus={focusHandler}
                        value={jobNum}
                        onChange={(e) => setJobNum(e.target.value)}
                        onKeyDown={evaluateKey}
                        disabled={!!camera}
                    />
                    <Button
                        variant="gradient"
                        color={!!Object.keys(job).length ? 'gray' : 'light-blue'}
                        className="mt-2 w-full"
                        id="EnterJobButton"
                        onClick={getJob}
                        disabled={!!Object.keys(job).length}
                    >
                        Enter
                    </Button>
                </div>

                <RenderIf isTrue={!Object.keys(job).length}>
                    <div className="w-full flex justify-between items-center mb-1 mt-6 px-2">
                        <Heading size='xxs'>
                            Jobs without photo
                        </Heading>
                        <Badge className={'bg-blue-gray-400'}>
                            {jobsWithNoPictures?.length}
                        </Badge>
                    </div>
                    <div className="h-96 overflow-y-auto">
                        <RenderIf isTrue={loading}>
                            <div className="bg-white shadow rounded-lg border border-1 border-blue-gray-50 mb-1 px-3 py-1 cursor-pointer w-full h-12 animate-pulse" />
                            <div className="bg-white shadow rounded-lg border border-1 border-blue-gray-50 mb-1 px-3 py-1 cursor-pointer w-full h-12 animate-pulse" />
                            <div className="bg-white shadow rounded-lg border border-1 border-blue-gray-50 mb-1 px-3 py-1 cursor-pointer w-full h-12 animate-pulse" />
                            <div className="bg-white shadow rounded-lg border border-1 border-blue-gray-50 mb-1 px-3 py-1 cursor-pointer w-full h-12 animate-pulse" />
                        </RenderIf>
                        <RenderIf isTrue={jobsWithNoPictures?.length}>
                            {jobsWithNoPictures?.map((job, index) => (
                                <div
                                    key={job.jobNumber}
                                    className={classNames("bg-white flex flex-col shadow rounded-lg border border-1 border-blue-gray-50 mb-1 px-3 py-1 cursor-pointer", {
                                        "border-blue-gray-300 bg-gray-100": selectedIndex === index,
                                    })}
                                    onClick={() => handleJobClick(index, job.jobNumber)}
                                >
                                    <h6 className="text-sm">{job.customerName}</h6>
                                    <h6 className="text-xs">{job.jobNumber}</h6>
                                </div>
                            ))}
                        </RenderIf>
                        <RenderIf isTrue={!loading && (!jobsWithNoPictures || (jobsWithNoPictures && !jobsWithNoPictures.length))}>
                            <p className="ml-2 italic text-xs text-blue-gray-200">
                                There is no Items to show under this criteria.
                            </p>
                        </RenderIf>
                    </div>
                </RenderIf>
            </div>


            <div className="grow flex flex-col">
                <div className="text-center">
                    <RenderIf isTrue={!job?.photosLocalIds?.length && camera}>
                        <CameraComponent
                            job={job}
                            uploadPhotosToDb={uploadPhotosToDb}
                            uploading={uploading}
                            photos={photos}
                            setPhotos={setPhotos}
                        />
                    </RenderIf>
                    <RenderIf isTrue={!Object.keys(job).length && !camera}>
                        <div className="flex">
                            <div className="grow h-96 bg-gray-100 rounded-xl flex flex-col justify-center items-center">
                                <CameraSVG />
                                <Heading size="xxs" className={'mt-6'}>
                                    Enter Job To Activate Webcam
                                </Heading>
                                <p className="text-blue-gray-500 text-sm">
                                    You need a connected webcam to take pictures
                                </p>
                            </div>
                            <div className="w-72 h-96 bg-gray-100 rounded-xl animate-pulse ml-6" />
                        </div>
                    </RenderIf>
                    <RenderIf isTrue={job?.photosLocalIds?.length && !uploadSuccess}>
                        <div className="w-full mt-12 bg-gray-50 border border-1 border-gray-100 py-16 rounded-lg">
                            <h1 className="mb-3 font-semibold text-gray-600">This Job number has already photos attached to it.</h1>
                            <Button onClick={() => history.push('/queries')}>Search Job</Button>
                        </div>
                    </RenderIf>
                </div>
                <ToastContainer 
                    position="bottom-right"
                    theme="colored"
                    autoClose={8000}
                    closeOnClick={true}                 
                />
            </div>
        </div>
    );
}

export default InputComp