import React, { useState } from "react"
import { useHistory } from "react-router-dom";
import { Input, Button } from "@material-tailwind/react";
import { ToastContainer, toast } from "react-toastify";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export default function LoginForm({ forgottenPassToggling }) {
    const auth = getAuth();
    const history = useHistory();

    const [state, setState] = useState({
        email: window.localStorage.getItem('userEmail') || "",
        password: "",
        errors: "",
        showPassword: false,
    });

    const handleFocus = () => {
        setState({ ...state, errors: "" });
    };

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    // login function
    const loginFunc = () => {
        signInWithEmailAndPassword(auth, state.email, state.password)
        .then((userCredential) => {
                history.push("/dashboard");
                console.log(userCredential.user.displayName);
                console.log("Logged user: ", { userCredential });   
                // refetch()
            })
            .catch((error) => {
                setState({ ...state, errors: error.message });
            });
    };

    const evaluateKey = (e) => {
        if (e.keyCode === 13) {
            loginFunc();
        }
    };

    React.useEffect(() => {
        if (state.errors) {
            toast(state.errors, {
                type: 'error',
            })
        }
    }, [state.errors]);

    const handleClickShowPassword = () => {
        setState({...state, showPassword: !state.showPassword });
    };

    return (
        <div className="w-full flex flex-col justify-center items-center">
            <div className="w-96 flex flex-col justify-center items-center space-y-6">
                <Input
                    label="Email"
                    value={state.email}
                    onFocus={handleFocus}
                    onChange={handleChange}
                    name="email"
                />
                
                <Input
                    label="Password"
                    type={state.showPassword ? "text" : "password"}
                    onKeyDown={evaluateKey}
                    onFocus={handleFocus}
                    onChange={handleChange}
                    name="password"
                    icon={state.showPassword ?
                        <FaRegEye onClick={handleClickShowPassword} className="cursor-pointer" /> :
                        <FaRegEyeSlash onClick={handleClickShowPassword} className="cursor-pointer" />
                    }
                />

                <ToastContainer
                    position="bottom-right"
                    theme="light"
                    autoClose={8000}
                    closeOnClick={true} 
                />
                <Button
                    variant="gradient"
                    onClick={loginFunc}
                    className="w-full"
                >
                    Login
                </Button>
                <div className="w-full flex justify-between">
                    <h1 
                        className="text-sm cursor-pointer text-light-blue-600 hover:text-yellow-900 font-semibold mt-6"
                        onClick={forgottenPassToggling}>
                        Forgot your password?
                    </h1>
                    {/* <h1 
                        className="text-sm cursor-pointer text-light-blue-600 hover:text-yellow-900 font-semibold mt-6"
                        onClick={() => history.push('/registration-form')}>
                        Register new Shop
                    </h1> */}
                </div>
            </div>
        </div>
    );
}
