import React from 'react'

const CameraSVG = (props) => {
  return (
    <svg width="473" height="231" viewBox="0 0 573 331" fill="none" {...props}>
      <rect x="73" y="2" width="426" height="329" rx="50" fill="#FCFCFC"/>
      <path d="M434.595 133L402 243.18L539.816 283.95L572.411 173.77L434.595 133Z" fill="#FAFAFA"/>
      <path d="M437.294 141.343L408.801 237.657L536.941 275.565L565.434 179.252L437.294 141.343Z" fill="#EBEBEB"/>
      <path d="M408.805 237.672L471.275 191.042L487.915 236.702L512.645 222.242L536.945 275.582L408.805 237.672Z" fill="#E0E0E0"/>
      <path d="M512.933 195.391C512.344 197.387 511.176 199.162 509.577 200.494C507.978 201.825 506.021 202.653 503.952 202.872C501.883 203.091 499.795 202.692 497.953 201.725C496.111 200.757 494.597 199.265 493.603 197.438C492.609 195.61 492.179 193.529 492.368 191.457C492.556 189.385 493.355 187.415 494.663 185.797C495.971 184.179 497.729 182.985 499.716 182.367C501.702 181.748 503.828 181.732 505.823 182.321C507.148 182.712 508.383 183.361 509.458 184.229C510.532 185.098 511.425 186.17 512.085 187.384C512.746 188.597 513.16 189.929 513.306 191.303C513.451 192.677 513.325 194.066 512.933 195.391V195.391Z" fill="#E0E0E0"/>
      <path d="M220.049 203L107 230.559L129.033 320.942L242.082 293.383L220.049 203Z" fill="#FAFAFA"/>
      <path d="M218.112 210L113 235.623L132.26 314.629L237.372 289.006L218.112 210Z" fill="#EBEBEB"/>
      <path d="M132.219 314.634L156.969 256.584L187.199 281.764L198.619 261.584L237.329 289.024L132.219 314.634Z" fill="#E0E0E0"/>
      <path d="M187.896 242.674C188.294 244.311 188.199 246.03 187.621 247.612C187.043 249.195 186.009 250.571 184.65 251.566C183.29 252.561 181.666 253.131 179.983 253.203C178.299 253.275 176.632 252.847 175.193 251.972C173.753 251.097 172.605 249.814 171.894 248.287C171.183 246.759 170.94 245.055 171.198 243.39C171.455 241.725 172.2 240.174 173.339 238.932C174.477 237.69 175.959 236.814 177.596 236.414C178.683 236.149 179.812 236.1 180.918 236.271C182.024 236.442 183.086 236.829 184.043 237.41C184.999 237.991 185.832 238.756 186.493 239.659C187.154 240.562 187.631 241.587 187.896 242.674V242.674Z" fill="#E0E0E0"/>
      <path d="M11.5217 96.9999L0 218.435L151.888 232.846L163.41 111.411L11.5217 96.9999Z" fill="#FAFAFA"/>
      <path d="M16.0357 105.14L5.96484 211.283L147.181 224.682L157.252 118.538L16.0357 105.14Z" fill="#EBEBEB"/>
      <path d="M5.92969 211.26L61.5097 149.96L88.1497 194.13L110.97 174.03L147.15 224.65L5.92969 211.26Z" fill="#E0E0E0"/>
      <path d="M105.798 146C105.591 148.2 104.735 150.288 103.34 152.002C101.945 153.715 100.073 154.977 97.9606 155.626C95.8485 156.275 93.5913 156.284 91.4744 155.65C89.3576 155.016 87.4763 153.769 86.0684 152.066C84.6606 150.363 83.7895 148.281 83.5654 146.082C83.3413 143.884 83.7742 141.669 84.8094 139.716C85.8445 137.764 87.4354 136.163 89.3808 135.115C91.3262 134.067 93.5387 133.62 95.7383 133.83C98.6857 134.111 101.401 135.551 103.287 137.833C105.174 140.115 106.077 143.052 105.798 146V146Z" fill="#E0E0E0"/>
      <path d="M537.939 1.00027L378 30.3545L401.469 158.229L561.408 128.874L537.939 1.00027Z" fill="#FAFAFA"/>
      <path d="M538.42 0L537.65 0.160004L535.41 0.600006L526.73 2.24001L494 8.37L378.55 29.8L378.89 29.31C385.89 67.37 393.89 110.99 402.41 157.16L401.85 156.8L561.85 127.52L561.54 127.98C554.54 89.46 548.76 57.53 544.71 35.2C542.71 24.03 541.13 15.27 540.05 9.26C539.52 6.26 539.12 3.99001 538.84 2.42001C538.56 0.850006 538.45 0.0400009 538.45 0.0400009C538.45 0.0400009 538.62 0.8 538.91 2.32C539.2 3.84 539.64 6.13 540.2 9.08C541.32 15.08 542.95 23.77 545.04 34.89C549.19 57.26 555.14 89.25 562.31 127.89L562.38 128.27L561.99 128.34L402.04 157.69L401.57 157.78L401.49 157.31C393.04 111.14 385.04 67.51 378.07 29.45L378 29.03L378.41 28.95L494.21 7.95L526.85 2.02L535.46 0.490005L537.67 0.110001L538.42 0Z" fill="#E0E0E0"/>
      <path d="M534.175 9.11552L385.469 36.4082L405.983 148.181L554.689 120.889L534.175 9.11552Z" fill="#EBEBEB"/>
      <path d="M405.988 148.17L445.168 69.1602L485.318 106.72L502.878 79.4702L554.688 120.87L405.988 148.17Z" fill="#E0E0E0"/>
      <path d="M489.44 52.2303C489.866 54.5474 489.596 56.9388 488.663 59.1021C487.73 61.2654 486.176 63.1034 484.198 64.3836C482.22 65.6637 479.907 66.3286 477.552 66.294C475.196 66.2594 472.903 65.527 470.964 64.1893C469.025 62.8516 467.526 60.9688 466.657 58.7791C465.787 56.5893 465.587 54.191 466.081 51.8874C466.575 49.5839 467.742 47.4786 469.432 45.838C471.123 44.1973 473.262 43.0949 475.58 42.6703C477.118 42.3872 478.696 42.4099 480.225 42.7372C481.754 43.0646 483.204 43.69 484.491 44.5779C485.778 45.4657 486.878 46.5985 487.727 47.9116C488.576 49.2247 489.158 50.6922 489.44 52.2303V52.2303Z" fill="#E0E0E0"/>
      <rect x="123" y="70" width="318" height="165" rx="56" fill="#455A64" stroke="#40555F" stroke-width="8"/>
      <path d="M282.44 208.35C312.783 208.35 337.38 183.752 337.38 153.41C337.38 123.067 312.783 98.4697 282.44 98.4697C252.097 98.4697 227.5 123.067 227.5 153.41C227.5 183.752 252.097 208.35 282.44 208.35Z" fill="#263238"/>
      <path d="M282.439 203.34C272.364 203.331 262.518 200.334 254.145 194.729C245.773 189.124 239.25 181.163 235.402 171.851C231.554 162.54 230.553 152.297 232.525 142.416C234.497 132.536 239.355 123.462 246.483 116.342C253.612 109.222 262.691 104.375 272.574 102.415C282.456 100.454 292.698 101.467 302.005 105.326C311.312 109.186 319.266 115.718 324.861 124.097C330.456 132.476 333.441 142.325 333.439 152.4C333.423 165.916 328.043 178.873 318.48 188.424C308.918 197.975 295.955 203.34 282.439 203.34V203.34ZM282.439 102.45C272.564 102.46 262.914 105.397 254.707 110.889C246.501 116.382 240.106 124.183 236.332 133.308C232.557 142.434 231.572 152.473 233.501 162.157C235.43 171.842 240.187 180.738 247.169 187.72C254.152 194.703 263.047 199.459 272.732 201.388C282.417 203.317 292.456 202.332 301.581 198.558C310.706 194.783 318.508 188.389 324 180.182C329.493 171.976 332.429 162.325 332.439 152.45C332.439 139.19 327.171 126.472 317.794 117.095C308.418 107.718 295.7 102.45 282.439 102.45Z" fill="#455A64"/>
      <path d="M282.439 193.85C305.016 193.85 323.319 175.547 323.319 152.97C323.319 130.392 305.016 112.09 282.439 112.09C259.861 112.09 241.559 130.392 241.559 152.97C241.559 175.547 259.861 193.85 282.439 193.85Z" fill="#455A64"/>
      <path d="M308.638 171.88C308.138 166.74 303.638 164.88 299.408 164.34C290.498 163.09 280.408 165.64 273.088 160.44C270.753 158.722 268.969 156.362 267.953 153.646C266.938 150.931 266.734 147.979 267.368 145.15C268.368 140.86 271.368 136.15 268.458 132.53C267.893 131.858 267.191 131.313 266.4 130.93C265.609 130.548 264.746 130.336 263.868 130.31C262.199 130.266 260.543 130.609 259.028 131.31C255.755 132.755 253.093 135.303 251.508 138.51C247.82 146.103 247.144 154.81 249.618 162.88C251.129 167.911 253.751 172.538 257.291 176.418C260.831 180.299 265.197 183.335 270.068 185.3C274.969 187.191 280.222 187.991 285.464 187.646C290.705 187.3 295.808 185.818 300.418 183.3C303.888 181.39 307.158 178.79 308.328 174.81C308.615 173.862 308.72 172.868 308.638 171.88V171.88Z" fill="white"/>
      <g opacity="0.3">
      <path d="M286.157 124.8C286.159 125.63 285.915 126.441 285.455 127.132C284.996 127.822 284.341 128.361 283.575 128.679C282.809 128.998 281.966 129.081 281.152 128.92C280.339 128.759 279.591 128.359 279.005 127.773C278.418 127.186 278.019 126.439 277.857 125.625C277.696 124.811 277.78 123.968 278.098 123.202C278.417 122.436 278.955 121.782 279.646 121.322C280.336 120.862 281.148 120.618 281.977 120.62C283.086 120.62 284.149 121.061 284.933 121.844C285.717 122.628 286.157 123.692 286.157 124.8V124.8Z" fill="white"/>
      </g>
      <path d="M267.998 189.1C267.755 189.031 267.52 188.933 267.298 188.81C266.858 188.59 266.178 188.32 265.368 187.81C263.03 186.537 260.831 185.022 258.808 183.29C255.799 180.721 253.181 177.726 251.038 174.4C245.661 166.207 243.592 156.279 245.248 146.62C245.879 142.732 247.072 138.956 248.788 135.41C249.948 133.016 251.355 130.75 252.988 128.65C253.548 127.9 254.068 127.38 254.388 127C254.548 126.804 254.721 126.62 254.908 126.45C254.779 126.669 254.632 126.876 254.468 127.07C254.178 127.47 253.688 128 253.158 128.78C251.605 130.907 250.265 133.181 249.158 135.57C247.486 139.11 246.331 142.872 245.728 146.74C244.14 156.28 246.181 166.069 251.448 174.18C253.548 177.482 256.107 180.469 259.048 183.05C261.033 184.795 263.184 186.342 265.468 187.67C266.258 188.17 266.928 188.46 267.348 188.67C267.579 188.791 267.796 188.935 267.998 189.1Z" fill="white"/>
      <path d="M320.548 158.521C320.438 158.521 320.898 156.521 321.048 153.351C321.048 152.541 321.098 151.671 321.048 150.731C320.998 149.791 320.928 148.781 320.828 147.731C320.277 143.167 318.851 138.753 316.628 134.731C316.088 133.821 315.628 132.931 315.058 132.171C314.488 131.411 314.058 130.681 313.518 130.041C311.518 127.531 310.018 126.221 310.088 126.141C310.48 126.397 310.842 126.695 311.168 127.031C311.584 127.39 311.978 127.774 312.348 128.181C312.788 128.661 313.348 129.181 313.808 129.811C314.377 130.487 314.911 131.191 315.408 131.921C315.978 132.691 316.488 133.581 317.048 134.491C319.349 138.559 320.796 143.054 321.298 147.701C321.409 148.697 321.469 149.698 321.478 150.701C321.507 151.585 321.484 152.469 321.408 153.351C321.37 154.078 321.294 154.802 321.178 155.521C321.117 156.066 321.023 156.607 320.898 157.141C320.821 157.61 320.704 158.071 320.548 158.521Z" fill="white"/>
      <path d="M236.979 124.19L241.109 125.33L238.109 126.08C236.549 126.33 235.859 126.99 235.569 127.86L234.569 135.54L233.399 127.85C233.256 127.492 233.039 127.168 232.761 126.901C232.484 126.634 232.152 126.429 231.789 126.3L227.789 125.3L231.969 124.12C232.312 123.976 232.622 123.765 232.882 123.498C233.141 123.232 233.344 122.916 233.479 122.57L234.479 113.88L235.229 122.38C235.354 122.8 235.578 123.184 235.883 123.499C236.188 123.814 236.564 124.051 236.979 124.19V124.19Z" fill="#EBEBEB"/>
      <path d="M312.259 165.58L323.769 168.75L315.259 170.83C310.929 171.52 308.979 173.39 308.189 175.83L305.539 197.22L302.279 175.78C301.877 174.782 301.27 173.879 300.496 173.132C299.723 172.384 298.8 171.807 297.789 171.44L286.789 168.75L298.439 165.45C299.4 165.054 300.271 164.467 300.998 163.724C301.725 162.982 302.293 162.099 302.669 161.13L305.569 136.88L307.649 160.6C307.984 161.728 308.574 162.764 309.373 163.627C310.173 164.491 311.16 165.159 312.259 165.58Z" fill="#EBEBEB"/>
      <path d="M282.44 208.88C271.475 208.88 260.755 205.628 251.638 199.536C242.521 193.444 235.415 184.785 231.219 174.654C227.023 164.523 225.926 153.375 228.066 142.621C230.206 131.866 235.487 121.988 243.242 114.234C250.996 106.481 260.875 101.202 271.63 99.0641C282.385 96.926 293.533 98.0254 303.663 102.223C313.793 106.421 322.451 113.528 328.542 122.646C334.632 131.765 337.882 142.485 337.88 153.45C337.864 168.148 332.018 182.239 321.624 192.631C311.23 203.023 297.138 208.867 282.44 208.88ZM282.44 99.01C271.673 99.01 261.147 102.203 252.195 108.185C243.242 114.167 236.264 122.669 232.144 132.617C228.024 142.564 226.945 153.51 229.046 164.071C231.147 174.631 236.332 184.331 243.945 191.945C251.559 199.558 261.259 204.743 271.819 206.844C282.38 208.945 293.326 207.866 303.273 203.746C313.221 199.626 321.723 192.648 327.705 183.695C333.687 174.743 336.88 164.217 336.88 153.45C336.875 139.01 331.139 125.162 320.931 114.947C310.724 104.732 296.88 98.9859 282.44 98.97V99.01Z" fill="#455A64"/>
      <path d="M228.5 240.5H345.5V254C345.5 258.694 341.694 262.5 337 262.5H237C232.306 262.5 228.5 258.694 228.5 254V240.5Z" fill="#455A64" stroke="#40555F" stroke-width="3"/>
      <path d="M342 103C342 97.4772 346.477 93 352 93H393C406.807 93 418 104.193 418 118V185C418 198.807 406.807 210 393 210H352C346.477 210 342 205.523 342 200V103Z" fill="url(#paint0_linear_0_1)"/>
      <path d="M219 103C219 97.4772 214.523 93 209 93H168C154.193 93 143 104.193 143 118V185C143 198.807 154.193 210 168 210H209C214.523 210 219 205.523 219 200V103Z" fill="url(#paint1_linear_0_1)"/>
      <defs>
      <linearGradient id="paint0_linear_0_1" x1="416" y1="150.5" x2="353.5" y2="150.5" gradientUnits="userSpaceOnUse">
      <stop stop-color="#394D57"/>
      <stop offset="1" stop-color="#394D57" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint1_linear_0_1" x1="145" y1="150.5" x2="207.5" y2="150.5" gradientUnits="userSpaceOnUse">
      <stop stop-color="#394D57"/>
      <stop offset="1" stop-color="#394D57" stopOpacity="0"/>
      </linearGradient>
      </defs>
    </svg>
  )
}

export default CameraSVG