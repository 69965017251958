import React from 'react'
import { useHistory, useLocation } from "react-router-dom";
import benchmanagerLogoWithCompanyName from "images/benchmanager.png";
import { RenderIf } from "components";

const PageHeader = () => {
  const history = useHistory()
  const { pathname } = useLocation()

  return (
    <div className='w-full bg-slate-800 flex justify-between items-center'>
      <div className='w-9/12 3xl:container mx-auto py-4 flex justify-between items-center'>
        <img
            src={benchmanagerLogoWithCompanyName}
            alt=""
            className='w-48 cursor-pointer'
            onClick={() => history.push('/')}
        />
      
        <RenderIf isTrue={pathname === '/'}>
          <div className='flex'>
            <button
              onClick={() => history.push('/authenticate')}
              className="text-sm text-slate-100 hover:text-white font-semibold px-3 rounded mr-3"
              >
                Sign In
            </button>
            {/* <button
              onClick={() => history.push('/registration-form')}
              className="text-sm text-slate-100 hover:text-white font-semibold px-3 rounded mr-3"
              >
                Register
            </button> */}
          </div>
        </RenderIf>
      </div>
    </div>
  )
}

export default PageHeader