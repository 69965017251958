import React, { useState } from "react";
import accounting from "accounting-js";
import DeleteConfirmation from "./DeleteConfirmation";
import { Chip, Button, Tooltip } from "@material-tailwind/react";
import { RiCloseFill } from "react-icons/ri";


export default function AddedCode({ code, removeCode, updateTotal }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleShow = () => {
        setShow(true);
    };

    const clickHandler = () => {
        updateTotal(code.price * -1);
        removeCode(code.code);
        setShow(false);
    };

    return (
        <div className="w-full">
            <span className="w-full flex items-center mt-1">
                <span className="flex-1">
                    <Chip value={code.code ?? "Loading..."} color="amber" />
                </span>
                <span className="flex-1 text-center text-xs">{code.description}</span>
                <span className="flex-1 text-right font-semibold text-gray-700">{code.quantity}</span>
                <span className="flex-1 text-right font-semibold text-green-700">
                    {accounting.formatMoney(code.price)}
                </span>
                <span className="flex-1 text-right">
                    <Tooltip content="Delete code">
                        <Button
                            color="red"
                            variant="text"
                            onClick={handleShow}
                        >
                            <RiCloseFill className="text-xl font-semibold" />
                        </Button>
                    </Tooltip>
                </span>
            </span>
            <DeleteConfirmation
                code={code}
                show={show}
                handleClose={handleClose}
                deleteCode={clickHandler}
            />
        </div>
    );
}
