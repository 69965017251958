export const marketToLocationRelation = {
    partner: 'Wholesale',
    sister: 'Retail',
    home: 'Retail',
}

export const relationColor = {
    partner: 'cyan',
    sister: 'blue',
    home: 'amber'
}

export const STATUS = {
    NEW_JOB: 0,
    RECEIVED: 1,
    COMPLETED: 2,
    INVOICED: 3,
    DELIVERED: 4,
}

export const INDUSTRY = {
	JEWELRY: 'jewelry',
	SMARTPHONES: 'smartphones',
	COMPUTERS: 'computer',
	DRONES: 'drones',
	ANTIQUES: 'antiques',
	WATCHMAKER: 'watchmaker',
	SUNGLASSES: 'sunglasses',
	VIDEOGAME_CONSOLES: 'videogameConsoles',
	GENERAL_ELECTRONICS: 'generalElectronics',
	SECURITY_CAMERAS: 'securityCameras',
	OTHER: 'other',
}