import React from "react";
import { Route, Redirect } from "react-router-dom";

// This component protects every route of unauthorized access from unauthorized users
// that might try to access a route directly
const PrivateRoute = ({ component, path, user }) => {
    const Component = component;

  return (
    <Route 
        exact 
        path={path} 
        render={
            props => !user ?
                <Redirect to='/authenticate' /> :
                user && !user.emailVerified ? 
                    <Redirect to='/user-needs-email-verifcation-landing-page' /> :
                <Component {...props} />
        }
    />
  );

};

export default PrivateRoute;
