export const initialState = {
    jobNumber: "",
    customer: "",
    store: "",
    status: null,
    receivedFromDate: "",
    receivedToDate: "",
    promiseFromDate: "",
    promiseToDate: "",
    minValue: "",
    maxValue: "",
};