import React, { useState } from 'react'
import {
    Input,
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Select, Option
} from "@material-tailwind/react";
import { ADD_REPAIR_GROUP } from "apollo/mutations/addRepairGroup";
import { useMutation, useApolloClient } from "@apollo/client";


const AddGroupModal = ({ isOpen, toggle, entity, userRepairCodesId, suggestedTaskNumbers }) => {
    const client = useApolloClient()

    const [groupName, setGroupName] = useState('')
    const [availableCode, setAvailableCode] = useState(null)

    const [mutate, { loading }] = useMutation(ADD_REPAIR_GROUP, {
        onCompleted: async () => {
            toggle()
            await client.refetchQueries({
                include: ["getUserRepairCodes"],
            })
        }
    })

    const handleAddGroupClick = () => {
        if (groupName && availableCode) {
            mutate({
                variables: {
                    input: {
                        entity,
                        repairGroup: groupName, 
                        groupCodes: [{
                            entity: entity,
                            code: Number(availableCode),
                            description: "Descriptive short names are easy to remember",
                            wholeSalePrice: 0,
                            retailPrice: 0
                        }], 
                        userGroup: {
                            id: userRepairCodesId
                        }
                    }
                }
            })
        } else {
            // throw error
        }
        setGroupName('')
        setAvailableCode(null)
    }
    
    const closeModal = () => {
        setGroupName('')
        setAvailableCode(null)
        toggle()
    }

    return (
        <Dialog open={isOpen} handler={closeModal}>
            <DialogHeader>
                <span>{`Add task group for ${entity[0]?.toUpperCase() + entity?.substring(1)}`}</span>
            </DialogHeader>
            <DialogBody className='flex flex-col'>
                <Input
                    label="Task Group Name"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                />
                <p className='text-blue-gray-600 font-normal mt-3 text-xs'>
                    Your group will be created with a first sample task that you can edit.
                    Choose an available code number to avoid duplicates. The available numbers
                    will be all the available numbers between the lowest code number and the
                    highest code number plus 10.
                </p>
                <p className='text-blue-gray-600 font-normal mt-3'>
                    
                </p>
                <div className="w-12">
                    <Select
                        label="Available Codes"
                        lockScroll
                        onChange={value => setAvailableCode(value)}
                    >
                        {suggestedTaskNumbers?.map(number => (
                            <Option key={number} value={number.toString()}>{number}</Option>
                        ))}                        
                    </Select>
                </div>                
            </DialogBody>
            <DialogFooter>
                <Button
                    variant="gradient"
                    color='gray'
                    onClick={closeModal}
                    className="mr-2"
                    disabled={loading}
                >
                    Cancel
                </Button>
                <Button
                    variant="gradient"
                    color={loading ? "gray" : "blue"}
                    onClick={handleAddGroupClick}
                    disabled={loading}
                >
                    {loading ? "Working on it..." : "Add Group"}
                </Button>
            </DialogFooter>
        </Dialog>   
    )
}

export default AddGroupModal