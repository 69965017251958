import React, { Fragment, useState } from "react";
import { Chip } from "@material-tailwind/react";
import { Heading, RenderIf } from "components";
import InfoContainer from "./InfoContainer";
import { relationColor } from "constants";
import classNames from "classnames";

const StoreCard = ({ store }) => {
    const [show, setShow] = useState(false)

    const clickHandler = () => {
        setShow(current => !current)
    };

    return (
        <Fragment>
            <div
                className={classNames("w-full flex justify-between items-center shadow-2xl rounded-xl p-6 mt-4 cursor-pointer border border-1", {
                    "border-gray-50": !show,
                    "bg-white": show,
                })}
                onClick={clickHandler}
            >
                <div className="flex items-center">
                    <Heading size="sm" className="mr-2">
                        {store?.storeNumber}
                    </Heading>
                    <span className="flex items-center">
                        <span className="text-4xl text-blue-gray-500 mr-2">
                            &middot;
                        </span>
                        <span className="text-blue-gray-400">
                            {store?.storeName}
                        </span>
                    </span>
                </div>
                <div>
                    <Chip
                        value={`${store?.relation} location`}
                        color={relationColor[store?.relation]}
                    />
                </div>
            </div>
            <RenderIf isTrue={show}>
                <div className={
                    `w-full mx-auto px-6 pb-6 pt-8 -mt-6 z-50 bg-white shadow-xl 
                    rounded-b-xl border border-1 border-t-white border-l-gray-100
                    border-b-gray-100 border-r-gray-100`}>
                    <InfoContainer store={store} />
                </div>
            </RenderIf>
        </Fragment>
    );
}

export default StoreCard;
