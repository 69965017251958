import React, { useEffect, useRef, useCallback } from "react"
import { Button } from "@material-tailwind/react"
import { Heading, AnimatedTooltip } from "components"
import { useUser } from "hooks"
import { isEqual } from "lodash"
import { useQuery } from "@apollo/client"
import { GET_USER_DATA } from "apollo/queries/getUserData"
import { ToastContainer, toast } from "react-toastify";
import { initialState } from './constants'
import { STATUS } from '../../constants'


export default function InputsBox({ filters, setFilters, doFilteredSearch, setUserFilteredByStore }) {
    const { currentUser } = useUser()
    const { data } = useQuery(GET_USER_DATA, {
        variables: { email: currentUser.email },
        onError: (error) => toast(error.message, {
            type: 'error',
        })
    })

    const isMounted = useRef(false)
    const storeInputRef = useRef(null)
    const statusInputRef = useRef(null)
    const receivedFromDateRef = useRef(null)
    const receivedToDateRef = useRef(null)
    const promisedFromDateRef = useRef(null)
    const promisedToDateRef = useRef(null)

    const handleChange = (e) => {
        setFilters(previous => ({ ...previous, [e.target.name]: e.target.value }));
    }

    const handleStoreNumberChange = (e) => {
        setFilters(previous => ({ ...previous, store: e.target.value }));
    }

    const handleStatusChange = (e) => {
        const value = STATUS[e.target.value]
        setFilters(previous => ({ ...previous, status: value }));
    }

    const searchClickHandler = useCallback(() => {
        doFilteredSearch()
    }, [doFilteredSearch])

    const keyListener = (event) => {
        if (event.keyCode === 13) {
            searchClickHandler()
        }
    }

    const clearClickHandler = () => { 
        setUserFilteredByStore(false)
        const emptyState = { ...initialState }
        setFilters(() => emptyState)
        storeInputRef.current.selectedIndex = 0
        statusInputRef.current.selectedIndex = 0
        receivedFromDateRef.current.value = ''
        receivedToDateRef.current.value = ''
        promisedFromDateRef.current.value = ''
        promisedToDateRef.current.value = ''
    }

    useEffect(() => { 
        if (isMounted && isEqual(filters, initialState)) {
            searchClickHandler()
        }
    }, [filters, searchClickHandler])

    useEffect(() => { 
        isMounted.current = true
    }, [])

    return (
        <div className="w-full mb-3 bg-blue-gray-100 pl-6 pt-2 pr-6 pb-6 rounded-lg mt-16">
            <Heading size="xs" className="mb-1">
                Filter search
            </Heading>
            <div className="flex justify-between items-start">
                <div className="flex flex-col grow">
                    <div className="flex justify-between items-start space-x-1 pr-2">
                        <AnimatedTooltip content="Job number">
                            <div className="flex-1">
                                <input
                                    className="w-full h-8 rounded-lg px-2 text-sm"
                                    placeholder="Job Number"
                                    name="jobNumber"
                                    value={filters?.jobNumber}
                                    onChange={handleChange}
                                    onKeyDown={keyListener}
                                />
                            </div>
                        </AnimatedTooltip>
                        <AnimatedTooltip content="Customer name">
                            <div className="flex-1">
                                <input
                                    className="w-full h-8 rounded-lg px-2 text-sm"
                                    placeholder="Customer"
                                    name="customer"
                                    value={filters?.customer}
                                    onChange={handleChange}
                                    onKeyDown={keyListener}
                                />
                            </div>
                        </AnimatedTooltip>
                        <AnimatedTooltip content="Store number">
                            <div className="flex-1">
                                <select
                                    ref={storeInputRef}
                                    className="w-full h-8 rounded-lg px-2 text-sm"
                                    name="store"
                                    onChange={handleStoreNumberChange}
                                >
                                    <option className="text-blue-gray-500">Store Number</option>
                                    {data?.getUser?.userStores.map((store, index) => (
                                        <option
                                            key={index}
                                            value={store.storeNumber.toString()}
                                        >
                                            {store.storeNumber}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </AnimatedTooltip>

                        <AnimatedTooltip content="Received from date">
                            <div className="flex-1">
                                <input
                                    ref={receivedFromDateRef}
                                    className="w-full h-8 rounded-lg px-2 text-sm"
                                    type="date"
                                    value={filters?.from}
                                    name="receivedFromDate"
                                    onChange={handleChange}
                                />
                            </div>
                        </AnimatedTooltip>
                        <AnimatedTooltip content="Received to date">
                            <div className="flex-1">
                                <input
                                    ref={receivedToDateRef}
                                    className="w-full h-8 rounded-lg px-2 text-sm"
                                    type="date"
                                    value={filters?.to}
                                    name="receivedToDate"
                                    onChange={handleChange}
                                />
                            </div>
                        </AnimatedTooltip>
                    </div>
                    {/*------------------------------------------------*/}
                    <div className="flex justify-between items-start space-x-1 pr-2 mt-2">
                        <AnimatedTooltip content="Job status">
                            <div className="flex-1">
                                <select
                                    ref={statusInputRef}
                                    className="w-full h-8 rounded-lg px-2 text-sm"
                                    onChange={handleStatusChange}
                                >
                                    <option value="Any">Any</option>
                                    <option value="NEW_JOB">New Job</option>
                                    <option value="RECEIVED">Received</option>
                                    <option value="COMPLETED">Completed</option>
                                    <option value="INVOICED">Invoiced</option>
                                    <option value="DELIVERED">Picked Up</option>
                                </select>
                            </div>
                        </AnimatedTooltip>
                        <AnimatedTooltip content="Minimum value">
                            <div className="flex-1">
                                <input
                                    placeholder="Min Value"
                                    className="w-full h-8 rounded-lg px-2 text-sm"
                                    name="minValue"
                                    value={filters?.minValue}
                                    onChange={handleChange}
                                    onKeyDown={keyListener}
                                />
                            </div>
                        </AnimatedTooltip>
                        <AnimatedTooltip content="Maximum value">
                            <div className="flex-1">
                                <input
                                    placeholder="Max Value"
                                    className="w-full h-8 rounded-lg px-2 text-sm"
                                    name="maxValue"
                                    value={filters?.maxValue}
                                    onChange={handleChange}
                                    onKeyDown={keyListener}
                                />
                            </div>
                        </AnimatedTooltip>
                        <AnimatedTooltip content="Promised from date">
                            <div className="flex-1">
                                <input
                                    ref={promisedFromDateRef}
                                    className="w-full h-8 rounded-lg px-2 text-sm"
                                    type="date"
                                    name="promiseFromDate"
                                    value={filters?.promiseFrom}
                                    onChange={handleChange}
                                />
                            </div>
                        </AnimatedTooltip>
                        <AnimatedTooltip content="Promised to date">
                            <div className="flex-1">
                                <input
                                    ref={promisedToDateRef}
                                    className="w-full h-8 rounded-lg px-2 text-sm"
                                    type="date"
                                    name="promiseToDate"
                                    value={filters?.promiseTo}
                                    onChange={handleChange}
                                />
                            </div>
                        </AnimatedTooltip>
                    </div>
                </div>
                <div className="h-full flex flex-col justify-between items-center">
                    <Button
                        variant="gradient"
                        size="sm"
                        className="mb-2 w-full"
                        onClick={searchClickHandler}
                    >
                        Search
                    </Button>
                    <Button
                        variant="gradient"
                        size="sm"
                        className="w-full"
                        color="blue-gray"
                        onClick={clearClickHandler}
                    >
                        Clear
                    </Button>

                </div>
            </div>
            <ToastContainer
                position="bottom-right"
                theme="colored"
                autoClose={8000}
                closeOnClick={true}
            />
        </div>
    );
}
