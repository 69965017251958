
import { gql } from "@apollo/client";

export const UPDATE_USER_REPAIR_CODES = gql`
mutation updateUserRepairCodes($input: UpdateUserRepairCodesInput!) {
  updateUserRepairCodes(input: $input) {
    numUids
  }
}
`
