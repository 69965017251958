import React from 'react'
import Routes from './routes/routes'
import './App.css'
import { ThemeProvider } from "@material-tailwind/react";
import 'react-toastify/dist/ReactToastify.css';
// react query provider
import { QueryClient, QueryClientProvider } from 'react-query'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const googleRecapchaApiKey = '6LcPbiEiAAAAAIxboWsrHyniFeLkO3762eQWzCJn'

// React-query client
const queryClient = new QueryClient()

const App = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={googleRecapchaApiKey}>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <Routes />
        </QueryClientProvider>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  )
}

export default App;

// TODO list :

//  Make a Search on the price codes as well
//  Make sure only one terminal is conected per account, not sure I want this
//  Blake videos

// disable complete job button if code hasnt been added to stack 



// *** Bugs ***
// When store number is changed, all old jobs keep the old store number {***  Expected  ***}
// When store is removed, all old jobs keep the old store number {***  Expected  ***}

// Investigate this:
// Some codes are not showing on the completed job
// and complete 1 job did complete them all once look into this 

// On Complete jobs, if an added code is duplicated, it will remove both when removing one, cuz code num is the same, remove by id



// *** After Release ***
      // Make Codes something that works for anyone not only Jewelers
      // Add all uncompleted jobs to the complete page so the user picks them one by one with 1 click
      // User delete account
      // The user can add a new code
      // 
      // Add telephon Country code setting

      // Add Deliver Job Feature--- to mark them as pickedUp maybe then after that call only userjobs that are in store, and pickedUp jobs only on filtering
      // Implementar close account
      // Create pagination on the Jobs Page
      // solve problem of aut focus on all enter job number inputs and also all the document.getElementById bullshit
      // Adding notifications badges all over the place with totals
      // Add date to job messages

      // Receiving job, jobs can be pushed, while editing one of the entered jobs
      // Prefered language Setting ( Implement Spanish )