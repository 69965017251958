import { gql } from '@apollo/client';

export const ADD_STORE_MUTATION = gql`
    mutation AddStore($store: [AddStoreInput!]!) {
        addStore(input: $store) {
            store {
                id
            }
        }
    }
`;

// "store": {
//   "relation": "sister",
//   "storeAddress": "9988 Violet Cannon Dr NW, Concord, NC 28027, USA",
//   "storePhoneNumber": "8573124946",
//   "storeEmail": "violet.store@gmail.com",
//   "storeName": "Violet Jewelres",
//   "storeNumber": 9988,
//   "user": {
//     "email": "business@benchmanager.io"
//   }
// },