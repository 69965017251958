import React from "react";
import {
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Chip,
    Button,
} from "@material-tailwind/react";

export default function DeleteConfirmation({
    job,
    show,
    handleClose,
    removeJob,
}) {
    const removingAndClosing = () => {
        removeJob();
        handleClose();
    };

    return (
        <div>
            <Dialog open={show} handler={handleClose}>
                <DialogHeader>
                    <Chip value={job.jobNumber ?? "Loading..."} />
                </DialogHeader>
                <DialogBody>
                    <div>You are about to Remove this job from the system!</div>
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="gradient"
                        onClick={handleClose}
                        color="gray"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="gradient"
                        onClick={removingAndClosing}
                        color="deep-purple"
                        className="ml-2"
                    >
                        Remove
                    </Button>
                </DialogFooter>
            </Dialog>
        </div>
    );
}
