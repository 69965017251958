import React from "react";
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";

const DeleteConfirmation = ({ element, show, handleClose, handleDelete, deleteActionInProgress }) => {
    return (
        <Dialog open={show} handler={handleClose}>
            <DialogHeader>
                <span>{element}</span>
            </DialogHeader>
            <DialogBody>
                <div>Are you sure you want to remove this location from your customer list?</div>
            </DialogBody>
            <DialogFooter>
                <Button
                    variant="gradient"
                    onClick={handleClose}
                    className="mr-2"
                    disabled={deleteActionInProgress}
                >
                    Cancel
                </Button>
                <Button
                    variant="gradient"
                    color={deleteActionInProgress ? "gray" : "red"}
                    onClick={handleDelete}
                    disabled={deleteActionInProgress}
                >
                    {deleteActionInProgress ? "Working on it..." : "Yes"}
                </Button>
            </DialogFooter>
        </Dialog>
    );
}

export default DeleteConfirmation