import React, { useState } from "react";
import { Input, Button } from "@material-tailwind/react";
import { ToastContainer, toast } from "react-toastify";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export default function ForgottenForm(props) {
    const auth = getAuth()

    const [state, setState] = useState({email: "", errors: ""});
    React.useEffect(() => {
        if(state.errors){
            toast(state.errors, {
                type: 'error'
            })
        }
    }, [state.errors])
    const handleFocus = () => {
        setState({ ...state, errors: "" });
    };
    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };
    const sendPasswordReset = () => {
        sendPasswordResetEmail(auth, state.email)
            .then((res) => {
                console.log('Email sent!', { res });
                props.sendingEmail();
            })
            .catch((err) => {
                setState({ ...state, errors: err.message });
            });
    };
    return (
        <div className="w-96 flex flex-col justify-between space-y-6">
            <h1 className="text-gray-700">
                Enter your email and we will send you a security link.
            </h1>
            <Input
                label="Email"
                onFocus={handleFocus}
                onChange={handleChange}
                name="email"
            />
            <ToastContainer 
                position="bottom-right"
                theme="light"
                autoClose={8000}
                closeOnClick={true}
            />
            <Button
                variant="gradient"
                onClick={sendPasswordReset}
                className="w-full"
            >
                Send email
            </Button>
            <h1
                className="text-sm cursor-pointer  text-light-blue-600 hover:text-yellow-900 font-semibold mt-6"
                onClick={props.forgottenPassToggling}>
                Go back to Login
            </h1>

        </div>
    );
}
