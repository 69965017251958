import React from 'react'
import dashboard_screenshot from "images/dashboard.jpg";
import InvoicingVideo from "images/Invoicing.mp4";
import PriceCodesVideo from "images/PriceCodes.mp4";
import DataEntryVideo from "images/data_entry.mp4";
import PhotographyVideo from "images/Photography.mp4";
import CompletingJobVideo from "images/CompletingJob.mp4";
import { PageHeader } from "components";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { IoReload } from "react-icons/io5";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { PopupButton } from "react-calendly";
// import { GiCutDiamond } from "react-icons/gi";
// import { MdPhoneIphone } from "react-icons/md";
// import { BsLaptop } from "react-icons/bs";
// import { GiDeliveryDrone } from "react-icons/gi";
// import { BsWatch } from "react-icons/bs";
// import { GiPaintedPottery } from "react-icons/gi";
// import { GiSunglasses } from "react-icons/gi";
// import { GiGameConsole } from "react-icons/gi";
// import { GiCircuitry } from "react-icons/gi";
// import { GiSwissArmyKnife } from "react-icons/gi";
import { BsFillPatchCheckFill } from "react-icons/bs";
import ModalVideo from 'react-modal-video'

const Website = () => {

  const [isOpen, setIsOpen] = React.useState(false)
  
  return (
    <div className='bg-gradient-to-b from-blue-gray-50 via-white to-blue-gray-100'>
      <section className='w-full h-screen flex justify-center relative'>
        <div className='w-full'>
          <PageHeader />
          <div className='container mx-auto flex justify-center mt-16 3xl:mt-36'>
            <div className='flex flex-col items-center z-50'>
              <h1 className='text-blue-gray-800 text-5xl 3xl:text-5xl font-black z-50'>
                The CRM made for Workshops!
              </h1>
              <div className="w-1/2 text-md 3xl:text-xl text-center text-slate-600 mt-8 mb-3">
                A powerful tool that gives you a way to manage your workshop,
                improving productivity and increasing revenue. An engineered
                workflow methodology developed and refined by Giants for over 100 years!
              </div>
              <div className='w-52 h-32 bg-light-blue-500 blur-3xl -mt-72 mb-32 opacity-10'/>
              <div className='w-3/4 h-16 bg-light-blue-300 blur-3xl -mt-24 mb-12 opacity-10' />
              <div className='w-1/2 h-32 bg-light-blue-500 blur-3xl -mt-52 mb-24 opacity-10' />
              
              <div className='flex justify-center items-center mt-6'>
                {/*  Calendly Widget */}
                <div className='bg-white text-light-blue-500 text-lg font-semibold pt-2 pb-2 px-4 rounded-lg  hover:shadow-2xl z-50'>
                    <PopupButton
                      id="popup-button"
                      url="https://calendly.com/benchmanager/30min"
                      /*
                      * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                      * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                      */
                      rootElement={document.getElementById("root")}
                      text="REQUEST A DEMO"
                      // textColor="#ffffff"
                      // color="#00a2ff"
                    />
                </div>
                <div className='w-52 h-32 bg-light-blue-500 blur-3xl opacity-20 absolute'/>
                {/* <Button
                  onClick={navigateToSubscription}
                  color='light-blue'
                  size='sm'
                  className='ml-6 z-50'
                >
                  Start Free Trial
                </Button> */}
              </div>

              {/*  First image/video */}
              <div className='w-1/2 3xl:w-7/12 mx-auto shadow-2xl mt-24 rounded-xl z-50' >
                <div className='h-8 rounded-t-xl bg-blue-gray-100 flex items-center'>
                  <span className='w-3 h-3 rounded-full bg-red-500 ml-3' />
                  <span className='w-3 h-3 rounded-full bg-yellow-900 ml-2' />
                  <span className='w-3 h-3 rounded-full bg-green-500 ml-2' />
                </div>
                <div className='h-10 bg-blue-gray-50 flex items-center'>
                  <BsArrowLeft className='ml-3 text-blue-gray-500' />
                  <BsArrowRight className='ml-2 text-blue-gray-500' />
                  <IoReload className='ml-3 text-blue-gray-500' />
                  <div className='h-6 w-full bg-white rounded-lg mx-4' />
                  <BiDotsVerticalRounded className='mr-3 text-blue-gray-500' />
                </div>
                <img
                  src={dashboard_screenshot}
                  alt=""
                  className='rounded-b-xl cursor-pointer'
                  onClick={() => setIsOpen(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className='w-3/4 mx-auto flex justify-center items-center mt-0'>
        <div className='w-1/2 flex flex-col justify-center items-center mt-36 3xl:mt-56'>
          <h1 className='text-blue-gray-800 text-3xl 3xl:text-4xl font-black z-50 mt-72'>
            Discover Bench Manager.
          </h1>
          <p className="text-sm font-semibold text-blue-gray-500 mt-3">
            Your Shop's Secret Weapon.
          </p>
          <div className="w-10/12 text-md 3xl:text-xl text-center text-slate-600 mt-4">
            Bench Manager is a transformative tool designed specifically for jewelry workshops.
            This innovative software integrates scientific methodologies with proven industry techniques,
            offering an effective solution to manage and streamline tasks. Organize your work,
            boost productivity, and enhance your income with Bench Manager. Experience firsthand the
            impressive impact of cutting-edge technology on your workshop.
          </div>
        </div>

        <div className='w-1/2 flex flex-col justify-center items-center mt-36 3xl:mt-56'>
          <h1 className='text-blue-gray-800 text-3xl 3xl:text-4xl font-black z-50 mt-72'>
            Am I in the right place?
          </h1>
          <p className="text-sm font-semibold text-blue-gray-500 mt-3">
            If you are a Jeweler most likely yes.
          </p>
          <div className="w-10/12 text-md 3xl:text-xl text-center text-slate-600 mt-4">
            If you're a jeweler aspiring to optimize operations and productivity, you've come to the right
            place. Bench Manager, tailor-made for jewelry workshops, offers a suite of tools to simplify
            job management, increase precision, and eliminate mundane tasks. Whether you handle wholesale,
            retail, repairs, or manufacturing, Bench Manager is your key to success. Take charge,
            streamline your workflow, and let your business flourish.
          </div>
        </div>
      </section>

      {/* <section className='w-full flex flex-col justify-center items-center mt-44 mb-12'>
        <h1 className='text-blue-gray-800 text-3xl 3xl:text-4xl font-black z-50'>
          Am I in the right place?
        </h1>
        <div className="w-1/2 text-md 3xl:text-xl text-center text-slate-600 mt-8">
          If you repair something in a bench, your are in the right place.
        </div>

        <div className="w-1/2 mx-auto flex justify-between items-center mt-12 mb-3">
          <div className='flex flex-col justify-center items-center'>
            <GiCutDiamond className='text-blue-gray-300 text-4xl'/>
            <h1 className='text-center text-blue-gray-300 text-xl font-black'>
              Jewelry Workshops
            </h1>
          </div>
          <div className='flex flex-col justify-center items-center'>
            <MdPhoneIphone className='text-blue-gray-300 text-4xl'/>
            <h1 className='text-center text-blue-gray-300 text-xl font-black'>
              Smartphone Repair Shops
            </h1>
          </div>
          <div className='flex flex-col justify-center items-center'>
            <BsLaptop className='text-blue-gray-300 text-4xl'/>
            <h1 className='text-center text-blue-gray-300 text-xl font-black'>
              Computer Repair Shops 
            </h1>
          </div>
          <div className='flex flex-col justify-center items-center'>
            <GiDeliveryDrone className='text-blue-gray-300 text-4xl'/>
            <h1 className='text-center text-blue-gray-300 text-xl font-black'>
              Drone Repair Shops
            </h1>
          </div>
        </div>

        <div className="w-1/2 mx-auto flex justify-between items-center mt-8 mb-3">
          <div className='flex flex-col justify-center items-center'>
            <BsWatch className='text-blue-gray-300 text-4xl' />
            <h1 className='text-center text-blue-gray-300 text-xl font-black'>
              Watchmaker Shops
            </h1>
          </div>
          <div className='flex flex-col justify-center items-center'>
            <GiPaintedPottery className='text-blue-gray-300 text-4xl' />
            <h1 className='text-center text-blue-gray-300 text-xl font-black'>
              Antiques Repair Shops
            </h1>
          </div>
          <div className='flex flex-col justify-center items-center'>
            <GiSunglasses className='text-blue-gray-300 text-4xl' />
            <h1 className='text-center text-blue-gray-300 text-xl font-black'>
              Glasses Repair Shops
            </h1>
          </div>
          <div className='flex flex-col justify-center items-center'>
            <GiGameConsole className='text-blue-gray-300 text-4xl' />
            <h1 className='text-center text-blue-gray-300 text-xl font-black'>
              Games Consoles Shops
            </h1>
          </div>
        </div>

        <div className="w-1/2 mx-auto flex justify-center items-center mt-8 mb-3">
          <div className='flex flex-col justify-center items-center mr-12'>
            <GiCircuitry className='text-blue-gray-300 text-4xl' />
            <h1 className='text-center text-blue-gray-300 text-xl font-black'>
              General Electronics Repairs
            </h1>
          </div>
          <div className='flex flex-col justify-center items-center'>
            <GiSwissArmyKnife className='text-blue-gray-300 text-4xl' />
            <h1 className='text-center text-blue-gray-300 text-xl font-black'>
              General Bench Items Repairs
            </h1>
          </div>
        </div>
      </section> */}

      {/*  Right picture block  */}
      <section className='w-full'>
        <div className='w-8/12 mx-auto text-center mt-44'>
          <h1 className='text-blue-gray-800 text-3xl 3xl:text-4xl font-black z-50'>
            Some of the Whys!
          </h1>
        </div>
        <div className='w-8/12 mx-auto flex justify-between items-start mt-24'>
          <div className='flex-1 pr-16'>
            <h1 className='text-blue-gray-600 text-4xl font-black'>
              Master Your Pricing: Fully Customizable Codes
            </h1>
            <p className="text-2xl font-semibold text-blue-gray-500 mt-6">
              Wholesale and retail pricing made easy.
            </p>
            <p className='text-blue-gray-500 mt-6'>
              Manage your workshop effortlessly with our task codes. Each code represents
              a service offered by your workshop, complete with wholesale and retail pricing.
              Tailored for individual stores, these task codes are fully customizable – add,
              edit, or delete at will. With Bench Manager, you have the complete authority to
              create, modify, and organize task groups as you see fit.
            </p>
          </div>
          <div className='w-3/4 flex-1 mx-auto shadow-2xl bg-white rounded-xl' >
            <div className='h-6 rounded-t-xl bg-blue-gray-100 flex items-center'>
              <span className='w-2 h-2 rounded-full bg-red-500 ml-3' />
              <span className='w-2 h-2 rounded-full bg-yellow-900 ml-2' />
              <span className='w-2 h-2 rounded-full bg-green-500 ml-2' />
            </div>
            <div className='h-6 bg-blue-gray-50 flex items-center'>
              <BsArrowLeft className='ml-3 text-blue-gray-500 text-xs' />
              <BsArrowRight className='ml-2 text-blue-gray-500 text-xs' />
              <IoReload className='ml-3 text-blue-gray-500 text-xs' />
              <div className='h-3 w-full bg-white rounded-lg mx-4' />
              <BiDotsVerticalRounded className='mr-3 text-blue-gray-500 text-xs' />
            </div>
            <video
                playsInline="playsinline"
                autoPlay="autoplay"
                muted="muted"
                loop="loop"
                className="h-11/12 rounded-b-xl"
            >
                <source src={PriceCodesVideo} type="video/mp4" />
            </video>
          </div>
        </div>
      </section>

      
      {/*  Left Picture Block */}
      <section className='w-full h-full'>
        <div className='w-8/12 mx-auto flex justify-between items-start'>
          <div className='flex-1 mx-auto shadow-2xl bg-white mt-44 rounded-xl' >
            <div className='h-6 rounded-t-xl bg-blue-gray-100 flex items-center'>
              <span className='w-2 h-2 rounded-full bg-red-500 ml-3' />
              <span className='w-2 h-2 rounded-full bg-yellow-900 ml-2' />
              <span className='w-2 h-2 rounded-full bg-green-500 ml-2' />
            </div>
            <div className='h-6 bg-blue-gray-50 flex items-center'>
              <BsArrowLeft className='ml-3 text-blue-gray-500 text-xs' />
              <BsArrowRight className='ml-2 text-blue-gray-500 text-xs' />
              <IoReload className='ml-3 text-blue-gray-500 text-xs' />
              <div className='h-3 w-full bg-white rounded-lg mx-4' />
              <BiDotsVerticalRounded className='mr-3 text-blue-gray-500 text-xs' />
            </div>
            <video
                playsInline="playsinline"
                autoPlay="autoplay"
                muted="muted"
                loop="loop"
                className="h-11/12 rounded-b-xl"
            >
                <source src={CompletingJobVideo} type="video/mp4" />
            </video>
          </div>
          <div className='mt-44 flex-1 pl-16'>
            <h1 className='text-blue-gray-600 text-4xl font-black'>
              Don't memorize task codes!
            </h1>
            <p className="text-2xl font-semibold text-blue-gray-500 mt-6">
              Let our autocomplete do the work.
            </p>
            <p className='text-blue-gray-500 mt-6'>
              With Bench Manager, you don’t have to remember all those task codes.
              As your workshop expands, the volume of codes can increase exponentially.
              This is why we've integrated an autocomplete feature, ensuring an easy
              search and attach process when you charge a job in the system.
              Spend less time memorizing, and more time creating.
            </p>
          </div>
        </div>
      </section>

      
      {/*  Right Picture Block */}
      <section className='w-full'>
        <div className='w-8/12 mx-auto flex justify-between items-start'>
          <div className='mt-44 flex-1 pr-16'>
            <h1 className='text-blue-gray-600 text-4xl font-black'>
              Save photos of every Job!
            </h1>
            <p className="text-2xl font-semibold text-blue-gray-500 mt-6">
              Document your work with our photo record feature.
            </p>
            <p className='text-blue-gray-500 mt-6'>
              Ensure a visual record of every piece of jewelry that comes to your
              workshop. By capturing photos when you receive each item, you can
              document its original condition, preserving this essential information
              for future reference or in case of potential claims.
              This feature provides you an extra layer of security, and valuable
              peace of mind. Please note that this function requires a webcam and
              browser camera access.
            </p>
          </div>
          <div className='w-3/4 flex-1 mx-auto shadow-2xl bg-white mt-44 rounded-xl' >
            <div className='h-6 rounded-t-xl bg-blue-gray-100 flex items-center'>
              <span className='w-2 h-2 rounded-full bg-red-500 ml-3' />
              <span className='w-2 h-2 rounded-full bg-yellow-900 ml-2' />
              <span className='w-2 h-2 rounded-full bg-green-500 ml-2' />
            </div>
            <div className='h-6 bg-blue-gray-50 flex items-center'>
              <BsArrowLeft className='ml-3 text-blue-gray-500 text-xs' />
              <BsArrowRight className='ml-2 text-blue-gray-500 text-xs' />
              <IoReload className='ml-3 text-blue-gray-500 text-xs' />
              <div className='h-3 w-full bg-white rounded-lg mx-4' />
              <BiDotsVerticalRounded className='mr-3 text-blue-gray-500 text-xs' />
            </div>
            <video
                playsInline="playsinline"
                autoPlay="autoplay"
                muted="muted"
                loop="loop"
                className="h-11/12 rounded-b-xl"
            >
                <source src={PhotographyVideo} type="video/mp4" />
            </video>

          </div>
        </div>
      </section>      

      
      {/*  Left Picture Block */}
      <section className='w-full h-full'>
        <div className='w-8/12 mx-auto flex justify-between items-start'>
          <div className='flex-1 mx-auto shadow-2xl bg-white mt-44 rounded-xl' >
            <div className='h-6 rounded-t-xl bg-blue-gray-100 flex items-center'>
              <span className='w-2 h-2 rounded-full bg-red-500 ml-3' />
              <span className='w-2 h-2 rounded-full bg-yellow-900 ml-2' />
              <span className='w-2 h-2 rounded-full bg-green-500 ml-2' />
            </div>
            <div className='h-6 bg-blue-gray-50 flex items-center'>
              <BsArrowLeft className='ml-3 text-blue-gray-500 text-xs' />
              <BsArrowRight className='ml-2 text-blue-gray-500 text-xs' />
              <IoReload className='ml-3 text-blue-gray-500 text-xs' />
              <div className='h-3 w-full bg-white rounded-lg mx-4' />
              <BiDotsVerticalRounded className='mr-3 text-blue-gray-500 text-xs' />
            </div>
            <video
                playsInline="playsinline"
                autoPlay="autoplay"
                muted="muted"
                loop="loop"
                className="h-11/12 rounded-b-xl"
            >
                <source src={DataEntryVideo} type="video/mp4" />
            </video>
          </div>
          <div className='mt-44 flex-1 pl-16'>
            <h1 className='text-blue-gray-600 text-4xl font-black'>
              Detailed data entry!
            </h1>
            <p className="text-2xl font-semibold text-blue-gray-500 mt-6">
              Multiple job entries made easy.
            </p>
            <p className='text-blue-gray-500 mt-6'>
              Bench Manager gives you the capability to input multiple job tickets simultaneously,
              while maintaining full control over every detail. With a single click, you can
              confirm and preserve all the information, creating an indelible record for each job.
              This feature streamlines your ticketing process, saving time and ensuring no detail
              is ever lost. Simplify your workflow and ensure the accuracy of your records with
              Bench Manager.
            </p>
          </div>
        </div>
      </section>

      



      {/*  Right Picture Block */}
      <section className='w-full'>
        <div className='w-8/12 mx-auto flex justify-between items-start'>
          <div className='mt-44 flex-1 pr-16'>
            <h1 className='text-blue-gray-600 text-4xl font-black'>
              Update jobs with 1 click!
            </h1>
            <p className="text-2xl font-semibold text-blue-gray-500 mt-6">
              Invoice and deliver multiple jobs, while notifying partners and clients via email, all with a single click!
            </p>
            <p className='text-blue-gray-500 mt-6'>
              By accessing the invoices page, you can easily keep track of all completed work
              categorized by each store, including your own store. When selecting all, you can
              simply click the Submit button and the system will email each store with a list
              of completed jobs and the total amount due, letting them know the jobs are ready
              for pickup or shipment. In the case of your own store, the customer are notified
              directly.
            </p>
          </div>
          <div className='w-3/4 flex-1 mx-auto shadow-2xl bg-white mt-44 rounded-xl' >
            <div className='h-6 rounded-t-xl bg-blue-gray-100 flex items-center'>
              <span className='w-2 h-2 rounded-full bg-red-500 ml-3' />
              <span className='w-2 h-2 rounded-full bg-yellow-900 ml-2' />
              <span className='w-2 h-2 rounded-full bg-green-500 ml-2' />
            </div>
            <div className='h-6 bg-blue-gray-50 flex items-center'>
              <BsArrowLeft className='ml-3 text-blue-gray-500 text-xs' />
              <BsArrowRight className='ml-2 text-blue-gray-500 text-xs' />
              <IoReload className='ml-3 text-blue-gray-500 text-xs' />
              <div className='h-3 w-full bg-white rounded-lg mx-4' />
              <BiDotsVerticalRounded className='mr-3 text-blue-gray-500 text-xs' />
            </div>
            <video
                playsInline="playsinline"
                autoPlay="autoplay"
                muted="muted"
                loop="loop"
                className="h-11/12 rounded-b-xl"
            >
                <source src={InvoicingVideo} type="video/mp4" />
            </video>

          </div>
        </div>
      </section>


      <section className='py-52'>
        {/* <h1 className='w-8/12 mx-auto flex justify-center text-blue-gray-300 text-7xl font-black mb-6'>
          15 Days of free trial
        </h1> */}
        <h1 className='w-full flex justify-center text-blue-gray-800 text-3xl 3xl:text-4xl font-black z-50 mb-16'>
          Your Package
        </h1>

        <div className='flex justify-center items-stretch'>
          <div className='w-1/2 mx-auto bg-white pb-12 rounded-xl'>
            <div className='w-full bg-black rounded-t-xl py-8 flex flex-col justify-center items-center mb-3'>
              <h1 className='text-4xl font-black text-white'>Custom Package</h1>
              <h6 className='text-white mt-1'>Monthly Suscription</h6>
            </div>
            <div className='w-full py-8'>
              <p className='w-1/2 mt-6 mx-auto flex justify-between italic text-sm text-blue-gray-400'>
                Analytic Dashboard
                <BsFillPatchCheckFill  className='text-green-300'/>
              </p>
              <p className='w-1/2 mt-6 mx-auto flex justify-between italic text-sm text-blue-gray-400'>
                Task Code Management
                <BsFillPatchCheckFill  className='text-green-300'/>
              </p>
              <p className='w-1/2 mt-6 mx-auto flex justify-between italic text-sm text-blue-gray-400'>
                Wholesale/Retail Price Management
                <BsFillPatchCheckFill  className='text-green-300'/>
              </p>
              <p className='w-1/2 mt-6 mx-auto flex justify-between italic text-sm text-blue-gray-400'>
                Built in Invoice Notification Emailing
                <BsFillPatchCheckFill  className='text-green-300'/>
              </p>
              <p className='w-1/2 mt-6 mx-auto flex justify-between italic text-sm text-blue-gray-400'>
                Built in Delivery Notification Emailing
                <BsFillPatchCheckFill  className='text-green-300'/>
              </p>
              <p className='w-1/2 mt-6 mx-auto flex justify-between italic text-sm text-blue-gray-400'>
                Bulk Email Sender
                <BsFillPatchCheckFill  className='text-green-300'/>
              </p>
              <p className='w-1/2 mt-6 mx-auto flex justify-between italic text-sm text-blue-gray-400'>
                Bulk Data Entry
                <BsFillPatchCheckFill  className='text-green-300'/>
              </p>
              <p className='w-1/2 mt-6 mx-auto flex justify-between italic text-sm text-blue-gray-400'>
                Store Clasification Management
                <BsFillPatchCheckFill  className='text-green-300'/>
              </p>
              <p className='w-1/2 mt-6 mx-auto flex justify-between italic text-sm text-blue-gray-400'>
                Smart Charging Component
                <BsFillPatchCheckFill  className='text-green-300'/>
              </p>
              <p className='w-1/2 mt-6 mx-auto flex justify-between italic text-sm text-blue-gray-400'>
                Unlimited Data Storage
                <BsFillPatchCheckFill className='text-green-300' />
              </p>
              <p className='w-1/2 mt-6 mx-auto flex justify-between italic text-sm text-blue-gray-400'>
                Unlimited Photo Storage
                <BsFillPatchCheckFill className='text-green-300' />
              </p>
              <p className='w-1/2 mt-6 mx-auto flex justify-between italic text-sm text-blue-gray-400'>
                Unlimited Support
                <BsFillPatchCheckFill className='text-green-300' />
              </p>
              <p className='w-1/2 mt-6 mx-auto flex justify-between italic text-sm text-blue-gray-400'>
                Training Videos
                <BsFillPatchCheckFill className='text-green-300' />
              </p>
              <p className='w-1/2 mt-6 mx-auto flex justify-between italic text-sm text-blue-gray-400'>
                Secured Data
                <BsFillPatchCheckFill className='text-green-300' />
              </p>
              <p className='w-1/2 mt-6 mx-auto flex justify-between italic text-sm text-blue-gray-400'>
                Pause or Cancel any time
                <BsFillPatchCheckFill className='text-green-300' />
              </p>
            </div>
            <div className='w-full border-t-2 border-blue-gray-50 mt-6 mb-10' />
            <div className='w-full flex justify-center items-center'>
              <div className='bg-blue-gray-50 text-light-blue-500 text-lg font-semibold pt-2 pb-2 px-4 rounded-lg hover:shadow-2xl mr-6'>
                  <PopupButton
                    id="popup-button"
                    url="https://calendly.com/benchmanager/30min"
                    /*
                    * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                    * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                    */
                    rootElement={document.getElementById("root")}
                    text="REQUEST A DEMO"
                    // textColor="#ffffff"
                    // color="#00a2ff"
                  />
              </div>                
              <div className='bg-light-blue-500 text-white text-lg font-semibold pt-2 pb-2 px-4 rounded-lg hover:shadow-2xl'>
                  <PopupButton
                    id="popup-button"
                    url="https://calendly.com/benchmanager/30min"
                    /*
                    * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                    * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                    */
                    rootElement={document.getElementById("root")}
                    text="GET QUOTE"
                    // textColor="#ffffff"
                    // color="#00a2ff"
                  />
              </div>                
            </div>
          </div>
        </div>
      </section>

      <section className='w-full pb-8'>
        <div className="w-full text-center pt-12 text-xs text-blue-gray-600">
            Bench Manager is a software developed by <a href='https://www.softcro.com' target="_blank" rel="noreferrer">Softcro</a>
        </div>
        <div className="w-full text-center pt-1 text-xs text-blue-gray-600">
            2022 - All Rights Reserved.
        </div>
      </section>

      
      <ModalVideo
        autoplay
        isOpen={isOpen}
        channel='youtube'
        videoId="sSFEBAP-xT8"
        onClose={() => setIsOpen(false)}
      />
    </div>
  )
}

export default Website