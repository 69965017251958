import React, { useState } from "react"
import InputNewJob from "./InputNewJob"
import NewJobList from "./NewJobList"
import { Drawer } from "components";

export default function ReceiveJob() {
    const [loadingMutation, setLoadingMutation] = useState(false)

    return (
        <div className="w-full flex">
            <Drawer />
            <div className="w-full h-screen px-12 overflow-y-auto bg-blue-gray-50">
                <div className="flex mt-16">
                    <InputNewJob setLoadingMutation={setLoadingMutation} />
                    <NewJobList loadingMutation={loadingMutation} />
                </div>
            </div>
        </div>
    );
}
