import React from "react"
import NewJob from "./NewJob"
import HeaderRow from "./HeaderRow"
import { Button } from "@material-tailwind/react";
import { useQuery, useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import { GET_USER_DATA } from "apollo/queries/getUserData";
import { GET_USER_JOBS } from "apollo/queries/getUserJobs";
import { UPDATE_JOB } from "apollo/mutations/updateJob";
import { useUser } from "hooks";
import { ToastContainer, toast } from "react-toastify";
import { STATUS } from "constants";
import { RenderIf } from "components";
import { BeatLoader } from 'react-spinners'

export default function NewJobList({ loadingMutation }) {
    const { currentUser } = useUser()
    const client = useApolloClient()

    const [getJobs, { data: userNewReceivedJobs }] = useLazyQuery(GET_USER_JOBS, {
        onCompleted: res => console.log("jobs ->", { res })
    })

    useQuery(GET_USER_DATA, {
        variables: { email: currentUser.email },
        onCompleted: (res) => getJobs({ variables: { id: res?.getUser?.userJobs?.id } }),
        onError: (error) => toast(error.message, { type: 'error' }),
    })

    const [mutate, { loading: isLoadingJobMutation }] = useMutation(UPDATE_JOB)
    
    const updateJobsOnDB = () => { 
        const array = userNewReceivedJobs?.getUserJobs?.userJobs?.filter(job => !job.status)
        for (let i = 0; i < array.length; i++) {
            const job = array[i];
            mutate({
                variables: {
                    input: {
                        filter: { id: job.id },
                        set: {
                           status: STATUS.RECEIVED,
                        }
                    }
                },
                // Cambiar esto aqui estan pasando muchos fetch
                // Hay que hacer uno solo al final de todas las mutaciones 
                // con un Promess.all o algo asi
                onCompleted: async () => { 
                    await client.refetchQueries({
                        include: ["getUserJobs"],
                    })
                }
            });
        }
    }

    return (
        <div className="bg-white w-full flex flex-col p-6 shadow-xl rounded-xl h-fit mb-16 border border-blue-gray-50">
            <div className="mb-6">
                <HeaderRow />
            </div>
            <div className="space-y-2">
                {userNewReceivedJobs?.getUserJobs?.userJobs?.filter(job => !job.status)?.map((job, idx) => (
                    <NewJob
                        key={idx}
                        job={job}
                        id={job.id}
                    />
                ))}
                <RenderIf isTrue={loadingMutation}>
                    <div className="flex">
                        <BeatLoader color="#94A3B8" size={9} className="ml-1 mt-3" />
                        <BeatLoader color="#94A3B8" size={9} className="ml-1 mt-3" />
                        <BeatLoader color="#94A3B8" size={9} className="ml-1 mt-3" />
                    </div>
                </RenderIf>
            </div>
            <Button
                variant="gradient"
                color={isLoadingJobMutation ? 'gray' : 'light-blue'}
                onClick={updateJobsOnDB}
                className="w-full mt-6"
                disabled={isLoadingJobMutation}
            >
                {isLoadingJobMutation ? 'Updating Jobs...' : 'Confirm and Push'}
            </Button>
            <ToastContainer 
                position="bottom-right"
                theme="colored"
                autoClose={8000}
                closeOnClick={true}
            />
        </div>
    );
}
