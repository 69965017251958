import axios from 'axios';
import { useMutation } from 'react-query';

const createCheckoutSession = ({ priceId, customerId }) => axios.post(
    `https://us-central1-bench-manager-cms.cloudfunctions.net/v1/create-stripe-checkout-session`, {
        priceId, customerId
    }
);

const useCreateCheckoutSession = options => useMutation(createCheckoutSession, options);

export default useCreateCheckoutSession;