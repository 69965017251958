import React, { useState, useEffect, useCallback } from 'react'
import useUser from "hooks/useUser";
import { Avatar, IconButton, Tooltip } from "@material-tailwind/react";
import img_placeholder from "images/img_placeholder.jpg";
import { RiAddFill } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import { useQuery } from "@apollo/client";
import { GET_USER_DATA } from "apollo/queries/getUserData";
import { storage } from "firebaseConfig/config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import { updateProfile } from "firebase/auth";
import { RenderIf, Spinner } from "components";
import AvatarSizeModal from "./AvatarSizeModal";
import { useUserPhoto } from "states";

const ProfileCard = () => {
    const { currentUser } = useUser()
    
    const [userPhoto, setUserPhoto] = useUserPhoto()

    const [imageObject, setImageObject] = useState(null)
    const [loadingUrl, setLoadingUrl] = useState(false)
    const [openAvatarSizeModal, setOpenAvatarSizeModal] = useState(false)
    const toggleAvatarSizeModal = () => setOpenAvatarSizeModal(!openAvatarSizeModal)

    const { data } = useQuery(GET_USER_DATA, {
        variables: { email: currentUser.email },
        onError: (error) => toast(error.message, {
            type: 'error',
        })
    })

    const locationsLength = data?.getUser?.userStores.length || 0;

    const openFileInput = () => { 
        document.getElementById('file-input').click()
    }

    const fileInputChangeHandler = (e) => {
        if (e.target.files[0].size > 400000) {
            toggleAvatarSizeModal()
        } else {
            console.log(e.target.files[0])
            setImageObject(e.target.files[0])
        }
    }

    const handleSubmit = useCallback(() => {
        setLoadingUrl(true)
        const imageRef = ref(storage, `${currentUser.email}/${imageObject.name}_${v4()}`)
        uploadBytes(imageRef, imageObject).then((res) => {
            console.log({ res }, "upload completed")
            getDownloadURL(imageRef).then(async (photoURL) => {
                setUserPhoto(photoURL)
                setLoadingUrl(false)
                await updateProfile(currentUser, { photoURL })
            }).catch((error) => console.log(error.message, "Error getting the image URL"))
            setImageObject(null)
        }).catch((error) => console.log(error.message, "Error uploading Bytes"))
    }, [imageObject, currentUser])

    useEffect(() => { 
        if (imageObject) {
            handleSubmit()
        }
    }, [imageObject, handleSubmit])

    // This code removes the photo URL from the firebase user
    // const wipeOut = async () => await updateProfile(currentUser, { photoURL: '' })
    // useEffect(() => { 
    //     wipeOut()
    // }, [])

    return (
        <div className="flex flex-col bg-white shadow-lg rounded-xl px-6 border border-blue-gray-50">
                <div className="w-full flex flex-col items-center justify-center">
                    <RenderIf isTrue={loadingUrl}>
                        <Spinner />
                    </RenderIf>
                    <RenderIf isTrue={!loadingUrl}>
                        <Avatar
                            src={userPhoto || img_placeholder}
                            alt='avatar'
                            size='xxl'
                            className='shadow-lg -mt-12 border border-blue-gray-50'
                        />
                    </RenderIf>
                    <input
                        type="file"
                        id="file-input"
                        className='opacity-0 absolute bottom-0 right-0'
                        onChange={fileInputChangeHandler}
                    />
                    <RenderIf isTrue={!loadingUrl}>
                        <Tooltip content="Add new avatar">
                            <IconButton
                                variant="gradient"
                                size='sm'
                            onClick={openFileInput}
                            className='-mt-6 ml-16'
                            >
                                <RiAddFill className="text-xl font-black"/>
                            </IconButton>
                        </Tooltip>
                    </RenderIf>
                </div>

            <div className="text-center mt-6">
                    <h1 className="font-black leading-normal mb-2 bg-blue-gray-50 p-3 rounded-xl">
                        {data?.getUser?.businessName}
                    </h1>
                </div>

                <div className="mt-6 py-6 border-t-2 border-blue-gray-200 text-center">
                    <div className="w-full flex justify-center lg:pt-1 pt-1 pb-0">
                        <div className="p-3 text-center">
                            <span className="text-xl font-bold block uppercase tracking-wide text-blueGray-700">
                                {locationsLength}
                            </span>
                            <span className="text-sm text-blueGray-400">
                                Locations
                            </span>
                        </div>
                    </div>
            </div>
            <ToastContainer
                position="bottom-right"
                theme="colored"
                autoClose={8000}
                closeOnClick={true}
            />
            <AvatarSizeModal
                open={openAvatarSizeModal}
                toggle={toggleAvatarSizeModal}
            />
        </div>

    )
}

export default ProfileCard