export const genericCodes = (userEmail) => ([
    {
        repairGroup: "Manufacture",
        entity: 'generic',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "generic",
                code: 101,
                description: "Create item components",
                wholeSalePrice: 1,
                retailPrice: 1,
            },
            {
                entity: "generic",
                code: 102,
                description: "Assemble components",
                wholeSalePrice: 1,
                retailPrice: 1,
            },
            {
                entity: "generic",
                code: 103,
                description: "Detail finish",
                wholeSalePrice: 1,
                retailPrice: 1,
            },
        ]
    },
    {
        repairGroup: "Repair",
        entity: 'generic',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "generic",
                code: 201,
                description: "Replace component part",
                wholeSalePrice: 1,
                retailPrice: 1,
            },
            {
                entity: "generic",
                code: 202,
                description: "Item maintenance",
                wholeSalePrice: 1,
                retailPrice: 1,
            },
        ]
    },
]);
