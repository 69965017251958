import React, { useState } from "react"
import { useHistory } from "react-router-dom";
import QuickMenu from "./QuickMenu"
import { Drawer, Heading, RenderIf } from "components"
import DataSection from "./DataSection"
import { AmountDisplayCard } from "components"
import { useQuery, useLazyQuery } from "@apollo/client"
import { GET_USER_DATA } from "apollo/queries/getUserData"
import { GET_USER_JOBS } from "apollo/queries/getUserJobs"
import { useUser } from "hooks"
import { STATUS } from "constants"
import classNames from 'classnames'
import { useRetrieveStripeCustomer, useCreateCheckoutSession } from "hooks";
import { GridLoader } from "react-spinners";

const Dashboard = ({ showReceive }) => {
    const history = useHistory()
    const { currentUser } = useUser()

    const [loadingCheckoutSession, setLoadingCheckoutSession] = useState(false)
    const [pollInterval, setPollInterval] = React.useState(5000)


    // This checkout session is created to send the user to subscribe in case he didn't complete it while registering
    const { mutate: createCheckoutSession } = useCreateCheckoutSession({
        onSuccess: response => {
            window.location = response.data.sessionUrl
        }
    })
    // retrieving stripe customer to check if is subscribed
    const { mutate: retrieveStripeCustomer } = useRetrieveStripeCustomer({
        onSuccess: async response => {
            if (!response?.data?.customer?.subscriptions?.data.length) {
                setLoadingCheckoutSession(true)
                createCheckoutSession({ priceId: 'price_1LoeH2IfIvvWDE54oCrndsLM', customerId: response.data.customer.id })
            }
        },
        onError: error => console.log('Error retrieving customer: ', { error })
    })
    
    const [getActiveJobs, { data: activeJobs, loading: loadingActiveJobs }] = useLazyQuery(GET_USER_JOBS, {
        onCompleted: res => console.log("completed jobs query", { res }),
        onError: error => console.log(error)
    })

    const { data: userData, loading: loadingUserData } = useQuery(GET_USER_DATA, {
        nextFetchPolicy: "cache-first",
        variables: { email: currentUser.email },
        pollInterval,
        onCompleted: (res) => {
            console.log("user data", { res })
            console.log("user data", res.getUser.userJobs.id)
            retrieveStripeCustomer({ id: res.getUser.stripeCustomerId })
            getActiveJobs({ variables: { id: res.getUser.userJobs.id } })
        },
        onError: error => console.log({ error }),
    })
    
    const jobs = activeJobs?.getUserJobs ? activeJobs?.getUserJobs?.userJobs : []
    const receivedJobs = jobs?.filter(job => ((job?.status === STATUS.NEW_JOB) || (job?.status === STATUS.RECEIVED)))?.length;
    const completedJobs = jobs?.filter(job => job?.status === STATUS.COMPLETED)?.length;
    const invoicedJobs = jobs?.filter(job => job?.status === STATUS.INVOICED)?.length;

    // Refetch on tab focus
    React.useEffect(() => {
        const startPolling = () => setPollInterval(5000)
        const stopPolling = () => setPollInterval(0)
        window.addEventListener('focus', startPolling)
        window.addEventListener('blur', stopPolling)
        return () => {
            window.removeEventListener('focus', startPolling)
            window.removeEventListener('blur', stopPolling)
        }
    })
    
    console.log({ currentUser })
    return (
        <div className="w-full flex">
            <RenderIf isTrue={loadingCheckoutSession}>
                <div className="w-full h-screen flex flex-col justify-center items-center">
                    <GridLoader color="#94A3B8" size={9} className="ml-1" />
                    <h1 className="mt-6 text-blue-gray-400 text-sm">Payment method needed.</h1>
                </div>
            </RenderIf>

            <RenderIf isTrue={!loadingCheckoutSession}>
                <Drawer />
                <div className='w-full h-screen px-12 overflow-y-auto bg-blue-gray-50'>
                    <Heading size='xs' className={"mt-6"}>
                        Forecast
                    </Heading>
                    <div className='mt-3 w-full flex justify-start items-end mb-6'>
                        <div className="cursor-pointer">
                            <AmountDisplayCard
                                amount={receivedJobs}
                                label="Need work"
                                className="mr-6"
                                loading={loadingActiveJobs}
                                amountColorClass={classNames({
                                    'text-orange-600': receivedJobs,
                                    'text-green-300': !receivedJobs,
                                })}
                            />
                        </div>
                        <div className="cursor-pointer" onClick={() => history.push('/invoice')}>
                            <AmountDisplayCard
                                amount={completedJobs}
                                label="Need invoice"
                                className="mr-6"
                                loading={loadingActiveJobs}
                                amountColorClass={classNames({
                                    'text-red-300': completedJobs,
                                    'text-green-300': !completedJobs,
                                })}
                            />
                        </div>
                        <div className="cursor-pointer" onClick={() => history.push('/delivery')}>
                            <AmountDisplayCard
                                amount={invoicedJobs}
                                label="Need delivery"
                                className="mr-6"
                                loading={loadingActiveJobs}
                                amountColorClass={classNames({
                                    'text-purple-300': invoicedJobs,
                                    'text-green-300': !invoicedJobs,
                                })}
                            />
                        </div>
                    </div>
                    <QuickMenu showReceive={showReceive} />
                    <DataSection
                        userData={userData}
                        activeJobs={activeJobs}
                        loadingUserData={loadingUserData}
                        loadingActiveJobs={loadingActiveJobs}
                    />
                </div>
            </RenderIf>
        </div>
    );
}

export default React.memo(Dashboard);
