import React from 'react'
import {  Heading } from "components"
import { Button, Card, CardHeader, CardBody, CardFooter } from "@material-tailwind/react";
import jeweler_image from "images/jeweler.jpg"
import smartphone_image from "images/smartphone_repair.jpg"
import antique_image from "images/antique_repair.jpg"
import computer_image from "images/computer_repair.jpg"
import drone_image from "images/drone_repair.jpeg"
import watch_maker_image from "images/watch_maker.jpg"
import sunglasses_image from "images/sunglasses.jpg"
import videogame_console_image from "images/videoconsoles.jpg"
import electronics_image from "images/electronics.jpg"
import security_camera_image from "images/security_camera.jpg"
import other_image from "images/other.jpg"
import { INDUSTRY } from "../../constants";

// Presets

const Presets = ({ handleAddPresetClick }) => {
  return (
      <>
        <Heading className={'mt-16'}>
            Price Code Presets
        </Heading>
            <p className="w-1/2 text-sm text-blue-gray-600 mt-3">
              Bench Manager is a transformative tool designed specifically for jewelry workshops.
              This innovative software integrates scientific methodologies and proven industry
              techniques with artificial intelligence, offering an effective solution to manage
              and streamline tasks. Please generate your services with suggested prices.
            </p>
        <div className="flex flex-wrap mt-12">
            <Card className="bg-white w-fit mr-6 mb-12">
                <CardHeader>
                    <img src={jeweler_image} className="w-52" alt="jeweler" />
                </CardHeader>
                <CardBody className="w-52 ml-4 text-center flex justify-center text-sm text-blue-gray-600">
                    Jewelry Services
                </CardBody>
                <CardFooter className="w-full flex justify-center pt-0">
                    <Button 
                        name={INDUSTRY.JEWELRY}
                        onClick={handleAddPresetClick}
                    >
                        Generate
                    </Button>
                </CardFooter>
            </Card>
              
            {/* <Card className="bg-white w-fit mr-6 mb-12">
                <CardHeader>
                    <img src={smartphone_image} className="w-52" alt="jeweler" />
                </CardHeader>
                <CardBody className="w-52 ml-4 text-center flex justify-center text-sm text-blue-gray-600">
                    Smart Devices Shops Tasks Presets
                </CardBody>
                <CardFooter className="w-full flex justify-center pt-0">
                    <Button 
                        name={INDUSTRY.SMARTPHONES}
                        onClick={handleAddPresetClick}
                    >
                        Smart Devices Shop
                    </Button>
                </CardFooter>
            </Card>

            <Card className="bg-white w-fit mr-6 mb-12">
                <CardHeader>
                    <img src={computer_image} className="w-52" alt="jeweler" />
                </CardHeader>
                <CardBody className="w-52 ml-4 text-center flex justify-center text-sm text-blue-gray-600">
                    Computer Shop Tasks Presets
                </CardBody>
                <CardFooter className="w-full flex justify-center pt-0">
                    <Button 
                        name={INDUSTRY.COMPUTERS}
                        onClick={handleAddPresetClick}
                    >
                        Computer Shop
                    </Button>
                </CardFooter>
            </Card>
              
            <Card className="bg-white w-fit mr-6 mb-12">
                <CardHeader>
                    <img src={drone_image} className="w-52" alt="jeweler" />
                </CardHeader>
                <CardBody className="w-52 ml-4 text-center flex justify-center text-sm text-blue-gray-600">
                    Drones Shop Tasks Presets
                </CardBody>
                <CardFooter className="w-full flex justify-center pt-0">
                    <Button 
                        name={INDUSTRY.DRONES}
                        onClick={handleAddPresetClick}
                    >
                        Drones Shop
                    </Button>
                </CardFooter>
            </Card>
            
            <Card className="bg-white w-fit mr-6 mb-12">
                <CardHeader>
                    <img src={antique_image} className="w-52" alt="jeweler" />
                </CardHeader>
                <CardBody className="w-52 ml-4 text-center flex justify-center text-sm text-blue-gray-600">
                    Antiques Shop Tasks Presets
                </CardBody>
                <CardFooter className="w-full flex justify-center pt-0">
                    <Button 
                        name={INDUSTRY.ANTIQUES}
                        onClick={handleAddPresetClick}
                    >
                        Antiques Shop
                    </Button>
                </CardFooter>
            </Card>

            <Card className="bg-white w-fit mr-6 mb-12">
                <CardHeader>
                    <img src={watch_maker_image} className="w-52" alt="jeweler" />
                </CardHeader>
                <CardBody className="w-52 ml-4 text-center flex justify-center text-sm text-blue-gray-600">
                    Watchmaker Shop Tasks Presets
                </CardBody>
                <CardFooter className="w-full flex justify-center pt-0">
                    <Button 
                        name={INDUSTRY.WATCHMAKER}
                        onClick={handleAddPresetClick}
                    >
                        Watchmaker Shop
                    </Button>
                </CardFooter>
            </Card>

            <Card className="bg-white w-fit mr-6 mb-12">
                <CardHeader>
                    <img src={sunglasses_image} className="w-52" alt="jeweler" />
                </CardHeader>
                <CardBody className="w-52 ml-4 text-center flex justify-center text-sm text-blue-gray-600">
                    Sunglasses Repair Shop Tasks Presets
                </CardBody>
                <CardFooter className="w-full flex justify-center pt-0">
                    <Button 
                        name={INDUSTRY.SUNGLASSES}
                        onClick={handleAddPresetClick}
                    >
                        Sunglasses Shop
                    </Button>
                </CardFooter>
            </Card>
              
            <Card className="bg-white w-fit mr-6 mb-12">
                <CardHeader>
                    <img src={videogame_console_image} className="w-52" alt="jeweler" />
                </CardHeader>
                <CardBody className="w-52 ml-4 text-center flex justify-center text-sm text-blue-gray-600">
                    Consoles Repair Shop Tasks Presets
                </CardBody>
                <CardFooter className="w-full flex justify-center pt-0">
                    <Button 
                        name={INDUSTRY.VIDEOGAME_CONSOLES}
                        onClick={handleAddPresetClick}
                    >
                        Video Games Shop
                    </Button>
                </CardFooter>
            </Card>

              <Card className="bg-white w-fit mr-6 mb-12">
                <CardHeader>
                    <img src={security_camera_image} className="w-52" alt="jeweler" />
                </CardHeader>
                <CardBody className="w-52 ml-4 text-center flex justify-center text-sm text-blue-gray-600">
                    Security Cameras Shop Tasks Presets
                </CardBody>
                <CardFooter className="w-full flex justify-center pt-0">
                    <Button 
                        name={INDUSTRY.SECURITY_CAMERAS}
                        onClick={handleAddPresetClick}
                    >
                        Security Cameras Shop
                    </Button>
                </CardFooter>
            </Card>
              
            <Card className="bg-white w-fit mr-6 mb-12">
                <CardHeader>
                    <img src={electronics_image} className="w-52" alt="jeweler" />
                </CardHeader>
                <CardBody className="w-52 ml-4 text-center flex justify-center text-sm text-blue-gray-600">
                    General Electronics Shop Tasks Presets
                </CardBody>
                <CardFooter className="w-full flex justify-center pt-0">
                    <Button 
                        name={INDUSTRY.GENERAL_ELECTRONICS}
                        onClick={handleAddPresetClick}
                    >
                        Electronics Shop
                    </Button>
                </CardFooter>
            </Card>
              
            <Card className="bg-white w-fit mr-6 mb-12">
                <CardHeader>
                    <img src={other_image} className="w-52" alt="jeweler" />
                </CardHeader>
                <CardBody className="w-52 ml-4 text-center flex justify-center text-sm text-blue-gray-600">
                    Other General Repair Workshops
                </CardBody>
                <CardFooter className="w-full flex justify-center pt-0">
                    <Button 
                        name={INDUSTRY.OTHER}
                        onClick={handleAddPresetClick}
                    >
                        Other Shops
                    </Button>
                </CardFooter>
            </Card> */}
        </div>          
      </>    
  )
}

export default Presets