import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import { RiFolderAddFill } from "react-icons/ri";
import { MdAddAPhoto } from "react-icons/md";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { BsCalendarCheckFill } from "react-icons/bs";
import { MdEditLocationAlt } from "react-icons/md";
import { Heading } from "components";
    
const QuickMenu = () => {
    const history = useHistory();
    const handleClickToPath = (pathname) => {
        history.push(pathname);
    }

    return (
        <div className="w-full">
            <Heading size="xs" className="mb-3">
                Quick Access
            </Heading>
            <div className="flex">
                <Button
                    variant="gradient"
                    size="lg"
                    color="pink"
                    className="w-48 flex flex-col justify-center items-center py-6 mr-3 shadow-xl text-white"
                    onClick={() => handleClickToPath('/queries')}
                >
                    <FaSearch style={{ fontSize: "3em", marginBottom: '10px' }} />
                    Search
                </Button>

                <Button
                    variant="gradient"
                    size="lg"
                    color="orange"
                    className="w-48 flex flex-col justify-center items-center py-6 mr-3 shadow-xl text-white"
                    onClick={() => handleClickToPath('/receive-job')}
                >
                    <RiFolderAddFill style={{ fontSize: "3em", color: 'white', marginBottom: '10px' }} />
                    Receive
                </Button>
                <Button
                    variant="gradient"
                    size="lg"
                    color="red"
                    className="w-48 flex flex-col justify-center items-center py-6 mr-3 shadow-xl text-white"
                    onClick={() => handleClickToPath('/photography')}
                >
                    <MdAddAPhoto style={{ fontSize: "3em", marginBottom: '10px' }} />
                    Photo
                </Button>
                <Button
                    variant="gradient"
                    size="lg"
                    color="deep-purple"
                    className="w-48 flex flex-col justify-center items-center py-6 mr-3 shadow-xl text-white"
                    onClick={() => handleClickToPath('/complete-job')}
                >
                    <BsCalendarCheckFill style={{ fontSize: "2.8em", marginBottom: '10px' }} />
                    Complete
                </Button>
            </div>
            <div className="flex mt-6">
                <Button
                    variant="gradient"
                    size="lg"
                    color="green"
                    className="w-48 flex flex-col justify-center items-center py-6 mr-3 shadow-xl text-white"
                    onClick={() => handleClickToPath('/invoice')}
                >
                    <FaFileInvoiceDollar style={{ fontSize: "3em", marginBottom: '10px' }} />
                    Invoice
                </Button>
                <Button
                    variant="gradient"
                    size="lg"
                    color="indigo"
                    className="w-48 flex flex-col justify-center items-center py-6 mr-3 shadow-xl text-white"
                    onClick={() => handleClickToPath('/delivery')}
                >
                    <FaFileInvoiceDollar style={{ fontSize: "3em", marginBottom: '10px' }} />
                    Deliver
                </Button>
                <Button
                    variant="gradient"
                    size="lg"
                    color="purple"
                    className="w-48 flex flex-col justify-center items-center py-6 mr-3 shadow-xl text-white"
                    onClick={() => handleClickToPath('/locations')}
                >
                    <MdEditLocationAlt style={{ fontSize: "3em", marginBottom: '10px' }} />
                    Locations
                </Button>
            </div>


            {/* <div>
                    <Link to="/Send-Estimate">
                        <CenterMid>
                            <FcAddressBook style={{ fontSize: "3em" }} />
                            <div>Estimate</div>
                        </CenterMid>
                    </Link>
                    <Link to="/Create-Custom">
                        <BottomLeft>
                            <FcBiomass style={{ fontSize: "3em" }} />
                            <div>Custom</div>
                        </BottomLeft>
                    </Link>
                    <Link to="/Reports">
                        <BottomMid>
                            <FcBarChart style={{ fontSize: "3em" }} />
                            <div>Reports</div>
                        </BottomMid>
                    </Link>
                </div> */}


        </div>
    );
}

export default QuickMenu;
