import React from "react";
import StoreList from "./StoreList";
import AddStoreModalForm from "./AddStoreModalForm";
import { RenderIf, Drawer } from "components";
import { useUser } from "hooks";
import { useQuery } from "@apollo/client";
import { GET_USER_DATA } from "apollo/queries/getUserData";
import { ToastContainer, toast } from "react-toastify";

const Stores = () => {
    const { currentUser } = useUser()
    const { data, loading } = useQuery(GET_USER_DATA, {
        variables: { email: currentUser.email },
        onError: (error) => toast(error.message, {
            type: 'error',
        })
    })
    
    const [showModal, setShowModal] = React.useState(false)

    return (
        <div className="w-full flex">
            <Drawer />
            <div className="w-full h-screen px-12 overflow-y-auto bg-blue-gray-50">
                <RenderIf isTrue={loading}>
                    <div className="w-full h-12 rounded-xl cursor-pointer border border-1 border-gray-100 animate-pulse" />
                </RenderIf>
                <RenderIf isTrue={!loading && data}>
                    <StoreList
                        stores={data?.getUser?.userStores}
                        openModal={() => setShowModal(true)}
                    />
                </RenderIf>
            </div>
            <RenderIf isTrue={showModal}>
                <AddStoreModalForm
                    show={showModal}
                    onHide={() => setShowModal(false)}
                />
            </RenderIf>
            <ToastContainer
                position="bottom-right"
                theme="colored"
                autoClose={8000}
                closeOnClick={true}
            />
        </div>
    );  

}

export default Stores;
