import React, { Fragment, useState } from "react";
import { RenderIf, DeleteConfirmation } from "components";
import { Button } from "@material-tailwind/react";
import { useApolloClient, useMutation } from "@apollo/client";
import ToggleComponent from "./ToggleComponent";
import { DELETE_STORE_MUTATION } from "apollo/mutations/deleteStore";

const InfoContainer = ({ store }) => {    
    const client = useApolloClient()
    const [mutate, { loading }] = useMutation(DELETE_STORE_MUTATION)

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    
    console.log({ store })
    

    const handleDelete = async () => {
        mutate({
            variables: { id: store.id },
            onCompleted: async () => { 
                await client.refetchQueries({
                    include: ["getUserData"]
                })
            }
        })
    };

    return (
        <Fragment>

            <div className="w-full bg-blue-gray-50 p-6 rounded-lg">
                {StoreInfoContainerHeaders.map(field => (
                    <ToggleComponent 
                        key={field}
                        field={field}
                        store={store}
                    />
                ))}
            </div>

            <RenderIf isTrue={store.relation !== 'home'}>
                <div className="w-full flex justify-end mt-3">
                    <Button
                        variant="text"
                        onClick={() => setShowDeleteConfirmation(true)}
                    >
                        Remove Store
                    </Button>
                </div>
            </RenderIf>

            <DeleteConfirmation
                element={
                    <span>
                        <h1 className="font-semibold text-gray-800">
                            {store.storeName}
                        </h1>
                    </span>
                }
                show={showDeleteConfirmation}
                handleClose={() => setShowDeleteConfirmation(false)}
                handleDelete={handleDelete}
                deleteActionInProgress={loading}
            />
        </Fragment>
    )

}

export default InfoContainer;

const StoreInfoContainerHeaders = [
    "Store Name",
    "Store Address",
    "Store Email",
    "Store Phone Number",
    "Store Number",
]
