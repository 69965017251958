import React, { useState, useCallback } from "react";
import InputsBox from "./InputsBox";
import JobsListHeader from "./JobListHeader";
import JobsList from "./JobsList";
import { Drawer } from "components";
import { ToastContainer, toast } from "react-toastify";
import { useUser } from "hooks";
import { useQuery, useLazyQuery } from "@apollo/client";
import { GET_PAGINATED_JOBS } from "apollo/queries/getPaginatedJobs";
import { GET_USER_DATA } from "apollo/queries/getUserData";
import { GET_PAGINATED_STORE_JOBS } from "apollo/queries/getPaginatedStoreJobs";
import Pagination from '@mui/material/Pagination';
import { initialState } from './constants';
import { buildFilters } from './helpers'

/*
 * This component contains two main GraphQL queries - GET_PAGINATED_JOBS and GET_PAGINATED_STORE_JOBS.
 * 
 * 1. GET_PAGINATED_JOBS:
 *    This query is used to fetch all jobs based on certain filters (excluding the store filter).
 *    It also includes a subquery 'queryUserJobs' which aggregates job details such as the total count.
 *    This aggregated data is used primarily for pagination.
 * 
 * 2. GET_PAGINATED_STORE_JOBS:
 *    This query is specifically used when there's a need to filter jobs by a specific store.
 *    This query became necessary because the underlying GraphQL implementation (DGraph) does not support filtering by nested keys.
 *    So when we want to filter jobs by a specific store, we fetch the store by its ID and get the jobs associated with it.
 * 
 * Note: If you're adding a filter that involves a nested key, you'll likely need to create a separate GraphQL query similar 
 * to GET_PAGINATED_STORE_JOBS to handle it due to DGraph's limitations.
 * 
 * The decision of which query to use is made on the client side. If the filters contain a store, GET_PAGINATED_STORE_JOBS is 
 * used, otherwise GET_PAGINATED_JOBS is used. This allows us to work around DGraph's nested filtering limitations while still
 * providing the necessary functionality.
 */

export default function Queries() {
    const { currentUser } = useUser()

    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)
    const [filters, setFilters] = useState(initialState)
    const [userFilteredByStore, setUserFilteredByStore] = useState(false)

    const [getJobs, { data: currentUserJobs, loading: isLoadingUserJobs }] = useLazyQuery(GET_PAGINATED_JOBS, {
        onCompleted: response => {
            setCount(response?.queryUserJobs[0].userJobsAggregate.count)
        },
        onError: (error) => toast(error.message, { type: 'error' }),
    })

    const [
        getJobsByStore,
        { data: currentUserJobsByStore, loading: isLoadingUserJobsByStore }] = useLazyQuery(GET_PAGINATED_STORE_JOBS, {
            onCompleted: response => {
            setCount(response?.aggregateJob?.count)
        },
        onError: (error) => toast(error.message, { type: 'error' }),
    })

    const { data: userData } = useQuery(GET_USER_DATA, {
        variables: { email: currentUser.email },
        onCompleted: (res) => {
            getJobs({
                variables: {
                    id: res?.getUser?.userJobs?.id,
                    first: 20,
                    offset: 0,
                    filters: buildFilters(filters),
                }
            })
        },
        onError: (error) => toast(error.message, { type: 'error' }),
    })

    
    const fetchJobs = useCallback((offset) => {
        if (filters.store) {
            const matchingStore =
                userData?.getUser?.userStores?.find(store => store?.storeNumber === filters?.store)            
            if (!matchingStore) {
                toast(
                    `The selected store was not found in your stores. Please contact our 
                    customer support for possible solutions. This issue might be due to
                    changes in store numbers that you may have made in your stores.`,
                    { type: 'error' }
                )
                return;
            }
            const storeId =  matchingStore.id
            getJobsByStore({
                variables: {
                    storeId,
                    first: 20,
                    offset,
                    filters: buildFilters(filters),
                },
            });
        } else {
            getJobs({
                variables: {
                    id: userData?.getUser?.userJobs?.id,
                    first: 20,
                    offset,
                    filters: buildFilters(filters),
                },
            });
        }
    }, [
        getJobs,
        filters,
        getJobsByStore,
        userData?.getUser?.userJobs?.id,
        userData?.getUser?.userStores
    ])

const handlePageChange = (event, pageNumber) => {
    setPage(pageNumber)
    fetchJobs((pageNumber - 1) * 20)
};

const doFilteredSearch = useCallback(() => {
    if (filters.store) {
        setUserFilteredByStore(true)
    }    
    fetchJobs(0)
}, [fetchJobs, filters]);

    
    const totalPages = Math.ceil(count / 20);
    const isLoading = isLoadingUserJobs || isLoadingUserJobsByStore;
    
    return (
        <div className="w-full flex">
            <Drawer />
            <div className="w-full h-screen px-12 overflow-y-auto flex flex-col justify-between  bg-blue-gray-50">
                <div>
                    {/*  This function should bring the filters to set on the query */}
                    <InputsBox
                        filters={filters}
                        setFilters={setFilters}
                        doFilteredSearch={doFilteredSearch}
                        setUserFilteredByStore={setUserFilteredByStore}
                    />
                    <JobsListHeader />
                    <JobsList
                        loading={isLoading}
                        currentJobs={
                            userFilteredByStore && filters.store && currentUserJobsByStore?.getStoreById?.jobs ?
                                currentUserJobsByStore?.getStoreById?.jobs :
                                currentUserJobs?.getUserPaginatedJobs?.userJobs
                        }
                    />
                </div>
                <div className="w-fit mx-auto mb-12">
                    <Pagination
                        variant="outlined"
                        color="primary"
                        page={page}
                        count={totalPages}
                        onChange={handlePageChange}
                        siblingCount={2}
                        defaultPage={1}
                        showFirstButton
                        showLastButton
                        size="small"
                        
                    />
                </div>
            </div>
            <ToastContainer
                position="bottom-right"
                theme="colored"
                autoClose={8000}
                closeOnClick={true}
            />
        </div>
    );
}
