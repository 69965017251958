import React from 'react'

const Card = ({ children, className }) => {
  return (
    <div
      className={
        `bg-white w-fit px-12 py-6 rounded-xl shadow-xl flex flex-col items-center border border-blue-gray-50 
        ${className}`
      }
    >
      {children}    
    </div>
  )
}

export default Card