import React from "react";
import CodeInputRow from "./CodeInputRow";
import AddedCodesList from "./AddedCodesList";

export default function ChargeCodesBox({ job, codesCharged, addCode, removeCode, updateTotal }) {

    const buildChargedCode = (code, description, quantity, price) => {
        let additionalCode = {
            code,
            description,
            quantity: Number(quantity),
            price,
        };
        addCode(additionalCode);
        updateTotal(additionalCode.price);
    };

    return (
        <div className="w-full mt-6">
            <CodeInputRow
                job={job}
                market={job.market}
                buildChargedCode={buildChargedCode}
            />
            <AddedCodesList
                codesCharged={codesCharged}
                updateTotal={updateTotal}
                removeCode={removeCode}
            />
        </div>
    );

}
