import React, { Fragment, useState, useEffect, useRef } from "react";
import { useMutation, useApolloClient } from "@apollo/client";
import { UPDATE_REPAIR_CODE } from "apollo/mutations/updateRepairCode";
import { RenderIf } from "components";
import { BeatLoader } from "react-spinners";

export default function PriceInput({ code, market, toggleEditMode }) {
    const client = useApolloClient()
    const inputRef = useRef();
    const [value, setValue] = useState("");

    const [mutate, { loading }] = useMutation(UPDATE_REPAIR_CODE)

    useEffect(() => {
        const { current } = inputRef;
        current.focus();
    }, []);

    const handleBlur = () => {
        toggleEditMode();
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            mutate({
                variables: {
                    input: {
                        filter: {
                            id: code.id
                        }, 
                        set: market === "wholesale" ?
                            { wholeSalePrice: Number(value).toFixed(2) } :
                            { retailPrice: Number(value).toFixed(2) }
                    }
                },
                onCompleted: async () => { 
                    toggleEditMode();
                    await client.refetchQueries({
                        include: ["getUserRepairCodes"],
                    });
                }
            })
        } else if (e.keyCode === 27) {
            toggleEditMode();
        }
    };

    const onChangeHandler = (e) => {
        setValue(e.target.value);
    };

    return (
        <Fragment>
            <RenderIf isTrue={loading}>
                <BeatLoader color="#94A3B8" size={9} className="ml-1" />
            </RenderIf>
            <RenderIf isTrue={!loading}>
                <input
                    type="text"
                    value={value}
                    ref={inputRef}
                    className="w-full text-right h-8 p-1 mr-3 border border-1 border-blue-gray-100 rounded-lg px-2 text-sm"
                    style={{ float: "right" }}
                    onChange={onChangeHandler}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                />
            </RenderIf>
        </Fragment>
    );
}
