import React from 'react'
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,    
} from "@material-tailwind/react";


const DeleteGroupConfirmationModal = (
    { isDeleteGroupModalOpen, toggleDeleteGroupModal, isGroupMutationLoading, handleDeleteGroup }
) => {
    return (
        <Dialog open={isDeleteGroupModalOpen} handler={toggleDeleteGroupModal}>
            <DialogHeader>
                <span>Delete Entire Group</span>
            </DialogHeader>
            <DialogBody>
                <div className="font-medium">
                    Are you sure you want to delete this entire group of tasks from your price inventory?
                </div>
            </DialogBody>
            <DialogFooter>
                <Button
                    variant="gradient"
                    onClick={toggleDeleteGroupModal}
                    className="mr-2"
                    disabled={isGroupMutationLoading}
                >
                    Cancel
                </Button>
                <Button
                    variant="gradient"
                    color={isGroupMutationLoading ? "gray" : "red"}
                    onClick={handleDeleteGroup}
                    disabled={isGroupMutationLoading}
                >
                    {isGroupMutationLoading ? "Working on it..." : "Yes"}
                </Button>
            </DialogFooter>
        </Dialog>
    )
}

export default DeleteGroupConfirmationModal
