import React from 'react'

const ListRow = ({ handleClick, children, className, ...rest }) => {
    return (
        <div
            className={`w-full rounded text-blue-gray-400 hover:text-gray-900 hover:bg-white font-medium pt-1 cursor-pointer ${className}`}
            onClick={handleClick}
            {...rest}
        >
            {children}
        </div>
    )
}

export default ListRow
