import React, { useState } from "react";
import { GoTrashcan } from "react-icons/go";
import { GoPencil } from "react-icons/go";
import moment from "moment";
import DeleteConfirmation from "./DeleteConfirmation";
import { Tooltip } from "@material-tailwind/react";


const NewJobDisplayView = ({ job, toggleView, removeJob }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const formatingDate = (value) => {
        return moment(value).format("l");
    };

    return (
        <div className="flex space-y-1 h-8 text-gray-700 font-semibold bg-gradient-to-r from-gray-50 to-gray-100 rounded text-sm">
            <span className="flex-1 flex justify-end items-center bg-gradient-to-r from-blue-300 to-blue-700 text-white pr-2 rounded">
                {job.jobNumber}
            </span>
            <span className="flex-1 text-right">{job.customerName}</span>
            <span className="flex-1 text-right">{"$ " + Number(job.itemValue).toFixed(2)}</span>
            <span className="flex-1 text-right">{job.store.storeNumber}</span>
            <span className="flex-1 text-center">{formatingDate(job.promiseDate)}</span>
            <span className="flex" style={{ marginTop: ".5em" }}>
                <Tooltip content="Edit job">
                    <div onClick={toggleView}>
                        <GoPencil className="cursor-pointer text-gray-500 hover:text-green-500 mr-1" />
                    </div>
                </Tooltip>
                <Tooltip content="Delete job">
                    <div onClick={handleShow}>
                        <GoTrashcan className="cursor-pointer text-gray-500 hover:text-pink-500 mr-2" />
                    </div>
                </Tooltip>

            </span>
            <DeleteConfirmation
                job={job}
                show={show}
                handleClose={handleClose}
                removeJob={removeJob}
            />
        </div>
    );
}

export default NewJobDisplayView;
