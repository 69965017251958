import React from "react";
import {
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Chip,
    Button,
} from "@material-tailwind/react";


export default function DeleteConfirmation({
    code,
    show,
    handleClose,
    deleteCode,
}) {
    return (
        <>
            <Dialog open={show} handler={handleClose}>
                <DialogHeader>
                    <Chip value={code.code ?? "Loading..."} color="amber" />
                    <span className="text-md text-gray-600 ml-3">{code.description}</span>
                </DialogHeader>
                <DialogBody>
                    <div>Do you want to remove this Code from the stack?</div>
                </DialogBody>
                <DialogFooter>
                    <Button
                        color="gray"
                        variant="gradient"
                        onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        color="deep-purple"
                        className="ml-2"
                        variant="gradient"
                        onClick={deleteCode}>
                        Yes
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    );
}
