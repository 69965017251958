import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

// const environment = process.env.NODE_ENV
// const backend = {
//   'development': 'https://blue-surf-610045.us-east-1.aws.cloud.dgraph.io/graphql',
//   'production': 'https://blue-surf-610042.us-east-1.aws.cloud.dgraph.io/graphql',
// }
// const apiKey = {
//   'development': 'MTFlNTBlZmFlMzU5NTlkYWZhNzlmMTQwY2VkNjYwMzg=',
//   'production': 'MDhmNjgwYTdlZTMwMDU1Zjg5ZjcyODI4MDljODhmMzA=',
// }

export const createApolloClient = token => {
  const httpLink = createHttpLink({
    // uri: backend[environment],
    uri: 'https://blue-surf-610042.us-east-1.aws.cloud.dgraph.io/graphql',
    options: {
      reconnect: true,
    },
  });

  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        // "Dg-Auth": apiKey[environment],
        "Dg-Auth":'MDhmNjgwYTdlZTMwMDU1Zjg5ZjcyODI4MDljODhmMzA=',
        "X-Auth-Token": token,
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });
}
