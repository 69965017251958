import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";


const AvatarSizeModal = ({ open, toggle }) => {
    
    return (
        <Dialog open={open} handler={toggle}>
            <DialogHeader>Your Avatar is Big in Size.</DialogHeader>
            <DialogBody divider>
                Your Avatar should be 400 KB in size or less.
            </DialogBody>
            <DialogFooter>
                <Button
                    variant="gradient"
                    color="green"
                    onClick={toggle}>
                    <span>Confirm</span>
                </Button>
            </DialogFooter>
        </Dialog>
    )
}

export default AvatarSizeModal