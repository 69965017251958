import React from "react";
import moment from "moment";
import { GoCheck } from "react-icons/go";
import { GoX } from "react-icons/go";
import { Tooltip } from "@material-tailwind/react";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { GET_USER_DATA } from "apollo/queries/getUserData";
import { UPDATE_JOB } from "apollo/mutations/updateJob";
import { useUser } from "hooks"
import { ToastContainer, toast } from "react-toastify";


const NewJobEditModeView = ({ job, id, toggleView }) => {
    const client = useApolloClient()
    const { currentUser } = useUser()

    const { data: userData } = useQuery(GET_USER_DATA, {
        variables: { email: currentUser.email },
        onError: (error) => toast(error.message, { type: 'error' }),
    })

    const [mutate] = useMutation(UPDATE_JOB)

    const [jobNumberField, setJobNumberField] = React.useState(job.jobNumber);
    const [customerField, setCustomerField] = React.useState(job.customerName);
    const [itemValueField, setItemValueField] = React.useState(job.itemValue);
    const [storeNumberfield, setStoreNumberfield] = React.useState(job.store.storeNumber);

    const submitUpdate = () => {
        const jobStore = userData?.getUser?.userStores.find(store => store.storeNumber === storeNumberfield)
        mutate({
            variables: {
                input: {
                    filter: { id }, 
                    set: {
                        customerName: customerField,
                        jobNumber: jobNumberField,
                        itemValue: itemValueField,
                        store: {
                            storeNumber: jobStore.jobNumber,
                            id: jobStore.id,
                            user: { email: currentUser.email },
                        }
                    }
                }
            },
            onCompleted: async () => { 
                await client.refetchQueries({
                    include: ["getUserJobs"],
                })
                toggleView()
            }
        });
    };

    const onNumberChange = (e) => setJobNumberField(e.target.value);
    const onNameChange = (e) => setCustomerField(e.target.value);
    const onValueChange = (e) => setItemValueField(e.target.value);
    const onStoreChange = (e) => setStoreNumberfield(e.target.value);


    const evaluateKey = (e) => {
        if (e.keyCode === 13) {
            submitUpdate();
        }
    };


    return (
        <div className="flex items-center space-x-1 h-8 text-gray-700 text-sm">
            <span className="flex-1 text-right">
                <input
                    className="h-8 border border-1 border-blue-gray-300 text-blue-gray-500 text-sm rounded-lg pr-2 text-right w-full"
                    name="jobNumberField"
                    type="text"
                    defaultValue={job.jobNumber}
                    onChange={onNumberChange}
                    onKeyDown={evaluateKey}
                />
            </span>
            <span className="flex-1 text-right">
                <input
                    className="h-8 border border-1 border-blue-gray-300 text-blue-gray-500 text-sm rounded-lg pr-2 text-right w-full"
                    name="customerField"
                    type="text"
                    defaultValue={job.customerName}
                    onChange={onNameChange}
                    onKeyDown={evaluateKey}
                />
            </span>
            <span className="flex-1 text-right">
                <input
                    className="h-8 border border-1 border-blue-gray-300 text-blue-gray-500 text-sm rounded-lg pr-2 text-right w-full"
                    name="itemValueField"
                    type="text"
                    defaultValue={Number(job.itemValue).toFixed(2)}
                    onChange={onValueChange}
                    onKeyDown={evaluateKey}
                />
            </span>
            <span className="flex-1 text-right">
                <select
                    className="h-8 border border-1 border-blue-gray-300 text-blue-gray-500 text-sm rounded-lg pr-2 text-right w-full"
                    dir="rtl"
                    name="storeNumberfield"
                    onChange={onStoreChange}
                    defaultValue={job.storeNumber}
                >
                    {userData?.getUser?.userStores?.map((store) => {
                        return (
                            <option key={store.storeNumber}>
                                {store.storeNumber}
                            </option>
                        );
                    })}
                </select>
            </span>
            <span className="flex-1 text-center font-semibold text-gray-700 text-sm">
                {moment().format("l")}
            </span>
            <div className="flex">
                <Tooltip content="Apply changes">
                    <div onClick={submitUpdate}>
                        <GoCheck className="cursor-pointer text-gray-500 hover:text-green-500 mr-1" />
                    </div>
                </Tooltip>
                <Tooltip content="Discard changes">
                    <div onClick={toggleView}>
                        <GoX className="cursor-pointer text-gray-500 hover:text-pink-500 mr-2" />
                    </div>
                </Tooltip>
            </div>
            <ToastContainer 
                position="bottom-right"
                theme="colored"
                autoClose={8000}
                closeOnClick={true}
            />
        </div>
    );
}

export default NewJobEditModeView;
