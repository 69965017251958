import React from "react";
import { Header } from "components";

export default function JobListHeader() {
    return (
        <Header>
            <div className="flex-1 flex justify-start items-center">
                {"Job "}
            </div>
            <div className="flex-1 flex justify-end items-center">
                {"Customer "}
            </div>
            <div className="flex-1 flex justify-end items-center">
                {"Merch "}
            </div>
            <div className="flex-1 flex justify-end items-center">
                {"Location "}
            </div>
            <div className="flex-1 flex justify-end items-center">
                {"Received "}
            </div>
            <div className="flex-1 flex justify-end items-center">
                {"Promised "}
            </div>
            <div className="flex-1 flex justify-end items-center mr-1">
                {"Status "}
            </div>
        </Header>
    );
}
