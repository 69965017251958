import { gql } from '@apollo/client';

export const GET_REPAIR_CODES = gql`
query getUserRepairCodes($id: ID!) {
  getUserRepairCodes(id: $id) @cascade {
    userRepairCodeGroups {
      id
      entity
      repairGroup
      groupCodes {
        entity
        code
        description
        wholeSalePrice
        retailPrice
        id
      }
    }
  }
}`