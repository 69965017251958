import React from 'react'

const RenderIf = ({ children, isTrue }) => {
    if (Boolean(isTrue)) {
        return <React.Fragment>{children}</React.Fragment>
    }
    return null;
}

export default RenderIf
