import React from "react";
import ChargeCodesBox from "./ChargeCodesBox";
import accounting from "accounting-js";
import moment from "moment";
import { Header, RenderIf, Badge } from "components";

const JobToCompleteDisplay = ({ job, codesCharged, addCode, removeCode, updateTotal }) => {
    return (
        <>
            <Header>
                <div className="w-full flex flex-col">
                    <div className="flex items-center">
                        <span className="flex-1 text-xs text-left">
                            Number
                        </span>
                        <span className="flex-1 text-xs text-right">Name Lastname</span>
                        <span className="flex-1 text-xs text-right">Item Value</span>
                        <span className="flex-1 text-xs text-right">Location</span>
                        <span className="flex-1 text-xs text-right">Received</span>
                        <span className="flex-1 text-xs text-right">Promise Date</span>
                    </div>
                    <RenderIf isTrue={job}>
                        <div className="flex items-center font-semibold">
                            <span className="flex-1 text-sm text-left flex justify-start items-center">
                                {job?.jobNumber}
                                <Badge className={'ml-2'}>
                                    {job?.market}
                                </Badge>
                            </span>
                            <span className="flex-1 text-sm text-right">{job?.customerName}</span>
                            <span className="flex-1 text-sm text-right">
                                {accounting.formatMoney(job?.itemValue)}
                            </span>
                            <span className="flex-1 text-sm text-right">{job?.store?.storeNumber}</span>
                            <span className="flex-1 text-sm text-right">
                                {moment(job?.receivedDate).format("l")}
                            </span>
                            <span className="flex-1 text-sm text-right">
                                {moment(job?.promiseDate).format("l")}
                            </span>
                        </div>
                    </RenderIf>
                </div>
            </Header>

            <div className="bg-white w-full mt-2 rounded-xl shadow-xl">
                <div className="w-full flex items-center px-6 py-2 font-semibold bg-blue-gray-100 rounded-t-xl">
                    <span className="flex-1 text-left">Code</span>
                    <span className="flex-1 text-center" style={{ textAlign: "center" }}>
                        Repair Description
                    </span>
                    <span className="flex-1 text-right">Quantity</span>
                    <span className="flex-1 text-right">Price</span>
                    <span className="flex-1" />
                </div>
                <div className="p-6">
                    <ChargeCodesBox
                        job={job}
                        codesCharged={codesCharged}
                        addCode={addCode}
                        removeCode={removeCode}
                        updateTotal={updateTotal}
                    />
                </div>
            </div>
        </>
    );
}

export default JobToCompleteDisplay;
