import React from 'react'
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,    
} from "@material-tailwind/react";

const DeleteTaskConfirmationModal = (
    { isDeleteTaskModalOpen, toggleDeleteTaskModal, isTaskMutationLoading, handleDeleteTaskModal }
) => {
  return (
        <Dialog open={isDeleteTaskModalOpen} handler={toggleDeleteTaskModal}>
            <DialogHeader>
                <span>Delete Task Code</span>
            </DialogHeader>
            <DialogBody>
                <div className="font-medium">
                    Are you sure you want to delete this task code from your price inventory?
                </div>
            </DialogBody>
            <DialogFooter>
                <Button
                    variant="gradient"
                    onClick={toggleDeleteTaskModal}
                    className="mr-2"
                    disabled={isTaskMutationLoading}
                >
                    Cancel
                </Button>
                <Button
                    variant="gradient"
                    color={isTaskMutationLoading ? "gray" : "red"}
                    onClick={handleDeleteTaskModal}
                    disabled={isTaskMutationLoading}
                >
                    {isTaskMutationLoading ? "Working on it..." : "Yes"}
                </Button>
            </DialogFooter>
        </Dialog>
  )
}

export default DeleteTaskConfirmationModal
