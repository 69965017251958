import React, { useState } from "react";
import PriceDisplay from "./PriceDisplay";
import PriceInput from "./PriceInput";

export default function PriceContainer({ code, market }) {
    const [edit, setEdit] = useState(false);
    const toggleEditMode = () => {
        setEdit(!edit);
    };
    return (
        <span style={{ float: "right" }}>
            {edit ? (
                <PriceInput
                    code={code}
                    market={market}
                    toggleEditMode={toggleEditMode}
                />
            ) : (
                    <PriceDisplay
                        code={code}
                        market={market}
                        toggleEditMode={toggleEditMode}
                    />
            )}
        </span>
    );
}
