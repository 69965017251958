import React, { useState, useEffect, useRef, useCallback, Fragment } from "react"
import { RenderIf, Drawer, Header, Heading } from "components"
import ListRowComp from './ListRowComp'
import { Button, Checkbox } from "@material-tailwind/react"
import axios from 'axios'
import { ToastContainer, toast } from "react-toastify";
import { STATUS } from "constants";
import { useUser } from "hooks";
import { useQuery, useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import { GET_USER_DATA } from "apollo/queries/getUserData";
import { GET_USER_JOBS } from "apollo/queries/getUserJobs";
import { UPDATE_JOB } from "apollo/mutations/updateJob";
import { BeatLoader } from 'react-spinners'

const Delivery = () => {
    const isMounted = useRef(false)
    const client = useApolloClient()
    const { currentUser } = useUser()

    const [getJobs, { data: currentUserJobs, loading }] = useLazyQuery(GET_USER_JOBS, {
        onCompleted: res => console.log("jobs ->", { res })
    })

    const { data } = useQuery(GET_USER_DATA, {
        variables: { email: currentUser.email },
        onCompleted: (res) => getJobs({ variables: { id: res?.getUser?.userJobs?.id } }),
        onError: (error) => toast(error.message, {
            type: 'error',
        })
    })

    const [mutate, { loading: isLoadingInvoiceMutation }] = useMutation(UPDATE_JOB)

    const homeLocation = data?.getUser?.userStores?.find(store => store.relation === 'home')
    const otherLocations = data?.getUser?.userStores?.filter(store => store.relation !== 'home')
    
    const [activeStoreNumber, setActiveStoreNumber] = useState(homeLocation.storeNumber)
    const [selectedStoreToNotify, setSelectedStoreToNotify] = useState({})
    const [storeItems, setStoreItems] = useState()
    const [listOfItemsStringForEmail, setListOfItemsStringForEmail] = useState()
    const [isSending, setIsSending] = useState(false)

    const [itemsWithCheck, setItemsWithCheck] = useState([])


    const checkboxOnChangeHandler = (event) => {
        const { name, checked } = event.target;
        let itemsWithIsCheckedField;
        let string = ""
        if (name === "allSelect") {
            itemsWithIsCheckedField = storeItems?.map((item) => ({ ...item, isChecked: checked }));
            setStoreItems(itemsWithIsCheckedField);
        } else {
            itemsWithIsCheckedField = storeItems?.map((item) => item.customerName === name ? { ...item, isChecked: checked } : item);
            setStoreItems(itemsWithIsCheckedField);
        }
        const checkedItems = itemsWithIsCheckedField?.filter(job => job.isChecked)
        checkedItems.forEach((item, index) => { 
            if (checkedItems.length === index + 1) {
                string = `${string} ${item.customerName}`
            } else { 
                string = `${string} ${item.customerName} |`
            }
        })
        setItemsWithCheck(checkedItems)
        setListOfItemsStringForEmail(string)
    };


    const setItemsThatNeedDeliveryForMap = useCallback(() => {
        if (currentUserJobs?.getUserJobs?.userJobs?.length) {
            const selectedStoreItems =
                currentUserJobs?.getUserJobs?.userJobs?.filter( job => job.store.storeNumber === activeStoreNumber && job.status === STATUS.INVOICED)
            setStoreItems(selectedStoreItems)
        }
    }, [activeStoreNumber, currentUserJobs])


    const updateJobsAsInvoicedInDB = async () => {
        const selectedItems = storeItems?.filter(job => job.isChecked)
        for (let i = 0; i < selectedItems.length; i++) {
            const job = selectedItems[i];
            mutate({
                variables: {
                    input: {
                        filter: { id: job.id },
                        set: {
                            status: STATUS.DELIVERED,
                        }
                    }
                },
                // Cambiar esto aqui estan pasando muchos fetch
                // Hay que hacer uno solo al final de todas las mutaciones 
                // con un Promess.all o algo asi
                onCompleted: async () => { 
                    await client.refetchQueries({
                        include: ["getUserJobs"],
                    })
                }
            });
        }
    }   

    const deliverJobsToStore = async () => { 
        try {
            setIsSending(true)
            await axios.post('https://us-central1-bench-manager-cms.cloudfunctions.net/v1/store-delivery-notification', {
                recipient: selectedStoreToNotify.storeEmail,
                subject: 'You repair orders has been picked up or shipped out.',
                preheader: "Good News! You've got orders heading your way!",
                storeName: selectedStoreToNotify.storeName,
                userBusinessName: currentUser.displayName,
                jobsList: listOfItemsStringForEmail,
            });
            updateJobsAsInvoicedInDB()
            setIsSending(false)
        } catch (error) {
            console.log(error)
            toast(error.message, {
                type: 'error'
            })
        }
    }


    const deliverJobToHomeLocationRetailCustomer = () => { 
        setIsSending(true)
        Promise.all(
            itemsWithCheck.map(async (job) => { 
                await axios.post('https://us-central1-bench-manager-cms.cloudfunctions.net/v1/customer-delivery-notification', {
                    recipient: job?.customerEmail,
                    preheader: "Your repair order has been picked up!",
                    subject: `At ${currentUser?.displayName} store location.`,
                    jobNumber: job?.jobNumber,
                    customerName: job?.customerName,
                    storeAddress: homeLocation?.storeAddress,
                    businessName: currentUser?.displayName,
                    storePhoneNumber: homeLocation?.storePhoneNumber,
                });
            })
        ).then(() => {
            // update db to invoiced
            updateJobsAsInvoicedInDB()
            setIsSending(false)
        }).catch((error) => { 
            console.log(error)
            console.log(error.message)
            toast(
                `An error has ocurred while trying to invoice jobs, 
                please ensure you have an estable internet connection
                or try later. If the error persist please contact support`, {
                type: 'error'
            })
        })
    }

    const deliverJobs = async () => {
        if (activeStoreNumber === homeLocation.storeNumber) {
            deliverJobToHomeLocationRetailCustomer()
        } else {
            await deliverJobsToStore()
        }
    }

    useEffect(() => { 
        if (isMounted.current && activeStoreNumber) {
            const selectedStore = data?.getUser?.userStores.find(store => store.storeNumber === activeStoreNumber)
            setSelectedStoreToNotify(selectedStore)
            setItemsThatNeedDeliveryForMap();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStoreNumber])


    useEffect(() => { 
        setItemsThatNeedDeliveryForMap()
    }, [currentUserJobs, setItemsThatNeedDeliveryForMap])


    useEffect(() => { 
        isMounted.current = true
    }, [])

   
    return (
        <div className="w-full flex">
            <Drawer />
            <div className="w-full h-screen px-12 overflow-y-auto bg-blue-gray-50">
                <div className="flex justify-between items-start mt-16">
                    <div className="w-80 sticky top-16">
                        <div className="bg-white shadow-xl rounded-xl border border-blue-gray-50 p-6">
                            <Heading size="sm">
                                Home Location
                            </Heading>
                            <ListItemStore
                                selected={activeStoreNumber === homeLocation.storeNumber}
                                callback={() => setActiveStoreNumber(homeLocation.storeNumber)}
                                storeNumber={homeLocation.storeNumber}
                                storeName={homeLocation.storeName}
                            />
                            <RenderIf isTrue={otherLocations && otherLocations?.length}>
                                <Heading size="sm" className={"mt-4"}>
                                    Serviced Location
                                </Heading>
                                <div className="max-h-64 overflow-auto">
                                    {otherLocations?.map((store, index) => (
                                        <Fragment key={store.storeName}>
                                            <ListItemStore
                                                selected={activeStoreNumber === store.storeNumber}
                                                callback={() => setActiveStoreNumber(store.storeNumber)}
                                                storeNumber={store.storeNumber}
                                                storeName={store.storeName}
                                            />
                                        </Fragment>
                                    ))}
                                </div>
                            </RenderIf>
                        </div>
                        <div className="mt-6">
                            <Button
                                className="w-full cursor-pointer mt-6"
                                color={isSending || (!storeItems?.some(item => item?.isChecked)) ? 'gray' : 'blue'}
                                variant="gradient"
                                onClick={deliverJobs}
                                disabled={
                                    isSending ||
                                    isLoadingInvoiceMutation ||
                                    !storeItems?.some(item => item?.isChecked)
                                }
                            >
                                {
                                    isSending ? 'Updating Jobs...' :
                                        isLoadingInvoiceMutation ? 'Updating Jobs...' :
                                            'Deliver Jobs'
                                }
                            </Button>
                        </div>
                    </div>

                    {/*  Right side List */}
                    <div className="grow mx-3">
                        <Header className="flex justify-between items-center">
                            <div className="flex-1 flex justify-start items-center">
                                <Checkbox
                                    // type="checkbox"
                                    className="mr-3 ml-3"
                                    name="allSelect"
                                    checked={!storeItems?.some((item) => item?.isChecked !== true)}
                                    onChange={checkboxOnChangeHandler}
                                />
                                <b className="py-3">Job Number</b>
                            </div>
                            <div className="flex-1 text-right -mr-3">
                                <b>Customer Name</b>
                            </div>
                            <div className="flex-1 text-right -mr-3">
                                <b>Job Charge</b>
                            </div>
                            <div className="flex-1 text-right pr-3">
                                <b>Location</b>
                            </div>
                        </Header>

                        <div className="w-full mt-3 py-3 rounded-lg bg-white mb-16">
                            <RenderIf isTrue={storeItems?.length}>
                                {storeItems?.map((job, index) => {
                                    return (
                                        <ListRowComp
                                            key={index}
                                            job={job}
                                            checkboxOnChangeHandler={checkboxOnChangeHandler}
                                        />
                                    )
                                })}
                            </RenderIf>
                            <RenderIf isTrue={loading}>
                                <BeatLoader color="#94A3B8" size={9} className="ml-6 mb-3 mt-6" />
                                <div className="flex mb-3">
                                    <BeatLoader color="#94A3B8" size={9} className="ml-6" />
                                    <BeatLoader color="#94A3B8" size={9} className="ml-1" />
                                </div>
                                <BeatLoader color="#94A3B8" size={9} className="ml-6" />
                            </RenderIf>
                            <RenderIf isTrue={!storeItems || (storeItems && !storeItems?.length)}>
                                <p className="ml-3 italic text-sm text-blue-gray-200">
                                    There is no Items to show under this criteria.
                                </p>
                            </RenderIf>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="bottom-right"
                theme="colored"
                autoClose={8000}
                closeOnClick={true}
            />
        </div>
    )
}

export default Delivery;



const ListItemStore = ({ selected, callback, storeNumber, storeName }) => (
    <div
        className={`pl-1 flex items-center hover:bg-gray-50 hover:cursor-pointer truncate ${selected ? `bg-gray-50 border-r-2 border-light-blue-300` : ``}`}
        onClick={callback}
    >
        <span className="text-4xl text-blue-gray-200 mx-1">
            &middot;
        </span>
        <Heading size="xs" colorClass="text-blue-gray-500">{storeNumber}</Heading>
        <span className="text-blue-gray-300 text-sm ml-2">{storeName}</span>
    </div>
)