export const jewelryCodes = (userEmail) => ([
    {
        repairGroup: "Ring Sizings - Up to 3mm (Shank Width)",
        entity: 'gold',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "gold",
                code: 101,
                description: "Up to 3mm Size Smaller",
                wholeSalePrice: 9.99,
                retailPrice: 39.99,
            },
            {
                entity: "gold",
                code: 102,
                description: "Up to 3mm 1 Size Larger",
                wholeSalePrice: 18.99,
                retailPrice: 49.99,
            },
            {
                entity: "gold",
                code: 103,
                description: "Up to 3mm Each Additional Size",
                wholeSalePrice: 8.99,
                retailPrice: 19.99,
            },
            {
                entity: "gold",
                code: 104,
                description: "Up to 3mm 1/2 Shank Up To Size 7",
                wholeSalePrice: 44.99,
                retailPrice: 99.99,
            },
            {
                entity: "gold",
                code: 105,
                description: "Up to 3mm Full Shank Up To Size 7",
                wholeSalePrice: 89.99,
                retailPrice: 229.99,
                
            },
            {
                entity: "gold",
                code: 106,
                description: "Up to 3mm Shank Additional Size",
                wholeSalePrice: 9.99,
                retailPrice: 22.99,
                
            },
            {
                entity: "gold",
                code: 107,
                description: "Up to 3mm Sizing Bumps Inside Ring",
                wholeSalePrice: 29.0,
                retailPrice: 79.99,
                
            },
            {
                entity: "gold",
                code: 131,
                description: "Up to 3mm Fill Ring Back",
                wholeSalePrice: 142.0,
                retailPrice: 299.99,
                
            },
        ]
    },
    {
        repairGroup: "Ring Sizings - 3mm (Shank Width) and Up",
        entity: 'gold',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "gold",
                code: 108,
                description: "3mm and Up Size Smaller",
                wholeSalePrice: 9.99,
                retailPrice: 44.99,
                
            },
            {
                entity: "gold",
                code: 109,
                description: "3mm and Up Size Larger",
                wholeSalePrice: 17.99,
                retailPrice: 52.99,
                
            },
            {
                entity: "gold",
                code: 110,
                description: "3mm and Up Each Additional Size",
                wholeSalePrice: 12.99,
                retailPrice: 25.99,
                
            },
            {
                entity: "gold",
                code: 111,
                description: "3mm and Up 1/2 Shank Up To Size 10",
                wholeSalePrice: 70.0,
                retailPrice: 229.99,
                
            },
            {
                entity: "gold",
                code: 112,
                description: "3mm and Up Full Shank Up To Size 10",
                wholeSalePrice: 120.0,
                retailPrice: 229.99,
                
            },
            {
                entity: "gold",
                code: 113,
                description: "3mm and Up Shank Additional Size",
                wholeSalePrice: 12.99,
                retailPrice: 25.99,
                
            },
            {
                entity: "gold",
                code: 114,
                description: "3mm and Up Sizing Bumps Inside Ring",
                wholeSalePrice: 40.0,
                retailPrice: 99.99,
                
            },
            {
                entity: "gold",
                code: 132,
                description: "3mm and Up Fill Ring Back",
                wholeSalePrice: 192.0,
                retailPrice: 379.99,
                
            },
        ]
    },
    {
        repairGroup: "Rings General Maintenance",
        entity: 'gold',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "gold",
                code: 115,
                description: "Repair Break In Shank",
                wholeSalePrice: 8.99,
                retailPrice: 27.99,
                
            },
            {
                entity: "gold",
                code: 116,
                description: "Solder 2 Rings Together",
                wholeSalePrice: 14.99,
                retailPrice: 44.99,
                
            },
            {
                entity: "gold",
                code: 117,
                description: "Reshape Ring",
                wholeSalePrice: 8.99,
                retailPrice: 27.99,
                
            },
            {
                entity: "gold",
                code: 133,
                description: "Solder 2 Rings Together",
                wholeSalePrice: 48.0,
                retailPrice: 115.99,
                
            },
        ]
    },
    {
        repairGroup: "Finishing and Polishing",
        entity: 'gold',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "gold",
                code: 118,
                description: "Clean and Polish",
                wholeSalePrice: 5.5,
                retailPrice: 19.99,
                
            },
            {
                entity: "gold",
                code: 119,
                description: "Re Antique",
                wholeSalePrice: 8.99,
                retailPrice: 35.99,
                
            },
            {
                entity: "gold",
                code: 120,
                description: "Satin or Brush Finish",
                wholeSalePrice: 6.99,
                retailPrice: 34.99,
                
            },
            {
                entity: "gold",
                code: 121,
                description: "Refinish Bracelet",
                wholeSalePrice: 8.99,
                retailPrice: 33.99,
                
            },
            {
                entity: "gold",
                code: 122,
                description: "Refinish Watch Case",
                wholeSalePrice: 10.0,
                retailPrice: 35.99,
                
            },
            {
                entity: "gold",
                code: 123,
                description: "Rhodium Plating",
                wholeSalePrice: 10.5,
                retailPrice: 38.99,
                
            },
            {
                entity: "gold",
                code: 124,
                description: "Magnetic Tumble-Beads",
                wholeSalePrice: 10.0,
                retailPrice: 29.99,
                
            },
        ]
    },
    {
        repairGroup: "Chain Repair and Clasps",
        entity: 'gold',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "gold",
                code: 401,
                description: "Gold Soldering",
                wholeSalePrice: 6.5,
                retailPrice: 27.99,
                
            },
            {
                entity: "gold",
                code: 402,
                description: "Chain/Bracelet Repair",
                wholeSalePrice: 8.99,
                retailPrice: 34.99,
                
            },
            {
                entity: "gold",
                code: 403,
                description: "Rebraid ( per braid )",
                wholeSalePrice: 2.99,
                retailPrice: 9.99,
                
            },
            {
                entity: "gold",
                code: 404,
                description: "Bracelet Hinge Repair",
                wholeSalePrice: 19.99,
                retailPrice: 49.99,
                
            },
            {
                entity: "gold",
                code: 405,
                description: "Small Spring Ring",
                wholeSalePrice: 6.5,
                retailPrice: 22.99,
                
            },
            {
                entity: "gold",
                code: 406,
                description: "Large Sprin Ring",
                wholeSalePrice: 7.5,
                retailPrice: 28.99,
                
            },
            {
                entity: "gold",
                code: 407,
                description: "2mm Barrel or Box Clasp",
                wholeSalePrice: 24.0,
                retailPrice: 69.99,
                
            },
            {
                entity: "gold",
                code: 408,
                description: "4mm Barrel or Box Clasp",
                wholeSalePrice: 30.0,
                retailPrice: 75.0,
                
            },
            {
                entity: "gold",
                code: 409,
                description: "6mm Barrel or Box Clasp",
                wholeSalePrice: 36.0,
                retailPrice: 115.0,
                
            },
            {
                entity: "gold",
                code: 410,
                description: "Small or Medium Lobster Clasp",
                wholeSalePrice: 20.0,
                retailPrice: 59.99,
                
            },
            {
                entity: "gold",
                code: 411,
                description: "13mm Large Lobster Clasp",
                wholeSalePrice: 32.0,
                retailPrice: 94.99,
                
            },
            {
                entity: "gold",
                code: 412,
                description: "16mm XL Lobster Clasp",
                wholeSalePrice: 59.99,
                retailPrice: 159.99,
                
            },
            {
                entity: "gold",
                code: 413,
                description: "Figure 8 Safety Catch",
                wholeSalePrice: 22.0,
                retailPrice: 69.99,
                
            },
            {
                entity: "gold",
                code: 431,
                description: "Tennis Bracelet Catch",
                wholeSalePrice: 35.0,
                retailPrice: 97.99,
                
            },
            {
                entity: "gold",
                code: 432,
                description: "Tighten Clasp",
                wholeSalePrice: 4.0,
                retailPrice: 12.0,
                
            },
        ]
    },
    {
        repairGroup: "Miscellaneous",
        entity: 'gold',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "gold",
                code: 807,
                description: "5mm Jump Ring",
                wholeSalePrice: 7.99,
                retailPrice: 29.99,
                
            },
            {
                entity: "gold",
                code: 808,
                description: "8mm Jump Ring",
                wholeSalePrice: 8.99,
                retailPrice: 32.99,
                
            },
            {
                entity: "gold",
                code: 809,
                description: "Rabbit Ear Bail",
                wholeSalePrice: 22.0,
                retailPrice: 64.99,
                
            },
            {
                entity: "gold",
                code: 810,
                description: "3.5mm Snap-on Bail",
                wholeSalePrice: 15.0,
                retailPrice: 46.99,
                
            },
            {
                entity: "gold",
                code: 811,
                description: "1 Chain End Tab",
                wholeSalePrice: 12.0,
                retailPrice: 36.99,
                
            },
            {
                entity: "gold",
                code: 812,
                description: "Make Nugget",
                wholeSalePrice: 54.0,
                retailPrice: 129.99,
                
            },
            {
                entity: "gold",
                code: 813,
                description: "Custom Fabrication & Miscellaneous",
                wholeSalePrice: 0.0,
                retailPrice: 0.0,
                
            },
            {
                entity: "gold",
                code: 815,
                description: "Cement Pearl",
                wholeSalePrice: 4.0,
                retailPrice: 12.0,
                
            },
            {
                entity: "gold",
                code: 816,
                description: "Re-string Peals Knot per Inch",
                wholeSalePrice: 2.5,
                retailPrice: 6.99,
                
            },
            {
                entity: "gold",
                code: 829,
                description: "Re-Do ( No Charge )",
                wholeSalePrice: 8.99,
                retailPrice: 32.99,
                
            },
        ]
    },
    {
        repairGroup: "Earrings",
        entity: 'gold',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "gold",
                code: 414,
                description: "14K Friction Posts & Backs",
                wholeSalePrice: 22.0,
                retailPrice: 65.99,
                
            },
            {
                entity: "gold",
                code: 415,
                description: "14K Screw Posts & Backs",
                wholeSalePrice: 29.99,
                retailPrice: 98.99,
                
            },
            {
                entity: "gold",
                code: 416,
                description: "Friction Back (Each)",
                wholeSalePrice: 5.5,
                retailPrice: 14.99,
                
            },
            {
                entity: "gold",
                code: 417,
                description: "Screw Back (Each)",
                wholeSalePrice: 6.99,
                retailPrice: 23.99,
                
            },
            {
                entity: "gold",
                code: 418,
                description: "Earring Wire (Each)",
                wholeSalePrice: 12.0,
                retailPrice: 39.99,
                
            },
            {
                entity: "gold",
                code: 419,
                description: "La Poussette Post & Back",
                wholeSalePrice: 34.99,
                retailPrice: 99.99,
                
            },
            {
                entity: "gold",
                code: 420,
                description: "La Poussette Back Only (Each)",
                wholeSalePrice: 12.0,
                retailPrice: 37.99,
                
            },
            {
                entity: "gold",
                code: 421,
                description: "Lever Back (Each)",
                wholeSalePrice: 18.99,
                retailPrice: 57.99,
                
            },
        ]
    },
    {
        repairGroup: "Diamond & Stone Setting",
        entity: 'gold',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "gold",
                code: 501,
                description: ".01 to .2 ct Stone Set",
                wholeSalePrice: 5.5,
                retailPrice: 22.99,
                
            },
            {
                entity: "gold",
                code: 502,
                description: ".21 to .5 ct Stone Set",
                wholeSalePrice: 8.5,
                retailPrice: 29.99,
                
            },
            {
                entity: "gold",
                code: 503,
                description: ".51 to .99 ct Stone Set",
                wholeSalePrice: 8.5,
                retailPrice: 29.99,
                
            },
            {
                entity: "gold",
                code: 504,
                description: "1 ct and Up Stone Set",
                wholeSalePrice: 16.0,
                retailPrice: 48.99,
                
            },
            {
                entity: "gold",
                code: 536,
                description: "Melee Stone Set (over 20 stones)",
                wholeSalePrice: 2.0,
                retailPrice: 6.0,
                
            },
            {
                entity: "gold",
                code: 505,
                description: "Bead Set",
                wholeSalePrice: 2.0,
                retailPrice: 6.0,
                
            },
        ],
    },
    {
        repairGroup: "Supply Head and Set",
        entity: 'gold',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "gold",
                code: 506,
                description: ".01 to .1 ct Head and Set",
                wholeSalePrice: 17.0,
                retailPrice: 54.99,
                
            },
            {
                entity: "gold",
                code: 507,
                description: ".11 to .5 ct Head and Set",
                wholeSalePrice: 29.99,
                retailPrice: 94.99,
                
            },
            {
                entity: "gold",
                code: 508,
                description: ".51 to 1 ct Head and Set",
                wholeSalePrice: 34.99,
                retailPrice: 124.99,
                
            },
            {
                entity: "gold",
                code: 509,
                description: "1.01 to 2 ct Head and Set",
                wholeSalePrice: 49.99,
                retailPrice: 179.99,
                
            },
            {
                entity: "gold",
                code: 533,
                description: "2.01 to 3 ct Head and Set",
                wholeSalePrice: 54.99,
                retailPrice: 189.99,
                
            },
            {
                entity: "gold",
                code: 534,
                description: "3.01 to 5 ct Head and Set",
                wholeSalePrice: 59.99,
                retailPrice: 199.99,
                
            },
            {
                entity: "gold",
                code: 535,
                description: "5.1 ct and Above Head and Set",
                wholeSalePrice: 64.99,
                retailPrice: 219.99,
                
            },
            {
                entity: "gold",
                code: 510,
                description: "Color Stone and/or Fancy Cut",
                wholeSalePrice: 5.5,
                retailPrice: 17.99,
                
            },
            {
                entity: "gold",
                code: 532,
                description: "Install Head Only",
                wholeSalePrice: 20.0,
                retailPrice: 59.99,
                
            },
        ]
    },
    {
        repairGroup: "Tighten Stones",
        entity: 'gold',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "gold",
                code: 511,
                description: "Tighten Single Diamond",
                wholeSalePrice: 8.99,
                retailPrice: 39.99,
                
            },
            {
                entity: "gold",
                code: 512,
                description: "Tighten Color and/or Fancy",
                wholeSalePrice: 10.0,
                retailPrice: 34.99,
                
            },
            {
                entity: "gold",
                code: 513,
                description: "Tighten Multiple Stones",
                wholeSalePrice: 11.5,
                retailPrice: 44.99,
                
            },
        ]
    },
    {
        repairGroup: "Retipping",
        entity: 'gold',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "gold",
                code: 514,
                description: "Retip 1 Gold Prong",
                wholeSalePrice: 9.99,
                retailPrice: 35.99,
                
            },
            {
                entity: "gold",
                code: 515,
                description: "Retip Additional Gold Prong (Each)",
                wholeSalePrice: 6.99,
                retailPrice: 19.99,
                
            },
            {
                entity: "gold",
                code: 516,
                description: "Rebuild 1 Gold Prong",
                wholeSalePrice: 12.0,
                retailPrice: 37.99,
                
            },
            {
                entity: "gold",
                code: 517,
                description: "Rebuild Additional Gold Prong (Each)",
                wholeSalePrice: 7.99,
                retailPrice: 23.99,
                
            },
            {
                entity: "gold",
                code: 518,
                description: "Rebuild V-Shape Prong",
                wholeSalePrice: 14.99,
                retailPrice: 43.99,
                
            },
            {
                entity: "gold",
                code: 519,
                description: "Price per Stone To Remove",
                wholeSalePrice: 2.0,
                retailPrice: 4.0,
                
            },
            {
                entity: "gold",
                code: 520,
                description: "Straighten 1 Prong (Each)",
                wholeSalePrice: 4.0,
                retailPrice: 11.99,
                
            },
            {
                entity: "gold",
                code: 521,
                description: "Rebuild Channel Side (Price per Stone)",
                wholeSalePrice: 5.0,
                retailPrice: 18.99,
                
            },
        ]
    },
    {
        repairGroup: "14K Watch Bands",
        entity: 'gold',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "gold",
                code: 801,
                description: "Shorten Watch Band (per Side)",
                wholeSalePrice: 14.0,
                retailPrice: 38.99,
                
            },
            {
                entity: "gold",
                code: 802,
                description: "14K Gold Safety Chain",
                wholeSalePrice: 23.99,
                retailPrice: 57.99,
                
            },
            {
                entity: "gold",
                code: 803,
                description: "Solder 14K Gold Safety Chain",
                wholeSalePrice: 7.99,
                retailPrice: 17.99,
                
            },
            {
                entity: "gold",
                code: 804,
                description: "Solder Mesh Watch Band",
                wholeSalePrice: 15.0,
                retailPrice: 39.99,
                
            },
            {
                entity: "gold",
                code: 805,
                description: "Watch Band Hinge Repair (per Side)",
                wholeSalePrice: 19.0,
                retailPrice: 49.99,
                
            },
        ]
    },
    {
        repairGroup: "Engraving",
        entity: 'gold',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "gold",
                code: 806,
                description: "Inside/Outside Ring Engraving (per Task)",
                wholeSalePrice: 7.0,
                retailPrice: 17.99,
                
            },
            {
                entity: "gold",
                code: 831,
                description: "Flat Engrave (per Task)",
                wholeSalePrice: 14.0,
                retailPrice: 35.99,
                
            },
            {
                entity: "gold",
                code: 832,
                description: "Image Engraving (per Task)",
                wholeSalePrice: 20.0,
                retailPrice: 47.99,
                
            },
        ]
    },
    {
        repairGroup: "Mountings",
        entity: 'gold',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "gold",
                code: 870,
                description: "Supply Yellow Gold Mounting",
                wholeSalePrice: 0.0,
                retailPrice: 0.0,
                
            },
            {
                entity: "gold",
                code: 875,
                description: "Supply White Gold Mounting",
                wholeSalePrice: 0.0,
                retailPrice: 0.0,
                
            },
            {
                entity: "gold",
                code: 880,
                description: "Supply Platinum Mounting",
                wholeSalePrice: 0.0,
                retailPrice: 0.0,
                
            },
            {
                entity: "gold",
                code: 885,
                description: "Supply Silver Mounting",
                wholeSalePrice: 0.0,
                retailPrice: 0.0,
                
            },
        ]
    },
    {
        repairGroup: "Specialty Codes",
        entity: 'gold',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "gold",
                code: 139,
                description: "Covered by my Warranty ( tracking )",
                wholeSalePrice: 0.0,
                retailPrice: 0.0,
                
            },
        ]
    },
    {
        repairGroup: "Ring Sizings - Up to 3mm (Shank Width)",
        entity: 'platinum',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "platinum",
                code: 601,
                description: "Up to 3mm Size Smaller",
                wholeSalePrice: 29.0,
                retailPrice: 89.99,
                
            },
            {
                entity: "platinum",
                code: 602,
                description: "Up to 3mm Size Larger",
                wholeSalePrice: 39.99,
                retailPrice: 119.99,
                
            },
            {
                entity: "platinum",
                code: 603,
                description: "Up to 3mm Additional Size",
                wholeSalePrice: 25.0,
                retailPrice: 59.99,
                
            },
            {
                entity: "platinum",
                code: 604,
                description: "Up to 3mm 1/2 Shank to Size 7",
                wholeSalePrice: 125.0,
                retailPrice: 379.99,
                
            },
            {
                entity: "platinum",
                code: 605,
                description: "Plat Sizing Bumps Inside Shank",
                wholeSalePrice: 106.0,
                retailPrice: 259.99,
                
            },
        ]
    },
    {
        repairGroup: "Ring Sizings - 3mm (Shank Width) and Up",
        entity: 'platinum',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "platinum",
                code: 608,
                description: "3mm and Up Size Smaller",
                wholeSalePrice: 25.0,
                retailPrice: 94.99,
                
            },
            {
                entity: "platinum",
                code: 609,
                description: "3mm and Up Size Larger",
                wholeSalePrice: 40.0,
                retailPrice: 129.99,
                
            },
            {
                entity: "platinum",
                code: 610,
                description: "3mm and Up Additional Size (Each)",
                wholeSalePrice: 22.5,
                retailPrice: 64.99,
                
            },
            {
                entity: "platinum",
                code: 611,
                description: "3mm and Up 1/2 Shank to Size 10",
                wholeSalePrice: 199.99,
                retailPrice: 499.99,
                
            },
        ]
    },
    {
        repairGroup: "Platinum Rings General",
        entity: 'platinum',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "platinum",
                code: 615,
                description: "Plat Repair Break in Shank",
                wholeSalePrice: 22.5,
                retailPrice: 58.99,
                
            },
            {
                entity: "platinum",
                code: 616,
                description: "Solder 2 Plat Rings Together (Also unsolder)",
                wholeSalePrice: 32.0,
                retailPrice: 119.99,
                
            },
            {
                entity: "platinum",
                code: 617,
                description: "Plat Reshape Ring",
                wholeSalePrice: 12.0,
                retailPrice: 44.99,
                
            },
        ]
    },
    {
        repairGroup: "Plat Refinish and Polish",
        entity: 'platinum',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "platinum",
                code: 619,
                description: "Clean and Polish Platinum",
                wholeSalePrice: 10.0,
                retailPrice: 34.99,
                
            },
            {
                entity: "platinum",
                code: 620,
                description: "Satin or Brush Finish",
                wholeSalePrice: 11.0,
                retailPrice: 34.99,
                
            },
        ]
    },
    {
        repairGroup: "Platinum Earrings",
        entity: 'platinum',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "platinum",
                code: 631,
                description: "Plat Friction Posts & Backs",
                wholeSalePrice: 80.0,
                retailPrice: 229.99,
                
            },
            {
                entity: "platinum",
                code: 632,
                description: "Plat Screws Posts & Backs",
                wholeSalePrice: 119.99,
                retailPrice: 299.99,
                
            },
            {
                entity: "platinum",
                code: 633,
                description: "Plat Friction Back (Each)",
                wholeSalePrice: 30.0,
                retailPrice: 74.99,
                
            },
            {
                entity: "platinum",
                code: 634,
                description: "Plat Screw Back (Each)",
                wholeSalePrice: 52.0,
                retailPrice: 119.99,
                
            },
        ]
    },
    {
        repairGroup: "Platinum Diamond & Stonce Set",
        entity: 'platinum',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "platinum",
                code: 641,
                description: "Plat | .001 - .2 ct Set Stone (include Beads)",
                wholeSalePrice: 12.0,
                retailPrice: 39.99,
                
            },
            {
                entity: "platinum",
                code: 642,
                description: "Plat | .21 - .5 ct Set Stone",
                wholeSalePrice: 18.0,
                retailPrice: 59.99,
                
            },
            {
                entity: "platinum",
                code: 643,
                description: "Plat | .51 - .99 ct Set Stone",
                wholeSalePrice: 25.0,
                retailPrice: 77.99,
                
            },
            {
                entity: "platinum",
                code: 644,
                description: "Plat | Set Stone Over 1 ct",
                wholeSalePrice: 35.0,
                retailPrice: 99.99,
                
            },
            {
                entity: "platinum",
                code: 645,
                description: "Plat | Melee Stones (over 21)",
                wholeSalePrice: 3.0,
                retailPrice: 14.99,
                
            },
        ]
    },
    {
        repairGroup: "Platinum Supply Head & Set",
        entity: 'platinum',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "platinum",
                code: 648,
                description: "Plat | .001 - .10 ct Head & Set",
                wholeSalePrice: 59.99,
                retailPrice: 199.99,
                
            },
            {
                entity: "platinum",
                code: 649,
                description: "Plat | .11 - .5 ct Head & Set",
                wholeSalePrice: 119.99,
                retailPrice: 299.99,
                
            },
            {
                entity: "platinum",
                code: 650,
                description: "Plat | .51 - 1 ct Head & Set",
                wholeSalePrice: 149.99,
                retailPrice: 379.99,
                
            },
            {
                entity: "platinum",
                code: 651,
                description: "Plat | 1 - 2 ct Head & Set",
                wholeSalePrice: 179.99,
                retailPrice: 389.99,
                
            },
            {
                entity: "platinum",
                code: 657,
                description: "Plat | 2 - 3 ct Head & Set",
                wholeSalePrice: 219.99,
                retailPrice: 499.99,
                
            },
            {
                entity: "platinum",
                code: 658,
                description: "Plat | 3 - 5 ct Head & Set",
                wholeSalePrice: 319.99,
                retailPrice: 699.99,
                
            },
            {
                entity: "platinum",
                code: 659,
                description: "Plat | 5 ct and Above Head & Set",
                wholeSalePrice: 349.99,
                retailPrice: 799.99,
                
            },
            {
                entity: "platinum",
                code: 653,
                description: "Plat | Color or Fancy Cut Add",
                wholeSalePrice: 15.0,
                retailPrice: 54.99,
                
            },
        ]
    },
    {
        repairGroup: "Platinum Tighten Stones",
        entity: 'platinum',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "platinum",
                code: 654,
                description: "Plat | Tighten Single Diamond",
                wholeSalePrice: 8.99,
                retailPrice: 29.99,
                
            },
            {
                entity: "platinum",
                code: 655,
                description: "Plat | Tighten Color and/or Fancy",
                wholeSalePrice: 10.0,
                retailPrice: 32.99,
                
            },
            {
                entity: "platinum",
                code: 656,
                description: "Plat | Tighten Multiple Stones",
                wholeSalePrice: 11.5,
                retailPrice: 67.99,
                
            },
        ]
    },
    {
        repairGroup: "Platinum Retipping",
        entity: 'platinum',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "platinum",
                code: 660,
                description: "Plat | Rebuild 1 Prong",
                wholeSalePrice: 19.99,
                retailPrice: 59.99,
                
            },
            {
                entity: "platinum",
                code: 661,
                description: "Plat | Additional Prong (Each)",
                wholeSalePrice: 15.0,
                retailPrice: 44.99,
                
            },
            {
                entity: "platinum",
                code: 664,
                description: "Plat | Straighten Prong",
                wholeSalePrice: 4.0,
                retailPrice: 16.99,
                
            },
            {
                entity: "platinum",
                code: 665,
                description: "Plat | Rebuild Channel Side (Price per Stone)",
                wholeSalePrice: 12.5,
                retailPrice: 39.99,
                
            },
        ]
    },
    {
        repairGroup: "Sterling Silver Ring Sizing - Up to 3mm (Shank Width)",
        entity: 'silver',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "silver",
                code: 901,
                description: "SS | Up to 3mm Size Smaller",
                wholeSalePrice: 6.0,
                retailPrice: 29.99,
                
            },
            {
                entity: "silver",
                code: 902,
                description: "SS | Up to 3mm Size Larger",
                wholeSalePrice: 10.0,
                retailPrice: 39.99,
                
            },
            {
                entity: "silver",
                code: 904,
                description: "SS | Up to 3mm 1/2 Shank to Size 7",
                wholeSalePrice: 17.99,
                retailPrice: 79.99,
                
            },
            {
                entity: "silver",
                code: 905,
                description: "SS | Full Shank to Size 7",
                wholeSalePrice: 35.0,
                retailPrice: 109.99,
                
            },
        ]
    },
    {
        repairGroup: "Sterling Silver Ring Sizing - 3mm (Shank Width) and Up",
        entity: 'silver',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "silver",
                code: 908,
                description: "SS | Silver Size Smaller",
                wholeSalePrice: 7.0,
                retailPrice: 29.99,
                
            },
            {
                entity: "silver",
                code: 909,
                description: "SS | Silver Size Larger",
                wholeSalePrice: 15.0,
                retailPrice: 45.99,
                
            },
            {
                entity: "silver",
                code: 911,
                description: "SS | 1/2 Shank to Size 10",
                wholeSalePrice: 24.0,
                retailPrice: 89.99,
                
            },
            {
                entity: "silver",
                code: 912,
                description: "SS | Full Shank to Size 10",
                wholeSalePrice: 50.0,
                retailPrice: 129.99,
                
            },
        ]
    },
    {
        repairGroup: "Sterling Silver Rings General",
        entity: 'silver',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "silver",
                code: 915,
                description: "SS | Repair Break In Silver Shank",
                wholeSalePrice: 7.0,
                retailPrice: 24.99,
                
            },
            {
                entity: "silver",
                code: 916,
                description: "SS | Solder 2 Silver Rings Together",
                wholeSalePrice: 11.0,
                retailPrice: 39.99,
                
            },
            {
                entity: "silver",
                code: 917,
                description: "SS | Reshape Silver Ring",
                wholeSalePrice: 6.0,
                retailPrice: 24.99,
                
            },
        ]
    },
    {
        repairGroup: "Sterling Silver Refinish and Polish",
        entity: 'silver',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "silver",
                code: 919,
                description: "SS |  Clean and Polish",
                wholeSalePrice: 5.0,
                retailPrice: 23.99,
                
            },
            {
                entity: "silver",
                code: 920,
                description: "Satin or Brush Finish",
                wholeSalePrice: 7.0,
                retailPrice: 24.99,
                
            },
            {
                entity: "silver",
                code: 921,
                description: "Refinish Bracelet",
                wholeSalePrice: 7.0,
                retailPrice: 34.99,
                
            },
            {
                entity: "silver",
                code: 923,
                description: "Rhodium Platin",
                wholeSalePrice: 10.0,
                retailPrice: 38.99,
                
            },
        ]
    },
    {
        repairGroup: "Sterling Silver Chain Repair and Clasp",
        entity: 'silver',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "silver",
                code: 970,
                description: "SS | Solder at Break",
                wholeSalePrice: 5.5,
                retailPrice: 23.99,
                
            },
            {
                entity: "silver",
                code: 971,
                description: "SS | Chain/Bracelet Repair",
                wholeSalePrice: 6.5,
                retailPrice: 29.99,
                
            },
            {
                entity: "silver",
                code: 972,
                description: "SS | Rebraid (per braid)",
                wholeSalePrice: 2.5,
                retailPrice: 9.99,
                
            },
            {
                entity: "silver",
                code: 973,
                description: "SS | Bracelet Hinge Repair",
                wholeSalePrice: 14.5,
                retailPrice: 49.99,
                
            },
            {
                entity: "silver",
                code: 974,
                description: "SS | Spring Ring",
                wholeSalePrice: 7.0,
                retailPrice: 24.99,
                
            },
            {
                entity: "silver",
                code: 975,
                description: "SS | Clasp/catch",
                wholeSalePrice: 10.5,
                retailPrice: 34.99,
                
            },
            {
                entity: "silver",
                code: 976,
                description: "SS | Tighten Clasp",
                wholeSalePrice: 3.5,
                retailPrice: 13.99,
                
            },
            {
                entity: "silver",
                code: 980,
                description: "SS | 1 Chain End Tap",
                wholeSalePrice: 6.0,
                retailPrice: 25.99,
                
            },
        ]
    },
    {
        repairGroup: "Sterling Silver Miscellaneous",
        entity: 'silver',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "silver",
                code: 977,
                description: "SS | Jump Ring",
                wholeSalePrice: 6.5,
                retailPrice: 25.99,
                
            },
            {
                entity: "silver",
                code: 978,
                description: "SS | Rabbit Ear Bail",
                wholeSalePrice: 11.0,
                retailPrice: 39.99,
                
            },
            {
                entity: "silver",
                code: 979,
                description: "SS | 3.5mm snap-on Bail",
                wholeSalePrice: 9.0,
                retailPrice: 35.99,
                
            },
            {
                entity: "silver",
                code: 982,
                description: "SS | Miscellaneous Repair",
                wholeSalePrice: 0.0,
                retailPrice: 0.0,
                
            },
        ]
    },
    {
        repairGroup: "Sterling Silver Earrings",
        entity: 'silver',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "silver",
                code: 924,
                description: "SS | Earring Posts and Backs",
                wholeSalePrice: 18.0,
                retailPrice: 48.99,
                
            },
            {
                entity: "silver",
                code: 925,
                description: "SS | Earring Back (Each)",
                wholeSalePrice: 1.0,
                retailPrice: 7.99,
                
            },
        ]
    },
    {
        repairGroup: "Sterling Silver Diamond & Stone Setting",
        entity: 'silver',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "silver",
                code: 922,
                description: "SS | Stone Set Melee (21 or over)",
                wholeSalePrice: 2.0,
                retailPrice: 7.99,
                
            },
            {
                entity: "silver",
                code: 927,
                description: "SS | .21 - .5 ct Stone Set",
                wholeSalePrice: 8.5,
                retailPrice: 29.99,
                
            },
            {
                entity: "silver",
                code: 926,
                description: "SS | .51 - .99 ct Stone Set",
                wholeSalePrice: 9.99,
                retailPrice: 34.99,
                
            },
            {
                entity: "silver",
                code: 928,
                description: "SS | .001 - .2 ct Stone Set",
                wholeSalePrice: 5.5,
                retailPrice: 22.99,
                
            },
            {
                entity: "silver",
                code: 929,
                description: "SS | Stone Set 1 ct and Up",
                wholeSalePrice: 15.5,
                retailPrice: 48.99,
                
            },
        ]
    },
    {
        repairGroup: "Sterling Silver Supply Head & Set",
        entity: 'silver',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "silver",
                code: 930,
                description: "SS | .001 - 1 ct Head/Set",
                wholeSalePrice: 15.0,
                retailPrice: 52.99,
                
            },
            {
                entity: "silver",
                code: 931,
                description: "SS | 1 - 3 ct Head/Set",
                wholeSalePrice: 20.0,
                retailPrice: 63.99,
                
            },
            {
                entity: "silver",
                code: 932,
                description: "SS | 3 ct and Above Head/Set",
                wholeSalePrice: 25.0,
                retailPrice: 75.99,
                
            },
            {
                entity: "silver",
                code: 933,
                description: "SS | Color/Fancy Cut Stone Add",
                wholeSalePrice: 4.0,
                retailPrice: 17.99,
                
            },
            {
                entity: "silver",
                code: 934,
                description: "SS | Install Head Only",
                wholeSalePrice: 20.0,
                retailPrice: 63.99,
                
            },
        ]
    },
    {
        repairGroup: "Sterling Silver Tighten Stone",
        entity: 'silver',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "silver",
                code: 935,
                description: "SS | Tighten Single Diamond",
                wholeSalePrice: 7.0,
                retailPrice: 32.99,
                
            },
            {
                entity: "silver",
                code: 936,
                description: "SS | Tighten Color/Fancy Cut Stone",
                wholeSalePrice: 10.0,
                retailPrice: 35.99,
                
            },
            {
                entity: "silver",
                code: 937,
                description: "SS | Tighten Multiple Stones",
                wholeSalePrice: 11.5,
                retailPrice: 44.99,
                
            },
        ]
    },
    {
        repairGroup: "Sterling Silver Retipping",
        entity: 'silver',
        userGroup: {
            user: { email: userEmail }
        },
        groupCodes: [
            {
                entity: "silver",
                code: 938,
                description: "SS | Retip 1 Silver Prong",
                wholeSalePrice: 8.0,
                retailPrice: 34.99,
                
            },
            {
                entity: "silver",
                code: 939,
                description: "SS | Retip Additional Prong (Each)",
                wholeSalePrice: 6.0,
                retailPrice: 19.99,
                
            },
            {
                entity: "silver",
                code: 940,
                description: "SS | Rebuild 1 Silver Prong",
                wholeSalePrice: 12.0,
                retailPrice: 37.99,
                
            },
            {
                entity: "silver",
                code: 941,
                description: "SS | Rebuild Additional (Each)",
                wholeSalePrice: 8.0,
                retailPrice: 23.99,
                
            },
            {
                entity: "silver",
                code: 943,
                description: "SS | Price per Stone Removed",
                wholeSalePrice: 3.0,
                retailPrice: 7.99,
                
            },
            {
                entity: "silver",
                code: 944,
                description: "SS | Straighten 1 Prong",
                wholeSalePrice: 2.5,
                retailPrice: 11.99,
                
            },
            {
                entity: "silver",
                code: 945,
                description: "SS | Rebuild Channel per Stone",
                wholeSalePrice: 6.0,
                retailPrice: 18.99,
                
            },
            {
                entity: "silver",
                code: 947,
                description: "SS | Rebuild V-Shape Prong",
                wholeSalePrice: 12.0,
                retailPrice: 43.99,
                
            },
        ]
    },
]);
