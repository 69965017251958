import React from "react";
import StoreCard from "./StoreCard";
import { RiAddFill } from "react-icons/ri";
import { Button, Tooltip, IconButton } from "@material-tailwind/react"
import { RenderIf, Heading, EmptyStateStores } from "components";


export default function StoreList({ stores, openModal }) {
    const homeLocation = stores.find(store => store.relation === 'home')
    const otherLocations = stores.filter(store => store.relation !== 'home')

    return (
        <div className="flex flex-col justify-center items-center mt-6 mb-40">
            <div className="w-full flex justify-end items-center">
                <Tooltip content="Add location">
                    <IconButton
                        variant="gradient"
                        onClick={openModal}
                        className="mt-12"
                    >
                        <RiAddFill  className="text-xl font-black"/>
                    </IconButton>
                </Tooltip>
            </div>
            <div className="w-full flex flex-wrap justify-start mt-6">
                <Heading size="sm">
                    Home Location
                </Heading>
                <StoreCard store={homeLocation} />
                <Heading size="sm" className={"mt-12"}>
                    Serviced Locations
                </Heading>

                <RenderIf isTrue={!otherLocations.length}>
                    <div className="w-full flex justify-center">
                        <div className="flex flex-col justify-center items-center mb-40">
                            <EmptyStateStores />
                            <h1 className="mb-8 -mt-8 font-black">Whoops! You don't have any Serviced Locations yet!</h1>
                            <Tooltip content="Start by adding your first Location">
                                <Button onClick={openModal}>
                                    Add your first Location
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </RenderIf>

                <RenderIf isTrue={otherLocations.length}>
                    {otherLocations?.map((store, index) => {
                        return (
                            <StoreCard
                                key={`${index} - ${store.id}`}
                                store={store}
                            />
                        );
                    })}
                </RenderIf>

            </div>
        </div>
    );
}
