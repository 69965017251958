import React, { useState, Fragment, useCallback } from "react";
import {
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Select,
    Option,
    Button,
    Input,
} from "@material-tailwind/react";
import { getAuth } from "firebase/auth";
import { LookupInputWrap } from "components";
import * as yup from "yup";
// import useAddLocation from "hooks/queries/useAddLocation";
import { ToastContainer, toast } from "react-toastify";

import { useMutation, useApolloClient } from "@apollo/client";
import { ADD_STORE_MUTATION } from "apollo/mutations/addStore";

const AddStoreModalForm = ({ show, onHide }) => {
    // const { addLocation } = useAddLocation()
    const client = useApolloClient()
    const [mutate] = useMutation(ADD_STORE_MUTATION)

    const { currentUser } = getAuth()

    const [errors, setErrors] = useState([])
    const [data, setData] = useState({
        storeNumber: undefined,
        storeName: "",
        storeEmail: "",
        storePhoneNumber: "",
        storeAddress: "",
        relation: "partner",
    })

    const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
    const schema = yup.object().shape({
        storeNumber: yup.string().required(),
        storeName: yup.string().required(),
        storeEmail: yup.string().email().required(),
        storePhoneNumber: yup.string().matches(phoneRegExp, 'storePhoneNumber is not valid'),
        storeAddress: yup.string().required(),
        relation: yup.string().required(),
        createdOn: yup.date().default(function () {
            return new Date();
        }),
    })

    const submitNewStoreObj = () => {
        schema.validate(data, { abortEarly: false })
            .then((validatedData) => { 
                mutate({
                    variables: {
                        store: {
                            ...validatedData,
                            user: {
                                email: currentUser.email
                            }
                        }
                    },
                    onCompleted: async () => { 
                        await client.refetchQueries({
                            include: ["getUserData"],
                        });
                    }
                })

                setData({
                    storeName: "",
                    storeEmail: "",
                    storePhoneNumber: "",
                    storeId: "",
                    storeAddress: "",
                    relation: "partner",
                });
                setErrors([])
                onHide();
            }).catch(error => { 
                console.log("Error creating store: ", {error})
                setErrors(error.errors)
                toast()
            })

    };


    const storeNameOnChange = (e) => setData(current => ({ ...current, [e.target.name]: e.target.value }))
    const onEmailChange = (e) => setData(current => ({ ...current, [e.target.name]: e.target.value }))
    const onPhoneChange = (e) => setData(current => ({ ...current, [e.target.name]: e.target.value }))

    const addressChangeHandler = useCallback((addressObject) => {
        if (Object.keys(addressObject).length) {
            const formattedAddress = addressObject.formatted_address
            const number = formattedAddress?.split(" ")[0]?.match(/\d+/g) ?
                formattedAddress?.split(" ")[0]?.match(/\d+/g)[0] :
                formattedAddress?.split(" ")[1]?.match(/\d+/g)[0]
            setData(current => ({
                ...current,
                storeNumber: number,
                storeAddress: formattedAddress,
            }))

        } else {
           toast("There was an error while reading Store Address, please contact support to get help with entering your store", {
                type: 'error',
            })
        }
    }, [])
    
    const onRelationChange = (value) => setData(current => ({ ...current, relation: value }))

    const handleFocus = useCallback(() => setErrors([]), [])
    

    return (
        <Fragment>
            <Dialog
                open={show}
                handler={() => { }}
            >
                <DialogHeader>
                    <div className="flex flex-col">
                        <h5 className="text-gray-800">
                            Enter business information:
                        </h5>
                    </div>
                </DialogHeader>
                <DialogBody>
                    <form>
                        <div className="mb-2">
                            <Input
                                label="Store Name"
                                name="storeName"
                                onFocus={handleFocus}
                                onChange={storeNameOnChange}
                                error={errors.some(error => error.includes('storeName'))}
                            />
                        </div>
                        <div className="mb-2">
                            <Input
                                label="Store Email"
                                name="storeEmail"
                                onFocus={handleFocus}
                                onChange={onEmailChange}
                                error={errors.some(error => error.includes('storeEmail'))}
                            />
                        </div>
                        <div className="mb-2">
                            <Input
                                label="Phone Number"
                                name="storePhoneNumber"
                                onFocus={handleFocus}
                                onChange={onPhoneChange}
                                error={errors.some(error => error.includes('storePhoneNumber'))}
                            />
                        </div>
                        <div className="flex items-center mt-3 py2">
                            <h1 className="font-semibold">
                                Store Number:
                            </h1>
                            <span className="ml-2 h-6 text-xl font-semibold text-pink-500">
                                {data.storeNumber}
                            </span>
                        </div>
                        <p className="text-xs mb-2">
                            This number is how this Location is represented in your system.
                            This number is autogenerated out of the Address field, but can be changed at any time, just
                            click on the Location once it's added and
                            edit/correct any info you want.
                        </p>

                        <div className="my-2">
                            <LookupInputWrap
                                selectedValue={data.storeAddress}
                                addressChangeHandler={addressChangeHandler}
                                label="Business Address Lookup"
                                onFocus={handleFocus}
                                error={errors?.some(error => error.includes('storeAddress'))}
                            />
                        </div>
                        <Select
                            label="Location Relation"
                            name="relation"
                            onChange={onRelationChange}
                        >
                            <Option value="partner">Partner</Option>
                            <Option value="sister">Sister</Option>
                        </Select>
                        <div className="text-xs">
                            This is the relationship between this Location and your business. Choose Sister Location to apply Retail price to this Location.
                        </div>
                    </form>
                </DialogBody>

                <DialogFooter>
                    <Button variant="gradient" color="gray" onClick={onHide}>
                        Cancel
                    </Button>
                    <Button variant="gradient" onClick={submitNewStoreObj} className="ml-3">
                        Add Location
                    </Button>
                </DialogFooter>
            </Dialog>

            <ToastContainer
                position="bottom-right"
                theme="light"
                autoClose={8000}
                closeOnClick={true} 
            />
        </Fragment>
    );

}

export default AddStoreModalForm;
