import React from 'react'
import { GoCheck } from "react-icons/go";
import { GoX } from "react-icons/go";
import { Tooltip } from "@material-tailwind/react";
import { useMutation } from "@apollo/client";
import {  } from "@apollo/client/";
import { UPDATE_STORE } from "apollo/mutations/updateStore";
import { useApolloClient } from '@apollo/client';


const EditView = ({ field, store, setEditMode }) => {
    const client = useApolloClient();
    const [inputValue, setInputValue] = React.useState(store[StoreInfoContainerFieldDataKey[field]])

    const [mutate] = useMutation(UPDATE_STORE, {
        onCompleted: () => setEditMode(current => !current),
    })

    const writeParentState = () => { 
        mutate({
            variables: {
                input: {
                    filter: {id: store.id}, 
                    set: {
                        [StoreInfoContainerFieldDataKey[field]]: inputValue
                    }
                }
            },
            onCompleted: async () => { 
                await client.refetchQueries({
                    include: ["getUserData", "getUserJobs"],
                });
            }
        })
        
    }

    const evaluateKey = (e) => {
        if (e.keyCode === 13) {
            writeParentState()
        }
    };

    return (
        <div className="w-full flex justify-between items-center my-3">
            <span style={{ fontWeight: "bold" }}>{`${field} :`}</span>
            <span className="flex items-center grow ml-3">
                <input
                    type="text"
                    className="w-full h-8 rounded-lg px-2 text-sm text-right border border-1 border-gray-100 mr-2"
                    value={inputValue}
                    onChange={e => setInputValue(e.target.value)}
                    onKeyDown={evaluateKey}
                />
                <Tooltip content="Apply changes">
                    <div style={{ display: "inline-block" }}>
                        <GoCheck
                            className="cursor-pointer text-gray-500 hover:text-green-500 mr-1"
                            onClick={writeParentState}
                        />
                    </div>
                </Tooltip>
                <Tooltip content="Discard changes">
                    <div
                        style={{ display: "inline-block" }}
                        onClick={() => setEditMode(current => !current)}
                    >
                        <GoX className="cursor-pointer text-gray-500 hover:text-pink-500 mr-2" />
                    </div>
                </Tooltip>
            </span>
        </div>
    )
}

export default EditView

const StoreInfoContainerFieldDataKey = {
    "Store Name": "storeName",
    "Store Address": "storeAddress",
    "Store Email": "storeEmail",
    "Store Phone Number": "storePhoneNumber",
    "Store Number": "storeNumber",
}