import { gql } from '@apollo/client';

export const ADD_USER_MUTATION = gql`
  mutation addUser($user: [AddUserInput!]!) {
  addUser(input: $user) {
    user {
      id
      businessName
      businessAddress
      email
      phoneNumber
      createdOn
    }
  }
}
`;
