import {
    AccountPage,
    CompleteJob,
    Dashboard,
    Invoice,
    Photography,
    Queries,
    ReceiveJob,
    PriceCodes,
    Stores,
    Delivery,
} from "pages";
import Inventory from "pages/Inventory/Inventory";

export const privateRoutes = [
    {
        component: Dashboard,
        path: '/dashboard',
    },
    {
        component: AccountPage,
        path: '/shop-account',
    },
    {
        component: Inventory,
        path: '/Inventory',
    },
    {
        component: CompleteJob,
        path: '/complete-job',
    },
    {
        component: Invoice,
        path: '/invoice',
    },
    {
        component: Delivery,
        path: '/delivery',
    },
    {
        component: Photography,
        path: '/photography',
    },
    {
        component: Queries,
        path: '/queries',
    },
    {
        component: ReceiveJob,
        path: '/receive-job',
    },
    {
        component: PriceCodes,
        path: '/price-codes',
    },
    {
        component: Stores,
        path: '/locations',
    },
]