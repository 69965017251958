import React from 'react'
import Card from "../Card";
import RenderIf from "../RenderIf/RenderIf";
import { GridLoader } from "react-spinners";

const AmountDisplayCard = ({ amount, amountColorClass = 'text-light-green-500', label, className, loading }) => {
  return (
    <Card className={className}>
      <RenderIf isTrue={!loading}>
        <div className={`text-4xl font-black ${amountColorClass}`}>
            {amount}
        </div>
      </RenderIf>
      <RenderIf isTrue={loading}>
        <GridLoader color="#94A3B8" size={12} />
      </RenderIf>
        <p className='text-xs text-blue-gray-500'>
            {label}
        </p>
    </Card>

  )
}

export default AmountDisplayCard