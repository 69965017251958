
import { gql } from '@apollo/client';

export const ADD_REPAIR_CODE = gql`
mutation addRepairCode($input: [AddRepairCodeInput!]!) {
  addRepairCode(input: $input) {
    numUids
  }
}
`;

// {
// 	"input": {
//     "entity": "gold",
//     "code": 710,
//     "description": "New added code number 3",
//     "wholeSalePrice": 1.00,
//     "retailPrice": 1.00,
//     "group": {
//       "id": "0xfffd8d6ab2e2dcac"
//     }
//   }
// }
