import { gql } from '@apollo/client';

// Variables object to try this query on the DGraph playground
// {
//   "id": "0xfffd8d6ab2e3534f",
// 	"first": 10,
//   "offset": 0,
//   "jobNumber": "1664774689322",
//   "customerName": "",
//   "customerPhoneNumber": "",
//   "customerEmail": "",
//   "status": 4,
//   "receivedFromDate": "2000-01-26T04:00:00Z",
//   "receivedToDate": "2023-05-24T04:00:00Z",
//   "promiseFromDate": "2000-10-26T04:00:00Z",
//   "promiseToDate": "2023-05-01T00:00:00Z",
//   "itemValueMin": 0,
//   "itemValueMax": 50000
// }

export const GET_PAGINATED_JOBS = gql`
query getUserJobs(
  $id: ID!, 
  $first: Int!, 
  $offset: Int!, 
  $filters: [JobFilter]
) {
  getUserPaginatedJobs: getUserJobs(id: $id) {
    id
    userJobs(
      first: $first, 
      offset: $offset, 
      filter: { 
        and: $filters
      }, 
      order: { desc: createdOn }
    ) {
      id
      createdOn
      jobNumber
      customerName
      customerEmail
      customerPhoneNumber
      status
      itemValue
      photosLocalIds
      moneyCharged
      market
      promiseDate
      store {
        id
        storeNumber
      }
    }
  }
  queryUserJobs(filter: { id: [$id] }) {
    userJobsAggregate {
      count
    }
  }
}
`;

// eq	equals to
// lt	less than
// le	less than or equal to
// gt	greater than
// ge	greater than or equal to