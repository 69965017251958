import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import { getAuth, sendEmailVerification, signOut } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import { FaInfoCircle } from "react-icons/fa";
import { useWindowSize } from "hooks";
import Confetti from 'react-confetti'

export default function NewRegistrationLandingPage() {
    const history = useHistory();
    const { width, height } = useWindowSize()

    const auth = getAuth();
    const [error, setError] = useState("")
    const [isSending, setIsSending] = useState(false)

    const goBackToLogin = async() => {
        await signOut(auth).then(() => {
            history.push('/authenticate')
        })
    }

    const resendVerification = async (e) => {
        e.preventDefault();
        setIsSending(true)
        if (auth.currentUser.emailVerified) {
            console.log('Email is verified');
        } else {
            await sendEmailVerification(auth.currentUser)
                .then(() => {
                    console.log("Emaill sent!")
                    setError("")
                    setIsSending(false)
                    toast('Success! Email has been sent, please check also the Spam folder.', {
                        type: 'success'
                    })
                })
                .catch((error) => { 
                    console.log(error)
                    console.log(error.message)
                    setError(error.message)
                    setIsSending(false)
                });
        }
    };


    React.useEffect(() => {
        if (error) {
            toast('An error has ocurred, please try again later.', {
                type: 'error',
            })
            setTimeout(() => {
                setError(false)
            }, 2000);
        }
    }, [error])

    return (
        <div className="w-full h-screen bg-gradient-to-r from-blue-900 to-purple-500">
            <Confetti
                width={width}
                height={height}
            />
            <div className="container mx-auto pt-32 flex flex-col items-center">
                <h2 className="text-2xl text-white font-semibold mb-12">Congratulations !</h2>

                <div className="w-1/2 flex justify-between items-start p-6 rounded-xl bg-gradient-to-r from from-green-500 to-green-800">
                    <FaInfoCircle className="text-white text-2xl mr-3 mt-1" />
                    <div>
                        <h1 className="text-white font-bold">Your account is registered already.</h1>
                        <p className="text-white text-xs">
                            A verification email has been sent to you, please finish now the
                            last step and verify your email by clicking on the link!
                        </p>
                    </div>
                </div>

                <div className="flex items-center mt-6">
                    <Button
                        variant="gradient"
                        color="blue-gray"
                        className="mt-3 mr-3"
                        onClick={resendVerification}
                    >
                        {isSending ? `Sending ...` : `Send me another verification email`}
                    </Button>

                    <Button
                        variant="gradient"
                        className="mt-3"
                        onClick={goBackToLogin}
                    >
                        Go Back To Login
                    </Button>
                </div>
            </div>
                <ToastContainer
                    position="bottom-right"
                    theme="light"
                    autoClose={8000}
                    closeOnClick={true} 
                    className="text-xs"
                />
        </div>
    );
}
