import React from "react";
import { FaInfoCircle } from "react-icons/fa";

export default function ForgottenSent({ sendingEmail, forgottenPassToggling }) {
    const showingCompToResendEmail = (e) => {
        e.preventDefault();
        sendingEmail();
    };

    return (
        <div className="w-96 flex flex-col space-y-6">
            <div className="w-full flex justify-between items-start p-6 rounded-xl bg-gradient-to-r from from-green-500 to-green-800">
                <FaInfoCircle className="text-white text-6xl mr-3 -mt-4" />
                <div>
                    <h1 className="text-white font-bold">Success!</h1>
                    <p className="text-white text-xs">
                        An email has been sent with instructions to Reset your password. 
                        Please review your spam folder, as some times email servers flag automatic emails.
                    </p>
                </div>
            </div>

            <div className="w-full flex justify-between">
                <h1 className="text-sm cursor-pointer hover:text-pink-500" onClick={showingCompToResendEmail}>
                    Resent email
                </h1>
                <div className="text-sm cursor-pointer hover:text-pink-500" onClick={forgottenPassToggling}>
                    Go back to Login
                </div>
            </div>
        </div>
    );
}
