import React, { Fragment, useState, useRef, useEffect } from 'react'
import { RenderIf } from "components";
import { useMutation, useApolloClient } from "@apollo/client";
import { UPDATE_REPAIR_CODE } from "apollo/mutations/updateRepairCode";
import { BeatLoader } from "react-spinners";

const DescriptionContainer = ({ code }) => {
    const client = useApolloClient()
    const inputElement = useRef(null)

    const [editMode, setEditMode] = useState()
    const [newDescription, setNewDescription] = useState(code?.description)

    const [mutate, { loading }] = useMutation(UPDATE_REPAIR_CODE)

    const handleDoubleClick = () => { 
        setEditMode(true)
    }

    const handleCancel = () => { 
        setEditMode(false)
        setNewDescription('')
    }

    const submitNewDescriptionToDb = () => {
        setEditMode(false)
        mutate({
            variables: {
                input: {
                    filter: { id: code.id }, 
                    set: { description: newDescription }
                }
            },
            onCompleted: async () => {
                await client.refetchQueries({
                    include: ["getUserRepairCodes"],
                });
            }
        })
    }

    const evaluateKey = (e) => {
        if (e.keyCode === 13) {
            submitNewDescriptionToDb()
        } else if (e.keyCode === 27) {
            handleCancel()
        }
    };

    useEffect(() => { 
        if (editMode) {
            inputElement.current.focus()
        }
    }, [editMode])

    return (
        <Fragment>
            <RenderIf isTrue={!editMode}>
                <RenderIf isTrue={loading}>
                    <div className='w-full'>
                        <BeatLoader color="#94A3B8" size={9} className="ml-1" />
                    </div>
                </RenderIf>
                <RenderIf isTrue={!loading}>
                    <div
                        className="w-full text-sm"
                        onDoubleClick={handleDoubleClick}
                    >
                        {code.description}
                    </div>
                </RenderIf>
            </RenderIf>

            <RenderIf isTrue={editMode}>
                <input
                    ref={inputElement}
                    type="text"
                    className="w-full h-8 p-1 mr-3 border border-1 border-blue-gray-100 rounded-lg px-2 text-sm"
                    value={newDescription}
                    onChange={(e) => setNewDescription(e.target.value)}
                    onKeyDown={evaluateKey}
                    onBlur={handleCancel}
                />
            </RenderIf>

        </Fragment>

    )
}

export default DescriptionContainer