import React from "react";

export default function PriceDisplay({ code, market, toggleEditMode }) {
    return (
        <span
            onDoubleClick={() => toggleEditMode()}
            className="flex">
            <span className="mr-1 text-gray-400">$</span>
            <span className="font-semibold text-gray-700">
                {market === "wholesale" ? code.wholeSalePrice.toFixed(2) : code.retailPrice.toFixed(2)}
            </span>
        </span>
    );
}
