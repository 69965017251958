import React from 'react'
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";

const ConfirmationModal = ({ isOpen, setIsOpen, headerText, message, callback, loadingCallback }) => (
        <Dialog open={isOpen} handler={setIsOpen}>
            <DialogHeader>
                <h1 className='font-bold'>
                    {headerText}
                </h1>
            </DialogHeader>
            <DialogBody divider className='py-8 pl-6'>
                {message}
            </DialogBody>
            <DialogFooter>
                <Button
                    variant="text"
                    color="light-blue"
                    onClick={() => setIsOpen(false)}
                    className="mr-1"
                    disabled={loadingCallback}
                >
                    <span>Cancel</span>
                </Button>
                <Button
                    variant="gradient"
                    color="deep-purple"
                    onClick={callback}
                    className="ml-2"
                    disabled={loadingCallback}
                >
                    <span>
                        {loadingCallback ? 'Working on it...' : 'Confirm'}
                    </span>
                </Button>
            </DialogFooter>
        </Dialog>
    )

export default ConfirmationModal